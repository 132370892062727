import React, { FC, useEffect, useRef, useState } from "react";
import { debounce } from "lodash";

type TextTypeComponentProp = {
  questionData: any;
  response: (data: {
    question: string;
    options: string[];
    correctAnswer: string;
    questionType: string;
    answerType: string;
  }) => void;
  readOnly?: boolean;
  onQuestionsCleared: () => void;
};

const EditTextTypeComponent: FC<TextTypeComponentProp> = ({
  response,
  questionData,
  readOnly,
}) => {
  const [question, setQuestion] = useState(questionData.question);
  const [options, setOptions] = useState(questionData.options);
  const [selectedOptions, setSelectedOptions] = useState<any>(
    questionData.correctAnswer
  );
  const questionId = questionData._id || "";
  const editable = questionData?.isEditable;
  const categoryId = questionData.category_id;
  const mapId = questionData?.mapId;

  useEffect(() => {
    setQuestion(questionData?.question);
    setOptions(questionData?.options);
    setSelectedOptions(questionData?.correctAnswer);
  }, [questionData]);

  useEffect(() => {
    if (!options.includes(questionData.correctAnswer)) {
      setSelectedOptions(undefined);
    }
  }, [options, questionData.correctAnswer]);

  const responseRef = useRef(response);

  useEffect(() => {
    responseRef.current = response;
  }, [response]);

  useEffect(() => {
    const debouncedOutputData = debounce((data: any) => {
      responseRef.current(data);
    }, 300);

    const data = {
      question,
      options,
      correctAnswer: selectedOptions || "",
      questionType: "TEXT",
      answerType: "TEXT",
      _id: questionId,
      isEditable: editable,
      category_id: categoryId,
      mapId,
    };
    debouncedOutputData(data);

    // Cleanup function to cancel debounce on unmount
    return () => {
      debouncedOutputData.cancel();
    };
  }, [
    question,
    options,
    selectedOptions,
    questionId,
    editable,
    categoryId,
    mapId,
  ]);

  const handleCheckboxChange = (index: number) => {
    setSelectedOptions(options[index]);
  };

  const handleChangeOption = (index: number, value: string) => {
    const newOptions = [...options];
    newOptions[index] = value;
    setOptions(newOptions);
  };

  return (
    <div className="w-full h-full ">
      <hr className="border-1 mt-2" />
      {/* Question Section */}
      <section className="border xl:mt-[40px] lg:mt-[20px] mt-8 border-[#D7D7D7] p-2 rounded-[10px]">
        <textarea
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          className="text-[#000] font-[inter] lg:text-[18px] text-[16px] lg:font-[400] leading-normal ml-[40] mr-[10] w-full max-h-[60px] border resize-none placeholder:text-zinc-400 placeholder:text-lg placeholder:font-normal flex items-center box-border pt-4 pb-5 px-2 overflow-auto border-none focus:outline-none placeholder:items-center no-scrollbar"
          placeholder="Question"
          readOnly={readOnly}
        />
      </section>

      {/* options section*/}
      <section className="space-y-4 w-full lg:mt-[32px] mt-[24px] gap-2 text-[#000] font-[inter] lg:text-[18px] text-[16px]  leading-normal ">
        <h2 className="text-xl font-semibold mb-4">Options</h2>
        {options?.map((option: any, index: number) => {
          return (
            <div
              key={index}
              className="relative flex items-center border border-[#0A687426l] bg-[#1FC4D903] lg:my-0 my-2 rounded-[10px] p-1"
            >
              <div className="w-[90%] flex">
                <textarea
                  className="flex-1 flex items-center flex-wrap pt-[10px] pb-2 px-2 border-none lg:text-[18px] text-[16px] h-[50px] font-[inter] lg:font-[400] resize-none rounded-[10px] placeholder:text-zinc-400 placeholder:text-lg placeholder:font-normal focus:outline-none no-scrollbar"
                  placeholder={`Option ${index + 1}`}
                  value={option}
                  onChange={(e) => handleChangeOption(index, e.target.value)}
                  readOnly={readOnly}
                />
              </div>
              <div className="absolute top-0 right-0 w-[10%] flex items-center h-full justify-center">
                <input
                  className={`rounded-[10px] border-[#0000002B] bg-white`}
                  type="checkbox"
                  checked={selectedOptions === option}
                  onChange={() => handleCheckboxChange(index)}
                  disabled={readOnly}
                />
              </div>
            </div>
          );
        })}
      </section>
    </div>
  );
};

export default EditTextTypeComponent;
