import React, { useEffect, useState } from "react";
interface DomainDisplayProps {
  link?: string; // Define the link prop as an optional string
}

const FullHttpLink: React.FC<DomainDisplayProps> = ({ link }) => {
  const [fullHttpAddress, setFullHttpAddress] = useState("");

  useEffect(() => {
    const protocol = window.location.protocol;
    const host = window.location.host;

    const fullAddress = `${protocol}//${host}${link || ""}`;
    setFullHttpAddress(fullAddress);
  }, [link]);

  return <p>{fullHttpAddress}</p>;
};

export default FullHttpLink;
