import React, { useEffect, useState, useRef } from "react";
import { io } from "socket.io-client";

import { hostGameMode } from "src/services/hostGameMode/hostGameMode.service";
import { useNavigate, useParams } from "react-router-dom";
import QuizTimer from "src/components/quizTimer/QuizTimer";
import FullHttpLink from "src/components/domainName/DomainName";
import events from "../websocket/EventsName";
import LoaderComponent from "src/components/loader/LoaderComponent";
import playerCount from "../../assets/playerCount.svg";
import copyClipboard from "../../assets/copyClipboard.svg";
import Navbar from "src/components/navBar/NavBar";
import { Helmet } from "react-helmet";

interface PlayerData {
  _id: string;
  name: string;
  socketId: string;
  avatarUrl: string;
}

const HostGameMode: React.FC = () => {
  const [showStartAlert, setShowStartAlert] = useState<boolean>(false);
  const [connectedPlayers, setConnectedPlayers] = useState<PlayerData[]>([]);
  const [getDetail, setGetDetail] = useState<any>({});

  const [timer, setTimer] = useState<boolean>(false);
  const [countdown, setCountdown] = useState<number>(3);
  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const { gameid } = useParams();
  const [name, setName] = useState<string | null>(null);
  const [generatedCode, setGeneratedCode] = useState("");
  const [copied, setCopied] = useState(false);
  const Slug = JSON.stringify(getDetail?.gameSettings?.slug || "").replace(
    /['"]/g,
    ""
  );

  const linkRef = useRef<HTMLDivElement>(null);

  sessionStorage.setItem("slug", Slug);

  useEffect(() => {
    if (getDetail?.gameSettings?.link) {
      const link = getDetail.gameSettings.link;
      const code = link.split("/").pop();
      setGeneratedCode(code);
    }
  }, [getDetail]);

  useEffect(() => {
    if (gameid) {
      hostGameMode(gameid)
        .then((data) => {
          setGetDetail(data);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [gameid]);

  useEffect(() => {
    if (connectedPlayers.length > 0) {
      setError("");
    }
  }, [connectedPlayers]);

  useEffect(() => {
    if (gameid && process.env.React_App_SERVER_URL) {
      const socket = io(process.env.React_App_SERVER_URL);

      socket.on(events.SOCKET_CONNECTING, () => {
        socket.emit(events.HOST_CONNECTING, {
          slug: getDetail?.gameSettings?.slug,
        });

        socket.on(events.PLAYERS_DATAS, (playersData: PlayerData[]) => {
          setConnectedPlayers(playersData);
        });
      });

      socket.on(events.GET_READY, () => {
        setTimer(true);
      });

      socket.on(events.START_COUNTDOWNS, (updatedCountdown) => {
        setCountdown(updatedCountdown);
      });

      socket.on(events.FOR_NAVIGATION, () => {
        navigate(`/host/result/${getDetail?.gameSettings?.slug}`);
      });

      return () => {
        socket.disconnect();
      };
    }
  }, [gameid, getDetail, navigate]);

  const startQuizHandler = () => {
    if (connectedPlayers.length === 0) {
      window.alert("No players have joined yet");
      return;
    }
    setShowStartAlert(true);
  };

  const copyLinkToClipboard = (type: "link" | "code") => {
    let textToCopy = "";

    if (type === "link") {
      const link = linkRef.current?.textContent;
      textToCopy = link || "";
    } else if (type === "code") {
      textToCopy = generatedCode;
    }

    if (textToCopy) {
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          setCopied(true);
          setTimeout(() => setCopied(false), 3000);
        })
        .catch((error) => console.error("Could not copy text: ", error));
    }
  };
  const startGame = () => {
    if (process.env.React_App_SERVER_URL) {
      const socket = io(process.env.React_App_SERVER_URL);
      const quizData = {
        slug: getDetail.gameSettings.slug,
        responseTime: getDetail.gameSettings.respondTime,
        restTime: getDetail.gameSettings.restTime,
        totalQuestions: getDetail.questionCount,
        connectedPlayersCount: connectedPlayers.length,
      };
      sessionStorage.setItem("quizData", JSON.stringify(quizData));

      socket.emit(events.HOST_START_QUIZ, quizData);
      socket.emit(events.SEND_QUESTION_COUNTS, quizData);
    }
    setShowStartAlert(false);
  };

  useEffect(() => {
    const storedName = localStorage.getItem("Name");

    if (storedName) {
      const nameValue = JSON.parse(storedName);
      setName(nameValue);
    }
  }, []);

  return (
    <div
    className="bg-[#192E3B]">
    <div className="max-w-screen-2xl mx-auto  w-full  bg-[#192E3B]">
      <Helmet>
        <title>Host Game Mode</title>
        <meta
          name="description"
          content="Host your quiz game and manage participants with Quiz Circles."
        />
      </Helmet>
      {isLoading ? (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50">
          <LoaderComponent />
        </div>
      ) : (
        <div className="w-full h-full overflow-y-auto font-[Poppins]">
          <div className="lg:flex">
            <div className="bg-[#192E3B] flex-col lg:w-[60%] lg:min-h-screen ">
              <div className="fixed px-[10px] lg:px-0  bg-[#192E3B] w-full">
                <Navbar hideItems={true} />
              </div>

              <div className="w-full flex justify-center lg:justify-start items-center lg:px-0 px-4">
                <div className="lg:ml-[60px] xl:ml-[100px] mt-[100px] lg:mt-[150px] mb-[9%] lg:mb-[20%] ">
                  <div>
                    <h1 className="text-white font-[Poppins] font-[600] lg:text-[48px] text-[40px] leading-normal mb-[16px]">
                      Hi, {name}
                    </h1>
                    <p className="text-[#95D6FF] font-[Poppins] text-[24px] lg:text-[25px] font-[400] leading-normal">
                      Please wait for participants to join ...
                    </p>
                  </div>
                  {/* // Copy  link */}
                  <h2 className="text-[#95D6FF] font-[Poppins] text-[24px] lg:text-[20px] font-[400] leading-normal mt-5 whitespace-nowrap">
                    Quiz Link
                  </h2>

                  <div className="flex flex-row w-full lg:w-auto h-[56px] bg-[#233D4E] rounded-[10px] mt-[11px]">
                    <div className="text-white font-normal w-full font-[Poppins] ml-[10px] flex items-center text-[16px] sm:text-[20px]">
                      <div
                        ref={linkRef}
                        className="w-[280px] sm:w-auto truncate"
                      >
                        <FullHttpLink link={getDetail?.gameSettings?.link} />
                      </div>
                    </div>
                    <button
                      className="bg-[#008AB6] w-[60px] lg:w-[64px] h-[56px] lg:h-[56px] rounded-[10px] flex items-center justify-center"
                      onClick={() => copyLinkToClipboard("link")}
                    >
                      <img src={copyClipboard} alt="copyClipboardImage" />
                    </button>
                  </div>

                  {/* // Copy  code */}

                  <h2 className="text-[#95D6FF] font-[Poppins] text-[24px] lg:text-[20px] font-[400] leading-normal mt-5 whitespace-nowrap">
                    Quiz Code
                  </h2>

                  <div className="flex flex-row w-full lg:w-auto h-[56px] bg-[#233D4E] rounded-[10px] mt-[10px]">
                    <div className="text-white font-normal w-full font-[Poppins] flex items-center justify-center text-[16px] sm:text-[20px]">
                      <div className="w-[280px] sm:w-auto truncate text-center">
                        {generatedCode}
                      </div>
                    </div>
                    <button className="bg-[#008AB6] w-[60px] lg:w-[64px] h-[56px] lg:h-[56px] rounded-[10px] flex items-center justify-center">
                      <img
                        src={copyClipboard}
                        alt="copyClipboardImage"
                        onClick={() => copyLinkToClipboard("code")}
                      />
                    </button>
                  </div>

                  {/* // start Quiz button */}
                  <div className="flex flex-col items-center mt-[49.5px]">
                    <button
                      className="w-full  h-[56px] flex items-center justify-center gap-[11.902px] flex-shrink-0 rounded-[5.951px] bg-[#008BB7]"
                      onClick={startQuizHandler}
                    >
                      <p className="text-white font-poppins text-[16px] md:text-[23px] font-bold">
                        Start Quiz
                      </p>
                    </button>
                    {error && (
                      <div className="mt-4">
                        <p className="text-red-500">{error}</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* right */}
            <div className="lg:w-[40%] lg:fixed lg:right-0 min-h-screen bg-[#1D3441] p-4 lg:p-0 ">
              <div className="lg:px-[30px] lg:mt-[39px] bg-[#1D3441]">
                <div className="px-[16px] py-[8px] items-center bg-[#233D4E] gap-[14px] rounded-[90px] inline-flex mb-[48px]">
                  <img src={playerCount} alt="img"></img>
                  <p className="text-white text-[16px] font-[Poppins] font-semibold">
                    {connectedPlayers.length}
                  </p>
                </div>
                <br />
                <div className="inline-flex flex-wrap items-start gap-[24px]">
                  {connectedPlayers.map((player, index) => (
                    <div
                      key={`${player._id}_${index}`}
                      className="flex w-auto h-auto p-[16px] items-start gap-[11px] rounded-[10px] border-[1px] border-1 border-solid border-[#95D6FF]"
                    >
                      <img
                        className="w-[26px] h-[26px] rounded-[26px] bg-cover bg-no-repeat bg-center"
                        src={player.avatarUrl}
                        alt="Avatar"
                      />
                      <div className="text-[#95D6FF] font-[Poppins] text-[16px] font-[400px] leading-normal">
                        {player.name}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <dialog open={timer}>
                <div className="fixed top-0 left-0 w-full items-center h-full flex justify-center lg:px-0 px-[16px]">
                  <QuizTimer countdown={countdown} />
                </div>
              </dialog>
            </div>
          </div>
        </div>
      )}

      {showStartAlert && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="lg:w-fit w-[80%] h-auto min-h-[150px] flex flex-col bg-[#1D3441] p-8 rounded-lg shadow-lg items-center justify-center ">
            <div className="flex items-center justify-center my-3 text-[16px] md:text-[18px] font-bold text-center text-white">
              Players won't be able join once the game is started. <br />
              Are you sure you want to start the game?
            </div>
            <div className="flex flex-row w-full gap-5 justify-center gap-x-2">
              <button
                className="flex gap-[10px] px-4 py-2 rounded-[12px] bg-[#008BB7] border border-none text-white items-center justify-center w-[100px]"
                onClick={startGame}
              >
                <h1 className="text-[20px] font-semibold">Start</h1>
              </button>
              <button
                className="flex gap-[10px] px-4 py-2 rounded-[12px] bg-[#008BB7] border border-none text-white items-center justify-center w-[100px]"
                onClick={() => setShowStartAlert(false)}
              >
                <h1 className="text-[20px] font-semibold"> Cancel</h1>
              </button>
            </div>
          </div>
        </div>
      )}
      {copied && (
        <div className="fixed bottom-5 left-1/2 transform -translate-x-1/2 bg-black text-white w-[60%] md:w-auto px-4 py-2 rounded-md shadow-lg z-50">
          Link copied to clipboard
        </div>
      )}
    </div>
    </div>
  );
};

export default HostGameMode;
