import React, { useState } from "react";
import { deleteGames } from "src/services/upcomingGames/upcomingGames.service";
import questionImg from "../../assets/question.svg";
import checkCircle from "../../assets/CheckCircle.svg";
import Delete from "src/assets/delete.svg";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { useLocation } from "react-router-dom";

interface TemplateCardProps {
  id: string;
  imageUrl: string;
  name: string;
  questions?: number;
  played?: number;
  date?: string;
  time?: string;
  amount?: string;
  superHost?: string;
  isHovered?: boolean;
  deletedId?: (id: string) => void;
  deleteLibrary?: boolean;
  is_free?: string;
  needDeletebutton?: boolean;
}

const TemplateCard: React.FC<TemplateCardProps> = ({
  id,
  imageUrl,
  name,
  questions,
  played,
  date,
  time,
  amount,
  isHovered,
  superHost,
  deleteLibrary,
  deletedId = () => {},
  is_free,
  needDeletebutton = true,
}) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const location = useLocation();
  const path = location.pathname;

  const handleDelete = () => {
    deleteGames(id)
      .then((data) => {
        deletedId(id);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // Helper function to format number greater than 1000 as '1k', '2k', etc.
  const formatNumber = (num?: number) => {
    if (num === undefined) return "";

    if (num > 1000) {
      return `${(num / 1000).toFixed(1)}K`;
    } else {
      return `${num}`;
    }
  };

  return (
    <div className="flex justify-between w-full">
      <div className="w-full h-[341px] rounded-lg overflow-hidden shadow-md">
        {/* Image Section */}
        <div>
          <img
            className="w-full h-[210px] object-cover rounded-t-lg bg-white"
            src={imageUrl}
            alt={name}
          />
        </div>
        {/* Content Section */}
        <div className="h-[131px] flex flex-col px-6 py-[28px] justify-between">
          <div className="flex items-center justify-start">
            <div className="flex-1 truncate">
              <p className="font-[Poppins] text-[20px] text-left truncate font-semibold leading-normal">
                {name}
              </p>
            </div>

            {path === "/home" && (
              <>
                {is_free === "paid" ? (
                  <div className="flex items-center justify-center pl-2">
                    <p className="font-[Poppins] font-extrabold text-[20px] text-[#B15BF5]">
                      {"$ " + amount}
                    </p>
                  </div>
                ) : (
                  <div className="flex items-center justify-center pl-2">
                    <Stack>
                      <Chip label="Free" color="primary" variant="outlined" />
                    </Stack>
                  </div>
                )}
              </>
            )}

            {deleteLibrary && (
              <button
                className="px-2 text-[20px]"
                onClick={(e) => {
                  e.stopPropagation();
                  deletedId(id);
                }}
              >
                <img
                  src={Delete}
                  alt="Delete item"
                  className="w-[35px] h-[35px]"
                />
              </button>
            )}
          </div>
          {/* Additional Information Section */}
          {questions !== undefined ? (
            <div className="flex justify-between items-end w-full font-[Poppins] font-medium">
              <div className="gap-2 flex">
                <img
                  src={questionImg}
                  alt="Questions preview"
                  className={`transition-transform duration-300 ${
                    isHovered ? "filter brightness-0 invert" : ""
                  }`}
                />
                <p className="inline">{questions} Questions</p>
              </div>
              {played !== 0 && (
                <div className="flex gap-2">
                  <img
                    src={checkCircle}
                    alt="Used indicator"
                    className={`transition-transform duration-300 ${
                      isHovered ? "filter brightness-0 invert" : ""
                    }`}
                  />
                  <p className="">{formatNumber(played)} Used</p>
                </div>
              )}
            </div>
          ) : (
            <>
              {date && time && (
                <div className="flex font-[poppins] justify-between items-center">
                  <p className="inline">🗓 {date}</p>
                  <p className="inline">⌛ {time}</p>
                  {needDeletebutton && (
                    <button
                      className="px-2 text-[20px]"
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowConfirmation(true);
                      }}
                    >
                      🗑
                    </button>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {/* Confirmation Modal */}
      {showConfirmation && (
        <div className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 font-[Poppins] z-50">
          <div className="lg:w-fit w-full h-auto min-h-[150px] flex flex-col md:max-w-[360px] min-w-[300px] px-2 md:px-4 bg-slate-50 rounded-lg shadow-lg py-2 items-center justify-center border border-slate-400">
            <h3 className="flex w-full items-center justify-center my-3 text-[16px] md:text-[18px] font-bold text-center text-black">
              Do you want to Delete?
            </h3>
            <div className="flex flex-row w-full justify-between gap-x-2">
              <button
                className="bg-slate-800 hover:bg-slate-500 rounded-[12px] bg-gradient-to-r from-[#1EC6DA] to-[#6F2A96] border border-none text-white  lg:text-[23px] text-[16px] font-bold hover:shadow-[0px_20px_20px_-10px_rgba(131,42,184,0.4)] hover:inner-shadow-[inset 0px 20px 20px -10px rgba(131,42,184,0.4)]  rounded-md text-white font-[poppins] text-base font-normal px-4 py-2 w-full "
                onClick={(e) => {
                  e.stopPropagation();
                  handleDelete();
                  setShowConfirmation(false);
                }}
              >
                Yes
              </button>
              <button
                className="bg-slate-800 hover:bg-slate-500 rounded-[12px] bg-gradient-to-r from-[#1EC6DA] to-[#6F2A96] border border-none text-white  lg:text-[23px] text-[16px] font-bold hover:shadow-[0px_20px_20px_-10px_rgba(131,42,184,0.4)] hover:inner-shadow-[inset 0px 20px 20px -10px rgba(131,42,184,0.4)]  rounded-md text-white font-[poppins] text-base font-normal px-4 py-2 w-full "
                onClick={(e) => {
                  e.stopPropagation();
                  setShowConfirmation(false);
                }}
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TemplateCard;
