import React, { useEffect, useState } from "react";
import Navbar from "../navBar/NavBar";
import { Helmet } from "react-helmet";

const faqData = [
  {
    question: "What types of quizzes can I create on this platform?",
    answer: {
      "Multiple Choice Quizzes":
        "Traditional quizzes where participants choose the correct answer from a list of options.",
      "True/False Quizzes":
        "Simple quizzes where participants decide whether statements are true or false.",
      "Picture Quizzes":
        "Visual-based quizzes where participants answer questions based on images.",
    },
  },
  {
    question: "Is it possible to add images to my quiz questions?",
    answer: "Yes",
  },
  {
    question: "Is it possible to add videos, or audio to my quiz questions?",
    answer: "No",
  },
  {
    question: "How do I share my quiz with others?",
    answer: {
      "Direct Link":
        "You will receive a unique URL once your quiz is published. You can share this link via email, social media, or any other platform to allow participants to access your quiz directly.",
    },
  },
  {
    question:
      "Is there a limit to the number of questions I can add to a quiz?",
    answer: "No, limit only applies for pre-defined quiz templates",
  },
  {
    question: "Are my quizzes and user data secure?",
    answer:
      "Yes, your quizzes and user data are secure on our platform. We take several measures to ensure the highest level of security",
  },
  {
    question: "Do you offer any templates or pre-made quizzes?",
    answer:
      "Yes, we offer a wide range of templates and pre-made quizzes to help you get started quickly and easily. Our templates cover various categories and purposes, including educational quizzes, personality quizzes, trivia quizzes, customer feedback surveys, employee training assessments, and health and wellness quizzes.",
  },
  {
    question: "What types of templates and pre-made quizzes do you offer?",
    answer: {
      "Educational Quizzes":
        "Templates designed for different subjects and grade levels, perfect for teachers and educators. These include math, science, history, language arts, and more.",
      "Personality Quizzes":
        "Fun and engaging templates that help users discover aspects of their personality based on their answers. Ideal for entertainment and social media engagement.",
      "Trivia Quizzes":
        "Ready-made trivia quizzes on various topics such as general knowledge, pop culture, sports, movies, and more. Great for casual fun and competition.",
      "Customer Feedback Surveys":
        "Templates tailored for businesses to gather feedback from their customers. These surveys help in understanding customer satisfaction and areas for improvement.",
      "Employee Training Assessments":
        "Pre-made quizzes to assess employee knowledge and skills. Useful for onboarding, compliance training, and ongoing professional development.",
      "Health and Wellness Quizzes":
        "Templates focused on health, fitness, and wellness topics. These can be used by health coaches, gyms, and wellness programs.",
    },
  },
  {
    question: "Possible to edit or update a quiz after it's been published?",
    answer:
      "Yes, you can easily edit or update a custom-created quiz even after it has been published. Our platform is designed to provide maximum flexibility, allowing you to make changes to your quizzes whenever needed.",
  },
  {
    question: "What support options are available if I encounter issues?",
    answer: {
      " Email Support":
        "If you prefer, you can contact our support team via email. Send your inquiries to support@perfectzdigital.com, and a representative will get back to you within 24 hours.",
    },
  },
];


const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [scrolled, setScrolled] = useState(false);

  const toggleFAQ = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <main className="max-w-screen-2xl mx-auto  w-full">
  <Helmet>
        <title> FAQ</title>
        <meta
          name="description"
          content="Find answers to frequently asked questions about Quiz Circles."
        />
      </Helmet>
      <Navbar className={`bg-white ${scrolled ? "bg-white z-[99] shadow-[#ABB3DE] shadow-lg " : ""}`}   templateDetailsMobileView={false}
        showmenuIcon={true}/>
      <div className="bg-[#f8f9fc] overflow-y-auto w-full min-h-screen top-0 font-[poppins] text-justify flex justify-center lg:px-[250px]">
        <div className="w-full my-7 mx-5 lg:mx-0 md:mx-10 px-5 md:px-0 ">
          <h1 className="text-3xl font-semibold mb-6 text-center">FAQ</h1>
          <hr className="mb-8 border-gray-300" />
          {faqData.map((faq, index) => (
            <div key={index} className="mb-4">
              <button
                onClick={() => toggleFAQ(index)}
                className="w-full text-left py-4 text-[#333] font-medium text-[20px] hover:underline focus:outline-none transition duration-300 ease-in-out"
              >
                <div className="flex justify-between items-center">
                  <h2>{faq.question}</h2>
                  <span className="text-xl">{activeIndex === index ? "-" : "+"}</span>
                </div>
              </button>
              {activeIndex === index && (
                <div className="py-4 pl-5 pr-10 text-[18px] text-[#555]">
                  {typeof faq.answer === "string" ? (
                    <p>{faq.answer}</p>
                  ) : (
                    <ul className="list-disc pl-5">
                      {Object.entries(faq.answer).map(([key, value]) => (
                        <li key={key}>
                          <strong>{key}:</strong> {value}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              )}
              <hr className="my-4 border-gray-300" />
            </div>
          ))}
        </div>
      </div>
    </main>
  );
};

export default FAQ;
