import React from "react";
import PlayerLeaderboard from "../playerLeaderboard/PlayerLeaderboard";
import Navbar from "src/components/navBar/NavBar";

const ShareLeaderboard = () => {
  return (
    <div className="max-w-screen-2xl mx-auto  w-full">
      <Navbar
        hideItems={true}
        className="bg-[#192E3B] z-10 lg:px-0 px-[15px] "   />
      <div className="pt-10 bg-[#192E3B] ">
        <PlayerLeaderboard />
      </div>
    </div>
  );
};

export default ShareLeaderboard;
