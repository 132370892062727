import React, { FC, useEffect, useRef, useState } from "react";
import correct from "../../assets/correct.svg";
import wrong from "../../assets/wrong.svg";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import {
  getClickedDetails,
  getQuestions,
} from "src/services/playerQuizPage/playerQuiz.service";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { io } from "socket.io-client";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import events from "../websocket/EventsName";
import { useSpring, animated, config } from "react-spring";
import correctAudio from "../../audio/correctAudio.wav";
import inCorrectAudio from "../../audio/wrong1.mp3";
import questionAudio from "../../audio/question.wav";
import CryptoJS from "crypto-js";
import Navbar from "src/components/navBar/NavBar";
import MuteIcon from "../../assets/mute.png";
import UnmuteIcon from "../../assets/volume.png";

interface PlayerData {
  marks: number;
  time: number;
  _id: string;
  name: string;
  socketId: string;
  avatarUrl: string;
}

type params = {
  responseAdmin?: any;
};

const DemoQuiz: FC<params> = ({ responseAdmin }) => {
  const location = useLocation();
  const path = location.pathname;
  const [correctAnswerEmitted, setCorrectAnswerEmitted] = useState<boolean>();
  const [trueCount, setTrueCount] = useState(0);
  const [falseCount, setFalseCount] = useState(0);
  const [marks, setMarks] = useState(0);
  const [cumulativeTime, setCumulativeTime] = useState<number>(0);
  const [countdown, setCountdown] = useState<any>();
  const [connectedPlayers, setConnectedPlayers] = useState<PlayerData[]>([]);
  const [gameSettings, setGameSettings] = useState<any>();
  const [questions, setQuestions] = useState<any>([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answer, setAnswer] = useState("");
  const [currentTimer, setCurrentTimer] = useState("questionTimer");
  const [showResult, setShowResult] = useState(false);
  const [selectedResult, setSelectedResult] = useState<boolean>();
  const [startTimer, setStartTimer] = useState(false);
  const [questionStartTime, setQuestionStartTime] = useState<number>(0);
  const { slug } = useParams();
  const [initialTimer, setInitialTimer] = useState(
    gameSettings?.respondTime || 0
  );
  const [lastCorrectAnswerTime, setLastCorrectAnswerTime] = useState<number>(0);
  const [marksAwarded, setMarksAwarded] = useState<number>(0);
  const [animatedPlayers, setAnimatedPlayers] = useState<PlayerData[]>([]);
  const [height, setHeight] = useState(0);
  const navigate = useNavigate();
  const [demoDetails, setDemoDetails] = useState<any>();
  const slugId = responseAdmin ? responseAdmin?.playerData?.slug : slug;
  const [questionCompleted, setQuestionCompleted] = useState(false); // Track if a question is completed
  const [isAutoNavigation, setIsAutoNavigation] = useState(false);

  const correctSoundRef = useRef(new Audio(correctAudio));
  const wrongSoundRef = useRef(new Audio(inCorrectAudio));
  const newQuestionSoundRef = useRef(new Audio(questionAudio));
  const hasPlayed = useRef(false);
  const [isMuted, setIsMuted] = useState(true);

  const selectAnswer = (selectedOption: any) => {
    setAnswer(selectedOption);
  };
  useEffect(() => {
    if (currentQuestion > 0) {
      setQuestionCompleted(true);
    }
  }, [currentQuestion]);

  useEffect(() => {
    if (!responseAdmin) {
      const demoResponse = sessionStorage.getItem("demoResponse") || "";
      const demoResponseJson = demoResponse ? JSON.parse(demoResponse) : null;
      setDemoDetails(demoResponseJson);
      const { respondTime, restTime } = demoResponseJson?.gameSetting;
      const timeDetails = { respondTime, restTime };
      setGameSettings(timeDetails);
    } else {
      setDemoDetails(responseAdmin);
      sessionStorage.setItem(
        "playerId",
        JSON.stringify(responseAdmin?.playerData?._id)
      );
      sessionStorage.setItem(
        "slug",
        JSON.stringify(responseAdmin?.playerData?.slug)
      );
      const { respondTime, restTime } = responseAdmin.gameSettings;
      const timeDetails = { respondTime, restTime };
      setGameSettings(timeDetails);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  // Function to update height based on available space- leaderboard
  useEffect(() => {
    const updateHeight = () => {
      const windowHeight = window.innerHeight;
      const container = document.getElementById("leaderboard-container");
      if (container) {
        const offsetTop = container.offsetTop;
        const newHeight = windowHeight - offsetTop - 8;
        setHeight(newHeight);
      }
    };
    updateHeight();

    // Event listener for window resize
    window.addEventListener("resize", updateHeight);

    // Cleanup
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  useEffect(() => {
    if (!isMuted) {
      if (currentTimer === "respondTime") {
        questionInitializeAudio();
      }
    }
    // eslint-disable-next-line
  }, [currentTimer]);

  // Play sound function outside of useEffect
  const playSound = () => {
    if (!isMuted) {
      if (showResult && selectedResult) {
        correctSoundRef.current.play().catch((error) => {
          console.error("Correct sound play failed:", error);
        });
      } else if (showResult && !selectedResult) {
        wrongSoundRef.current.play().catch((error) => {
          console.error("Wrong sound play failed:", error);
        });
      }
    }
  };
  useEffect(() => {
    if (!isMuted) {
      if (showResult && !hasPlayed.current) {
        playSound();
        hasPlayed.current = true;
      }
    }
    // eslint-disable-next-line
  }, [showResult, selectedResult]);

  const questionInitializeAudio = () => {
    if (!isMuted) {
    if (currentTimer === "respondTime") {
      newQuestionSoundRef.current.play().catch((error) => {
        console.error("New question sound play failed:", error);
      });
    }}
  };

  useEffect(() => {
    let playerId: any;
    if (showResult && answer === "") {
      if (demoDetails) {
        playerId = demoDetails?.playerData?._id;
      } else {
        const userInfo = JSON.parse(sessionStorage.getItem("userInfo") || "{}");
        playerId = userInfo?.user?._id;
      }
      const questionId = questions[currentQuestion]?.question_id;
      const body = {
        playerId,
        option: -1,
        slug: slugId,
        demo_slug: slug,
        questionId,
        correctAnswer: false,
      };
      getClickedDetails(body)
        .then((response) => {})
        .catch((error) => {
          console.error(error);
        });
    }
  }, [
    showResult,
    answer,
    cumulativeTime,
    currentQuestion,
    questions,
    slug,
    slugId,
    demoDetails,
  ]);

  useEffect(() => {
    if (startTimer) {
      setQuestionStartTime(Date.now());
    }
  }, [startTimer, currentQuestion]);

  const handleExitQuiz = () => {
    // Emit a socket event to notify the server that the super host wants to exit
    if (process.env.React_App_SERVER_URL) {
      const socket = io(process.env.React_App_SERVER_URL);
      socket.emit("STOP", { slugId });

      // Navigate to the results page
      navigate(`/demo/player/result/${slug}`);
    }
  };

  const handleClick = (option: number, questionId: string, answer: string) => {
    let playerId: any;
    if (demoDetails) {
      playerId = demoDetails?.playerData?._id;
    } else {
      const userInfo = JSON.parse(sessionStorage.getItem("userInfo") || "{}");
      playerId = userInfo?.user?._id;
    }
    const timeMs = Date.now() - questionStartTime;
    const time = timeMs / 1000;
    const respondTimePercentage = (time / initialTimer) * 100;

    // Determine the marks based on the response time
    let marksAwarded = 100;
    switch (true) {
      case respondTimePercentage <= 20:
        marksAwarded = 150;
        break;
      case respondTimePercentage <= 40:
        marksAwarded = 140;
        break;
      case respondTimePercentage <= 60:
        marksAwarded = 130;
        break;
      case respondTimePercentage <= 80:
        marksAwarded = 120;
        break;
      default:
    }
    setMarksAwarded(marksAwarded);

    const correctAnswer =
      questions[currentQuestion]?.question.correctAnswer === answer;
    if (correctAnswer) {
      setSelectedResult(true);
      setCumulativeTime((prevCumulativeTime) => prevCumulativeTime + time);
    } else {
      setSelectedResult(false);
      marksAwarded = 0;
    }
    const body = {
      playerId,
      option,
      slug: slugId,
      demo_slug: slug,
      questionId,
      time,
      correctAnswer,
    };

    getClickedDetails(body)
      .then((response) => {})
      .catch((error) => {
        console.error(error);
      });

    // Update the marks
    setMarks((prevMarks) => prevMarks + marksAwarded);
  };

  useEffect(() => {
    if (process.env.React_App_SERVER_URL) {
      const socket = io(process.env.React_App_SERVER_URL);
      if (socket) {
        socket.on(events.SOCKET_CONNECTING, () => {
          let userInfo: any;
          let avatartURL: any;
          if (demoDetails) {
            userInfo = demoDetails?.playerData;
            avatartURL = demoDetails?.playerData?.avatarUrl;
          } else {
            userInfo = JSON.parse(sessionStorage.getItem("userInfo") || "{}");
            avatartURL = JSON.parse(sessionStorage.getItem("url") || "{}");
          }

          if (userInfo) {
            socket.emit(events.SEND_CLIENT_DATA, {
              _id: userInfo?.user?._id || userInfo?._id,
              name: userInfo?.user?.name || userInfo?.name,
              avatarUrl: avatartURL,
              slug: slugId,
            });
          }
        });
        socket.on(events.PLAYERS_DATAS, (playersData: PlayerData[]) => {
          setConnectedPlayers(playersData);
        });
        socket.on(events.QUIZ_ROOM, (joinedRoom) => {
          socket.on(
            events.REST_RESPONSE_SIGNAL,
            (updatedCountdown, currentTimer, index) => {
              setCurrentTimer(currentTimer);
              if (currentTimer === "respondTime") {
                setInitialTimer(gameSettings?.respondTime);
                setShowResult(false);
                setCurrentQuestion(index);
                if (updatedCountdown === 10) {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }
                hasPlayed.current = false;
              } else {
                setInitialTimer(gameSettings?.restTime);
                setShowResult(true);
                setCurrentQuestion(index);
              }

              setCountdown(updatedCountdown);

              socket.on("afterCounts", (data) => {
                setIsAutoNavigation(true);
                if (path === `/demo/${slug}`) {
                  navigate(`/player/result/${slug}`);
                } else {
                  navigate(`/demo/player/result/${slug}`);
                }
                // Reset back to manual after navigation
                setTimeout(() => {
                  setIsAutoNavigation(false);
                }, 1000);
              });
            }
          );
        });

        socket.on("NewEventAfterStop", (data) => {
          navigate(`/player-leaderboard/${slug}`);
        });
        return () => {
          socket.off(events.REST_RESPONSE_SIGNAL);
          socket.disconnect();
        };
      }
    }
    const handleBeforeUnload = (event: any) => {
      if (!isAutoNavigation) {
        localStorage.setItem("refreshFlag", "true");
        const confirmationMessage = "Are you sure you want to leave?";
        event.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameSettings, navigate, questions.length, demoDetails, path]);

  window.addEventListener("load", function () {
    const refreshFlag = localStorage.getItem("refreshFlag");

    if (refreshFlag) {
      localStorage.removeItem("refreshFlag");
      setTimeout(function () {
        window.location.href = `https://staging.quizcircles.com/home/quiz`;
      }, 1000);
    }
  });

  useEffect(() => {
    setAnswer("");
  }, [currentQuestion]);

  useEffect(() => {
    let playerId: any;
    if (
      currentTimer === "restTime" &&
      !correctAnswerEmitted &&
      process.env.React_App_SERVER_URL
    ) {
      if (demoDetails) {
        playerId = demoDetails?.playerData?._id;
      } else {
        const userInfo = JSON.parse(sessionStorage.getItem("userInfo") || "");
        playerId = userInfo.user._id;
      }

      const socket = io(process.env.React_App_SERVER_URL);

      if (questions[currentQuestion]?.question.correctAnswer === answer) {
        setSelectedResult(true);
        setTrueCount((prevTrueCount) => prevTrueCount + 1);
        socket.emit(events.QUESTIONS_RESPONSE, {
          playerId,
          questionId: questions[currentQuestion]._id,
          slug: slugId,
          demo_slug: slug,
          time: cumulativeTime,
          marks: marks,
          trueCount: trueCount + 1,
          falseCount,
        });

        setLastCorrectAnswerTime(cumulativeTime);
      } else {
        setSelectedResult(false);
        setFalseCount((prevFalseCount) => prevFalseCount + 1);
        socket.emit(events.QUESTIONS_RESPONSE, {
          playerId,
          questionId: questions[currentQuestion]._id,
          slug: slugId,
          demo_slug: slug,
          time: lastCorrectAnswerTime,
          marks: marks,
          trueCount,
          falseCount: falseCount + 1,
        });
      }
      setCorrectAnswerEmitted(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    demoDetails,
    currentTimer,
    answer,
    currentQuestion,
    questions,
    cumulativeTime,
    slug,

    marks,
    correctAnswerEmitted,
    trueCount,
    falseCount,
    lastCorrectAnswerTime,
  ]);

  // count correct and wrong answer
  useEffect(() => {}, [trueCount, falseCount]);

  useEffect(() => {
    setCorrectAnswerEmitted(false);
  }, [currentQuestion]);

  useEffect(() => {
    const secretKey = "quiz123456";
    if (slug) {
      getQuestions(slug)
        .then((data) => {
          setGameSettings(data?.gameSettings);
          setInitialTimer(data?.gameSettings?.respondTime);
          const decryptedQuestions = data?.questionDetails?.map(
            (question: any) => {
              const bytes = CryptoJS.AES.decrypt(
                question.question.correctAnswer,
                secretKey
              );
              const decryptValue = bytes.toString(CryptoJS.enc.Utf8);
              return {
                ...question,
                question: {
                  ...question.question,
                  correctAnswer: decryptValue,
                },
              };
            }
          );
          const decryptedData = {
            ...data,
            questionDetails: decryptedQuestions,
          };

          setQuestions(decryptedData.questionDetails);

          setStartTimer(true);
        })
        .catch((error) => {
          return error;
        });
    }
  }, [slug]);

  // window.addEventListener("beforeunload", function (event) {
  //   localStorage.setItem("refreshFlag", "true");

  //   const confirmationMessage = "Are you sure you want to leave?";
  //   event.returnValue = confirmationMessage;
  //   return confirmationMessage;
  // });

  // window.addEventListener("load", function () {
  //   const refreshFlag = localStorage.getItem("refreshFlag");

  //   if (refreshFlag) {
  //     localStorage.removeItem("refreshFlag");
  //     setTimeout(function () {
  //       window.location.href = `https://staging.quizcircles.com/home/quiz`;
  //     }, 1000);
  //   }
  // });

  // Disable the back button
  useEffect(() => {
    const disableBackButton = () => {
      window.history.pushState(null as any, null as any, document.URL as any);
      window.addEventListener("popstate", () => {
        window.history.pushState(null as any, null as any, document.URL as any);
      });
    };
    disableBackButton();
    return () => {
      window.removeEventListener("popstate", disableBackButton);
    };
  }, []);

  // Animations for player leaderboard
  const leaderboardSpring = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: config.default,
  });

  useEffect(() => {
    setAnimatedPlayers(connectedPlayers);
  }, [connectedPlayers]);

  const sortPlayers = (players: PlayerData[]) => {
    return players.sort((a, b) => {
      if (a.marks !== b.marks) {
        return b.marks - a.marks;
      }
      return a.time - b.time;
    });
  };

  // Update leaderboard animation when connected players change
  useEffect(() => {
    setAnimatedPlayers(connectedPlayers);
  }, [connectedPlayers]);

  return (
    <div className="w-full h-full bg-[#192E3B] font-[Poppins]">
      <div className="lg:flex bg-[#192E3B] min-h-screen overflow-y-auto">
        <header className="fixed-container fixed top-0  px-[20px] lg:px-0  lg:fixed bg-[#192E3B] w-[100%] z-10">
          {/* Navbar and Timer */}
          <Navbar hideItems={true} className="" />
          <button
            className="fixed bottom-10 right-10 bg-green-500 p-4 rounded-full"
            onClick={() => setIsMuted(!isMuted)}
          >
            <img
              className="w-6 h-6"
              src={isMuted ? MuteIcon : UnmuteIcon}
              alt="Toggle Mute"
            />
          </button>
          {/* Mobile view timer */}
          <div className="flex justify-between py-2">
            <div className="lg:mt-5 mt-0 bg-[#192E3B] lg:hidden flex">
              <p className="font-[Poppins] ml-[12px] text-white text-[16px] font-semibold leading-normal lg:py-0 py-[12px]">
                Question : {currentQuestion + 1} / {questions.length}
              </p>
            </div>
            {path !== `/demo/${slug}` && questionCompleted && (
              <div className="lg:mt-5 lg:hidden flex" onClick={handleExitQuiz}>
                <button className="flex justify-center items-center  px-4 py-2 rounded-[5px] lg:text-[20px] text-[16px] bg-[#008BB7] text-white ">
                  Exit Quiz
                </button>
              </div>
            )}
          </div>
          {/* linear progress bar */}

          <div className="lg:hidden flex">
            <Box sx={{ width: "100%" }}>
              <LinearProgress
                variant="determinate"
                value={(countdown / initialTimer) * 100}
                sx={{ width: "100%" }}
              />
            </Box>
          </div>
        </header>

        {/* Main Content */}

        <>
          <main className="bg-[#192E3B] flex-col lg:w-[66%] lg:min-h-screen lg:p-0 p-3 mt-[150px] lg:mt-[50px] ">
            {/* Question container */}

            <section className=" lg:w-[80%] lg:ml-20 bg-[#233d4e] lg:mt-[70px] rounded-[10px] flex-col lg:p-0 p-5">
              {/* Question */}

              <div className="flex justify-around flex-start ">
                <div className="lg:mt-[50px] lg:w-[85%] lg:mb-0 mb-4 ">
                  <p className="font-[Poppins] text-white text-[20px] font-normal leading-normal lg:flex  items-center">
                    {questions[currentQuestion]?.question.questionType ===
                      "IMAGE_TEXT" && (
                      <img
                        src={questions[currentQuestion]?.question.questionImg}
                        className="lg:w-[50%] lg:h-[50%] lg:mr-[8%] lg:mb-0 mb-[16px] rounded-[10px]"
                        alt="imgqs"
                      />
                    )}
                    {questions[currentQuestion]?.question.question}
                  </p>
                </div>
              </div>

              {/* Option */}

              <section className=" flex flex-wrap lg:py-[40px]  justify-center gap-[15px]">
                {questions[currentQuestion]?.question.options
                  .filter((option: string) => option !== "")

                  .map((option: any, index: any) => {
                    return (
                      <>
                        {/* QuestionType=text and answerType=image */}
                        {questions[currentQuestion].question.answerType ===
                        "IMAGE" ? (
                          <button
                            key={index}
                            disabled={
                              answer !== "" || currentTimer === "restTime"
                            }
                            className={`lg:w-[42%] w-full  rounded-[10px] m-2 flex items-center justify-center font-[Poppins] 
                    ${
                      answer === option && !showResult
                        ? "border-[2px] border-blue-400  "
                        : ""
                    }`}
                            onClick={() => {
                              selectAnswer(option);

                              handleClick(
                                index,
                                questions[currentQuestion]?.question._id,
                                option
                              );
                            }}
                          >
                            <div
                              className={`relative text-center w-full h-[200px]  ${
                                answer === option && !showResult
                                  ? "bg-transparent opacity-20  "
                                  : ""
                              } `}
                            >
                              <img
                                className="absolute inset-0 w-full h-full object-cover rounded-[10px] "
                                src={option}
                                alt="options"
                              />
                              <div className="absolute top-2 right-2">
                                <div
                                  className={` ${
                                    questions[currentQuestion]?.question
                                      .correctAnswer === option && showResult
                                      ? ""
                                      : "hidden"
                                  }`}
                                >
                                  <img
                                    className="w-[27px] h-[27px] "
                                    src={correct}
                                    alt="tick"
                                  />
                                </div>
                                <div
                                  className={`${
                                    questions[currentQuestion]?.question
                                      .correctAnswer !== option &&
                                    showResult &&
                                    answer === option
                                      ? ""
                                      : "hidden"
                                  }`}
                                >
                                  <img
                                    className="w-[27px] h-[27px] flex-shrink-0"
                                    src={wrong}
                                    alt="wrong"
                                  />
                                </div>
                              </div>
                            </div>
                          </button>
                        ) : // QuestionType=text and answerType=text

                        questions[currentQuestion].question.answerType ===
                            "TEXT" ||
                          questions[currentQuestion].question.answerType ===
                            "BOOLEAN" ? (
                          <button
                            key={index}
                            disabled={
                              answer !== "" || currentTimer === "restTime"
                            }
                            className={`lg:w-[42%] w-full rounded-[10px] font-[Poppins] p-2 border-[1px] border-solid flex justify-between items-center
                    
                    ${
                      questions[currentQuestion]?.question.correctAnswer ===
                        option && showResult
                        ? "bg-green-500"
                        : ""
                    }
                    ${
                      questions[currentQuestion]?.question.correctAnswer !==
                        option &&
                      showResult &&
                      answer === option
                        ? "bg-red-500"
                        : ""
                    }
                    ${
                      answer === option && !showResult
                        ? " bg-[#67c1f9]  border-[2px] "
                        : "border-[#95D6FF] border-[1px]"
                    }`}
                            onClick={() => {
                              selectAnswer(option);
                              handleClick(
                                index,
                                questions[currentQuestion]?.question._id,
                                option
                              );
                            }}
                          >
                            <div
                              className={`text-white flex  w-full justify-center items-center text-center font-[Poppins] text-[20px] font-semibold leading-normal`}
                            >
                              {option}
                            </div>

                            <div
                              className={` ${
                                questions[currentQuestion]?.question
                                  .correctAnswer === option && showResult
                                  ? ""
                                  : "hidden"
                              }`}
                            >
                              <img src={correct} alt="tick" />
                            </div>
                            <div
                              className={`${
                                questions[currentQuestion]?.question
                                  .correctAnswer !== option &&
                                showResult &&
                                answer === option
                                  ? ""
                                  : "hidden"
                              }`}
                            >
                              <img src={wrong} alt="wrong" />
                            </div>
                          </button>
                        ) : null}
                      </>
                    );
                  })}
              </section>
            </section>
          </main>
          {/* Result Notification */}
          {showResult && (
            <div
              className={`lg:fixed lg:w-[66%]  lg:bottom-0 z-10 ${
                selectedResult
                  ? "bg-green-500 flex justify-between"
                  : "bg-red-500"
              } p-5 text-white font-[Poppins] font-normal lg:text-[20px] text-[14px] leading-normal `}
            >
              <p className="">
                {selectedResult && answer !== ""
                  ? "Hurry! You have chosen the correct answer."
                  : answer === ""
                  ? "You didn't choose anything."
                  : "Oops, your answer is incorrect."}
              </p>
              {selectedResult && (
                <div className="font-semibold">
                  You got {marksAwarded} points
                </div>
              )}
            </div>
          )}
        </>

        {/* Timer and Leaderboard Sidebar */}
        <aside className="bg-[#1D3441] lg:w-[34%] p-3 lg:p-0 lg:fixed lg:right-0 lg:min-h-screen lg:px-8 lg:gap-0 gap-4 z-20">
          <div>
            <div className="flex justify-between items-center">
              <div className="lg:mt-5 lg:flex hidden">
                <p className="font-[Poppins] text-white text-[16px] font-semibold leading-normal lg:py-0 py-[12px]">
                  Question: {currentQuestion + 1} / {questions.length}
                </p>
              </div>
              {path !== `/demo/${slug}` && questionCompleted && (
                <div
                  className=" lg:flex hidden lg:mt-5"
                  onClick={handleExitQuiz}
                >
                  <button className="flex justify-center items-center gap-[11.902px] shrink-0 px-4 py-2 rounded-[5px] lg:text-[20px] text-[16px] bg-[#008BB7] text-white">
                    Exit Quiz
                  </button>
                </div>
              )}
            </div>

            {/* timer */}
            <div className="hidden lg:flex justify-center">
              <div className="  lg:w-[100%] w-[90%]  h-[237px] rounded-[10px] bg-[#233D4E] lg:mt-[40px] ">
                <div className="">
                  <div className="flex justify-center">
                    <p className="font-[Poppins] text-[14px] font-normal leading-normal text-white py-5">
                      {currentTimer === "respondTime"
                        ? "END'S IN"
                        : "NEXT QUESTION IN"}
                    </p>
                  </div>

                  <div className="flex justify-center">
                    <div className="flex justify-center items-center">
                      <div className="w-[128px] h-[128px]  relative">
                        <CircularProgressbarWithChildren
                          value={(countdown / initialTimer) * 100}
                          styles={buildStyles({
                            rotation: 180,
                            strokeLinecap: "round",
                            textSize: "16px",
                            pathTransitionDuration: 0.5,
                            pathColor: "#1AC8FF",
                            textColor: "#ffffff",
                            trailColor: "#1B4E5F",
                            backgroundColor: "#233D4E",
                          })}
                        >
                          <div className="font-semibold text-[44px] text-[#1AC8FF]">
                            {countdown}
                          </div>
                        </CircularProgressbarWithChildren>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* leaderboard */}
          <div>
            <h1 className="font-[Poppins] text-[14px] font-semibold leading-normal text-white py-3">
              Leaderboard
            </h1>
          </div>
          <animated.div
            style={{
              ...leaderboardSpring,
              maxHeight: `${height}px`,
              minHeight: `100px`,
            }}
            className="justify-center overflow-y-auto mt-[8px] no-scrollbar flex-wrap"
            id="leaderboard-container" // Add an id to your component
          >
            {sortPlayers(animatedPlayers).map((player: PlayerData, index) => (
              <AnimatedLeaderboardItem
                key={player._id}
                player={player}
                index={index}
              />
            ))}
          </animated.div>
        </aside>
      </div>
    </div>
  );
};

const AnimatedLeaderboardItem: React.FC<{
  player: PlayerData;
  index: number;
}> = ({ player, index }) => {
  const [style, set] = useSpring(() => ({
    opacity: 0,
    transform: `translateY(${index * 4}px)`,
    config: config.stiff,
  }));

  const truncateTime = (time: number) => {
    return String(time).slice(0, 5);
  };

  useEffect(() => {
    const delay = setTimeout(() => {
      set({
        opacity: 1,
        transform: `translateY(${index * 4}px)`,
      });
    }, 100);

    return () => clearTimeout(delay);
  }, [index, set]);

  return (
    <animated.div
      style={style}
      className="w-[100%] text-[white] font-[Poppins] flex px-[12px] py-[10px] mb-[8px] rounded-[20px] bg-[#233D4E] h-[53px]"
    >
      <div className="flex items-center gap-3 w-[55%] ">
        <div className="flex w-[24px] h-[24px] justify-center items-center rounded-[90px] bg-[#E3E3E3]">
          <h1 className="text-[#171717] font-[Poppins] text-[12px] font-[400] ">
            {index + 1}
          </h1>
        </div>
        <img
          className="w-[40px] h-[40px] rounded-[40px]"
          src={player.avatarUrl} // Use the player's avatar URL
          alt="profile"
        />
        <p className="font-[400] text-[14px]">{player.name}</p>{" "}
      </div>
      <div className="flex w-[45%] items-center">
        <div className="flex gap-[2px] ">
          <p className="flex justify-start">🏆</p>
          <p className="text-[16px] font-[700] mr-[12px] text-right">
            {player.marks ?? 0}
          </p>
        </div>
        <div className="flex gap-[2px]">
          <p className="flex justify-start">⏰</p>
          <p className="text-[#95D6FF] text-right">
            {player.time === 0
              ? truncateTime(player.time ?? 0)
              : `${truncateTime(player.time ?? 0)}s`}
          </p>
        </div>
      </div>
    </animated.div>
  );
};

export default DemoQuiz;
