import React, { useEffect, useState } from "react";
import { io } from "socket.io-client";
import events from "../websocket/EventsName";
import {
  adminDemoPlayerData,
  getPlayerData,
} from "src/services/playerLeaderboard/playerLeaderboard.service";
import PlayerQuizSummary from "src/components/playerQuizSummary/PlayerQuizSummary";
import PlayerLeaderboard from "../playerLeaderboard/PlayerLeaderboard";
import share from "../../assets/ShareIcon.svg";
import copyClipboard from "../../assets/copyClipboard.svg";
import facebook from "../../assets/icons8-facebook.svg";
import instagram from "../../assets/icons8-instagram.svg";
import whatsapp from "../../assets/whatsapp.svg";
import linkedIn from "../../assets/linkedin1.svg";
import { getPlayershareData } from "src/services/playerQuizSummary/playerQuizSummaryservice";
import Navbar from "src/components/navBar/NavBar";
import { useLocation, useParams } from "react-router-dom";
import invite from "../../assets/add-group.png";
import FullHttpLink from "src/components/domainName/DomainName";

interface PlayerData {
  _id: string;
  name: string;
  socketId: string;
  avatarUrl: string;
  marks: number;
  time: number;
  trueCount: number;
  falseCount: number;
}

const option = [{ name: "LEADERBOARD" }, { name: "QUIZ SUMMARY" }];
const PlayerQuizResult = () => {
  const location = useLocation();
  const path = location.pathname;
  const [webUrl, setWebUrl] = useState<any>();
  const [currentTab, setCurrentTab] = useState({ name: "LEADERBOARD" });
  const [, setConnectedPlayers] = useState<PlayerData[]>([]);
  const [isShareButtonVisible, setIsShareButtonVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const { slug } = useParams();
  const [inviteOpen, setInviteOpen] = useState(false);

  useEffect(() => {
    document.title =
      currentTab.name === "LEADERBOARD"
        ? "Player Leaderboard"
        : "Player QuizSummary";
    if (open) {
      document.title = "Share";
    }
  }, [currentTab, open]);

  useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => {
        setCopied(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [copied]);

  // Disable the back button
  useEffect(() => {
    const disableBackButton = () => {
      window.history.pushState(null as any, null as any, document.URL as any);
      window.addEventListener("popstate", () => {
        window.history.pushState(null as any, null as any, document.URL as any);
      });
    };
    disableBackButton();
    return () => {
      window.removeEventListener("popstate", disableBackButton);
    };
  }, []);

  useEffect(() => {
    if (path === `/player/result/${slug}`) {
      if (process.env.React_App_SERVER_URL) {
        const socket = io(process.env.React_App_SERVER_URL);

        socket.on(events.SOCKET_CONNECTING, () => {
          const slug = JSON.parse(sessionStorage.getItem("slug") || "");

          socket.emit(events.HOST_CONNECTING, { slug });

          socket.on(events.PLAYERS_DATAS, (playersData: PlayerData[]) => {
            if (playersData.length > 0) {
              setConnectedPlayers(playersData);
            } else {
              getPlayerData(slug).then((data) => {
                setConnectedPlayers(data);
              });
            }
          });
        });

        return () => {
          socket.disconnect();
        };
      }
    } else {
      adminDemoPlayerData(slug || "")
        .then((response) => {})
        .catch((error) => {
          console.error(error);
        });
    }
  }, [slug, path]);

  useEffect(() => {
    setIsShareButtonVisible(currentTab.name === "LEADERBOARD");
  }, [currentTab]);

  const handleShare = () => {
    setOpen(true);
    if (slug) {
      getPlayershareData(slug)
        .then((data: any) => {
          const parser = new DOMParser();
          const doc = parser.parseFromString(data, "text/html");
          const scriptElements = doc.querySelectorAll("script");
          let webUrl = null;

          scriptElements.forEach((scriptElement) => {
            const scriptContent = scriptElement.textContent;
            if (scriptContent) {
              const match = scriptContent.match(/var webUrl = '(.*?)';/);
              if (match) {
                webUrl = match[1];
              }
            }
          });

          if (webUrl) {
            setWebUrl(webUrl);
          } else {
            console.error("webUrl not found in script elements");
          }
        })
        .catch((error: Error) => {
          console.error(error);
        });
    }
  };

  const handleClose = () => {
    setOpen(false);
    setInviteOpen(false);
  };

  const copyLinkToClipboard = () => {
    const link = document.querySelector(".link-container")?.textContent;
    if (link) {
      navigator.clipboard
        .writeText(link)
        .then(() => setCopied(true))
        .catch((error) => console.error("Could not copy text: ", error));
    }
  };

  return (
    <div className="bg-[#192E3B] min-h-screen">
    <div className="max-w-screen-2xl mx-auto  w-full ">
      <div className="bg-[#192E3B] font-[Poppins]">
        <Navbar hideItems={true} className="bg-[#192E3B] z-10 " />

        {/* invite friends */}
        {inviteOpen && (
          <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50 ">
            <div className="w-[90%] md:w-[500px] bg-white p-5 ">
              <div className="flex mt-0 justify-between">
                <h3 className="font-[Poppins] flex justify-center items-center font-bold text-[20px] ">
                  Invite Friends
                </h3>
                <button
                  className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#008BB7]"
                  onClick={handleClose}
                >
                  <svg
                    className="h-7 w-7"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>

              <div className="flex w-full justify-between h-[56px] bg-[#233D4E] rounded-[10px] mt-3 ">
                <div className="text-white font-normal w-[80%] font-[Poppins] ml-[15px] md:ml-[26px] text-[15px] lg:text-[20px] flex items-center link-container">
                  <div className="overflow-hidden whitespace-nowrap overflow-ellipsis md:text-ellipsis">
                    {<FullHttpLink link={`/player/demo/${slug}`} />}
                  </div>
                </div>
                <button
                  className="bg-[#008AB6] p-3 h-[56px] rounded-[10px] flex items-center justify-center"
                  onClick={copyLinkToClipboard}
                >
                  <img src={copyClipboard} alt="copyClipboardImage" />
                </button>
              </div>

              <div className="flex gap-3 mt-3 justify-around">
                <a
                  className=""
                  href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                    window.location.href
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={facebook} alt="insta logo" />
                </a>
                <a
                  className=""
                  href={`https://www.instagram.com?utm_source=share_link&utm_medium=link&utm_campaign=smsr_share`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={instagram} alt="insta logo" />
                </a>
                <a
                  className=""
                  href={`https://api.whatsapp.com/send?text=${encodeURIComponent(
                    window.location.href
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={whatsapp} alt="whatsapp logo" />
                </a>
                <a
                  className=""
                  href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
                    window.location.href
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={linkedIn} alt="linkedin logo" />
                </a>
              </div>
            </div>
          </div>
        )}
        {/* Share options */}
        {open && (
          <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50 ">
            <div className="w-[90%] md:w-[500px] bg-white p-5 ">
              <div className="flex mt-0 justify-between">
                <h3 className="font-[Poppins] flex justify-center items-center font-bold text-[20px] ">
                  Share
                </h3>
                <button
                  className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#008BB7]"
                  onClick={handleClose}
                >
                  <svg
                    className="h-7 w-7"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>

              <div className="flex w-full justify-between h-[56px] bg-[#233D4E] rounded-[10px] mt-3 ">
                <div className="text-white font-normal w-[80%] font-[Poppins] ml-[15px] md:ml-[26px] text-[15px] lg:text-[20px] flex items-center link-container">
                  <div className="overflow-hidden whitespace-nowrap overflow-ellipsis md:text-ellipsis">
                    {webUrl}
                  </div>
                </div>
                <button
                  className="bg-[#008AB6] p-3 h-[56px] rounded-[10px] flex items-center justify-center"
                  onClick={copyLinkToClipboard}
                >
                  <img src={copyClipboard} alt="copyClipboardImage" />
                </button>
              </div>

              <div className="flex gap-3 mt-3 justify-around">
                <a
                  className=""
                  href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                    window.location.href
                  )} `}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={facebook} alt="insta logo" />
                </a>
                <a
                  className=""
                  href={`https://www.instagram.com?utm_source=share_link&utm_medium=link&utm_campaign=smsr_share`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={instagram} alt="insta logo" />
                </a>
                <a
                  className=""
                  href={`https://api.whatsapp.com/send?text=${encodeURIComponent(
                    window.location.href
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={whatsapp} alt="whatsapp logo" />
                </a>
                <a
                  className=""
                  href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
                    window.location.href
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={linkedIn} alt="linkedin logo" />
                </a>
              </div>
            </div>
          </div>
        )}

        {/* head */}
        <div className="lg:px-[90px] md:px-[40px] xl:px-[100px] px-[16px] flex py-[3%] justify-between  ">
        <div className="w-fit flex sm:flex-row flex-col">
        {option.map((data: any, index: number) => (
              <button
                key={index}
                className={`text-[#95D6FF] lg:w-[190px] w-[140px] font-[Poppins] flex-shrink-0 lg:text-[19px] text-[16px] font-[600] h-[40px] rounded-[10px] ${
                  currentTab.name === data.name ? "bg-[#008BB7] text-white" : ""
                } `}
                onClick={() => {
                  setCurrentTab({ name: data?.name });
                }}
              >
                {data?.name}
              </button>
            ))}
          </div>
          {/* share */}
          <div className="flex gap-5 max-h-10">
            {path === `/demo/player/result/${slug}` && (
              <div className="">
                <div className="hidden lg:flex ">
                  <button
                    className="px-7 py-2 bg-[#008BB7]  gap-3 flex items-center rounded-[10px] "
                    onClick={() => {
                      setInviteOpen(true);
                    }}
                  >
                    <img
                      className="filter grayscale invert w-6 h-6 "
                      src={invite}
                      alt="share"
                    />
                    <h4 className="font-[Poppins] text-white text-[20px]">
                      Invite Friends
                    </h4>
                  </button>
                </div>

                <div className="lg:hidden flex">
                  <button
                    className="px-2 py-2 bg-[#008BB7] rounded-[10px] "
                    onClick={() => {
                      setInviteOpen(true);
                    }}
                  >
                    <img
                      className="filter grayscale invert w-6 h-6"
                      src={invite}
                      alt="share"
                    />
                  </button>
                </div>
              </div>
            )}
            {isShareButtonVisible && (
              <div className="hidden lg:flex ">
                <button
                  className="px-7 py-2 bg-[#008BB7]  gap-3 flex rounded-[10px] "
                  onClick={handleShare}
                >
                  <img
                    className="filter grayscale invert"
                    src={share}
                    alt="share"
                  />
                  <h4 className="font-[Poppins] text-white text-[20px]">
                    Share
                  </h4>
                </button>
              </div>
            )}
            {isShareButtonVisible && (
              <div className="lg:hidden flex">
                <button
                  className="px-2 py-2 bg-[#008BB7] rounded-[10px] "
                  onClick={handleShare}
                >
                  <img
                    className="filter grayscale invert"
                    src={share}
                    alt="share"
                  />
                </button>
              </div>
            )}
          </div>
        </div>

        <h2>
          {currentTab.name === "QUIZ SUMMARY" && <PlayerQuizSummary />}
          {currentTab.name === "LEADERBOARD" && <PlayerLeaderboard />}
        </h2>

        {/* Message for link copied */}
        {copied && (
          <div className="fixed bottom-5 left-1/2 transform -translate-x-1/2 bg-black text-white w-[60%] md:w-auto px-4 py-2 rounded-md shadow-lg z-50">
            Link copied to clipboard
          </div>
        )}
      </div>
    </div>
    </div>
  );
};

export default PlayerQuizResult;
