import { useEffect, useRef, useState } from "react";
import Navbar from "src/components/navBar/NavBar";
import { useLocation, useNavigate } from "react-router-dom";
import Tick from "src/assets/Tick.svg";
import Cancel from "src/assets/Cancel.svg";
import CryptoJS from "crypto-js";
import { fetchQuizDetails } from "src/services/quizDetails.service.tsx/quizDetails.service";
import { useParams } from "react-router-dom";
import QuizPackages from "src/components/quizPackages/QuizPackages";
import LoaderComponent from "src/components/loader/LoaderComponent";
import { gameStore } from "src/services/gameSettings/gameSettings.service";
import { Helmet } from "react-helmet";

import instagram from "../../assets/icons8-instagram.svg";

import copyClipboard from "../../assets/copyClipboard.svg";
import FullHttpLink from "src/components/domainName/DomainName";
import clipboard from "clipboard";

import {
  FacebookShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  FacebookIcon,
  WhatsappIcon,
  LinkedinIcon,
} from "react-share";

const TemplateDetails = () => {
  const location = useLocation();
  const [quizData, setQuizData] = useState<any>({});
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [scrolled, setScrolled] = useState(false);
  const [templateClick, setTemplateClick] = useState<any>();
  const quizIDWithQuotes = sessionStorage.getItem("quizID") || "";
  const quizID = quizIDWithQuotes.replace(/["']/g, "");
  const { QuizId } = useParams();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const [open, setOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const demoSlug = QuizId?.slice(-6);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const linkRef = useRef<HTMLDivElement>(null);

  const getCustomShareLink = () => {
    return `${window.location.origin}/player/demo/${demoSlug}`;
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const templateClick = params.get("templateClick");
    setTemplateClick(templateClick);
  }, [location]);

  useEffect(() => {
    if (showConfirmation) {
      document.title = "QuizPackages";
    }
  });

  const handlePublish = () => {
    if (quizData?.quiz?.is_free === "free") {
      gameStore(QuizId || "")
        .then((response) => {
          sessionStorage.setItem(
            "gameSettings",
            JSON.stringify(response.gameSetting)
          );

          navigate(`/host/quiz/game-settings/${response.game._id}`);
        })
        .catch((error) => {
          console.error("Error while redirecting:", error);
        });
    } else setShowConfirmation(true);
  };

  const handleExit = () => {
    if (searchParams.get("templateClick") === "true") {
      navigate("/home");
    } else if (quizID) {
      navigate(`/create-quiz?templateId=${quizID}`);
    }
  };

  useEffect(() => {
    const secretKey = "quiz123456";
    if (QuizId) {
      fetchQuizDetails(QuizId)
        .then((data) => {
          const decryptedQuestions = data?.questions?.map((question: any) => {
            const bytes = CryptoJS.AES.decrypt(
              question?.question?.correctAnswer,
              secretKey
            );
            const decryptValue = bytes.toString(CryptoJS.enc.Utf8);
            return {
              ...question,
              question: {
                ...question.question,
                correctAnswer: decryptValue,
              },
            };
          });

          const decryptedData = {
            ...data,
            questions: decryptedQuestions,
          };
          setQuizData(decryptedData);
          sessionStorage.setItem("quizID", JSON.stringify(data.quiz._id));
          sessionStorage.setItem(
            "quizCreatedBy",
            JSON.stringify(data.quiz.hostId)
          );
          sessionStorage.setItem(
            "displayStatus",
            JSON.stringify(data.quiz.display_status)
          );
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [QuizId]);

  const copyLinkToClipboard = (platform: string) => {
    const link = linkRef.current?.textContent || "";
    let message = link;

    // Add a special message for Instagram
    if (platform === "instagram") {
      message = `Check out this amazing quiz! ${link}`;
    }

    if (message) {
      navigator.clipboard
        .writeText(message)
        .then(() => {
          setCopied(true);
          setTimeout(() => setCopied(false), 3000);
        })
        .catch((error) => console.error("Could not copy text: ", error));
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  // Copy clipboard
  useEffect(() => {
    if (inputRef.current && buttonRef.current) {
      const clipboardInstance = new clipboard(buttonRef.current, {
        target: () => inputRef.current!,
      });
      clipboardInstance.on("success", (e: clipboard.Event) => {});
      clipboardInstance.on("error", (e: clipboard.Event) => {});
      return () => {
        clipboardInstance.destroy();
      };
    }
  }, []);

  return (
    <div className="max-w-screen-2xl mx-auto  w-full">
      <Helmet>
        <title>Template Details</title>
        <meta
          name="description"
          content="Explore detailed information about our exciting quiz templates."
        />
      </Helmet>

      {isLoading ? (
        <div className="fixed top-0  left-0 w-full h-full flex items-center justify-center z-50">
          <LoaderComponent />
        </div>
      ) : (
        <div className="font-[Poppins]">
          <div
            className={`w-full sticky top-0 z-50 lg:px-0   ${
              scrolled ? "z-[99] shadow-[#ABB3DE] shadow-lg " : ""
            }`}
          >
            <Navbar
              showHamburger={true}
              templateDetailsMobileView={true}
              showmenuIcon={false}
              showProfile={false}
              showUpcomingsDropdown={true}
              showExitButton={true}
              showGenerateLink={true}
              showPublishButton={true}
              handlePublish={handlePublish}
              handleExit={handleExit}
              className="bg-white"
              lgGapSizeHome="4"
              lgPaddingHome="10"
              xlPaddingHome="[70px]"
              pxCreate="[10px]"
              generateLinkButton={() => {
                setOpen(true);
              }}
              mdGapSize="1"
              smPadding="0"
            />
          </div>
          {/* Share options */}
          {open && (
            <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-[9999999]">
              <div className="w-[90%] md:w-[500px] bg-white rounded-lg p-6 shadow-lg">
                <div className="flex justify-between items-center mb-4">
                  <h3 className="font-[Poppins] text-lg font-semibold text-gray-800">
                    Share Your Game
                  </h3>
                  <button
                    className="rounded-full p-2 text-gray-400 hover:text-gray-500 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#008BB7]"
                    onClick={handleClose}
                  >
                    <svg
                      className="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
                <div className="flex gap-4 mt-4 justify-around py-4 bg-gray-50 rounded-md shadow-inner">
                  <FacebookShareButton
                    url={getCustomShareLink()}
                    hashtag="Check out this amazing game!"
                    className="flex justify-center w-full hover:opacity-80 transition-opacity"
                  >
                    <FacebookIcon size={32} round />
                  </FacebookShareButton>

                  <div className="border border-gray-300 h-8"></div>
                  <a
                    className="flex justify-center w-full hover:opacity-80 transition-opacity"
                    href={`https://www.instagram.com?utm_source=share_link&utm_medium=link&utm_campaign=smsr_share&url=${encodeURIComponent(
                      getCustomShareLink()
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => copyLinkToClipboard("instagram")}
                  >
                    <img
                      src={instagram}
                      alt="Instagram logo"
                      className="h-8 w-8"
                    />
                  </a>
                  <div className="border border-gray-300 h-8"></div>

                  <WhatsappShareButton
                    url={getCustomShareLink()}
                    title="Check out this amazing game!"
                    className="flex justify-center w-full hover:opacity-80 transition-opacity"
                  >
                    <WhatsappIcon size={32} round />
                  </WhatsappShareButton>
                  <div className="border border-gray-300 h-8"></div>

                  <LinkedinShareButton
                    url={getCustomShareLink()}
                    title="My Awesome Page"
                    summary="Check out this amazing game!"
                    source="Quiz Circles"
                    className="flex justify-center w-full hover:opacity-80 transition-opacity"
                  >
                    <LinkedinIcon size={32} round />
                  </LinkedinShareButton>
                </div>
                <h1 className="mt-6 text-gray-700 font-[Poppins]">
                  Or copy this link to share
                </h1>
                <div
                  className="flex w-full justify-between items-center h-[56px] bg-gray-100 rounded-md mt-3 pl-3"
                  ref={inputRef}
                >
                  <div
                    className="text-gray-800 font-normal w-full font-[Poppins] overflow-hidden whitespace-nowrap text-ellipsis"
                    ref={linkRef}
                  >
                    <FullHttpLink link={`/player/demo/${demoSlug}`} />
                  </div>

                  <button
                    className="bg-[#008AB6] w-[60px] lg:w-[64px] h-[56px] lg:h-[56px] rounded-[10px] flex items-center justify-center"
                    onClick={() => copyLinkToClipboard("default")}
                  >
                    <img src={copyClipboard} alt="copyClipboardImage" />
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* //background  */}
          <div
            className={`bg-[#F8F9FC] lg:py-4 px-4 min-h-screen py-4 lg:px-10 xl:px-[70px] 
              relative ${showConfirmation ? "fixed overflow-hidden" : ""}`}
          >
            {/* //Description */}
            <div>
              <div className="lg:w-full lg:h-[299.945px] lg:mt-[40.99px] lg:mb-[34.06px] mb-[38.35px] mt-[20.7px] lg:flex w-full bg-white ">
                <img
                  className="lg:w-[520px] lg:h-[299.945px] w-full rounded-[10px] lg:rounded-[10px] p-5 border-dashed border border-[#1FC4D9]"
                  src={quizData?.quiz?.imageUrl}
                  alt="template"
                  loading="lazy"
                />
                <div className="lg:h-[164.525px] lg:ml-[69.89px] lg:mt-[86.71px] lg:mb-[48.71px] ml-[23.5px] mt-[10.2px]">
                  <h1 className="text-[#171717] font-[Poppins] lg:text-[30px] text-[24px] font-bold leading-normal">
                    {quizData?.quiz?.name}
                  </h1>
                  <p className="text-[#171717] font-[Poppins] lg:text-[20px] font-[400px] text-[18px] md:mt-[14.53px] leading-normal h-[30px]">
                    {quizData && quizData.questions && (
                      <span className="ml-1">
                        {quizData?.questions?.length} Questions
                      </span>
                    )}
                  </p>
                </div>
              </div>
              {/* question.answerType === "TEXT" */}
              {quizData?.questions?.map((question: any, index: number) => {
                const capitalizedQuestion =
                  question.question.question.charAt(0).toUpperCase() +
                  question.question.question.slice(1);

                return (
                  <div className="mt-4 relative" key={index}>
                    <div className="flex flex-row lg:items-center items-start bg-white xl:px-[20px] xl:py-[40px] lg:px-[10px] lg:py-[40px] md:px-[10px] z-10 w-full">
                      <div className=" lg:w-[4%] lg:mb-0 ml-2">
                        <div className="text-[65px] font-extrabold text-black mt-10 lg:mt-[80px]">
                          {index + 1}
                        </div>
                      </div>
                      <div className="flex-grow  lg:w-[90%] ml-0 lg:ml-11 xl:ml-9 mt-4 lg:mt-0">
                        {question.question.questionType === "TEXT" && (
                          <div className="mt-[17px]">
                            <div
                              className="bg-white lg:px-[30px] lg:py-[10px] px-4
                            py-5"
                            >
                              <div className="lg:flex lg:justify-between lg:p-0">
                                <div>
                                  <p className="text-[#171717] font-[Poppins] xl:text-[25px] lg:text-[20px] text-[20px] font-bold leading-normal ml-[40] mr-[10] text-justify">
                                    {capitalizedQuestion}
                                  </p>
                                </div>
                              </div>
                              <div className="md:flex md:w-[100%] lg:mt-[32px] grid w-full gap-[24px] mt-[24px] text-[#000] font-[Poppins] xl:text-[20px] lg:text-[18px] text-[16px] justify-start font-[600] leading-normal">
                                {question?.question.options
                                  .filter((option: string) => option !== "")
                                  .map(
                                    (option: string, optionIndex: number) => (
                                      <div
                                        key={optionIndex}
                                        className="flex items-center border md:w-[25%] w-full bg-[#F8F9FC] p-4 md:p-3 lg:p-3 rounded-lg"
                                      >
                                        <img
                                          className="mr-[16px] w-6"
                                          src={
                                            option ===
                                            question.question.correctAnswer
                                              ? Tick
                                              : Cancel
                                          }
                                          alt="template"
                                        />
                                        {question.question.answerType ===
                                          "TEXT" ||
                                        question.question.answerType ===
                                          "BOOLEAN" ? (
                                          <div className="text-[#000] font-[Poppins] lg:text-[15px] xl:text-[21px] md:text-[15px] text-[18px] font-[600] leading-normal ">
                                            <span>{option}</span>
                                          </div>
                                        ) : null}
                                        {question.question.answerType ===
                                        "IMAGE" ? (
                                          <div className="">
                                            <img
                                              className="lg:w-[164px] h-[85.854px] md:w-[110px] w-[110px] md:h-[128px] rounded-[10px] lg:rounded-[10px]"
                                              src={option}
                                              alt="template"
                                              loading="lazy"
                                            />
                                          </div>
                                        ) : null}
                                      </div>
                                    )
                                  )}
                              </div>
                            </div>
                          </div>
                        )}
                        {question.question.questionType === "IMAGE_TEXT" && (
                          <div className="my-[17px]">
                            <div className="md:w-full mt-[17px] bg-white lg:px-[30px] lg:py-[10px] px-2 py-5">
                              <div className="lg:flex mt-[18px]">
                                <img
                                  className="lg:w-[312px] md:h-[210px] w-full md:w-[300px] rounded-[10px] lg:rounded-[10px]"
                                  src={question.question.questionImg}
                                  alt="template"
                                  loading="lazy"
                                />
                                <p className="lg:ml-[32px] text-[#171717] lg:mt-[40px] mt-[24px] font-[Poppins] xl:text-[25px] lg:text-[20px] text-[20px] font-bold leading-normal text-justify">
                                  {capitalizedQuestion}
                                </p>
                              </div>
                              <div className="md:flex md:w-[100%] lg:mt-[32px]  grid w-full  gap-[24px] mt-[24px] text-[#000] font-[Poppins] xl:text-[20px] lg:text-[18px] text-[16px] font-[600] leading-normal">
                                {question?.question.options.map(
                                  (option: string, optionIndex: number) => (
                                    <div
                                      key={optionIndex}
                                      className="flex items-center border md:w-[25%] w-full bg-[#F8F9FC] p-4 rounded-lg md:p-1 lg:p-3"
                                    >
                                      <img
                                        className="mr-[16px] w-6"
                                        src={
                                          option ===
                                          question.question.correctAnswer
                                            ? Tick
                                            : Cancel
                                        }
                                        alt="template"
                                      />
                                      {question.question.answerType ===
                                        "TEXT" ||
                                      question.question.answerType ===
                                        "BOOLEAN" ? (
                                        <div className="text-[#000] font-[Poppins] lg:text-[15px] xl:text-[21px] md:text-[15px] text-[18px] font-[600] leading-normal ">
                                          <span>{option}</span>
                                        </div>
                                      ) : null}
                                      {question.question.answerType ===
                                      "IMAGE" ? (
                                        <div className="">
                                          <img
                                            className="lg:w-[164px] h-[85.854px] md:w-[110px] w-[110px] md:h-[128px] rounded-[10px] lg:rounded-[10px]"
                                            src={option}
                                            alt="template"
                                            loading="lazy"
                                          />
                                        </div>
                                      ) : null}
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            {/* //quiz packages confirmation */}
            {showConfirmation && (
              <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
                <div className="bg-white absolute mt-6 overflow-y-auto md:h-auto h-full w-[80%] md:w-auto md:rounded-md">
                  <button
                    onClick={() => setShowConfirmation(false)}
                    className="absolute top-0 right-0 m-2 bg-gray-200 text-gray-500 hover:text-gray-700"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>

                  <QuizPackages
                    totalQuestions={quizData?.questions?.length}
                    templateClick={templateClick}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {copied && (
        <div className="fixed bottom-5 left-1/2 transform -translate-x-1/2 bg-black text-white w-[60%] md:w-auto px-4 py-2 rounded-md shadow-lg z-[999999999999]">
          Link copied to clipboard
        </div>
      )}
    </div>
  );
};

export default TemplateDetails;
