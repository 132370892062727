import instance from "src/http/httpClient";

export const hostSignUp = (payload: any): Promise<any> => {
  return instance
    .post("/auth/signup", payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

export const hostVerify = (payload: any): Promise<any> => {
  return instance
    .post("/auth/verify", payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

export const hostLogin = (
  email: string,
  password: string,
  isMobile: boolean
): Promise<any> => {
  const data = { email, password, isMobile };
  return instance
    .post("/auth/login", data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

export const hostFogotPassword = (email: string): Promise<any> => {
  const data = { email };
  return instance
    .post("/host/email", data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

export const hostResetPassword = (
  email: string,
  password: string,
  token: string // New parameter for cookie
): Promise<any> => {
  const data = { email, password };

  return instance
    .post("/host/password-reset", data, {
      headers: {
        Authorization: `${token}`, // Example assuming Bearer token scheme
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("Password reset failed:", error);
      throw error;
    });
};
