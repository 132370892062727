import { useEffect, useState } from "react";
import Tick from "src/assets/Tick.svg";
import Cancel from "src/assets/Cancel.svg";
import { useParams } from "react-router-dom";
import CryptoJS from "crypto-js";
import { getHostSummary } from "src/services/quizSummary/quizSummary.service";
import LoaderComponent from "../loader/LoaderComponent";

const QuizSummary = () => {
  const [quizData, setQuizData] = useState<any[]>([]);
  const { slug } = useParams();
  const quizIDWithQuotes = sessionStorage.getItem("quizID") || "";
  const quizID = quizIDWithQuotes.replace(/["']/g, "");
  const secretKey = "quiz123456";
  const [isLoading, setIsLoading] = useState(true);

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  useEffect(() => {
    if (quizID && slug) {
      setIsLoading(true);
      getHostSummary(quizID, slug || "")
        .then((data) => {
          const decryptedQuestions = data?.map((question: any) => {
            const bytes = CryptoJS.AES.decrypt(
              question?.question?.correctAnswer,
              secretKey
            );
            const decryptValue = bytes.toString(CryptoJS.enc.Utf8);
            return {
              ...question,
              question: {
                ...question.question,
                question: capitalizeFirstLetter(question.question.question),
                correctAnswer: decryptValue,
              },
            };
          });
          setQuizData(decryptedQuestions);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [quizID, slug]);

  return (
    <>
      {/* Loader Component */}
      {isLoading && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50">
          <LoaderComponent />
        </div>
      )}

      <div className="bg-[#192E3B] min-h-screen lg:py-4 px-4 py-4 lg:px-20">
        {/* Mapping through quiz data */}
        {quizData?.map((question: any, index: any) => {
          return (
            <div className="mt-4 relative" key={index}>
              <div className="flex flex-row items-start lg:items-center bg-[#233D4E] xl:px-[20px] xl:py-[40px] lg:px-[10px] lg:py-[40px] md:px-[10px] z-10 w-full">
                {" "}
                <div className=" lg:w-[4%] lg:mb-0 ml-2">
                  <div className="text-[65px] font-extrabold text-white mt-10 lg:mt-0">
                    {index + 1}
                  </div>
                </div>
                {/* Question Content */}
                <div className="flex-grow w-full lg:w-[90%] ml-0 lg:ml-[30px] mt-4 lg:mt-0">
                  {question?.question?.questionType === "TEXT" && (
                    <div className="mt-[17px]">
                      <div
                        className="bg-[#233D4E] lg:px-[30px] lg:py-[10px] px-4
                            py-5 rounded-[10px]"
                      >
                        {/* Attended Count */}
                        <div className="lg:flex lg:flex-col lg:justify-end lg:p-0">
                          <div className="flex justify-end ">
                            <h2 className="text-white font-[Poppins]  lg:text-[20px] text-[14px] font-[400] leading-normal">
                              {question.attendedCount} Attended
                            </h2>
                          </div>
                          <div>
                            <p className="text-white font-[Poppins] xl:text-[25px] lg:text-[20px] text-[20px] mt-[8px] lg:font-[400] leading-normal text-justify">
                              {question?.question?.question}
                            </p>
                          </div>
                        </div>
                        {/* Options List */}
                        <div className="md:flex md:w-[100%] lg:mt-[32px]  grid w-full  gap-[24px] mt-[24px] text-white font-[Poppins] lg:text-[18px] text-[16px] font-[600] leading-normal">
                          {question?.question?.options
                            .filter((option: string) => option !== "")
                            .map((option: any, optionIndex: number) => {
                              const count =
                                question?.options[optionIndex]?.count;
                              const totalCount = question?.options.reduce(
                                (acc: number, option: any) =>
                                  acc + option.count,
                                0
                              );
                              const percentage =
                                totalCount > 0
                                  ? Math.round((count / totalCount) * 100)
                                  : 0;

                              return (
                                <div
                                  key={optionIndex}
                                  className="  md:w-[25%] w-full  p-4 rounded-lg md:p-3 lg:p-3"
                                >
                                  {/* Option Container */}
                                  <div
                                    className={`flex  items-center bg-[#192E3B]  rounded-[10px] px-[16px] py-[20px]`}
                                  >
                                    <img
                                      className="mr-[16px] rounded-[10px]"
                                      src={
                                        option ===
                                        question?.question?.correctAnswer
                                          ? Tick
                                          : Cancel
                                      }
                                      alt="template"
                                    />

                                    {/* Display Option Text or Image */}
                                    {question?.question?.answerType ===
                                      "TEXT" && (
                                      <div className="text-white font-[Poppins] lg:text-[16px] xl:text-[20px] text-[16px] font-[600] leading-normal">
                                        <span>{option}</span>
                                      </div>
                                    )}
                                    {question?.question?.answerType ===
                                      "IMAGE" && (
                                      <div>
                                        <img
                                          className="lg:w-[164px] h-[85.854px] md:w-[110px] w-[110px] md:h-[128px] rounded-[10px] lg:rounded-[10px]"
                                          src={option}
                                          alt="template"
                                        />
                                      </div>
                                    )}
                                    {question?.question?.answerType ===
                                      "BOOLEAN" && (
                                      <div className="text-white font-[Poppins] lg:text-[16px] xl:text-[20px] text-[16px] font-[600] leading-normal ">
                                        <span>{option}</span>
                                      </div>
                                    )}
                                  </div>
                                  {/* Option Percentage */}
                                  <div className="text-white font-[Poppins] lg:text-[20px] text-[16px] font-[600] leading-normal text-center bg-[#192E3B] rounded-[10px] mt-2 py-2">
                                    {percentage}%
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  )}

                  {/* Image + Text Question Type */}
                  {question?.question?.questionType === "IMAGE_TEXT" && (
                    <div className="my-[17px]">
                      {/* Attended Count */}
                      <div className="md:w-full mt-[17px] bg-[#233D4E] lg:px-[30px] lg:py-[10px] px-2 py-5">
                        <div className="bg-[#233D4E]">
                          <h2 className="text-white font-[Poppins] lg:text-[20px] text-[14px] font-[400] leading-normal text-right">
                            {question.attendedCount} Attended
                          </h2>
                        </div>
                        <div className="lg:flex mt-[18px]">
                          <img
                            className="lg:w-[312px] md:h-[210px] w-full md:w-[300px] rounded-[10px] lg:rounded-[10px]"
                            src={question?.question?.questionImg}
                            alt="template"
                          />
                          <p className="lg:ml-[32px] text-white lg:mt-[80px] mt-[24px] font-[Poppins] xl:text-[25px] lg:text-[20px] text-[20px] font-[400] leading-normal text-justify">
                            {question?.question?.question}
                          </p>
                        </div>

                        {/* Options List */}
                        <div className="md:flex md:w-[100%] lg:mt-[32px]  grid w-full  gap-[24px] mt-[24px]">
                          {question?.question?.options
                            .filter((option: string) => option !== "")
                            .map((option: any, optionIndex: number) => {
                              const count =
                                question?.options[optionIndex]?.count;
                              const totalCount = question?.options.reduce(
                                (acc: number, option: any) =>
                                  acc + option.count,
                                0
                              );
                              const percentage =
                                totalCount > 0
                                  ? Math.round((count / totalCount) * 100)
                                  : 0;

                              return (
                                <div
                                  key={optionIndex}
                                  className="  md:w-[25%] w-full p-4 rounded-lg md:p-1 lg:p-3"
                                >
                                  <div className="flex items-center bg-[#192E3B] rounded-[10px] px-[16px] py-[20px]">
                                    <img
                                      className="mr-[16px] rounded-[10px]"
                                      src={
                                        option ===
                                        question?.question?.correctAnswer
                                          ? Tick
                                          : Cancel
                                      }
                                      alt="template"
                                    />
                                    {question?.question?.answerType ===
                                      "TEXT" && (
                                      <div className="text-white font-[Poppins] lg:text-[20px] text-[16px] font-[600] leading-normal">
                                        <span>{option}</span>
                                      </div>
                                    )}
                                    {question?.question?.answerType ===
                                      "IMAGE" && (
                                      <div>
                                        <img
                                          className="lg:w-[164px] h-[85.854px] md:w-[110px] w-[110px] md:h-[128px] rounded-[10px] lg:rounded-[10px]"
                                          src={option}
                                          alt="template"
                                        />
                                      </div>
                                    )}
                                    {question?.question?.answerType ===
                                      "BOOLEAN" && (
                                      <div className="text-white font-[Poppins] lg:text-[20px] text-[16px] font-[600] leading-normal ">
                                        <span>{option}</span>
                                      </div>
                                    )}
                                  </div>
                                  {/* Option Percentage */}
                                  <div className="text-white font-[Poppins] lg:text-[20px] text-[16px] w-full font-[600] leading-normal text-center bg-[#192E3B] rounded-[10px] mt-2 py-2">
                                    {percentage}%
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default QuizSummary;
