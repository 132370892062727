import React, { useEffect, useState } from "react";
import Navbar from "../../components/navBar/NavBar";
import { submitContactInformation } from "src/services/contact/contact.service";
import { Helmet } from "react-helmet";

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    reason: "",
  });
  const [scrolled, setScrolled] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    submitContactInformation(formData)
      .then((response) => {
        // Show success message
        setSuccessMessage("Form has been submitted successfully!");

        setTimeout(() => {
          setSuccessMessage("");
          // Clear form fields
          setFormData({
            name: "",
            email: "",
            phoneNumber: "",
            reason: "",
          });
        }, 3000);
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
      });
  };

  return (
    <div className="max-w-screen-2xl mx-auto  w-full">
      <Helmet>
        <title> Contact Us</title>
        <meta
          name="description"
          content="Get in touch with Quiz Circles for any inquiries or support."
        />
      </Helmet>
      {/* Navbar Component */}
      <Navbar
        className={`bg-[#F8F9FC] ${
          scrolled ? "bg-white z-[99] shadow-[#ABB3DE] shadow-lg " : ""
        }`}  templateDetailsMobileView={false}
        showmenuIcon={true}
      />

      <div className="bg-[#F8F9FC] p-5 font-[Inter] w-full min-h-screen overflow-y-hidden">
        {/* Main Form Container */}
        <div className="flex items-center justify-center ">
          <div className="max-w-md w-full mx-auto p-6 bg-white shadow-md rounded-md overflow-y-auto">
            <div className="mb-4">
              {/* Form Heading */}
              <h1 className="text-center text-[28px] font-[900] ">
                CONTACT US
              </h1>
            </div>
            <form onSubmit={handleSubmit}>
              {/* Name Field */}
              <div className="mb-4 space-y-2">
                <label className="block text-gray-700 ">
                  Name<span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="name"
                  placeholder="Full Name"
                  className="w-full px-3 py-2 border bg-[#f8fdfe] text-[14px] rounded-md focus:outline-none focus:border-blue-500"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              {/* Phone Number Field */}
              <div className="mb-4 space-y-2">
                <label className="block text-gray-700">Phone Number</label>
                <input
                  type="tel"
                  name="phoneNumber"
                  placeholder="Phone Number"
                  className="w-full px-3 py-2 border rounded-md bg-[#f8fdfe] text-[14px] focus:outline-none focus:border-blue-500"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                />
              </div>
              {/* Email Field */}
              <div className="mb-4 space-y-2">
                <label className="block text-gray-700">
                  Email<span className="text-red-500">*</span>
                </label>
                <input
                  type="email"
                  name="email"
                  placeholder="Work Email"
                  className="w-full px-3 py-2 border rounded-md bg-[#f8fdfe] text-[14px] focus:outline-none focus:border-blue-500"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              {/* Reason Field */}
              <div className="mb-4 space-y-2">
                <label className="block text-gray-700">
                  How can we help?
                  <span className="text-red-500">*</span>
                </label>
                <textarea
                  name="reason"
                  placeholder="How can we help?"
                  className="w-full px-3 py-2 border rounded-md bg-[#f8fdfe] text-[14px] focus:outline-none focus:border-blue-500"
                  value={formData.reason}
                  onChange={handleChange}
                  required
                />
              </div>
              {/* Submit Button */}
              <div className="flex justify-center ">
                <button
                  type="submit"
                  className="bg-gradient-to-r from-[#1EC6DA] to-[#6F2A96] text-white px-4 py-2 rounded-md  transition duration-200 w-full"
                >
                  Submit
                </button>
              </div>
            </form>
            {/* Success Message */}
            {successMessage && (
              <div className="mt-6 text-center text-green-500">
                {successMessage}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
