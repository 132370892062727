import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import LoaderComponent from "src/components/loader/LoaderComponent";
import Navbar from "src/components/navBar/NavBar";
import TemplateCard from "src/components/quizCard/QuizCard";
import { hostGameDetails } from "src/services/myGame/myGame.service";

const MyGame = () => {
  const navigate = useNavigate();
  const limit = 6;
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [isHovered, setIsHovered] = useState<number>();
  const [scrolled, setScrolled] = useState(false);
  const [gameDetails, setGameDetails] = useState<any[]>([]);
  const [hasData, setHasData] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const fetchGameDetails = async () => {
      setIsLoading(true);
      try {
        const params = { limit, page };
        const response = await hostGameDetails(params);
        setGameDetails([...gameDetails, ...(response?.games || [])]);
        // setGameDetailsCount(response?.count || 0);

        setHasData(
          response?.games?.length > 0 && page * limit < response?.count
        );
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchGameDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, page]);

  const loadMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  return (
    <div className="max-w-screen-2xl mx-auto  w-full">
      <Helmet>
        <meta charSet="UTF-8" />
        <title>My Game</title>
        <meta name="title" content="My Game | Quiz Circles" />
        <meta
          name="description"
          content="View the leaderboard for your played quizzes and track your performance in My Game on Quiz Circles."
        />
        <meta property="og:title" content="My Game | Quiz Circles" />
        <meta
          property="og:description"
          content="View the leaderboard for your played quizzes and track your performance in My Game on Quiz Circles."
        />
      </Helmet>
      <Navbar
        className={`top-0 ${
          scrolled ? "bg-white z-[99] shadow-[#ABB3DE] shadow-lg " : ""
        }`}
        templateDetailsMobileView={false}
        showmenuIcon={true}
      />

      <div className="w-full h-full bg-[#F8F9FC] overflow-y-auto min-h-screen font-[Poppins]">
        <div className="lg:px-[80px] px-[20px] md:px-[40px] sm:px-12 xl:px-[100px] mb-10 ">
          <h1 className="font-[Poppins] text-black text-[18px] font-semibold leading-normal mt-[2%] md:mt-[40px]">
            My Games
          </h1>
          {isLoading && (
        <div className="w-full h-full flex items-center justify-center z-50 min-h-[60vh]">
          <LoaderComponent />
        </div>
      )}
          {!isLoading && gameDetails.length === 0 && (
            <div className="flex items-center justify-center min-h-[60vh]">
              <p className="text-center text-black text-lg">
                No Games have been played.
              </p>
            </div>
          )}
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-3 md:gap-x-5 lg:gap-x-7 mt-[10px] gap-y-4 mb-8">
            {gameDetails.map((template, index) => {
              const eventDate = new Date(template?.createdAt);
              const date = eventDate.toLocaleDateString("en-GB");
              const time = eventDate.toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              });
              return (
                <button
                  className={`bg-white rounded-[12px] hover:bg-gradient-to-r hover:from-[#1EC6DA] hover:to-[#6F2A96] hover:border-none hover:border-transparent hover:text-white hover:items-center hover:justify-center hover:rounded-[8px] hover:shadow-[0px_20px_20px_-10px_rgba(131,42,184,0.4)] hover:inner-shadow-[inset 0px 20px 20px -10px rgba(131,42,184,0.4)]`}
                  onMouseEnter={() => setIsHovered(index)}
                  onMouseLeave={() => setIsHovered(-1)}
                  onClick={() => {
                    sessionStorage.setItem(
                      "quizID",
                      JSON.stringify(template?.quizID)
                    );
                    sessionStorage.setItem(
                      "slug",
                      JSON.stringify(template?.slug)
                    );
                    navigate(`/host/result/${template?.slug}?mygame`);
                  }}
                  key={template.id}
                >
                  <TemplateCard
                    id={template.id}
                    imageUrl={template.imageUrl}
                    name={template.quizName}
                    date={date}
                    time={time}
                    isHovered={isHovered === index}
                    needDeletebutton={false}
                  />
                </button>
              );
            })}
          </div>
          {hasData && (
            <div className="flex justify-center">
              <button
                className={`flex items-center justify-center gap-[10px] px-[32px] py-[17px] z-10 rounded-[12px] bg-gradient-to-r from-[#1EC6DA] to-[#6F2A96] border border-none text-white  hover:shadow-[0px_20px_20px_-10px_rgba(131,42,184,0.4)] hover:inner-shadow-[inset 0px 20px 20px -10px rgba(131,42,184,0.4)]`}
                onClick={loadMore}
              >
                Load More
              </button>
            </div>
          )}
        </div>
      </div>

     
    </div>
  );
};

export default MyGame;
