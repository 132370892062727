import React, { FC, useEffect, useRef, useState } from "react";
import { uploadImage } from "src/services/createQuiz/createQuiz.service";
import LoaderComponent from "src/components/loader/LoaderComponent";
import { debounce } from "lodash";
import ImageUpload from "../imageUpload/ImageUpload";

type ImageImageTypeComponentsProp = {
  response: (data: any) => void;
  questionData: any;
  readOnly?: boolean;
};

const EditImageImageTypeComponents: FC<ImageImageTypeComponentsProp> = ({
  response,
  questionData,
  readOnly,
}) => {
  const [questionImg, setQuestionImg] = useState<string>(
    questionData.questionImg
  );
  const [correctAnswer, setCorrectAnswer] = useState<any>(
    questionData.correctAnswer
  );
  const [question, setQuestion] = useState(questionData.question);
  const [options, setOptions] = useState(questionData.options);
  const [isLoading, setIsLoading] = useState(false);
  const [optionLoading, setOptionLoading] = useState<boolean[]>(
    new Array(options.length).fill(false)
  );
  const [categoryId, setCategoryId] = useState("");
  const questionId = questionData._id || "";
  const editable = questionData?.isEditable;
  const mapId = questionData?.mapId;

  useEffect(() => {
    setQuestion(questionData?.question);
    setCorrectAnswer(questionData?.correctAnswer);
    setQuestionImg(questionData?.questionImg);
    setOptions(questionData?.options);
  }, [questionData]);

  useEffect(() => {
    if (questionData?.category_id) {
      setCategoryId(questionData?.category_id);
    }
  }, [questionData]);

  useEffect(() => {
    if (!options.includes(questionData.correctAnswer)) {
      setCorrectAnswer(undefined);
    }
  }, [options, questionData.correctAnswer]);

  const responseRef = useRef(response);

  useEffect(() => {
    responseRef.current = response;
  }, [response]);

  useEffect(() => {
    const debouncedOutputData = debounce((data: any) => {
      responseRef.current(data);
    }, 300);

    const questionType = "IMAGE_TEXT";
    const answerType = "IMAGE";
    const data = {
      question,
      correctAnswer: correctAnswer || "",
      options,
      questionImg,
      questionType,
      answerType,
      _id: questionId,
      isEditable: editable,
      category_id: categoryId,
      mapId,
    };

    if (data) {
      debouncedOutputData(data);
    }

    // Cleanup function to cancel debounce on unmount
    return () => {
      debouncedOutputData.cancel();
    };
  }, [
    correctAnswer,
    question,
    options,
    questionImg,
    questionId,
    editable,
    categoryId,
    mapId,
  ]);

  const coverFileHandler = (event: any) => {
    setIsLoading(true);
    const file = event.target.files[0];
    if (file) {
      uploadImage(file)
        .then((imageUrl) => {
          setQuestionImg(imageUrl);
        })
        .catch((error) => {
          console.error("Error uploading image:", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const optionFileHandler = (event: any, index: number) => {
    const file = event.target.files[0];
    if (file) {
      setOptionLoading((prevLoading) => {
        const updatedLoading = [...prevLoading];
        updatedLoading[index] = true;
        return updatedLoading;
      });
      uploadImage(file)
        .then((imageUrl) => {
          setOptions((prevOptions: any) => {
            const updatedOptions = [...prevOptions];
            updatedOptions[index] = imageUrl;
            return updatedOptions;
          });
        })
        .catch((error) => {
          console.error("Error uploading image:", error);
        })
        .finally(() => {
          setOptionLoading((prevLoading) => {
            const updatedLoading = [...prevLoading];
            updatedLoading[index] = false;
            return updatedLoading;
          });
        });
    }
  };

  const handleCheckboxChange = (index: number) => {
    setCorrectAnswer(options[index]);
  };

  return (
    <div className="w-full h-full">
      <hr className="border-1 mt-2" />
      {/* Question Section*/}
      <section className="lg:flex mt-[18px]  items-center gap-4">
        <div className=" w-[300px] h-[200px] relative border-dashed border-[1px] border-[#1FC4D9] rounded-[10px]">
          {questionImg ? (
            <img
              className="w-[300px] h-[200px] rounded-[10px] p-2"
              src={questionImg}
              alt="template"
            ></img>
          ) : (
            <div className="lg:w-[312px] md:h-[210px] w-full md:w-[300px] flex items-center justify-center">
              <ImageUpload type="question" />
            </div>
          )}

          <div className="absolute inset-0 flex items-center justify-center  rounded-xl">
            {isLoading ? (
              <LoaderComponent />
            ) : (
              !readOnly && (
                <label
                  htmlFor="imageInput"
                  className="absolute inset-0 flex items-center justify-center rounded-[10px] cursor-pointer"
                >
                  <input
                    id="imageInput"
                    type="file"
                    accept="image/*"
                    className="absolute top-0 left-0 w-full h-full opacity-0"
                    onChange={coverFileHandler}
                  />
                </label>
              )
            )}
          </div>
        </div>

        <div className="border xl:mt-[40px] lg:mt-[20px] mt-8 border-[#D7D7D7] p-2 rounded-[10px] w-full">
          <textarea
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            className="text-[#000] font-[inter] lg:text-[18px] text-[16px] lg:font-[400] leading-normal ml-[40] mr-[10] w-full max-h-[60px] border resize-none placeholder:text-zinc-400 placeholder:text-lg placeholder:font-normal flex items-center box-border pt-4 pb-5 px-2 overflow-auto border-none focus:outline-none placeholder:items-center no-scrollbar"
            readOnly={readOnly}
          />
        </div>
      </section>

      {/* Options Section*/}
      <section className="space-y-4 lg:mt-[32px] mt-[24px] gap-2 text-[#000] font-[inter] lg:text-[18px] text-[16px] leading-normal ">
        <h2 className="text-xl font-semibold mb-4">Options</h2>
        {options.map((option: any, index: number) => (
          <div
            key={index}
            className="relative flex items-center bg-[#1FC4D903] lg:my-0 my-2 rounded-[10px] border-dashed border-[1px] border-[#1FC4D9]"
          >
            <div className=" relative w-[90%] p-2">
              {option ? (
                <img
                  className="lg:w-[164px] h-[85.854px] w-[110px] md:h-[128px] rounded-[10px]"
                  src={option}
                  alt={`option ${index}`}
                ></img>
              ) : (
                <div className="lg:w-[312px] md:h-[210px] w-full md:w-[300px] flex items-center justify-center">
                  <ImageUpload type="option" />
                </div>
              )}

              <div className="absolute inset-0 flex items-center justify-center  rounded-xl">
                {optionLoading[index] ? (
                  <LoaderComponent />
                ) : (
                  !readOnly && (
                    <label
                      htmlFor={`imageInput-${index}`}
                      className="absolute inset-0 flex items-center justify-center rounded-xl cursor-pointer"
                    >
                      <input
                        id={`imageInput-${index}`}
                        type="file"
                        accept="image/*"
                        className="absolute top-0 left-0 w-[200px] h-full opacity-0"
                        onChange={(event) => optionFileHandler(event, index)}
                      />
                    </label>
                  )
                )}
              </div>
            </div>
            <div className="absolute top-0 right-0 w-[10%] flex items-center h-full justify-center">
              <input
                className="rounded-[10px] border-[#0000002B] bg-white "
                type="checkbox"
                checked={correctAnswer === option}
                onChange={() => handleCheckboxChange(index)}
                disabled={readOnly}
              />
            </div>
          </div>
        ))}
      </section>
    </div>
  );
};

export default EditImageImageTypeComponents;
