import React from "react";
import { Link } from "react-router-dom";

import { Helmet } from "react-helmet";

const PageNotFound: React.FC = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <Helmet>
        <title>404 - Page Not Found</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <div id="main-content" className="text-center">
        <h1 className="text-9xl font-bold text-gray-800">404</h1>
        <p className="text-2xl font-medium text-gray-600">Page Not Found</p>
        <p className="mt-4 text-gray-500">
          We're sorry, but the page you're looking for cannot be found.
        </p>
        <p className="mt-4 text-gray-500">
          Please check the URL for errors or visit one of the following pages:
        </p>
        <div className="mt-6">
          <Link to="/home" className="text-blue-500 hover:underline">
            Home
          </Link>
          <br />
        </div>
        <p className="mt-4 text-gray-500">
          If you believe this is an error, please{" "}
          <Link to="/contact" className="text-blue-500 hover:underline">
            contact us
          </Link>
          .
        </p>
      </div>
    </div>
  );
};

export default PageNotFound;
