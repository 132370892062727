import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { FaRegClock } from "react-icons/fa";
type CustomTimePickerProp = {
  onChange: (data: any) => void;
  value: string;
};
const CustomTimePicker: FC<CustomTimePickerProp> = ({ onChange, value }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedHours, setSelectedHours] = useState("");
  const [selectedMinutes, setSelectedMinutes] = useState("");
  const [selectedOptionHours, setSelectedOptionHours] = useState<string | null>(
    null
  );
  const [selectedOptionMinutes, setSelectedOptionMinutes] = useState<
    string | null
  >(null);
  const [, setButtonClicked] = useState<"NOW" | "OK" | null>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  const generateOptions = (max: number, isHours: boolean) => {
    const options = [];
    for (let i = 0; i <= max; i++) {
      const formattedValue = i < 10 ? `0${i}` : `${i}`;
      options.push(
        <div
          key={formattedValue}
          onClick={() => handleOptionClick(formattedValue, isHours)}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === "Space") {
              handleOptionClick(formattedValue, isHours);
            }
          }}
          role="button" // Add a role to indicate it's an interactive element
          tabIndex={0} // Make it focusable
          className={`${isOptionSelected(
            formattedValue,
            isHours
          )} px-2 my-2 rounded-sm`}
        >
          {formattedValue}
        </div>
      );
    }
    return options;
  };
  const handleOptionClick = (value: string, isHours: boolean) => {
    if (isHours) {
      setSelectedHours(value);
      if (selectedMinutes === "") {
        setSelectedMinutes("00");
        setSelectedOptionMinutes("00");
      }
      setSelectedOptionHours(value);
      setButtonClicked(null);
    } else {
      if (selectedMinutes === "") {
        setSelectedHours("00");
        setSelectedOptionHours("00");
      }
      setSelectedMinutes(value);
      setSelectedOptionMinutes(value);
      setButtonClicked(null);
    }
  };
  const isOptionSelected = (value: string, isHours: boolean) => {
    return (isHours && selectedOptionHours === value) ||
      (!isHours && selectedOptionMinutes === value)
      ? "selected bg-gray-300"
      : "";
  };
  const handleTimeChange = () => {
    const newTime = `${selectedHours}:${selectedMinutes}`;
    onChange(newTime); // Notify the parent component about the change
    setButtonClicked("OK");
    closeModal();
  };
  const handleNowButtonClick = () => {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    setSelectedHours(hours);
    setSelectedMinutes(minutes);
    setSelectedOptionHours(hours);
    setSelectedOptionMinutes(minutes);
    const newTime = `${hours}:${minutes}`;
    onChange(newTime);
    setButtonClicked("NOW");
    closeModal();
  };
  const openModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = useCallback(() => {
    setIsModalVisible(false);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        closeModal();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectedHours, selectedMinutes, closeModal]);
  useEffect(() => {
    const newTime = `${selectedHours}:${selectedMinutes}`;
    onChange(newTime);
  }, [selectedHours, selectedMinutes, onChange]);

  return (
    <div className="relative w-[138.62px] h-full">
      <input
        className="px-2 border-gray-300 lg:ml-2 border w-[138.62px] h-[35.99px] rounded-md text-sm focus:outline-none focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm "
        type="text"
        value={
          selectedHours || selectedMinutes
            ? `${selectedHours}:${selectedMinutes}`
            : ""
        }
        onClick={openModal}
        placeholder="Select time"
        onChange={(e) => {
          const newValue = e.target.value;
          const [hours, minutes] = newValue.split(":");
          setSelectedHours(hours || "");
          setSelectedMinutes(minutes || "");
        }}
      />
      <FaRegClock className="absolute right-3 top-[10px] text-gray-300 flex " />
      {isModalVisible && (
        <div
          ref={modalRef}
          className="absolute z-[1] top-full left-0 mt-2 ml-2 bg-white border rounded shadow"
        >
          <div className="flex h-52 overflow-hidden w-28 pt-2">
            <div className="overflow-auto border-r pr-2 w-1/2 flex justify-center custom-scrollbar-thin">
              <div className="py-2">{generateOptions(23, true)}</div>
            </div>
            <div className="overflow-auto w-1/2 flex justify-center custom-scrollbar-thin">
              <div className="py-2">{generateOptions(59, false)}</div>
            </div>
          </div>
          <hr />
          <div className="w-full h-full p-2 flex justify-between">
            <button
              className="w-[40%] px-1 rounded-sm text-sm text-black font-semibold"
              onClick={handleNowButtonClick}
            >
              NOW
            </button>
            <button
              className="w-[40%] px-1 rounded-sm text-sm text-white bg-blue-500"
              onClick={handleTimeChange}
            >
              OK
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
export default CustomTimePicker;
