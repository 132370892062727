import instance from "src/http/httpClient";
// API Integration for quiz packages

export const getPaymentPlan = (quizId: string): Promise<any> => {
  return instance
    .get<any>(`/payment/plan`, {
      params: {
        quiz_id: quizId
      }
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};

// API Integration for payment
export const postPayment = (
  paymentMethodId: string,
  amount: string,
  selectedPlanId: string
): Promise<any> => {
  const data = {
    method_id: paymentMethodId,
    amount: amount,
    currency: "usd",
    plan_id: selectedPlanId,
  };
  return instance
    .post<any>(`/payment/charge`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);

      throw error;
    });
};
export const PaymentLink = (selectedPlanId: string): Promise<any> => {
  const data = {
    plan_id: selectedPlanId,
  };

  return instance
    .post<any>(`/payment/link`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};
