import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";

const BASE_URL = process.env.React_App_SERVER_URL;

axios.defaults.withCredentials = true;
// Create an Axios instance with default configuration
const instance: AxiosInstance = axios.create({
  baseURL: BASE_URL, // Replace with your API base URL
  timeout: 10000, // Timeout in milliseconds
  headers: {
    "Content-Type": "application/json",
    // Add any other default headers here
  },
});

// Add a request interceptor
instance.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    // You can modify the request configuration here
    // For example, add authentication headers or perform transformations

    // const token = localStorage.getItem("token");
    // if (token) {
    //   config.headers.Authorization = `Bearer ${token}`;
    // }
    return config;
  },
  (error) => {
    // Handle request errors
    return Promise.reject(error);
  }
);

// Add a response interceptor

let isAlertShown = false;

// Add a request interceptor
// instance.interceptors.request.use(
//   async (config: InternalAxiosRequestConfig) => {
//     // You can modify the request configuration here
//     // For example, add authentication headers or perform transformations
//     return config;
//   },
//   (error) => {
//     // Handle request errors
//     return Promise.reject(error);
//   }
// );
instance.interceptors.response.use(
  (response: AxiosResponse) => {
    // You can modify the response here
    // For example, handle global success scenarios
    return response;
  },
  async (error) => {
    if (error.response && error.response.status === 401) {
      if (!isAlertShown) {
        isAlertShown = true;
        localStorage.clear();
        sessionStorage.clear();

        // Set flag to show login dialog on home page
        localStorage.setItem("showLoginDialog", "true");

        alert("Your session has expired.Please login again.");
        window.location.href = "/home";
      }
    }
    return Promise.reject(error);
  }
);
// Define methods for HTTP requests

export const Get = <T>(
  url: string,
  config?: AxiosRequestConfig
): Promise<T> => {
  return instance.get<T>(url, config).then((response) => response.data);
};

export const Post = async <T>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig
): Promise<T> => {
  const response = await instance.post<T>(url, data, config);
  return response.data;
};

export const Put = <T>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig
): Promise<T> => {
  return instance.put<T>(url, data, config).then((response) => response.data);
};

export const Delete = <T>(
  url: string,
  config?: AxiosRequestConfig
): Promise<T> => {
  return instance.delete<T>(url, config).then((response) => response.data);
};

export default instance;
