import React, { FC } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import PrivateRoute from "./privateRoute";
import PublicRoute from "./publicRoute";
import Home from "src/pages/homePage/HomePage";
import PlayerHome from "src/pages/playerHome/PlayerHome";
import PlayerWaitingPage from "src/pages/playerWaitingPage/PlayerWaitingPage";
import TemplateDetails from "src/pages/templateDetails/TemplateDetails";
import PlayerQuizPage from "src/pages/playerQuizPage/PlayerQuizPage";
import HostGameMode from "src/pages/hostGameMode/HostGameMode";
import GameSettingsPage from "src/pages/gameSettingsPage/GameSettingsPage";
import QuizResult from "src/pages/quizResultDetails/QuizResult";
import PlayerLeaderboard from "src/pages/playerLeaderboard/PlayerLeaderboard";
import UpcomingGames from "src/pages/upcomingGames/UpcomingGames";
import MyLibrary from "src/pages/myLibrary/MyLibrary";
import PasswordReset from "src/pages/passwordReset/PasswordReset";
import PlayerQuizSummary from "src/components/playerQuizSummary/PlayerQuizSummary";
import PlayerQuizResult from "src/pages/quizResultDetails/PlayerQuizResult";
import ShareLeaderboard from "src/pages/share/ShareLeaderboard";
import Payment from "src/components/payment/Payment";
import DemoQuiz from "src/pages/demo/DemoQuiz";
import FAQ from "src/components/faqUi/faqUi";
import QuizCreation from "src/pages/createQuiz/QuizCreation";
import Contact from "src/pages/contact/Contact";
import AdminViewContact from "../pages/contact/AdminViewContact";
import MyGame from "src/pages/myGame/MyGame";
import PageNotFound from "src/routeConfig/PageNotFound";
import JoinPlayer from "src/pages/joinCode/JoinPlayer";

type RouteItem = {
  name: string;
  path: string;
  component: React.ComponentType;
  isPrivate: boolean;
};

const routesArray: RouteItem[] = [
  { name: "Home", path: "/home", component: Home, isPrivate: false },
];

const Routers: FC = () => {
  return (
    <Router>
      <Routes>
        {routesArray.map((route, index) => {
          return route.isPrivate ? (
            <Route
              key={index}
              path={route.path}
              element={<PrivateRoute component={route.component} />}
            />
          ) : (
            <Route
              key={index}
              path={route.path}
              element={<PublicRoute component={route.component} />}
            />
          );
        })}
        <Route path="/" element={<Navigate to="/home" />} />
        <Route path="/home" element={<Home />} />
        <Route path="/host/quiz-detail/:QuizId" element={<TemplateDetails />} />
        <Route
          path="host/quiz/game-settings/:gameId"
          element={<GameSettingsPage />}
        />
        <Route path="host/gamemode/:gameid" element={<HostGameMode />} />
        <Route path="host/result/:slug" element={<QuizResult />} />
        <Route path="player/result/:slug" element={<PlayerQuizResult />} />
        <Route path="demo/player/result/:slug" element={<PlayerQuizResult />} />
        <Route path="player/:slug" element={<PlayerHome />} />
        <Route path="player/demo/:slug" element={<PlayerHome />} />
        <Route path="join-player" element={<JoinPlayer />} />
        <Route path="player/waiting" element={<PlayerWaitingPage />} />
        <Route path="player/quiz/:slug" element={<PlayerQuizPage />} />
        <Route path="player-leaderboard/:slug" element={<PlayerLeaderboard />} />
        <Route path="host/upcoming-games" element={<UpcomingGames />} />
        <Route path="host/my-library" element={<MyLibrary />} />
        <Route path="host/my-game" element={<MyGame />} />
        <Route path="player-quiz-summary" element={<PlayerQuizSummary />} />
        <Route path="demo/:slug" element={<DemoQuiz />} />
        <Route path="Payment/:status" element={<Payment />} />
        <Route
          path="host/password-reset/:token/:email"
          element={<PasswordReset />}
        />
        <Route path="leaderboard/:slug" element={<ShareLeaderboard />} />
        <Route path="FAQ" element={<FAQ />} />
        <Route path="/create-quiz" element={<QuizCreation />} />
        <Route path="get-contact-details" element={<AdminViewContact />} />
        <Route path="contact" element={<Contact />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
  );
};

export default Routers;
