import React, { useState } from "react";
import Loader from "react-js-loader";

const LoaderComponent = () => {
  const [color] = useState("#008BB7");
  return (
    <div className={"App"}>
      <div className={"content"}>
        <div className={"zoom-out"}>
          <div className="flex">
            <div className={"item"}>
              <Loader
                type="spinner-cub"
                bgColor={color}
                color={color}
                size={50}
              />
            </div>
          </div>
          <div className="flex"></div>
        </div>
      </div>
    </div>
  );
};

export default LoaderComponent;
