import instance from "src/http/httpClient";

export const hostGameMode = (id: string): Promise<any> => {
  return instance
    .get<any>(`host/game/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};
