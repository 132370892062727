import instance from "src/http/httpClient";

export const gameStore = (data: string, data1?: string): Promise<any> => {
  return instance
    .post(`/host/game`, { quizID: data, payment_intent_id: data1 })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};

////////API Integration for game settings when toggle is OFF ///
export const gameSettingsSwitch = (
  gameSettingsId: string,
  payload: any
): Promise<any> => {
  return instance
    .put(`/host/setting/${gameSettingsId}`, payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};
