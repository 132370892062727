import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import image from "../../assets/playerHomeImg.svg";
import {
  getNameAndAvatar,
  playerCredential,
} from "src/services/playerHome/playerHome.services";
import LoaderComponent from "src/components/loader/LoaderComponent";
import Navbar from "src/components/navBar/NavBar";
import { Helmet } from "react-helmet";
import { adminDemoData } from "src/services/demoQuiz/demoQuiz.service";
import DemoQuiz from "../demo/DemoQuiz";
import events from "../websocket/EventsName";
import { io } from "socket.io-client";
import QuizTimer from "src/components/quizTimer/QuizTimer";

interface ScrollButtonProps {
  direction: "left" | "right";
  onClick: () => void;
  disabled: boolean;
  style?: React.CSSProperties; 
  hidden?: boolean; 
  scrollDistance: {
    current: number;
    total: number;
  }; 
}

const ScrollButton: React.FC<ScrollButtonProps> = ({
  direction,
  onClick,
  disabled,
  style,
  hidden,
  scrollDistance, 
}) => {
  const buttonText = direction === "left" ? "<<" : ">>";
  const isLeft = direction === "left";

  return (
    <button
      className={`absolute ${
        isLeft ? "left-0" : "right-0"
      } -translate-y-1/2 top-50% h-6 w-6 rounded-full ${
        disabled
          ? "bg-slate-800 text-white opacity-70"
          : "bg-white text-slate-400 pointer-events-auto"
      } shadow-md cursor-pointer hover:scale-110`}
      onClick={onClick}
      disabled={disabled}
      style={{
        ...style,
        visibility: hidden ? "hidden" : "visible",
      }}
    >
      {buttonText}
    </button>
  );
};

const PlayerHome = () => {
  const [quizDetails, setQuizDetails] = useState<any>();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [selectedAvatar, setSelectedAvatar] = useState<any>();
  const [nameEmpty, setNameEmpty] = useState("");
  const [emailEmpty, setEmailEmpty] = useState("");
  const scrollDiv = useRef<HTMLDivElement>(null);
  const [scrollDistance, setScrollDistance] = useState<{
    current: number;
    total: number;
  }>({ current: 0, total: 0 });
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const { slug } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const [openGame, setOpenGame] = useState<boolean>(false);
  const [gameResponse, setGameResponse] = useState<any>();
  const [timer, setTimer] = useState(false);
  const [countdown, setCountdown] = useState(3);

  const isEmailValid = (email: string) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };

  // Prepare audio
  const correctAnswerSoundRef = useRef(
    new Audio("../../audio/correctAudio.wav")
  );
  const wrongAnswerSoundRef = useRef(new Audio("../../audio/wrong1.mp3"));
  const newQuestionSoundRef = useRef(new Audio("../../audio/question.wav"));

  const handleStartQuiz = () => {
    // Function to prepare the audio without playing it
    const prepareAudio = (audioRef: HTMLAudioElement) => {
      audioRef
        .play()
        .then(() => {
          audioRef.pause(); 
          audioRef.currentTime = 0; 
        })
        .catch((error) => {
          console.error("Audio preparation failed:", error);
        });
    };

    // Prepare all the audio files when the quiz starts
    prepareAudio(correctAnswerSoundRef.current);
    prepareAudio(wrongAnswerSoundRef.current);
    prepareAudio(newQuestionSoundRef.current);

    if (!name && !email) {
      setNameEmpty("Field is required");
      setEmailEmpty("Field is required");
      return;
    }
    if (!name) {
      setEmailEmpty("");
      setNameEmpty("Field is required");
      return;
    }

    if (!email) {
      setNameEmpty("");
      setEmailEmpty("Field is required");
      return;
    }

    if (!isEmailValid(email)) {
      setEmailEmpty("Please enter a valid email");
      setNameEmpty("");
      return;
    } else {
      if (name && email && slug) {
        const userAgent = navigator.userAgent.toLowerCase();
        const isMobile =
          /iphone|ipad|ipod|android|blackberry|windows phone/.test(userAgent);
        const data = {
          name,
          email,
          avatarId: selectedAvatar?._id,
          isMobile,
          quizId: quizDetails?.quizId,
        };

        if (path === `/player/demo/${slug}`) {
          if (process.env.React_App_SERVER_URL) {
            const socket = io(process.env.React_App_SERVER_URL);
            adminDemoData(data)
              .then((response) => {
                setGameResponse(response);

                const quizData = {
                  slug: response?.playerData?.slug,
                  responseTime: response?.gameSettings?.respondTime,
                  restTime: response?.gameSettings?.restTime,
                  totalQuestions: response?.questionCount,
                  connectedPlayersCount: 1,
                };
                socket.emit(events.HOST_START_QUIZ, quizData);
                socket.emit(events.SEND_QUESTION_COUNTS, quizData);
              })
              .catch((error) => {
                console.error(error);
              });
          }
        } else {
          playerCredential(name, email, selectedAvatar?._id, slug, isMobile)
            .then((response) => {
              if (response) {
                sessionStorage.setItem("userInfo", JSON.stringify(response));
                sessionStorage.setItem("slug", JSON.stringify(slug));
                sessionStorage.setItem(
                  "playerId",
                  JSON.stringify(response.user._id)
                );
              }
              navigate(`/player/waiting?player_id=${response.user._id}`);
            })
            .catch((error) => {
              setShowPopup(true);
              setPopupMessage(error.response.data.message);
            });
        }
      }
    }
  };

  useEffect(() => {
    if (path === `/player/demo/${slug}` && gameResponse) {
      if (process.env.React_App_SERVER_URL) {
        const socket = io(process.env.React_App_SERVER_URL);
        socket.on("connect", () => {
          const playerId = gameResponse?.playerData?._id;
          const demoSlug = gameResponse?.playerData?.slug;

          socket.emit("player", { demoSlug, playerId });
        });
        // Listen for the countdown event
        socket.on(events.START_COUNTDOWNS, (countdownValue: number) => {
          setCountdown(countdownValue);
          setTimer(true);
        });
        socket.on(events.START_COUNTDOWNS, (updatedCountdown) => {
          setCountdown(updatedCountdown);
        });
        socket.on(events.FOR_NAVIGATION, (data) => {
          setTimer(false);
          setOpenGame(true);
        });
        return () => {
          socket.disconnect();
        };
      }
    }
  }, [gameResponse, slug, path]);

  useEffect(() => {
    if (slug) {
      setIsLoading(true);
      getNameAndAvatar(slug)
        .then((response) => {
          setQuizDetails(response);
          setSelectedAvatar(response.avatar[0]);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [slug]);

  const handleScroll = () => {
    if (scrollDiv.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollDiv.current;
      const totalScroll = scrollWidth - clientWidth;
      const currentScroll = scrollLeft;

      setScrollDistance({
        current: currentScroll,
        total: totalScroll,
      });
    }
  };

  useEffect(() => {
    const currentScrollDiv = scrollDiv.current;
    if (currentScrollDiv) {
      handleScroll(); 
      currentScrollDiv.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (currentScrollDiv) {
        currentScrollDiv.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  return (
    <main className="max-w-screen-2xl mx-auto  w-full ">
      <Helmet>
        <title>Player Home</title>
        <meta name="description" content="Join your quiz!" />
      </Helmet>
      {!openGame ? (
        <>
          <Navbar hideItems={true} className="  bg-[#192E3C] " />

          <div className="bg-[#192E3C] w-[full] min-h-screen overflow-y-auto font-[Poppins]">
            {isLoading ? (
              <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50">
                <LoaderComponent />
              </div>
            ) : (
              <div className="md:flex justify-center px-[5%]">
                <div className="text-[#95D6FF] text-[14px] font-normal  font-[Poppins] px-[3%] lg:w-[50%] leading-normal md:w-[85%]">
                  <h1 className="md:mt-[30px] flex  lg:w-[full] font-[Poppins]  text-white font-[600]  leading-normal lg:text-[50px] text-[38px] ">
                    {quizDetails?.quizName}
                  </h1>

                  <h2
                    className="text-[20px] font-semibold
              mt-[10px]"
                  >
                    Quiz Avatar
                  </h2>
                  <br />

                  <div className="lg:w-[400px] h-16 flex relative items-center">
                    <div
                      className="flex gap-[20px] overflow-x-auto  justify-between items-start lg:w-[400px] no-scrollbar mb-[32px]  "
                      ref={scrollDiv}
                    >
                      {quizDetails?.avatar.map((data: any, index: number) => (
                        <img
                          key={index}
                          className={`w-16 h-16 rounded-[64px] cursor-pointer ${
                            selectedAvatar?._id === data?._id
                              ? "border-blue-300 border-[10px]"
                              : ""
                          }`}
                          src={data?.avatarUrl}
                          alt="avatar"
                          onClick={() => setSelectedAvatar(data)}
                          loading="lazy"
                        />
                      ))}
                    </div>
                    <ScrollButton
                      direction="left"
                      onClick={() => {
                        scrollDiv.current?.scrollBy({ left: -300 });
                      }}
                      disabled={false}
                      style={{ left: "-2rem" }}
                      hidden={scrollDistance.current === 0} 
                      scrollDistance={scrollDistance} 
                    />
                    <ScrollButton
                      direction="right"
                      onClick={() => {
                        scrollDiv.current?.scrollBy({ left: 300 });
                      }}
                      disabled={false}
                      style={{ right: "-2rem" }}
                      hidden={
                        scrollDistance.current +
                          (scrollDiv.current?.clientWidth ?? 0) >=
                          (scrollDiv.current?.scrollWidth ?? 0) || 
                        !scrollDiv.current
                      }
                      scrollDistance={scrollDistance} 
                    />
                  </div>

                  <label className="text-[20px] font-semibold">Name</label>
                  <br />

                  <input
                    id="name"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    required
                    className="lg:w-[400px] w-full h-[56px] mb-[31.76px] mt-[13.18px] flex-shrink bg-[#233D4E] text-white rounded-[10px] px-[16px] py-[24px] gap-[8px]"
                  />
                  {nameEmpty && (
                    <div className="text-red-600">
                      <p>{nameEmpty}</p>
                    </div>
                  )}
                  <br />

                  <label className="text-[20px] font-semibold">Email</label>
                  <br />

                  <input
                    id="email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    required
                    className={`lg:w-[400px] w-full h-[56px] flex-shrink mb-[31.76px] mt-[13.18px] bg-[#233D4E] text-white rounded-[10px] px-[16px] py-[24px] gap-[8px] ${
                      emailEmpty ? "border-red-500" : ""
                    }`}
                  />
                  {emailEmpty && (
                    <div className="text-red-600">
                      <p>{emailEmpty}</p>
                    </div>
                  )}
                  <br />

                  <button
                    onClick={handleStartQuiz}
                    className="flex md:mb-[120px] mb-[65px] flex-shrink rounded-[5.008px] md:gap-[10.016px] text-white bg-[#008BB7] lg:w-[400px] w-full h-[56px] px-[10.016px] py-[20.032px] justify-center flex-col items-center text-[24px] font-semibold"
                  >
                    {path === `/player/demo/${slug}`
                      ? "Start Game"
                      : "Join Quiz"}
                  </button>
                </div>

                <div className="hidden lg:flex justify-center items-center  ">
                  <img
                    className="w-[90%] h-[95%]"
                    src={image}
                    alt="Player home"
                    loading="lazy"
                  ></img>
                </div>
              </div>
            )}
            {showPopup && (
              <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50 bg-black bg-opacity-50">
                <div className="lg:w-fit w-full h-auto min-h-[150px] flex flex-col md:max-w-[360px] min-w-[300px] px-2 md:px-4 bg-slate-50 rounded-lg shadow-lg py-2 items-center justify-center border border-slate-400">
                  <div className="flex w-full items-center justify-center my-3 text-[16px] md:text-[18px] font-bold text-center text-black">
                    <span>{popupMessage}</span>
                  </div>
                  <div className="flex flex-row w-full justify-between gap-x-2">
                    <button
                      className="flex gap-[10px]  px-4 py-2 rounded-[12px] bg-[#008BB7] border border-none text-white items-center justify-center w-full"
                      onClick={() => {
                        setShowPopup(false);
                      }}
                    >
                      <h1 className="text-[20px] font-semibold"> Ok</h1>
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <DemoQuiz responseAdmin={gameResponse} />
      )}
      <dialog open={timer}>
        <div
          className={`fixed bg-gray-900 opacity-80 top-0 left-0 w-full items-center h-full flex flex-col justify-center lg:px-0 px-[16px] z-[999999] `}
        >
          <QuizTimer countdown={countdown} />
        </div>
      </dialog>
    </main>
  );
};

export default PlayerHome;
