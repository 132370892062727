import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import LoaderComponent from "src/components/loader/LoaderComponent";
import { uploadImage } from "src/services/createQuiz/createQuiz.service";
import ImageUpload from "../imageUpload/ImageUpload";
import { debounce } from "lodash";

type ImageImageTYpeComponentsProp = {
  response: (data: any) => void;
  questions: any;
};

const placeholderImage =
  "https://www.svgrepo.com/show/508699/landscape-placeholder.svg";

const ImageImageTYpeComponents: FC<ImageImageTYpeComponentsProp> = ({
  response,
  questions,
}) => {
  const [questionImg, setQuestionImg] = useState(questions?.questionImg);
  const [listOfImages, setListOfImages] = useState<string[]>(
    new Array(4).fill("")
  );
  const [question, setQuestion] = useState(questions?.question || "");
  const [correctAnswer, setCorrectAnswer] = useState<string | undefined>(
    questions?.correctAnswer
  );
  const [isLoading, setIsLoading] = useState(false);
  const [optionLoading, setoptionLoading] = useState<boolean[]>(
    new Array(4).fill(false)
  );
  const [categoryId, setCategoryId] = useState("");

  const questionId = questions?._id;
  const editable = questions?.isEditable;
  const responseRef = useRef(response);

  const outputData = useCallback((data: any) => {
    responseRef.current(data);
  }, []);

  useEffect(() => {
    if (questions?.category_id) {
      setCategoryId(questions?.category_id);
    }
  }, [questions]);

  useEffect(() => {
    responseRef.current = response;
  }, [response]);

  useEffect(() => {
    const debouncedOutputData = debounce((data: any) => {
      responseRef.current(data);
    }, 300);
    if (question !== "") {
      const options = listOfImages.map(
        (image: any) => image || placeholderImage
      );

      const questionType = "IMAGE_TEXT";
      const answerType = "IMAGE";
      const data = {
        question,
        correctAnswer,
        options,
        questionImg,
        questionType,
        answerType,
        _id: questionId,
        isEditable: editable,
        category_id: categoryId,
      };
      debouncedOutputData(data);

      // Cleanup function to cancel debounce on unmount
      return () => {
        debouncedOutputData.cancel();
      };
    }
  }, [
    correctAnswer,
    editable,
    question,
    listOfImages,
    questionId,
    questionImg,
    outputData,
    categoryId,
  ]);

  const coverFileHandler = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      setIsLoading(true);
      try {
        const reader = new FileReader();
        reader.onload = () => {
          reader.result as string;
        };
        reader.readAsDataURL(file);

        const imageUrl = await uploadImage(file);
        setQuestionImg(imageUrl);
      } catch (error) {
        console.error("Error uploading image:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const optionFileHandler = async (event: any, index: number) => {
    const file = event.target.files[0];
    if (file) {
      setoptionLoading((prevState) => {
        const newState = [...prevState];
        newState[index] = true;
        return newState;
      });
      try {
        const imageUrl = await uploadImage(file);
        console.error("Error uploading image:", imageUrl);
        const updatedImages = listOfImages.map((image: any, i: number) => {
          if (i === index) {
            console.error("image:", imageUrl);

            return imageUrl;
          }
          console.error("Error uploading image:", image);

          return image;
        });

        setListOfImages(updatedImages);
      } catch (error) {
        console.error("Error uploading image:", error);
      } finally {
        setoptionLoading((prevState) => {
          const newState = [...prevState];
          newState[index] = false;
          return newState;
        });
      }
    }
  };

  return (
    <div className="w-full h-full">
      {/* Question Section */}
      <hr className="border-1 mt-2" />

      <section className="md:flex mt-[18px]  items-center gap-4">
        <div className="w-auto relative lg:w-[312px] md:h-[210px] md:w-[300px] rounded-[10px] border-dashed border border-[#1FC4D9] ">
          {!questionImg && (
            <div className="lg:w-[312px] md:h-[210px] w-full md:w-[300px] flex items-center justify-center">
              <ImageUpload type="question" />
            </div>
          )}
          {isLoading && <LoaderComponent />}
          {questionImg && !isLoading && (
            <img
              className="lg:w-[312px] md:h-[210px] w-full md:w-[300px] rounded-[10px] p-2"
              src={questionImg}
              alt="Preview"
            />
          )}
          <label
            htmlFor="imageInput"
            className="absolute inset-0 flex items-center justify-center rounded-[10px] cursor-pointer"
          >
            <input
              id="imageInput"
              type="file"
              accept="image/*"
              className="absolute top-0 left-0 w-full h-full opacity-0"
              onChange={coverFileHandler}
            />
          </label>
        </div>

        <div className="border xl:mt-[40px] lg:mt-[20px] mt-8 border-[#D7D7D7] p-2 rounded-[10px] w-full">
          <textarea
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            className="text-[#000] font-[inter] lg:text-[18px] text-[16px] lg:font-[400] leading-normal ml-[40] mr-[10]  w-full max-h-[60px] border resize-none placeholder:text-zinc-400 placeholder:text-lg placeholder:font-normal flex items-center box-border pb-5 pt-4 px-2 overflow-auto border-none focus:outline-none no-scrollbar "
            placeholder="Question"
          />
        </div>
      </section>

      {/* options Section*/}

      <section className="space-y-4 lg:mt-[32px] mt-[24px] gap-8 2xl:gap-8 text-[#000] font-[inter] lg:text-[18px] text-[16px] font-[600] leading-normal ">
        <h2 className="text-xl font-semibold mb-4">Options</h2>
        {listOfImages.map((image: any, index: number) => {
          return (
            <div
              className="relative flex items-center border-dashed border-[1px] border-[#1FC4D9] rounded-[10px] lg:my-0 my-2 bg-[#1FC4D903] "
              key={index}
            >
              <div className=" w-auto relative p-2">
                {image ? (
                  <img
                    className="w-[200px] h-[130px] rounded-[10px] object-cover "
                    src={image}
                    alt="template"
                  ></img>
                ) : (
                  <div className="w-[200px] h-[130px] rounded-[10px] object-cover flex items-center ">
                    <ImageUpload type="option" />
                  </div>
                )}

                <div className="absolute inset-0 flex items-center justify-center  rounded-xl">
                  {optionLoading[index] ? (
                    <LoaderComponent />
                  ) : (
                    <label
                      htmlFor={`imageInput-${index}`}
                      className="absolute inset-0 flex items-center justify-center rounded-xl cursor-pointer"
                    >
                      <input
                        id={`imageInput-${index}`}
                        type="file"
                        accept="image/*"
                        className="absolute top-0 left-0 w-[200px] h-full opacity-0"
                        onChange={(event) => optionFileHandler(event, index)}
                      />
                    </label>
                  )}
                </div>
              </div>
              <div className="absolute top-0 right-0 w-[10%] flex items-center h-full justify-center">
                <input
                  className="border-[#0000002B] bg-white rounded-[10px] "
                  type="checkbox"
                  name="answer"
                  checked={correctAnswer === image}
                  onChange={() => setCorrectAnswer(image)}
                />
              </div>
            </div>
          );
        })}
      </section>
    </div>
  );
};

export default ImageImageTYpeComponents;
