import instance from "src/http/httpClient";

// Fetch Avatar
export const getAvatar = (): Promise<any> => {
  return instance
    .get<any>("/players/avatar")
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
    });
};

export const getNameAndAvatar = (slug: string): Promise<any> => {
  return instance
    .get<any>(`/player-credential/${slug}`)
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
    });
};

// Save the details
export const playerCredential = (
  name: string,
  email: string,
  avatarId: string,
  slug: string,
  isMobile: boolean
): Promise<any> => {
  const data = { name, email, avatarId, slug, isMobile };
  return instance
    .post("/player-credential/login", data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};
