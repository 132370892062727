import img from "../../assets/seamless-wide-patterns-art-texture-is-symmetrical 1.png";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import TemplateCard from "src/components/quizCard/QuizCard";
import SearchBar from "src/components/searchBar/SearchBar";
import logoImg from "../../assets/icon.png";
import logoText from "../../assets/textIcon.png";
import { fetchQuizzes } from "src/services/homePage/homePage.service";
import {
  hostSignUp,
  hostLogin,
  hostVerify,
} from "src/services/hostCredential/hostCredential.services";
import Navbar from "src/components/navBar/NavBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { OtpValidationFields } from "src/components/OTP/OTP";
import { hostFogotPassword } from "src/services/hostCredential/hostCredential.services";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "react-js-loader";
import LoaderComponent from "src/components/loader/LoaderComponent";
import facebook from "../../assets/facebook.svg";
import likedIn from "../../assets/Linked.svg";
import instagram from "../../assets/instagram.svg";
import youtube from "../../assets/youtube.svg";
import box1 from "../../assets/StarFour.svg";
import box2 from "../../assets/Browsers.svg";
import box3 from "../../assets/Users.svg";
import box4 from "../../assets/Devices.svg";
import box5 from "../../assets/ImagesSquare.svg";
import img1 from "../../assets/Frame 38.png";
import img2 from "../../assets/Group 34225 (1).png";
import img3 from "../../assets/Group 34224.png";
import img4 from "../../assets/Frame 45.png";
import vector2 from "../../assets/Vector 2.svg";
import events from "../websocket/EventsName";
import { io } from "socket.io-client";
import QuizTimer from "src/components/quizTimer/QuizTimer";
import robo from "../../assets/Robo.png";
import arrow from "../../assets/Arrow 1.svg";
import bigStar from "../../assets/bigStar.png";
import smallStar from "../../assets/smallStar.png";
import StructuredData from "src/components/structuredData/StructuredData";
import { sharedStructuredData } from "src/components/structuredData/Data";
import { demoData } from "src/services/demoQuiz/demoQuiz.service";
import { debounce } from "lodash";
import { Helmet } from "react-helmet";

type Questiontype = {
  answerType: string;
  correct_answer: string;
  options: [];
  question: string;
  questionType: string;
};

interface TemplateDetail {
  name: string;
  imageUrl: string;
  questions: Questiontype[];
  played: number;
  _id: string;
  amount: string;
  is_free: string;
}

const Home = () => {
  const navigate = useNavigate();
  const [isRegisterOpen, setRegisterOpen] = useState(false);
  const [isLoginOpen, setLoginOpen] = useState(false);
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [nameEmpty, setNameEmpty] = useState("");
  const [emailEmpty, setEmailEmpty] = useState("");
  const [passwordEmpty, setPasswordEmpty] = useState("");
  const [loginEmail, setLoginEmail] = useState<string>("");
  const [loginPassword, setLoginPassword] = useState<string>("");
  const [loginError, setLoginError] = useState<string>("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [templateDetails, setTemplateDetails] = useState<TemplateDetail[]>([]);
  const [page, setPage] = useState(1);
  const limit = 6;

  const [signUpError, setSignUpError] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [isForgotPasswordOpen, setForgotPasswordOpen] = useState(false);
  const [totalQuiz, setTotalQuiz] = useState<number>(0);
  const [superhost, setSuperHost] = useState("");
  const [showMoreButton, setShowMoreButton] = useState(true);
  const [verification, setVerification] = useState(false);
  const [otp, setOtp] = useState("");
  const [validationError, setValidationError] = useState("");
  const [requestLink, setRequestLink] = useState("");
  const [loading, setLoading] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [isHovered, setIsHovered] = useState<number>();
  const [isFreeOn, setIsFreeOn] = useState(false);
  const [isPaidOn, setIsPaidOn] = useState(false);
  const [slug, setSlug] = useState("");
  const [demoDetail, setDemoDetail] = useState<any>();
  const [timer, setTimer] = useState(false);
  const [countdown, setCountdown] = useState(3);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [search, setSearch] = useState("");
  const carousalList = [0, 1];
  const shouldShowMoreButton = templateDetails.length < totalQuiz;

  const handlePlusIconClick = () => {
    if (!isLoggedIn) {
      openLoginDialog();
    } else {
      navigate("/create-quiz");
    }
  };

  useEffect(() => {
    setPage(1);
  }, [isFreeOn, isPaidOn]);

  const fetchData = async (
    updatedFields: {
      searchData?: string;
      freeOn?: boolean;
      paidOn?: boolean;
    } = {}
  ) => {
    const { searchData, freeOn, paidOn } = updatedFields;
    const searchGames = searchData !== undefined ? searchData : search;
    const freeGames = freeOn !== undefined ? freeOn : isFreeOn;
    const paidGames = paidOn !== undefined ? paidOn : isPaidOn;

    setLoading(true);
    let params: any = {
      limit,
      page,
    };

    if (searchGames) {
      params.searchTerm = searchGames;
    }

    if (freeGames && !paidGames) {
      params.is_free = "free";
    } else if (!freeGames && paidGames) {
      params.is_free = "paid";
    }

    try {
      const response = await fetchQuizzes(params);
      setTotalQuiz(response.count);

      if (response.quiz && response.quiz.length > 0) {
        if (page === 1) {
          setTemplateDetails(response.quiz);
        } else {
          setTemplateDetails((prevDetails) => [
            ...prevDetails,
            ...response.quiz,
          ]);
        }

        setShowMoreButton(response.quiz.length === limit);
      } else {
        setTemplateDetails([]);
        setShowMoreButton(false);
      }
    } catch (error) {
      console.error("Error loading quizzes:", error);
    } finally {
      setLoading(false);
    }
  };
  // eslint-disable-next-line
  const debouncedSearch = useCallback(debounce(fetchData, 750), []);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [page, limit, isFreeOn, isPaidOn]);

  const loadMore = async () => {
    setPage(page + 1);
  };

  useEffect(() => {
    // Check if login dialog flag is set
    const showLoginDialog = localStorage.getItem("showLoginDialog");
    if (showLoginDialog === "true") {
      setLoginOpen(true);

      // Clear the flag once used
      localStorage.removeItem("showLoginDialog");
    }
  }, []);

  useEffect(() => {
    if (isLoginOpen) {
      document.title = "Login";
    } else if (isRegisterOpen) {
      document.title = "SignUp";
    } else if (isForgotPasswordOpen) {
      document.title = " ForgotPassword";
    } else if (verification) {
      document.title = "EmailVerification";
    } else if (isFreeOn) {
      document.title = "FreeTemplates";
    } else if (isPaidOn) {
      document.title = " PaidTemplates";
    }
  }, [
    isLoginOpen,
    isRegisterOpen,
    isForgotPasswordOpen,
    verification,
    isFreeOn,
    isPaidOn,
  ]);

  const openRegisterDialog = () => {
    setRegisterOpen(true);
    setLoginOpen(false);
  };

  const openLoginDialog = () => {
    setLoginOpen(true);
    setRegisterOpen(false);
  };

  const closeDialogs = () => {
    setRegisterOpen(false);
    setLoginOpen(false);
    setName("");
    setEmail("");
    setPassword("");

    setLoginError("");
    setLoginEmail("");
    setLoginPassword("");

    setSignUpError("");
    setNameEmpty("");
    setEmailEmpty("");
    setPasswordEmpty("");
    setLoading(false);
  };

  const closeVerificationBox = () => {
    setVerification(false);
    setRegisterOpen(true);
  };

  // SignUp Validation
  const isEmailValid = (email: string) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };

  const handleSignUp = async () => {
    setNameEmpty("");
    setEmailEmpty("");
    setPasswordEmpty("");
    setSignUpError("");

    if (!name && !email && !password) {
      setNameEmpty("Field is required");
      setEmailEmpty("Field is required");
      setPasswordEmpty("Field is required");

      return;
    }
    if (!name) {
      setEmailEmpty("");
      setPasswordEmpty("");
      setNameEmpty("Field is required");
      return;
    }

    if (!email) {
      setNameEmpty("");
      setPasswordEmpty("");
      setEmailEmpty("Field is required");
      return;
    }

    if (!password) {
      setNameEmpty("");
      setPasswordEmpty("Field is required");
      setEmailEmpty("");
      return;
    }
    if (password.length < 6 || password.length > 12) {
      setPasswordEmpty("Password must be between 6 and 12 characters");
      setNameEmpty("");
      setEmailEmpty("");
      return;
    }

    if (!isEmailValid(email)) {
      setEmailEmpty("Please enter a valid email");
      setNameEmpty("");
      setPasswordEmpty("");
      return;
    }
    setLoading(true);
    try {
      const response = await hostSignUp({ email });
      sessionStorage.setItem("isLoggedIn", "true");
      if (response.message) {
        setVerification(true);
        setRegisterOpen(false);
      }
    } catch (error: any) {
      console.error("SignUp failed:", error);

      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setSignUpError(error.response.data.message);
      } else {
        // Fallback error message
        setSignUpError("Failed to sign up. Please try again later.");
      }
    }
    setIsLoggedIn(true);
    setLoading(false);
  };

  // Login
  const handleLogin = async () => {
    setLoading(true);

    setLoginError("");

    if (!loginEmail || !loginPassword) {
      setLoginError("Email and password are required");
      setLoading(false);
      return;
    }

    // Validate email format
    if (!isEmailValid(loginEmail)) {
      setLoginError("Please enter a valid email");
      setLoading(false);
      return;
    }

    try {
      const userAgent = navigator.userAgent.toLowerCase();
      const isMobile = /iphone|ipad|ipod|android|blackberry|windows phone/.test(
        userAgent
      );
      const response = await hostLogin(loginEmail, loginPassword, isMobile);

      if (response.statusCode === 400) {
        setLoginError(response.message);
      } else {
        localStorage.setItem("isLoggedIn", JSON.stringify(true));
        localStorage.setItem("hostID", JSON.stringify(response.user._id));
        localStorage.setItem("Name", JSON.stringify(response.user.name));
        sessionStorage.setItem("isLoggedIn", JSON.stringify(true));
        sessionStorage.setItem("hostID", JSON.stringify(response.user._id));
        sessionStorage.setItem("Name", JSON.stringify(response.user.name));
        setSuperHost(response.user.host_type);
        sessionStorage.setItem(
          "superHost",
          JSON.stringify(response.user.host_type)
        );
        localStorage.setItem(
          "superHost",
          JSON.stringify(response.user.host_type)
        );
        setIsLoggedIn(true);
        setLoginOpen(false);
        setLoginEmail("");
        setLoginPassword("");
        setName("");
        setEmail("");
        setPassword("");
      }
    } catch (error) {
      setLoginError("Invalid Credentials");
    } finally {
      setLoading(false);
    }
  };

  const openForgotPasswordDialog = () => {
    setLoginOpen(false);
    setForgotPasswordOpen(true);
  };

  const closeForgotPasswordDialog = () => {
    setForgotPasswordOpen(false);
  };

  const onVerify = async () => {
    if (otp && name && password && email) {
      try {
        const response = await hostVerify({
          name: name,
          password: password,
          email: email,
          code: otp,
        });
        sessionStorage.setItem("hostID", JSON.stringify(response._id));
        setVerification(false);
        setLoginOpen(true);
        setLoginEmail(email);
        setLoginPassword("");
      } catch (error) {
        console.error("Verification error:", error);
        setValidationError("Invalid OTP or credentials");
        setTimeout(() => {
          setValidationError("");
        }, 5000);
      }
    }
  };

  const handleSendLink = () => {
    if (!requestLink) {
      toast.error("Email is required!");
      return;
    }
    handleForgotPassword();
  };

  const handleForgotPassword = async () => {
    setLoading(true);
    try {
      await hostFogotPassword(requestLink);
      toast.success(
        "Password reset link successfully sent to your email address."
      );
      closeForgotPasswordDialog();
      setRequestLink("");
    } catch (error) {
      console.error("Error sending reset password link:", error);
      toast.error("Invalid email address. Please enter a valid email address.");
    }
    setLoading(false);
  };

  const handleJoiningPlay = () => {
    navigate("/join-player");
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleFree = () => {
    setIsFreeOn((prev) => !prev);
  };

  const handlePaid = () => {
    setIsPaidOn((prev) => !prev);
  };

  const startGame = () => {
    if (process.env.React_App_SERVER_URL) {
      const socket = io(process.env.React_App_SERVER_URL);
      demoData()
        .then((response: any) => {
          setDemoDetail(response);
          setSlug(response?.gameSetting?.slug);
          sessionStorage.setItem("demoResponse", JSON.stringify(response));
          sessionStorage.setItem(
            "guestAvatar",
            JSON.stringify(response?.playerData?.avatarUrl)
          );
          sessionStorage.setItem(
            "playerId",
            JSON.stringify(response?.playerData?._id)
          );
          sessionStorage.setItem(
            "slug",
            JSON.stringify(response?.playerData?.slug)
          );
          const quizData = {
            slug: response?.gameSetting?.slug,
            responseTime: response?.gameSetting?.respondTime,
            restTime: response?.gameSetting?.restTime,
            totalQuestions: response?.questionCount,
            connectedPlayersCount: 1,
          };
          socket.emit(events.HOST_START_QUIZ, quizData);
          socket.emit(events.SEND_QUESTION_COUNTS, quizData);
        })
        .catch((error: any) => {
          console.error(error);
        });
    }
  };

  useEffect(() => {
    if (process.env.React_App_SERVER_URL) {
      const socket = io(process.env.React_App_SERVER_URL);

      socket.on(events.SOCKET_CONNECTING, () => {
        const userInfo = demoDetail?.playerData;
        const savedAvatarUrl = demoDetail?.playerData?.avatarUrl;

        if (userInfo) {
          socket.emit(events.SEND_CLIENT_DATA, {
            _id: userInfo?._id,
            name: userInfo?.name,
            avatarUrl: savedAvatarUrl,
            slug: slug,
          });
        }
      });

      socket.on(events.GET_READY, () => {
        setTimer(true);
      });
      socket.on(events.START_COUNTDOWNS, (updatedCountdown) => {
        setTimer(true);
        setCountdown(updatedCountdown);
      });

      socket.on(events.FOR_NAVIGATION, (data) => {
        navigate(`/demo/${slug}`);
      });

      return () => {
        socket.disconnect();
      };
    }
  }, [demoDetail, slug, navigate]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide === 0 ? 1 : 0));
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="max-w-screen-2xl mx-auto  w-full overflow-y-auto">
      <Helmet>
        <meta charSet="UTF-8" />
        <title>Create Online Quizzes - 2000+ Free & paid Templates | Quiz Circles</title>
        <meta name="title" content="Create Online Quizzes - 2000+ Free & paid Templates | Quiz Circles" />
        <meta
          name="description"
          content="Discover 2000+ exciting quiz templates online for free! Enjoy this online game and pay per game. Start your quiz adventure and share the fun with friends!"
        />
        <meta property="og:title" content="Create Online Quizzes - 2000+ Free & paid Templates | Quiz Circles" />
        <meta
          property="og:description"
          content="Discover 2000+ exciting quiz templates online for free! Enjoy this online game and pay per game. Start your quiz adventure and share the fun with friends!"
        />
      </Helmet>

      <StructuredData data={sharedStructuredData} />
      <ToastContainer position="top-center" style={{ zIndex: 99999999 }} />
      {/* navbar */}

       <Navbar
        openLoginDialog={openLoginDialog}
        openRegisterDialog={openRegisterDialog}
        isLogin={setIsLoggedIn}
        showLogin={true}
        className={`${
          scrolled ? "bg-white z-[999999] shadow-[#ABB3DE] shadow-lg " : ""
        } fixed top-0 max-w-screen-2xl mx-auto w-full z-10`}
        lgGapSizeHome="3"
        lgPaddingHome="[40px]"
        startGame={startGame}
        sticky={false}
        templateDetailsMobileView={false}
        showmenuIcon={true}
      />

      <div className="w-full  relative top-0 left-0 z-0 ">
        <section
          className={`relative w-full h-[800px] sm:h-[900px] md:h-[1000px] lg:h-[800px] `}
        >
          <img
            className={`w-full h-full object-cover object-center`}
            src={img}
            alt="Background"
            loading="lazy"
          />
          {/* buttons responsive */}
          <div className="absolute flex-col px-[20px] md:px-[80px] xl:px-[100px] bottom-[180px] sm:bottom-[200px] md:bottom-[180px] space-y-5 w-full block lg:hidden">
            <div className="flex justify-center gap-[10px] font-[Outfit]">
              <button
                className={`flex  items-center gap-[10px]  px-6 py-5 rounded-full bg-gradient-to-r from-[#1EC6DA] to-[#6F2A96] border border-none text-white justify-center hover:shadow-[0px_20px_20px_-10px_rgba(131,42,184,0.4)] hover:inner-shadow-[inset 0px 20px 20px -10px rgba(131,42,184,0.4)] z-50 md:mt-4`}
                onClick={handlePlusIconClick}
              >
                <div className="flex items-center justify-center gap-2">
                  <h2 className="font-normal text-[16px]  text-white">
                    Create A Quiz
                  </h2>
                  <div className="w-[22px] flex ">
                    <img
                      className={`transition-transform duration-300 ${
                        isHovered ? "filter brightness-0 invert" : ""
                      }`}
                      src={arrow}
                      alt="Create Quiz"
                      loading="lazy"
                    />
                  </div>
                </div>
              </button>
              {!isLoggedIn && (
                <button
                  className={`flex items-center gap-[10px]  px-6 py-5 rounded-full bg-gradient-to-r from-[#1EC6DA] to-[#6F2A96] border border-none text-white justify-center hover:shadow-[0px_20px_20px_-10px_rgba(131,42,184,0.4)] hover:inner-shadow-[inset 0px 20px 20px -10px rgba(131,42,184,0.4)] z-50 md:mt-4`}
                  onClick={handleJoiningPlay}
                >
                  <button className=" text-[16px] font-normal text-white">
                    Join Game
                  </button>
                  <div className="w-[22px] flex ">
                    <img
                      className={`transition-transform duration-300 ${
                        isHovered ? "filter brightness-0 invert" : ""
                      }`}
                      src={arrow}
                      alt="Create Quiz"
                      loading="lazy"
                    />
                  </div>
                </button>
              )}
            </div>

            <div className="flex gap-2 justify-center">
              {carousalList.map((item, index) => (
                <button
                  key={index}
                  type="button"
                  className={`w-3 h-3 rounded-full ${
                    currentSlide === index
                      ? "bg-gradient-to-r from-[#1EC6DA] to-[#6F2A96]"
                      : "bg-[#D9D9D9]"
                  }`}
                  aria-current="false"
                  aria-label={`Slide ${index}`}
                  onClick={() => setCurrentSlide(index)}
                ></button>
              ))}
            </div>
          </div>
          {/* middle part */}
          <div className="md:px-[80px] z-[999] w-full flex justify-center absolute bottom-[30px]">
            <div className=" bg-white border-[#E4E9F6] border-[1px]  p-3 sm:p-4 lg:py-7 lg:px-[28px] flex justify-center items-center gap-[5px] sm:gap-[15px] lg:gap-[20px] xl:gap-[20px] rounded-[10px] lg:rounded-[20px] 2xl:rounded-[30px] overflow-x-auto lg:overflow-visible mt-5 shadow-[0px_15px_40px_25px_rgba(186, 194, 219, 0.25)] ">
              <div className="bg-[#BB6CEB0D] rounded-[10px] w-[130px] lg:w-[150px] xl:w-[185px] 2xl:w-[250px] lg:p-6 p-[5px] flex flex-col items-center justify-center gap-[16px] text-[12px] 2xl:text-[16px] font-[600] md:h-auto h-[120px]   ">
                <img src={box1} alt="Quick Start" loading="lazy" />
                <p className="flex justify-center items-center text-center">
                  QuizGen AI
                </p>
              </div>
              <div className="bg-[#BB6CEB0D] rounded-[10px] w-[130px] lg:w-[150px] xl:w-[185px] 2xl:w-[250px] lg:p-6 p-[5px] flex flex-col items-center justify-center gap-[16px] text-[12px] 2xl:text-[16px] font-[600] md:h-auto h-[120px] ">
                <img src={box2} alt="Templates" loading="lazy" />
                <p className="flex justify-center items-center text-center">
                  2000+ Templates
                </p>
              </div>
              <div className="bg-[#BB6CEB0D] rounded-[10px] w-[130px] lg:w-[150px] xl:w-[185px] 2xl:w-[250px] lg:p-6 p-[5px] 2xl:p-[6] flex flex-col items-center justify-center gap-[16px] text-[12px] 2xl:text-[16px] font-[600] md:h-auto h-[120px]  ">
                <img src={box3} alt="Invite Players" loading="lazy" />
                <p className="flex justify-center items-center text-center">
                  Invite Players
                </p>
              </div>
              <div className="bg-[#BB6CEB0D] rounded-[10px] w-[130px] lg:w-[150px] xl:w-[185px] 2xl:w-[250px] lg:p-6 p-[5px] flex flex-col items-center justify-center gap-[16px] text-[12px] 2xl:text-[16px] font-[600] md:h-auto h-[120px] ">
                <img src={box4} alt="Mobile & web" loading="lazy" />
                <p className="flex justify-center items-center text-center">
                  Mobile & Web
                </p>
              </div>
              <div className="bg-[#BB6CEB0D] rounded-[10px] w-[130px] lg:w-[150px] xl:w-[185px] 2xl:w-[250px] lg:p-6 p-[5px] flex flex-col items-center justify-center gap-[16px] text-[12px] 2xl:text-[16px] font-[600] md:h-auto h-[120px] ">
                <img src={box5} alt="Quiz" loading="lazy" />
                <p className="flex justify-center items-center text-center">
                  Image & Text Quiz
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="absolute top-[80px] left-0 w-full">
          <div>
            {/* part 1 */}

            <div className="relative z-10">
              {currentSlide === 0 ? (
                <div className="px-[20px] md:px-[80px] xl:px-[100px] flex lg:flex-row flex-col space-y-4 ">
                  <div
                    className={`lg:w-[52%] w-full h-full flex-col space-y-8 `}
                  >
                    <div className="flex-col ">
                      <div className="lg:flex hidden lg:ml-[350px] xl:ml-[450px] lg:w-[179px] lg:h-[119px]">
                        <img
                          className=""
                          src={bigStar}
                          alt="star"
                          loading="lazy"
                        />
                      </div>
                      <h1 className="xl:text-[60px] lg:text-[48px] md:text-[50px] sm:text-[40px] text-[30px] font-bold leading-none lg:leading-[75px] lg:mt-[-60px] font-[Urbanist] text-center lg:text-start ">
                        Compete Globally
                        <br /> Test Your Knowledge
                        <br /> Climb Leaderboards!
                      </h1>
                    </div>
                  </div>
                  <div
                    className={`lg:w-[48%] w-full flex justify-end h-[300px] sm:h-[350px] md:h-[440px] lg:h-[500px]`}
                  >
                    <img className=" mt-[-30px]" src={robo} alt="robo" />
                  </div>
                </div>
              ) : (
                <div className="px-[20px] md:px-[80px] xl:px-[100px] flex-col ">
                  <div className=" flex lg:justify-between lg:flex-row flex-col lg:space-y-0 md:space-y-10 sm:space-y-[60px] space-y-5 ">
                    <div className=" lg:w-[50%] w-full h-[1/2] flex lg:justify-start justify-center ">
                      <h1 className="xl:text-[66px] md:text-[50px] sm:text-[40px] text-[30px] font-extrabold xl:leading-[78px] font-[Urbanist] lg:mt-[90px] lg:text-left text-center leading-normal">
                        Pay Per Game
                        <span className="text-[#BB6CEB] block">
                          No Subscription
                        </span>
                      </h1>
                    </div>
                    <div className="flex justify-center">
                      <div className=" h-[400px] lg:w-auto  w-full   ">
                        <div
                          className={`relative h-full flex w-full lg:items-end lg:justify-end justify-center pl-[10px]  `}
                        >
                          <img
                            className="xl:w-[550px] lg:h-[300px] lg:w-[390px]  sm:w-[550px] sm:h-[300px] md:w-[600px] md:h-[400px] h-[290px] w-[300px] "
                            src={vector2}
                            alt="vector"
                            loading="lazy"
                          />
                        </div>

                        <div className=" lg:mt-[-340px] xl:mt-[-350px] md:mt-[-440px] sm:mt-[-460px] mt-[-400px] absolute gap-0 lg:block hidden">
                          <div className="flex gap-[70px] sm:gap-[100px] md:gap-[130px] lg:gap-[75px] xl:gap-[70px] 2xl:gap-[70px] xl:pl-[70px] 2xl:pl-[70px] md:pl-[10px] sm:pl-[30px] pl-[20px] ">
                            <div className=" ">
                              <img
                                className="w-[100px] h-[110px] sm:w-[160px] sm:h-[140px] md:w-[200px] md:h-[180px] lg:w-[150px] xl:w-[170px] lg:h-[140px] 2xl:h-[140px] "
                                style={{
                                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                                }}
                                src={img1}
                                alt="background1"
                                loading="lazy"
                              />
                            </div>
                            <div className="flex justify-center items-center ">
                              <img
                                className="w-[180px] h-[150px] sm:w-[300px] sm:h-[200px] md:w-[320px] md:h-[250px] lg:w-[200px] xl:w-[260px] lg:h-[200px] 2xl:w-[280px] "
                                style={{
                                  filter:
                                    "drop-shadow(0px 11.581px 24.816px rgba(0, 139, 183, 0.20))",
                                }}
                                src={img2}
                                alt="background2"
                                loading="lazy"
                              />
                            </div>
                          </div>

                          <div className=" 2xl:gap-[80px] absolute flex gap-[50px] sm:gap-[70px] md:gap-[90px] lg:gap-[60px] xl:gap-[90px] xl:pl-[20px]">
                            <div className="sm:mt-[-30px]  md:ml-[-40px] xl:ml-auto   ">
                              <img
                                className="xl:w-[180px] lg:h-[160px] 2xl:w-[200px] 2xl:h-[180px] md:w-[250px] md:h-[200px] sm:w-[200px] sm:h-[180px] w-[130px] h-[120px] "
                                src={img3}
                                alt="background3"
                                loading="lazy"
                              />
                            </div>

                            <div className="flex gap-0 items-center ">
                              <img
                                className="w-[100px] h-[110px] md:w-[200px] md:h-[190px] sm:w-[170px] sm:h-[160px] lg:w-[140px] lg:h-[150px] xl:w-[150px] xl:h-[160px] 2xl:w-[170px] "
                                style={{
                                  filter:
                                    "drop-shadow(0px 5.404px 20.617px rgba(106, 132, 182, 0.25))",
                                }}
                                src={img4}
                                alt="background4"
                                loading="lazy"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="flex justify-center lg:hidden ">
                          <div className=" lg:mt-[-340px] xl:mt-[-350px] md:mt-[-440px] sm:mt-[-460px] mt-[-400px] absolute gap-0">
                            <div className="flex gap-[70px] sm:gap-[100px] md:gap-[130px] lg:gap-[75px] xl:gap-[70px] 2xl:gap-[70px] xl:pl-[70px] 2xl:pl-[70px] md:pl-[10px] sm:pl-[30px] pl-[20px] ">
                              <div className=" ">
                                <img
                                  className="w-[100px] h-[110px] sm:w-[160px] sm:h-[140px] md:w-[200px] md:h-[180px] lg:w-[150px] xl:w-[170px] lg:h-[140px] 2xl:h-[140px] "
                                  style={{
                                    boxShadow:
                                      "0px 4px 4px rgba(0, 0, 0, 0.25)",
                                  }}
                                  src={img1}
                                  alt="background1"
                                  loading="lazy"
                                />
                              </div>
                              <div className="flex justify-center items-center ">
                                <img
                                  className="w-[180px] h-[150px] sm:w-[300px] sm:h-[200px] md:w-[320px] md:h-[250px] lg:w-[200px] xl:w-[260px] lg:h-[200px] 2xl:w-[280px] "
                                  style={{
                                    filter:
                                      "drop-shadow(0px 11.581px 24.816px rgba(0, 139, 183, 0.20))",
                                  }}
                                  src={img2}
                                  alt="background2"
                                  loading="lazy"
                                />
                              </div>
                            </div>

                            <div className=" 2xl:gap-[80px] absolute flex gap-[50px] sm:gap-[70px] md:gap-[90px] lg:gap-[60px] xl:gap-[90px] xl:pl-[20px]">
                              <div className="sm:mt-[-30px]  md:ml-[-40px] xl:ml-auto   ">
                                <img
                                  className="xl:w-[180px] lg:h-[160px] 2xl:w-[200px] 2xl:h-[180px] md:w-[250px] md:h-[200px] sm:w-[200px] sm:h-[180px] w-[130px] h-[120px] "
                                  src={img3}
                                  alt="background3"
                                  loading="lazy"
                                />
                              </div>

                              <div className="flex gap-0 items-center ">
                                <img
                                  className="w-[100px] h-[110px] md:w-[200px] md:h-[190px] sm:w-[170px] sm:h-[160px] lg:w-[140px] lg:h-[150px] xl:w-[150px] xl:h-[160px] 2xl:w-[170px] "
                                  style={{
                                    filter:
                                      "drop-shadow(0px 5.404px 20.617px rgba(106, 132, 182, 0.25))",
                                  }}
                                  src={img4}
                                  alt="background4"
                                  loading="lazy"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="absolute flex-col px-[20px] md:px-[80px] xl:px-[100px] lg:space-y-2 lg:top-[280px] lg:w-[60%] w-full lg:block hidden">
                <div className="flex gap-[10px] font-[Outfit]">
                  <button
                    className={`flex items-center gap-[10px] lg:px-[32px] lg:py-[17px] px-4 py-2 rounded-full bg-gradient-to-r from-[#1EC6DA] to-[#6F2A96] border border-none text-white justify-center hover:shadow-[0px_20px_20px_-10px_rgba(131,42,184,0.4)] hover:inner-shadow-[inset 0px 20px 20px -10px rgba(131,42,184,0.4)] z-50 md:mt-4`}
                    onClick={handlePlusIconClick}
                  >
                    <div className="flex items-center justify-center gap-2">
                      <button className="font-normal text-[16px]  text-white">
                        Create A Quiz
                      </button>
                      <div className="w-[22px] mt-1 flex items-center  ">
                        <img
                          className={`transition-transform duration-300 ${
                            isHovered ? "filter brightness-0 invert" : ""
                          }`}
                          src={arrow}
                          alt="Create Quiz"
                          loading="lazy"
                        />
                      </div>
                    </div>
                  </button>
                  {!isLoggedIn && (
                    <button
                      className={`flex items-center gap-[10px] lg:px-[52px] md:px-10 lg:py-[17px] px-4 py-2 rounded-full bg-gradient-to-r from-[#1EC6DA] to-[#6F2A96] border border-none text-white justify-center hover:shadow-[0px_20px_20px_-10px_rgba(131,42,184,0.4)] hover:inner-shadow-[inset 0px 20px 20px -10px rgba(131,42,184,0.4)] z-50 md:mt-4`}
                      onClick={handleJoiningPlay}
                    >
                      <p className=" text-[16px] font-normal text-white">
                        Join Game
                      </p>
                      <div className="w-[22px]  mt-1 flex items-center ">
                        <img
                          className={`transition-transform duration-300 ${
                            isHovered ? "filter brightness-0 invert" : ""
                          }`}
                          src={arrow}
                          alt="Create Quiz"
                          loading="lazy"
                        />
                      </div>
                    </button>
                  )}
                </div>
                <div className="flex justify-between items-center">
                  <div className="flex gap-2 ml-20 justify-start ">
                    {carousalList.map((item, index) => (
                      <button
                        key={index}
                        type="button"
                        className={`w-3 h-3 rounded-full ${
                          currentSlide === index
                            ? "bg-gradient-to-r from-[#1EC6DA] to-[#6F2A96]"
                            : "bg-[#D9D9D9]"
                        }`}
                        aria-current="false"
                        aria-label={`Slide ${index}`}
                        onClick={() => setCurrentSlide(index)}
                      ></button>
                    ))}
                  </div>
                  <div
                    className={`lg:flex hidden lg:ml-[350px]  w-[70px] h-[50px]`}
                  >
                    <img
                      className=""
                      src={smallStar}
                      alt="star"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* part 2 */}

        <section
          className={`relative bg-[#f8f9fc] overflow-y-auto w-full h-full px-[20px] ${
            isLoggedIn ? "lg:px-[40px]" : "lg:px-[80px]"
          }  xl:px-[100px]`}
        >
          {/* searchbar */}
          <div className="mt-[30px] w-full z-[1]">
            <SearchBar
              handleSearchIconClick={(searchTerm) => {
                setSearch(searchTerm);
                debouncedSearch({
                  searchData: searchTerm,
                  freeOn: isFreeOn,
                  paidOn: isPaidOn,
                });
                setPage(1);
              }}
            />
          </div>
          {/* Free-paid */}
          <div className="flex gap-4 mt-5 font-[Poppins]">
            <button
              className={`p-2 px-5 border rounded-[12px] font-[600] text-[18px] ${
                isFreeOn
                  ? "text-white bg-[#BB6CEB] shadow-[0px_20px_20px_-10px_rgba(131,42,184,0.4)]"
                  : "text-black bg-[#d3d3d3]"
              }`}
              onClick={handleFree}
            >
              Free
            </button>
            <button
              className={`p-2 px-5 border rounded-[12px] font-[600] text-[18px] ${
                isPaidOn
                  ? "text-white bg-[#BB6CEB] shadow-[0px_20px_20px_-10px_rgba(131,42,184,0.4)]"
                  : "text-black bg-[#d3d3d3]"
              }`}
              onClick={handlePaid}
            >
              Paid
            </button>
          </div>

          {/* button and head */}
          <div className=" ">
            <div className={`flex justify-between mt-6  `}>
              <div className=" flex items-center ">
                <h2 className="text-black font-bold font-[Poppins] text-[18px] lg:text-[25px] leading-normal ">
                  Choose a Template
                </h2>
              </div>
            </div>

            {loading && (
              <div className="flex justify-center h-screen">
                <LoaderComponent />
              </div>
            )}
            {/* Container div for scrollable content */}
            <div className="scrollable-content overflow-y-auto mt-[40px]">
              <div
                className={`grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2  xl:grid-cols-3 md:gap-x-5 lg:gap-x-7 gap-y-4 mb-[40px]`}
              >
                {templateDetails.map((template: any, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      {isLoggedIn ? (
                        <Link
                          to={`/host/quiz-detail/${template._id}?templateClick=true`}
                        >
                          <div
                            className="bg-white rounded-[8px] hover:bg-gradient-to-r hover:from-[#1EC6DA] hover:to-[#6F2A96] hover:border-none hover:border-transparent hover:text-white hover:items-center hover:justify-center hover:rounded-[8px] hover:shadow-[0px_20px_20px_-10px_rgba(131,42,184,0.4)] hover:inner-shadow-[inset 0px 20px 20px -10px rgba(131,42,184,0.4)]"
                            onMouseEnter={() => setIsHovered(index)}
                            onMouseLeave={() => setIsHovered(-1)}
                          >
                            <TemplateCard
                              id={template._id}
                              imageUrl={template.imageUrl}
                              name={template.name}
                              questions={template.questionCount}
                              played={template.played}
                              amount={template?.amount}
                              superHost={superhost}
                              isHovered={isHovered === index ? true : false}
                              deleteLibrary={false}
                              is_free={template.is_free}
                            />
                          </div>
                        </Link>
                      ) : (
                        <button
                          onClick={() => openLoginDialog()}
                          className="cursor-pointer border-none bg-none p-0"
                        >
                          <div
                            className="bg-white rounded-[12px] hover:bg-gradient-to-r hover:from-[#1EC6DA] hover:to-[#6F2A96] hover:border-none hover:border-transparent hover:text-white hover:items-center hover:justify-center hover:rounded-[12px] hover:shadow-[0px_20px_20px_-10px_rgba(131,42,184,0.4)] hover:inner-shadow-[inset 0px 20px 20px -10px rgba(131,42,184,0.4)]"
                            onMouseEnter={() => setIsHovered(index)}
                            onMouseLeave={() => setIsHovered(-1)}
                          >
                            <TemplateCard
                              id={template._id}
                              imageUrl={template.imageUrl}
                              name={template.name}
                              questions={template.questionCount}
                              played={template.played}
                              amount={template?.amount}
                              isHovered={isHovered === index ? true : false}
                              deleteLibrary={false}
                              is_free={template.is_free}
                            />
                          </div>
                        </button>
                      )}
                    </React.Fragment>
                  );
                })}
              </div>
            </div>

            {templateDetails.length === 0 && (
              <div className="flex justify-center items-center font-[900] text-[30px]">
                No quiz to display
              </div>
            )}

            <div className="flex justify-center mb-[40px] ">
              {showMoreButton && shouldShowMoreButton && (
                <button
                  className={`flex items-center justify-center gap-[10px] px-[32px] py-[17px] z-10 rounded-full bg-gradient-to-r from-[#1EC6DA] to-[#6F2A96] border border-none text-white  lg:text-[23px] text-[16px] font-bold hover:shadow-[0px_20px_20px_-10px_rgba(131,42,184,0.4)] hover:inner-shadow-[inset 0px 20px 20px -10px rgba(131,42,184,0.4)]`}
                  onClick={(e) => {
                    e.preventDefault();
                    loadMore();
                  }}
                >
                  Load More
                </button>
              )}
            </div>
          </div>
        </section>

        {/* footer */}

        <footer>
          <div className="bottom-0 py-[30px] bg-[rgb(22,42,54)] px-[20px] lg:px-[80px] xl:px-[100px] md:flex md:flex-row flex-col md:justify-between justify-center md:items-center font-[Poppins] space-y-5 md:space-y-0 overflow-hidden">
            <div
              className={`lg:w-[15%] h-[138px] flex-col flex justify-center items-center gap-4`}
            >
              <img
                className={`w-[88px] flex justify-center items-center h-[80px]`}
                src={logoImg}
                alt="logo"
                loading="lazy"
              />
              <img
                className="h-[18px] flex justify-center items-center"
                src={logoText}
                alt="logo"
                loading="lazy"
              />
            </div>
            <div className=" ">
              <p className="text-white text-center">
                Copyright@2024QuizCircles
              </p>
            </div>
            <div className="">
              <p className="text-[18px] font-semibold text-center text-white">
                Social Media
              </p>
              <div className="flex flex-row justify-center w-auto mt-[10px] space-x-2 md:space-x-3  md:mt-[22px] ">
                <a
                  href="https://www.facebook.com/profile.php?id=61563422265791 "
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="md:w-[40px] w-[50px]"
                    src={facebook}
                    alt="Facebook"
                    loading="lazy"
                  />
                </a>
                <a
                  href="https://www.instagram.com/quizcircles/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="md:w-[40px] w-[50px]"
                    src={instagram}
                    alt="Instagram"
                    loading="lazy"
                  />
                </a>
                <a
                  href="https://www.linkedin.com/company/quizcircles/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="md:w-[40px] w-[50px]"
                    src={likedIn}
                    alt="LinkedIn"
                    loading="lazy"
                  />
                </a>
                <img
                  className="md:w-[40px] w-[50px]"
                  src={youtube}
                  alt="Youtube"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </footer>
      </div>

      <dialog open={timer}>
        <div
          className={`fixed bg-gray-900 opacity-80 top-0 left-0 w-full items-center h-full flex flex-col justify-center lg:px-0 px-[16px] z-[999999] `}
        >
          <QuizTimer countdown={countdown} />
        </div>
      </dialog>

      {/* login, signup, verify dialogbox */}
      <div className="">
        {/* Register Dialog */}
        {isRegisterOpen && (
          <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-[9999999]">
            <div className="w-[350px] md:w-[500px] bg-white pl-6 pr-6 pb-6 pt-3 md:pl-9 md:pr-9 md:pb-9 md:pt-3">
              {/* close button */}
              <button
                className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#008BB7] ml-[278px] md:ml-[410px]"
                onClick={closeDialogs}
              >
                <svg
                  className="h-7 w-7"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
              <h2 className="text-black font-[poppins] lg:text-[23px] font-semibold text-2xl mb-[25px]">
                Sign Up
              </h2>

              {signUpError && (
                <p className="text-red-500 text-center">{signUpError}</p>
              )}

              <div className="mb-4">
                <label
                  className="block text-black font-[poppins] text-[18px] font-semibold mb-2"
                  htmlFor="name"
                >
                  Name
                </label>
                <input
                  className="border rounded w-full py-2 px-3 md:py-3 text-black font-[poppins] text-[15px] font-normal"
                  type="text"
                  id="name"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                    setNameEmpty("");
                  }}
                />
                {nameEmpty && <p className="text-red-600"> {nameEmpty}</p>}
              </div>
              <div className="mb-4">
                <label
                  className="block text-black font-[poppins] text-[18px] font-semibold mb-2"
                  htmlFor="email"
                >
                  Email
                </label>
                <form autoComplete="off">
                  <input
                    className="border rounded w-full py-2 px-3 md:py-3 text-black font-[poppins] text-[15px] font-normal "
                    type="email"
                    autoComplete="off"
                    value={email}
                    placeholder="Email Address"
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setEmailEmpty("");
                    }}
                  />
                </form>
                {emailEmpty && <p className="text-red-600">{emailEmpty}</p>}
              </div>
              <div className="mb-4">
                <label
                  className="block text-black font-[poppins] text-[18px] font-semibold mb-2"
                  htmlFor="password"
                >
                  Password
                </label>
                <div className="border rounded relative">
                  <form autoComplete="off">
                    <input
                      className="w-full py-2 px-3 md:py-3 pr-8 text-black font-[poppins] text-[15px] font-normal"
                      type={showPassword ? "text" : "password"}
                      id="password"
                      placeholder="Password"
                      value={password}
                      autoComplete="off"
                      onChange={(e) => {
                        setPassword(e.target.value);
                        setPasswordEmpty("");
                      }}
                    />
                  </form>
                  <button
                    className="absolute right-4 top-1/2 transform -translate-y-1/2"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <FontAwesomeIcon
                      icon={showPassword ? faEyeSlash : faEye}
                      className="h-4 w-5 cursor-pointer"
                    />
                  </button>
                </div>

                {passwordEmpty && (
                  <p className="text-red-600"> {passwordEmpty}</p>
                )}
              </div>

              <button
                className="rounded-[12px] bg-gradient-to-r from-[#1EC6DA] to-[#6F2A96] border border-none text-white  lg:text-[23px] text-[16px] font-bold hover:shadow-[0px_20px_20px_-10px_rgba(131,42,184,0.4)] hover:inner-shadow-[inset 0px 20px 20px -10px rgba(131,42,184,0.4)]  rounded-md text-white font-[poppins] text-base font-normal px-4 py-3 w-full mt-[25px]"
                onClick={handleSignUp}
                disabled={loading}
              >
                {loading ? (
                  <Loader type="bubble-top" size={30} bgColor={"#ffffff"} />
                ) : (
                  "Sign Up"
                )}
              </button>
              <p className="text-black text-center text-[18px] mt-4">
                Already have an account?{" "}
                <button
                  className="text-blue-500 hover:underline"
                  onClick={() => {
                    closeDialogs();
                    openLoginDialog();
                  }}
                >
                  Login
                </button>
              </p>
            </div>
          </div>
        )}
        {/* Email Verification */}
        {verification && (
          <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-[9999999]">
            <div className="lg:w-[490px] md:w-[60%]  w-[95%] bg-white p-6  md:p-9">
              <button
                className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#008BB7] md:ml-[95%] ml-[90%]  "
                onClick={closeVerificationBox}
              >
                <svg
                  className="h-7 w-7"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>

              <h2 className="text-black font-[poppins] text-[20px] md:text-[30px] font-bold flex justify-center  mb-[25px]">
                Please verify Your Email
              </h2>
              <p className="flex justify-center mb-5 font-[poppins]">
                Enter the OTP we sent to your email address to verify
              </p>
              <div className="flex justify-center font-[poppins] mb-5">
                <OtpValidationFields onOtpChange={setOtp} />
              </div>
              <div className="flex justify-center items-center">
                <button
                  className="fot-[poppins] text-[20px]  p-3 rounded-[12px] bg-gradient-to-r from-[#1EC6DA] to-[#6F2A96] border border-none  text-white font-medium hover:shadow-[0px_20px_20px_-10px_rgba(131,42,184,0.4)] hover:inner-shadow-[inset 0px 20px 20px -10px rgba(131,42,184,0.4)]"
                  onClick={onVerify}
                >
                  Verify and Continue
                </button>
              </div>
              {validationError && (
                <p className="text-center text-red-600 font-[poppins] text-[16px]">
                  {validationError}
                </p>
              )}
            </div>
          </div>
        )}
        {/* Login Dialog */}
        {isLoginOpen && (
          <div
            className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center 
          z-[9999999]"
          >
            <div className="w-[350px] md:w-[500px] lg:w-[500px] bg-white pl-6 pr-6 pb-6 pt-3 md:pl-9 md:pr-9 md:pb-9 md:pt-3">
              <button
                className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#008BB7] ml-[278px] md:ml-[410px]"
                onClick={closeDialogs}
              >
                <svg
                  className="h-7 w-7"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
              <h2 className="text-black font-[poppins] text-[16px] lg:text-[23px] font-bold mb-[25px]">
                Login
              </h2>
              {loginError && (
                <p className="text-center text-red-500">{loginError}</p>
              )}
              <div className="mb-4">
                <label className="block text-black font-[poppins] text-[18px] font-semibold mb-2">
                  Email
                </label>
                <input
                  className="border rounded w-full py-2 px-3 md:py-3 text-black font-[poppins] text-[15px] font-normal"
                  type="email"
                  id="email"
                  placeholder="Email Address"
                  value={loginEmail}
                  onChange={(e) => setLoginEmail(e.target.value)}
                />
              </div>
              <div className="mb-3 relative">
                <label className="block text-black font-[poppins] text-[18px] font-semibold mb-2">
                  Password
                </label>
                <input
                  className="border rounded w-full py-2 px-3 md:py-3 text-black font-[poppins] text-[15px] font-normal"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  placeholder="Password"
                  value={loginPassword}
                  onChange={(e) => setLoginPassword(e.target.value)}
                />
                <button
                  className="absolute top-[70%] right-4 transform -translate-y-1/2"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <FontAwesomeIcon
                    icon={showPassword ? faEyeSlash : faEye}
                    className="h-4 w-5 cursor-pointer"
                  />
                </button>
              </div>
              <div className="text-left">
                <button
                  className="text-blue-500 hover:underline font-[poppins] text-[18px] pl-2"
                  onClick={openForgotPasswordDialog}
                >
                  Forgot Password?
                </button>
              </div>
              <button
                className="rounded-[12px] bg-gradient-to-r from-[#1EC6DA] to-[#6F2A96] border border-none text-white lg:text-[23px] text-[16px] font-bold hover:shadow-[0px_20px_20px_-10px_rgba(131,42,184,0.4)] hover:inner-shadow-[inset 0px 20px 20px -10px rgba(131,42,184,0.4)] rounded-md text-white font-[poppins] text-base font-normal px-4 py-3 w-full mt-[25px]"
                onClick={handleLogin}
                disabled={loading}
              >
                {loading ? (
                  <Loader type="bubble-top" size={30} bgColor={"#ffffff"} />
                ) : (
                  "Login"
                )}
              </button>
              <p className="text-black text-center text-[18px] mt-4">
                Don't have an account?{" "}
                <button
                  className="text-blue-500 hover:underline"
                  onClick={() => {
                    closeDialogs();
                    openRegisterDialog();
                  }}
                >
                  Sign Up
                </button>
              </p>
            </div>
          </div>
        )}
        {/* Forget Password */}
        {isForgotPasswordOpen && (
          <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-[9999999]">
            <div className="w-[350px] md:w-[500px] lg:w-[500px] bg-white pl-6 pr-6 pb-6 pt-3 md:pl-9 md:pr-9 md:pb-9 md:pt-3">
              <button
                className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#008BB7] ml-[278px] md:ml-[410px]"
                onClick={closeForgotPasswordDialog}
              >
                <svg
                  className="h-7 w-7"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
              <h2 className="text-black font-[poppins] text-[18px] font-semibold text-2xl mb-[25px]">
                Forgot Password
              </h2>
              <div className="mb-4">
                <label
                  className="block text-black font-[poppins] text-[18px] font-semibold mb-2"
                  htmlFor="forgotPasswordEmail"
                >
                  Email <span className="text-red-500">*</span>
                </label>
                <input
                  className="border rounded w-full py-2 px-3 md:py-3 text-black font-[poppins] text-[15px] font-normal"
                  type="email"
                  placeholder="Email Address"
                  value={requestLink}
                  onChange={(e) => setRequestLink(e.target.value)}
                  required
                />
              </div>
              <button
                className="rounded-[12px] bg-gradient-to-r from-[#1EC6DA] to-[#6F2A96] border border-none text-white lg:text-[23px] text-[16px] font-bold hover:shadow-[0px_20px_20px_-10px_rgba(131,42,184,0.4)] hover:inner-shadow-[inset 0px 20px 20px -10px rgba(131,42,184,0.4)] rounded-md text-white font-[poppins] text-base font-normal px-4 py-3 w-full mt-[25px]"
                onClick={handleSendLink}
                disabled={loading}
              >
                {loading ? (
                  <Loader type="bubble-top" size={30} bgColor={"#ffffff"} />
                ) : (
                  "Send Link"
                )}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Home;
