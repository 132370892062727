import React, { FC, useEffect, useRef, useState } from "react";
import { uploadImage } from "src/services/createQuiz/createQuiz.service";
import LoaderComponent from "src/components/loader/LoaderComponent";
import { debounce } from "lodash";
import ImageUpload from "../imageUpload/ImageUpload";

type ImageTextTypeComponentsProp = {
  response: (data: any) => void;
  questionData: any;
  readOnly?: boolean;
};

const EditImageTextTypeComponents: FC<ImageTextTypeComponentsProp> = ({
  response,
  questionData,
  readOnly,
}) => {
  const [questionImg, setQuestionImg] = useState<string>(
    questionData.questionImg
  );
  const [question, setQuestion] = useState(questionData.question);
  const [options, setOptions] = useState(questionData.options);
  const [correctAnswer, setCorrectAnswer] = useState<any>(
    questionData.correctAnswer
  );
  const [isLoading, setIsLoading] = useState(false);
  const questionId = questionData._id || "";
  const editable = questionData?.isEditable;
  const categoryId = questionData.category_id;
  const mapId = questionData?.mapId;

  useEffect(() => {
    setQuestion(questionData?.question);
    setCorrectAnswer(questionData?.correctAnswer);
    setQuestionImg(questionData?.questionImg);
    setOptions(questionData?.options);
  }, [questionData]);

  useEffect(() => {
    if (!options.includes(questionData.correctAnswer)) {
      setCorrectAnswer(undefined);
    }
  }, [options, questionData.correctAnswer]);

  const responseRef = useRef(response);

  useEffect(() => {
    responseRef.current = response;
  }, [response]);

  useEffect(() => {
    const debouncedOutputData = debounce((data: any) => {
      responseRef.current(data);
    }, 300);

    const questionType = "IMAGE_TEXT";
    const answerType = "TEXT";
    const data = {
      question,
      options,
      correctAnswer: correctAnswer || "",
      questionImg,
      questionType,
      answerType,
      _id: questionId,
      isEditable: editable,
      category_id: categoryId,
      mapId,
    };

    if (data) {
      debouncedOutputData(data);
    }

    // Cleanup function to cancel debounce on unmount
    return () => {
      debouncedOutputData.cancel();
    };
  }, [
    correctAnswer,
    question,
    options,
    questionImg,
    questionId,
    editable,
    categoryId,
    mapId,
  ]);

  const coverFileHandler = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      setIsLoading(true);
      uploadImage(file)
        .then((imageUrl) => {
          setQuestionImg(imageUrl);
        })
        .catch((error) => {
          console.error("Error uploading image:", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleCheckboxChange = (index: number) => {
    setCorrectAnswer(options[index]);
  };

  return (
    <div>
      <div className="w-full h-full">
        <hr className="border-1 mt-2" />
        {/* question section*/}
        <section className="lg:flex mt-[18px]  items-center gap-4">
          <div className=" w-auto relative lg:w-[312px] md:w-[300px] md:h-[210px] border-dashed border-[1px] border-[#1FC4D9] rounded-[10px]">
            {questionImg ? (
              <img
                className="w-[300px] h-[210px] rounded-[10px] p-2"
                src={questionImg}
                alt="template"
              ></img>
            ) : (
              <div className="lg:w-[312px] md:h-[210px] w-full md:w-[300px] flex items-center justify-center">
                <ImageUpload type="question" />
              </div>
            )}

            <div className="absolute inset-0 flex items-center justify-center  rounded-xl">
              {isLoading ? (
                <LoaderComponent />
              ) : (
                !readOnly && (
                  <label
                    htmlFor="imageInput"
                    className="absolute inset-0 flex items-center justify-center rounded-[10px] cursor-pointer"
                  >
                    <input
                      id="imageInput"
                      type="file"
                      accept="image/*"
                      className="absolute top-0 left-0 w-full h-full opacity-0"
                      onChange={coverFileHandler}
                    />
                  </label>
                )
              )}
            </div>
          </div>

          <div className="border xl:mt-[40px] lg:mt-[20px] mt-8 border-[#D7D7D7] p-2 rounded-[10px] w-full">
            <textarea
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              className="text-[#000] font-[inter] lg:text-[18px] text-[16px] lg:font-[400] leading-normal ml-[40] mr-[10] w-full max-h-[60px] border resize-none placeholder:text-zinc-400 placeholder:text-lg placeholder:font-normal flex items-center box-border pt-4 pb-5 px-2 overflow-auto border-none focus:outline-none placeholder:items-center no-scrollbar"
              placeholder="Question"
              readOnly={readOnly}
            />
          </div>
        </section>

        {/* options section*/}

        <section className="space-y-4 lg:mt-[32px] mt-[24px] gap-2 text-[#000] font-[inter] lg:text-[18px] text-[16px] leading-normal ">
          <h2 className="text-xl font-semibold mb-4">Options</h2>
          {options.map((option: any, index: number) => (
            <div
              key={index}
              className="relative flex items-center lg:my-0 my-2 rounded-[10px] border border-[#0A687426] bg-[#1FC4D903] p-1"
            >
              <div className="w-[90%] flex">
                <textarea
                  className="flex-1 flex items-center flex-wrap pt-[10px] pb-2 px-2 border-none lg:text-[18px] text-[16px] h-[50px] font-[inter] lg:font-[400] resize-none rounded-[10px] placeholder:text-zinc-400 placeholder:text-lg placeholder:font-normal focus:outline-none no-scrollbar"
                  placeholder={`Option ${index + 1}`}
                  value={option}
                  onChange={(e) => {
                    const newOptions = [...options];
                    newOptions[index] = e.target.value;
                    setOptions(newOptions);
                  }}
                  readOnly={readOnly}
                />
              </div>
              <div className="absolute top-0 right-0 w-[10%] flex items-center h-full justify-center">
                <input
                  className="rounded-[10px] border-[#0000002B] bg-white"
                  type="checkbox"
                  checked={correctAnswer === option}
                  onChange={() => handleCheckboxChange(index)}
                  disabled={readOnly}
                />
              </div>
            </div>
          ))}
        </section>
      </div>
    </div>
  );
};

export default EditImageTextTypeComponents;
