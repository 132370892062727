import React, { FC } from "react";
import uploadImg from "../../assets/imageUpload.svg";

type ImageUploadProps = {
  type?: string;
};

const ImageUpload: FC<ImageUploadProps> = ({ type }) => {
  return (
    <div
      className={`${
        type === "question" ? " " : "w-[200px] h-[130px] rounded-[10px] border border-[#0000000A] bg-white "
      }flex flex-col justify-center items-center space-y-1 md:p-0 p-5`}
    >
      <img
        className={`${
          type === "question" ? "md:w-[65px] md:h-[56px]" : "w-[42px] h-[28px]"
        } rounded-[10px] flex justify-center items-center`}
        src={uploadImg}
        alt="Preview"
      />

      <h1
        className={` ${
          type === "question" ? "w-auto h-auto" : "text-[10px] "
        } text-neutral-500 text-lg font-semibold`}
      >
        Upload Image
      </h1>
    </div>
  );
};

export default ImageUpload;
