import instance from "src/http/httpClient";

// API Integration for submitting contact information
export const submitContactInformation = (data: {
  name: string;
  email: string;
  phoneNumber: string;
  reason: string;
}): Promise<any> => {
  return instance
    .post<any>("/contact", data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};


// get contact details
export const getContactDetails = (params: any): Promise<any> => {
  return instance
    .get<any>(`/contact`, { params })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};