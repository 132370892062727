import React, { FC, useEffect, useRef, useState } from "react";
import TextTypeComponent from "./TextTypeComponent";
import ImageTextTypeComponents from "./ImageTextTypeComponents";
import ImageImageTYpeComponents from "./ImageImageTYpeComponents";
import TextImageTypeComponent from "./TextImageTypeComponent";
import TrueOrFalse from "./TrueOrFalse";
import Delete from "src/assets/createPageDelete.svg";
import Category from "../category/Category";
import dropdownImage from "src/assets/drop-down.png";

type DropDownOptionProp = {
  response: (data: any) => void;
  onDelete: () => void;
  question?: any;
  index: number;
  handleAddCategory: () => void;
  newCategoryItem: any;
};

const DropDownOption: FC<DropDownOptionProp> = ({
  response,
  onDelete,
  question,
  index,
  handleAddCategory,
  newCategoryItem,
}) => {
  const [selectedOption, setSelectedOption] = useState<string>("Text");
  const getSuperHost = localStorage.getItem("superHost") || "";
  const superHost = getSuperHost.replace(/["']/g, "");
  const [questionData, setQuestionData] = useState<any>();
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setQuestionData(question);
  }, [question]);

  const handleOptionChange = (option: string) => {
    setSelectedOption(option);
    setQuestionData({
      ...questionData,
      options: undefined,
      question: undefined,
    });
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    if (question) {
      if (question.questionType === "TEXT" && question.answerType === "IMAGE") {
        setSelectedOption("Text and Image");
      } else if (
        question.answerType === "TEXT" &&
        question.questionType === "TEXT"
      ) {
        setSelectedOption("Text");
      } else if (
        question.answerType === "IMAGE" &&
        question.questionType === "IMAGE_TEXT"
      ) {
        setSelectedOption("Double Image");
      } else if (
        question.answerType === "BOOLEAN" &&
        question.questionType === "IMAGE_TEXT"
      ) {
        setSelectedOption("True or False");
      } else if (
        question.answerType === "BOOLEAN" &&
        question.questionType === "TEXT"
      ) {
        setSelectedOption("True or False");
      } else if (
        question.answerType === "TEXT" &&
        question.questionType === "IMAGE_TEXT"
      ) {
        setSelectedOption("Image and Text");
      } else {
        setSelectedOption("Text");
      }
    }
  }, [question]);

  const handleCategorySelection = (id: string) => {
    setQuestionData({
      ...questionData,
      category_id: id,
    });
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsDropdownOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="w-full">
      <header
        className={`${
          superHost === "admin"
            ? "top-0 flex flex-col w-full lg:mt-[20px] xl:mt-0 items-center"
            : "top-0 w-full flex items-center justify-between"
        }`}
      >
        <div
          className={`${
            superHost === "admin"
              ? "flex sm:justify-between justify-end w-full sm:items-center items-end sm:flex-row flex-col sm:gap-0 gap-4 "
              : "flex justify-end w-full items-center flex-shrink-0"
          }`}
        >
          <div
            className={`${superHost === "admin" ? "block gap-1" : "hidden"}`}
          >
            <Category
              selectedCategoryId={handleCategorySelection}
              existingCategoryId={
                questionData?.category_id || question?.category_id
              }
              newCategoryItem={newCategoryItem}
              handleAddCategory={handleAddCategory}
            />
          </div>

          <div
            className={`flex gap-2 relative ${
              superHost === "admin" && `lg:left-1`
            }`}
            ref={dropdownRef}
          >
            <div className="relative sm:w-[240px]">
              <div className="flex items-center justify-between bg-[#f8fdfe] border border-[#0A687426] rounded w-full">
                <div className="text-black font-[inter] lg:text-[18px] text-[14px] font-[400] leading-normal border-1 border-black rounded-md py-2 px-2 truncate">
                  {selectedOption}
                </div>
                <img
                  src={dropdownImage}
                  alt="Dropdown"
                  className="w-[30px] h-[30px] cursor-pointer ml-2"
                  onClick={toggleDropdown}
                />
              </div>
              {isDropdownOpen && (
                <div className="absolute w-full top-[calc(100%+4px)] left-0 bg-[#ffffff] border border-[#B1B1B1] rounded-[12px] z-10 gap-1 mt-1 p-2 ">
                  <div
                    className="text-black font-[inter] text-[14px] lg:text-[18px] font-[400] leading-normal px-4 py-2 cursor-pointer rounded-[6px] hover:bg-[#1FC4D9] truncate"
                    onClick={() => handleOptionChange("Text")}
                  >
                    Text
                  </div>
                  <div
                    className="text-black font-[inter] text-[14px] lg:text-[18px] font-[400] leading-normal px-4 py-2 cursor-pointer hover:bg-[#1FC4D9] rounded-[6px] truncate"
                    onClick={() => handleOptionChange("Image and Text")}
                  >
                    Image and Text
                  </div>
                  <div
                    className="text-black font-[inter] text-[14px] lg:text-[18px] font-[400] leading-normal px-4 py-2 cursor-pointer hover:bg-[#1FC4D9] rounded-[6px] truncate"
                    onClick={() => handleOptionChange("True or False")}
                  >
                    True or False
                  </div>
                  <div
                    className="text-black font-[inter] text-[14px] lg:text-[18px] font-[400] leading-normal px-4 py-2 cursor-pointer hover:bg-[#1FC4D9] whitespace-nowrap rounded-[6px] truncate"
                    onClick={() => handleOptionChange("Double Image")}
                  >
                    Double Image
                  </div>
                  <div
                    className="text-black font-[inter] text-[14px] lg:text-[18px] font-[400] leading-normal px-4 py-2 cursor-pointer hover:bg-[#1FC4D9] rounded-[6px] truncate"
                    onClick={() => handleOptionChange("Text and Image")}
                  >
                    Text and Image
                  </div>
                </div>
              )}
            </div>

            <button
              onClick={onDelete}
              className="hover:shadow-[0px_20px_20px_-10px_rgba(131,42,184,0.4)] hover:inner-shadow-[inset 0px 20px 20px -10px rgba(131,42,184,0.4)] rounded-full"
            >
              <img src={Delete} alt="Delete" className="object-fit" />
            </button>
          </div>
        </div>
      </header>
      <main>
        {selectedOption === "Text" && (
          <TextTypeComponent response={response} questions={questionData} />
        )}

        {selectedOption === "Image and Text" && (
          <ImageTextTypeComponents
            response={response}
            questions={questionData}
          />
        )}
        {selectedOption === "Double Image" && (
          <ImageImageTYpeComponents
            response={response}
            questions={questionData}
          />
        )}
        {selectedOption === "Text and Image" && (
          <TextImageTypeComponent
            response={response}
            questions={questionData}
          />
        )}

        {selectedOption === "True or False" && (
          <TrueOrFalse response={response} questions={questionData} />
        )}
      </main>
    </div>
  );
};

export default DropDownOption;
