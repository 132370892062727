import instance from "src/http/httpClient";

export const getPlayerData = (slug: string): Promise<any> => {
  return instance
    .get<any>(`/Leaderboard/${slug}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const adminDemoPlayerData = (slug: string): Promise<any> => {
  return instance
    .get<any>(`/Leaderboard/demo/${slug}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};



