import instance from "src/http/httpClient";

export const getHostSummary = (
  quizID: string,
  slug: string
): Promise<any> => {
  return instance
    .get<any>(`/host/quiz/summary/${quizID}`, {
      params: { slug_id:slug }
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};
