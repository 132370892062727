import instance from "src/http/httpClient";

// Upcoming Games
export const getUpcomingGames = (): Promise<any> => {
  return instance
    .get<any>(`/host/game/upcoming`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

// Delete Games

export const deleteGames = (gameID: string): Promise<any> => {
  return instance
    .delete<any>(`/host/game/${gameID}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};
