import instance from "src/http/httpClient";

// My Library
export const getMyLibrary = ( params: any): Promise<any> => {
  return instance
    .get<any>(`/host/quiz/myquiz`, { params })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};

// Delete My Library Tempalte
export const deleteMyLibrary = (id: string): Promise<any> => {
  return instance
    .delete<any>(`/host/quiz/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};
