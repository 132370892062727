import instance from "src/http/httpClient";

export const getPlayerSummary = (
  playerId: string,
  slug: string
): Promise<any> => {
  return instance
    .get<any>(`/player/summary/${ playerId}`, {
      params: { slug_id:slug }})
    
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const getPlayershareData = (slug: string): Promise<any> => {
  return instance
    .get<any>(`/Leaderboard/share/${slug}`)
    .then((response) => {     
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};