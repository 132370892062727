import React, { useEffect, useState } from "react";
import ToggleButton from "./toggleButton";
import Chat from "src/assets/chatbot.png";
import { generateQuestion } from "src/services/geminiAi/geminiAi.service";
import LoaderComponent from "../loader/LoaderComponent";

type GenerateQuestionProps = {
  onGenerate: (data: {
    question: string;
    options: { [key: string]: string };
    correctAnswer: string;
  }) => void;
  onClear: () => void;
  type: string;
};

const GenerateQuestion: React.FC<GenerateQuestionProps> = ({
  onGenerate,
  onClear,
  type,
}) => {
  const [isToggled, setIsToggled] = useState(false);
  const [keyword, setKeyword] = useState("");

  const [loading, setLoading] = useState(false);

  const handleToggle = () => {
    setIsToggled(!isToggled);
    if (!isToggled) {
      setKeyword("");
    }
  };
useEffect(()=>{
  if(isToggled){
    document.title = "Generate Question using Gemini";
  }
},[isToggled])
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value);
  };

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Backspace" && keyword === "") {
      onClear();
    }
  };

  const handleGenerate = async () => {
    setLoading(true);
    try {
      const response = await generateQuestion({ type, topic: keyword });

      onGenerate(response);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="border xl:p-4 lg:p-[12px] p-4 bg-gradient-to-r from-[#1EC6DA] to-[#6F2A96] rounded-[10px]">
      <div className="flex">
        <div className="flex md:ml-5">
          <div>
            <div className="sm:flex items-center space-x-2 mb-4 ">
              <img
                className="w-[28px] h-[28px] rounded-[10px] inline "
                src={Chat}
                alt="template"
              />
              <div className="inline sm:hidden bg-black text-white px-2 rounded text-center py-1">
                BETA
              </div>
              <div className="inline sm:hidden bg-green-500 text-white px-2 py-1 rounded">
                NEW
              </div>

              <h1 className="font-bold text-lg mt-7 sm:mt-0 text-white">
                Generate with AI
              </h1>

              <div className="hidden sm:inline bg-black text-white px-2 py-1 rounded">
                BETA
              </div>
              <div className="hidden sm:inline bg-green-500 text-white px-2 py-1 rounded">
                NEW
              </div>
            </div>
            <div className="flex items-center space-x-4 text-white">
              <p>
                Generate your question using Gemini - enter a keyword and the AI
                will generate a question and answers to it.
              </p>
            </div>
          </div>

          <div onClick={handleToggle} className="mt-[50px] md:mt-0">
            <ToggleButton />
          </div>
        </div>
      </div>
      {isToggled && (
        <div className="mt-4 md:flex md:gap-[15px] md:ml-5">
          <div>
            <h2
           
              className="block text-sm font-medium text-white"
            >
              Enter a keyword
            </h2>
            <input
              type="text"
              id="keyword"
              placeholder="Enter quiz title"
              value={keyword}
              onChange={handleInputChange}
              onKeyDown={handleInputKeyDown}
              className="mt-1 block md:w-[309px] md:h-[41px] w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-[#A1A1AA]"
            />
          </div>
          <div className="mt-[20px]">
            {loading ? (
              <LoaderComponent />
            ) : (
              <button
                className="w-[127.45px] h-[41px] px-4 py-2 bg-green-500 text-white rounded-md mt-1"
                onClick={handleGenerate}
              >
                Generate
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default GenerateQuestion;
