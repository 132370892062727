import React, { FC, useEffect, useRef, useState } from "react";
import { uploadImage } from "src/services/createQuiz/createQuiz.service";
import LoaderComponent from "src/components/loader/LoaderComponent";
import { debounce } from "lodash";
import ImageUpload from "../imageUpload/ImageUpload";

type EditTextImageTypeComponentProp = {
  questionData: any;
  response: (data: any) => void;
  readOnly?: boolean;
};

const EditTextImageTypeComponent: FC<EditTextImageTypeComponentProp> = ({
  response,
  questionData,
  readOnly,
}) => {
  const [question, setQuestion] = useState(questionData.question);
  const [correctAnswer, setCorrectAnswer] = useState<any>(
    questionData.correctAnswer
  );
  const [options, setOptions] = useState(questionData.options);
  const [optionLoading, setOptionLoading] = useState<boolean[]>(
    new Array(options.length).fill(false)
  );
  const questionId = questionData._id || "";
  const editable = questionData?.isEditable;
  const categoryId = questionData.category_id;
  const mapId = questionData?.mapId;

  useEffect(() => {
    setQuestion(questionData?.question);
    setCorrectAnswer(questionData?.correctAnswer);
    setOptions(questionData?.options);
  }, [questionData]);

  useEffect(() => {
    if (!options.includes(questionData.correctAnswer)) {
      setCorrectAnswer(undefined);
    }
  }, [options, questionData.correctAnswer]);

  const responseRef = useRef(response);

  useEffect(() => {
    responseRef.current = response;
  }, [response]);

  useEffect(() => {
    const debouncedOutputData = debounce((data: any) => {
      responseRef.current(data);
    }, 300);

    const questionType = "TEXT";
    const answerType = "IMAGE";
    const data = {
      question,
      correctAnswer: correctAnswer || "",
      options,
      questionType,
      answerType,
      _id: questionId,
      isEditable: editable,
      category_id: categoryId,
      mapId,
    };

    if (data) {
      debouncedOutputData(data);
    }

    // Cleanup function to cancel debounce on unmount
    return () => {
      debouncedOutputData.cancel();
    };
  }, [
    correctAnswer,
    question,
    options,
    questionId,
    editable,
    categoryId,
    mapId,
  ]);

  const handleCheckboxChange = (index: number) => {
    setCorrectAnswer(options[index]);
  };

  const optionFileHandler = (event: any, index: number) => {
    const file = event.target.files[0];
    if (file) {
      setOptionLoading((prevLoading) =>
        prevLoading.map((loading, i) => (i === index ? true : loading))
      );
      uploadImage(file)
        .then((imageUrl) => {
          setOptions((prevOptions: any) => {
            const updatedOptions = [...prevOptions];
            updatedOptions[index] = imageUrl;
            return updatedOptions;
          });
        })
        .catch((error) => {
          console.error("Error uploading image:", error);
        })
        .finally(() => {
          setOptionLoading((prevLoading) =>
            prevLoading.map((loading, i) => (i === index ? false : loading))
          );
        });
    }
  };

  return (
    <div className="w-full h-full">
      <hr className="border-1 mt-2" />
      {/* question */}

      <section className="border xl:mt-[40px] lg:mt-[20px] mt-8 border-[#D7D7D7] p-2 rounded-[10px] w-full">
        <textarea
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          className="text-[#000] font-[inter] lg:text-[18px] text-[16px] lg:font-[400] leading-normal ml-[40] mr-[10] w-full max-h-[60px] border resize-none placeholder:text-zinc-400 placeholder:text-lg placeholder:font-normal flex items-center box-border pt-4 pb-5 px-2 overflow-auto border-none focus:outline-none placeholder:items-center no-scrollbar"
          placeholder="Question"
          readOnly={readOnly}
        />
      </section>
      {/* options */}
      <section className="space-y-4 lg:mt-[32px] mt-[24px] text-[#000] font-[inter] lg:text-[18px] text-[16px] leading-normal gap-2  ">
        <h2 className="text-xl font-semibold mb-4">Options</h2>
        {options.map((option: any, index: number) => (
          <div
            className="relative flex items-center border-dashed border-[1px] border-[#1FC4D9] rounded-[10px] lg:my-0 my-2 bg-[#1FC4D903] "
            key={index}
          >
            <div className="w-[90%] relative p-2">
              {option ? (
                <img
                  className="lg:w-[164px] h-[85.854px] w-[110px] md:h-[128px] rounded-[10px]"
                  src={option}
                  alt={`option ${index}`}
                ></img>
              ) : (
                <div className="lg:w-[312px] md:h-[210px] w-full md:w-[300px] flex items-center justify-center">
                  <ImageUpload type="option" />
                </div>
              )}

              <div className="absolute inset-0 flex items-center justify-center rounded-[10px]">
                {optionLoading[index] ? (
                  <LoaderComponent />
                ) : (
                  !readOnly && (
                    <label
                      htmlFor={`imageInput-${index}`}
                      className="absolute inset-0 flex items-center justify-center rounded-xl cursor-pointer"
                    >
                      <input
                        id={`imageInput-${index}`}
                        type="file"
                        accept="image/*"
                        className="absolute top-0 left-0 w-[200px] h-full opacity-0"
                        onChange={(event) => optionFileHandler(event, index)}
                      />
                    </label>
                  )
                )}
              </div>
            </div>
            <div className="absolute top-0 right-0 w-[10%] flex items-center h-full justify-center">
              <input
                className="rounded-[10px] border-[#0000002B] bg-white"
                type="checkbox"
                checked={correctAnswer === options[index]}
                onChange={() => handleCheckboxChange(index)}
                disabled={readOnly}
              />
            </div>
          </div>
        ))}
      </section>
    </div>
  );
};

export default EditTextImageTypeComponent;
