import instance from "src/http/httpClient";

//API Integration for generating questions with Gemini
export const generateQuestion = (params: any): Promise<any> => {
  return instance
    .get<any>(`/gemini/question`, { params })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};
