import { useEffect, useRef, useState } from "react";

export function OtpValidationFields({
  onOtpChange = (otp: string) => {},
  isOtpValidate = (data: boolean) => {},
  clearOtp = false,
}) {
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const inputRefs = useRef<Array<HTMLInputElement | null>>([]);
  useEffect(() => {
    if (clearOtp) {
      setOtp(["", "", "", "", "", ""]);
      onOtpChange("");
    }
  }, [clearOtp, onOtpChange]);

  const handleChange = (index: number, value: string) => {
    if (value.match(/^\d$/)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      if (index < 5) {
        inputRefs.current[index + 1]?.focus();
      }
    }
  };
  useEffect(() => {
    const otpString = otp.join("");
    const regex = /\d{6}/;
    isOtpValidate(regex.test(otpString));
  }, [otp, isOtpValidate]);
  const handleKeyDown = (
    index: number,
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Backspace") {
      if (index > 0) {
        const newOtp = [...otp];
        if (otp[index]) {
          newOtp[index] = "";
        } else {
          newOtp[index - 1] = "";
          inputRefs.current[index - 1]?.focus();
        }
        setOtp(newOtp);
      } else if (index === 0 && !otp[index]) {
        inputRefs.current[index - 1]?.focus();
      }
    } else if (event.key === "Enter" && index === 5) {
      onOtpChange(otp.join(""));
    }
  };
  const handleInputChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    handleChange(index, value);
    onOtpChange(otp.join(""));
  };
  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedValue = event.clipboardData.getData("text");
    const newOtp = Array(6).fill("");
    pastedValue.split("").forEach((value, index) => {
      if (index < 6) {
        newOtp[index] = value;
      }
    });
    setOtp(newOtp);
    onOtpChange(newOtp.join(""));
    event.preventDefault();
  };
  const handleLastInputBlur = (index: number) => {
    if (otp[index]) {
      onOtpChange(otp.join(""));
    }
  };
  return (
    <>
      <div className="otp-container">
        <div className="otp-inputs mt-1 flex">
          {otp.map((digit, index) => (
            <input
              key={index}
              ref={(ref) => (inputRefs.current[index] = ref)}
              id={`otp-${index}`}
              name={`otp-${index}`}
              type="text"
              maxLength={1}
              required
              value={digit}
              className="md:w-12 md:h-12 w-[20%] h-[15%] text-3xl text-center rounded-md border border-gray-300 mx-1 px-2 py-2 placeholder-gray-400 shadow-sm sm:text-sm focus:outline-none focus:ring-2 focus:ring-inset focus:ring-black"
              onChange={(event) => handleInputChange(index, event)}
              onKeyDown={(event) => handleKeyDown(index, event)}
              onBlur={() => {
                if (index === 5) {
                  handleLastInputBlur(index);
                }
              }}
              pattern="[0-9]*"
              inputMode="numeric"
              onPaste={(event) => handlePaste(event)}
            />
          ))}
        </div>
      </div>
    </>
  );
}
