import instance from "src/http/httpClient";

export const getCategory = (): Promise<any> => {
    return instance
      .get(`/host/category`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  };

  export const createCategory = (data:any): Promise<any> => {
    return instance
      .post(`/host/category`,data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  };