import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faTimes,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import {
  fetchQuizCatagories,
  selectedQuizCatagoriesQuestions,
} from "src/services/quizChat/quizChat.service";
import Tick from "src/assets/Tick.svg";
import Cancel from "src/assets/Cancel.svg";
import CryptoJS from "crypto-js";

interface Category {
  _id: string;
  category_name: string;
}

interface ChatBoxProps {
  closeChatBox: () => void;
  onSelectQuestions: (selectedQuestions: any[]) => void;
}

const ChatBox = ({ closeChatBox, onSelectQuestions }: ChatBoxProps) => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [quizData, setQuizData] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [allQuestionsLoaded, setAllQuestionsLoaded] = useState(false);
  const [viewQuestions, setViewQuestions] = useState(false);
  const limit = 20;

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [showOptions, setShowOptions] = useState<boolean[]>([]);
  const [selectedQuestions, setSelectedQuestions] = useState<any[]>([]);

  useEffect(() => {
    fetchQuizCategories();
  }, []);

  const handleApply = () => {
    if (selectedQuestions.length === 0) {
      alert("Please select a question.");
      return;
    }
    onSelectQuestions(selectedQuestions);
    closeChatBox();
  };

  const handleSearch = () => {
    selectedQuizCatagoriesQuestions(selectedCategory, searchTerm, page, limit)
      .then((data) => {
        const decryptedQuestions = data.question.map((question: any) => {
          try {
            const secretKey = "quiz123456";
            const decryptedAnswer = CryptoJS.AES.decrypt(
              question.correctAnswer,
              secretKey
            ).toString(CryptoJS.enc.Utf8);
            return {
              ...question,
              correctAnswer: decryptedAnswer,
            };
          } catch (error) {
            console.error("Error decrypting answer:", error);
            return question;
          }
        });
        setQuizData(decryptedQuestions);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleCategorySelect = (categoryId: string) => {
    setSelectedCategory(categoryId);
    setViewQuestions(true);
    selectedQuizCatagoriesQuestions(categoryId, "", page, limit)
      .then((data) => {
        const decryptedQuestions = data.question.map((question: any) => {
          try {
            const secretKey = "quiz123456";
            const decryptedAnswer = CryptoJS.AES.decrypt(
              question.correctAnswer,
              secretKey
            ).toString(CryptoJS.enc.Utf8);
            return {
              ...question,
              correctAnswer: decryptedAnswer,
            };
          } catch (error) {
            console.error("Error decrypting answer:", error);
            return question;
          }
        });
        setQuizData(decryptedQuestions);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleLoadMore = () => {
    selectedQuizCatagoriesQuestions(
      selectedCategory,
      searchTerm,
      page + 1,
      limit
    )
      .then((data) => {
        if (data.question.length > 0) {
          setQuizData((prevData) => [...prevData, ...data.question]);
          setPage((prevPage) => prevPage + 1);
        } else {
          setAllQuestionsLoaded(true);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleSelectQuestion = (question: any) => {
    setSelectedQuestions([question]);
  };

  const fetchQuizCategories = () => {
    fetchQuizCatagories()
      .then((data) => {
        setCategories(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const toggleOptions = (index: number) => {
    setShowOptions((prevShowOptions) => {
      const updatedShowOptions = [...prevShowOptions];
      updatedShowOptions[index] = !updatedShowOptions[index];
      return updatedShowOptions;
    });
  };
  const handleBack = () => {
    setViewQuestions(false);
    setSelectedCategory("");
    setQuizData([]);
  };
  return (
    <div>
      <div className="fixed bottom-5 font-[Inter] sm:right-18 right-5 bg-white p-9 rounded-lg shadow-md z-50 xl:max-w-[840px] max-w-[90%] sm:max-w-auto max-h-[80%] flex flex-col">
        {/* Header Section */}
        <header className="flex justify-between items-center mb-4">
          <div className="flex">
            {viewQuestions && (
              <button onClick={handleBack} className="flex items-center pl-3">
                <FontAwesomeIcon icon={faArrowLeft} className="text-gray-500" />
              </button>
            )}
            <h2 className=" ml-2 text-zinc-900 text-2xl font-semibold font-['Inter']">
              Questions
            </h2>
          </div>
          <button onClick={closeChatBox}>
            <FontAwesomeIcon
              icon={faTimes}
              className="text-gray-400 w-7 h-7 px-2 py-2 border"
            />
          </button>
        </header>

        {/* Search Bar */}
        <div className="relative mb-4">
          <input
            type="text"
            placeholder="Search Questions"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              handleSearch();
            }}
            className={`w-full px-3 py-2 pl-10 border rounded-md text-white-400 text-sm font-normal font-['Inter']${
              selectedCategory
                ? "border bg-gradient-to-r from-[#1EC6DA] to-[#6F2A96] text-white placeholder-white"
                : "border-[#0A687426] bg-[#1FC4D903] text-zinc-400"
            }`}
          />

          <div className="absolute inset-y-0 left-0 flex items-center pl-3">
            <FontAwesomeIcon
              icon={faSearch}
              className={`${selectedCategory ? "text-white" : "text-gray-500"}`}
            />
          </div>
        </div>

        {/* Main Content */}
        <main className="overflow-y-auto flex-grow mb-4">
          {searchTerm || selectedCategory ? (
            <>
              <div className="flex justify-between">
                <h3 className="text-zinc-900 text-xl font-semibold font-['Inter'] mb-2">
                  {
                    categories.find((cat) => cat._id === selectedCategory)
                      ?.category_name
                  }
                </h3>
              </div>
              {/* Questions List */}
              {quizData &&
                quizData.map((question: any, index: number) => (
                  <div
                    className="border border-[#0A687426] bg-[#1FC4D903] rounded-md p-3 mb-7"
                    key={index}
                  >
                    <div className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        onChange={() => handleSelectQuestion(question)}
                        checked={selectedQuestions.some(
                          (q) => q._id === question._id
                        )}
                        className="w-[20px] h-[20px]"
                      />

                      {/* Question content */}
                      {question.questionType === "TEXT" && (
                        <div className="mt-[1px] w-full">
                          <div className="bg-white md:px-[20px]">
                            {/* Question text */}
                            <div className="lg:flex lg:justify-between lg:p-0">
                              <div>
                                <p className="text-zinc-800 text-lg font-normal font-['Inter'] ml-[40] mr-[10] text-justify">
                                  {question.question}
                                </p>
                              </div>
                            </div>
                            {/* Dropdown Icon */}
                            <button
                              onClick={() => toggleOptions(index)}
                              className=" items-center mt-[12px]
                            border bg-gradient-to-r from-[#1EC6DA] to-[#6F2A96]
                         w-[91px] h-[23px] p-[15.78px] rounded-lg justify-center inline-flex font-normal
                            text-white"
                            >
                              <span className="text-white text-[10.75px] font-normal font-['Inter']">
                                {showOptions[index] ? " Options" : "Options"}
                              </span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className={`h-3 w-7  ml-2 
                                transform transition-transform ${
                                  showOptions[index] ? "rotate-180" : ""
                                }`}
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M19 9l-7 7-7-7"
                                />
                              </svg>
                            </button>

                            {/* Options */}
                            {showOptions[index] && (
                              <div className="md:flex md:justify-between lg:mt-[32px] grid grid-cols-2 lg:grid-cols-1 gap-[24px] mt-[24px] text-[#000] font-[Inter] lg:text-[20px] text-[16px] font-[600] leading-normal">
                                {question?.options
                                  .filter((option: string) => option !== "")
                                  .map((option: any, optionIndex: number) => (
                                    <div
                                      key={optionIndex}
                                      className={`flex items-center ${
                                        (optionIndex === 0 ||
                                          optionIndex === 2) &&
                                        "justify-start"
                                      } ${
                                        (optionIndex === 1 ||
                                          optionIndex === 3) &&
                                        "justify-end"
                                      }`}
                                    >
                                      <img
                                        className="mr-2"
                                        src={
                                          option === question.correctAnswer
                                            ? Tick
                                            : Cancel
                                        }
                                        alt={
                                          option === question.correctAnswer
                                            ? "Correct"
                                            : "Incorrect"
                                        }
                                      />
                                      {question.answerType === "TEXT" ||
                                      question.answerType === "BOOLEAN" ? (
                                        <div className="text-zinc-800 text-lg font-normal font-['Inter']ml-2 ">
                                          <span>{option}</span>
                                        </div>
                                      ) : null}
                                      {question.answerType === "IMAGE" ? (
                                        <div className="">
                                          <img
                                            className="lg:w-[164px] h-[85.854px] md:w-[110px] w-0 md:h-[128px] rounded-[10px] lg:rounded-[10px]"
                                            src={option}
                                            alt="template"
                                          />
                                        </div>
                                      ) : null}
                                    </div>
                                  ))}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                      {question.questionType === "IMAGE_TEXT" && (
                        <div className=" w-full">
                          <div className="md:w-full mt-[7px] bg-white md:px-[5px] ">
                            {/* Question text with image */}
                            <div className="lg:flex mt-[18px]">
                              <img
                                className="w-[150px] h-[150px] rounded-[10px] lg:rounded-[10px]"
                                src={question.questionImg}
                                alt="template"
                              />
                              <p className=" lg:ml-[32px] text-zinc-800 text-lg font-normal font-['Inter']lg:mt-[20px] mt-[24px] text-justify">
                                {question.question}
                              </p>
                            </div>
                            {/* Dropdown Icon */}

                            <button
                              onClick={() => toggleOptions(index)}
                              className=" items-center mt-[12px]
                            border bg-gradient-to-r from-[#1EC6DA] to-[#6F2A96]
                         w-[91px] h-[23px] p-[15.78px] rounded-lg justify-center inline-flex font-normal
                            text-white"
                            >
                              <span className="text-white text-[10.75px] font-normal font-['Inter']">
                                {showOptions[index] ? " Options" : " Options"}
                              </span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className={`h-3 w-7  ml-2 transform transition-transform ${
                                  showOptions[index] ? "rotate-180" : ""
                                }`}
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M19 9l-7 7-7-7"
                                />
                              </svg>
                            </button>

                            {/* Options */}
                            {showOptions[index] && (
                              <div className="md:flex md:justify-between lg:mt-[32px] grid grid-cols-2 lg:grid-cols-1 gap-[24px] mt-[24px] text-[#000] font-[Inter] lg:text-[20px] text-[16px] font-[600] leading-normal">
                                {question?.options
                                  .filter((option: string) => option !== "")
                                  .map((option: any, optionIndex: number) => (
                                    <div
                                      key={optionIndex}
                                      className={`flex items-center ${
                                        (optionIndex === 0 ||
                                          optionIndex === 2) &&
                                        "justify-start"
                                      } ${
                                        (optionIndex === 1 ||
                                          optionIndex === 3) &&
                                        "justify-end"
                                      }`}
                                    >
                                      <img
                                        className="mr-2"
                                        src={
                                          option === question.correctAnswer
                                            ? Tick
                                            : Cancel
                                        }
                                        alt={
                                          option === question.correctAnswer
                                            ? "Correct"
                                            : "Incorrect"
                                        }
                                      />
                                      {question.answerType === "TEXT" ||
                                      question.answerType === "BOOLEAN" ? (
                                        <div className="text-zinc-800 text-lg font-normal font-['Inter'] ml-2 ">
                                          <span>{option}</span>
                                        </div>
                                      ) : null}
                                      {question.answerType === "IMAGE" ? (
                                        <div className="">
                                          <img
                                            className="w-[150px] h-[100px] rounded-[10px] lg:rounded-[10px]"
                                            src={option}
                                            alt="template"
                                          />
                                        </div>
                                      ) : null}
                                    </div>
                                  ))}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              {/* Load More Button */}
              {!allQuestionsLoaded && (
                <div className="flex justify-center mt-4">
                  <button
                    onClick={handleLoadMore}
                    className="bg-gradient-to-r from-[#1EC6DA] to-[#6F2A96] px-4 py-2 rounded text-white text-base font-medium font-['Inter']"
                  >
                    Load More
                  </button>
                </div>
              )}
            </>
          ) : (
            <>
              {/* Categories List */}
              {!searchTerm && !selectedCategory && (
                <div className="mt-4">
                  <h3 className=" mb-2 text-zinc-900 text-2xl font-semibold font-['Inter']">
                    Categories
                  </h3>
                  <ul>
                    {categories.map((category) => (
                      <li
                        key={category._id}
                        onClick={() => handleCategorySelect(category._id)}
                        className=" hover:rounded px-3 py-2 text-zinc-400 text-sm font-normal font-['Inter'] border border-[#0A687426] rounded-md mb-4 cursor-pointer bg-[#1FC4D903]"
                      >
                        {category.category_name}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </>
          )}
        </main>
        {/* Apply Button */}
        {quizData.length > 0 && (
          <button
            onClick={handleApply}
            className="bg-gradient-to-r from-[#1EC6DA] to-[#6F2A96] px-4 py-2 rounded text-white text-base font-medium font-['Inter']"
          >
            Apply
          </button>
        )}
      </div>
    </div>
  );
};

export default ChatBox;
