import React, { useState } from "react";

const ToggleButton: React.FC = () => {
  const [isToggled, setIsToggled] = useState(false);

  const toggle = () => {
    setIsToggled(!isToggled);
  };

  return (
    <button
      onClick={toggle}
      className={`relative flex items-center justify-between rounded-full w-16 h-8 ${
        isToggled ? "bg-green-500" : "bg-white"
      }`}
      aria-pressed={isToggled}
      aria-label={isToggled ? "Toggle is On" : "Toggle is Off"}
    >
      <span
        className={`absolute inset-y-0 w-6 mt-1 h-6 flex items-center justify-center rounded-full transition-all duration-200 ease-in-out ${
          isToggled
            ? "right-1 bg-white"
            : "left-1 bg-gradient-to-r from-[#1EC6DA] to-[#6F2A96]"
        }`}
      ></span>
      <span
        className={`absolute inset-y-0 flex items-center justify-center w-full text-center ${
          isToggled ? "right-0 mr-3 text-white" : "left-0 ml-3 text-black"
        }`}
      >
        {isToggled ? "On" : "Off"}
      </span>
    </button>
  );
};

export default ToggleButton;
