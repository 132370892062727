import React, { useEffect, useState } from "react";
import { io } from "socket.io-client";
import { getNameandAvatar } from "src/services/playerWaitingPage/playerWaiting.service";
import QuizTimer from "src/components/quizTimer/QuizTimer";
import playerCount from "../../assets/playerCount.svg";
import { useNavigate } from "react-router-dom";
import events from "../websocket/EventsName";
import Navbar from "src/components/navBar/NavBar";
import { Helmet } from "react-helmet";

interface PlayerData {
  _id: string;
  name: string;
  socketId: string;
  avatarUrl: string;
}

const PlayerWaitingPage = () => {
  const [timer, setTimer] = useState(false);
  const [countdown, setCountdown] = useState(3);
  const [connectedPlayers, setConnectedPlayers] = useState<PlayerData[]>([]);
  const [getDetail, setGetDetail] = useState<any>({});
  const queryParams = new URLSearchParams(window.location.search);
  const player_id = queryParams.get("player_id") || "";
  const slugWithQuotes = sessionStorage.getItem("slug") || "";
  const slug = slugWithQuotes.replace(/["']/g, "");
  const navigate = useNavigate();
  useEffect(() => {
    // Make an API call to fetch player details when the component mounts
    getNameandAvatar(player_id)
      .then((data) => {
        setGetDetail(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [player_id]);

  useEffect(() => {
    if (process.env.React_App_SERVER_URL) {
      const socket = io(process.env.React_App_SERVER_URL); // Replace with your backend URL

      socket.on(events.SOCKET_CONNECTING, () => {
        const userInfo = JSON.parse(sessionStorage.getItem("userInfo") || "");
        const savedAvatarUrl = sessionStorage.getItem("quizPageAvatar");

        if (userInfo) {
          socket.emit(events.SEND_CLIENT_DATA, {
            _id: userInfo.user._id,
            name: userInfo.user.name,
            avatarUrl: getDetail?.avatarUrl || savedAvatarUrl,
            slug: slug,
          });

          sessionStorage.setItem("url", JSON.stringify(getDetail?.avatarUrl));
        }
      });

      // Listen for the event that sends existing player data

      socket.on(events.PLAYERS_DATAS, (playersData: PlayerData[]) => {
        setConnectedPlayers(playersData);
      });

      // Listen for the "quizStarted" event
      socket.on(events.GET_READY, () => {
        setTimer(true);
      });
      //start countdowns
      socket.on(events.START_COUNTDOWNS, (updatedCountdown) => {
        setCountdown(updatedCountdown);
      });

      socket.on(events.FOR_NAVIGATION, (data) => {
        // Navigate to the next page only after the acknowledgment is received
        navigate(`/player/quiz/${slug}`);
        // Emit the 'check' event with the data
      });

      return () => {
        socket.disconnect();
      };
    }
  }, [getDetail, slug, navigate]);

  return (
    <div
    className="bg-[#192E3B] ">
    <main className="max-w-screen-2xl mx-auto  w-full bg-[#192E3B] ">
      <Helmet>
        <title>Players Waiting To Join </title>
        <meta
          name="description"
          content="Wait for participants to join the quiz."
        />
      </Helmet>
      <div className="w-full h-full overflow-y-auto font-[Poppins]">
        <div className="lg:flex ">
          {/* left */}
          <div className="bg-[#192E3B] flex-col lg:w-[60%] lg:min-h-screen  ">
            {/* logo */}
            <div className="fixed bg-[#192E3B] w-full">
              <Navbar hideItems={true} className="bg-[#192E3B]" />
            </div>

            {/* container */}

            <div className=" w-full flex justify-center lg:justify-start items-center lg:px-0 px-4">
              <div className="lg:ml-[100px] lg:w-full mt-[100px] lg:mt-[150px] mb-[9%] lg:mb-[20%] ">
                <div>
                  <h1 className="text-white font-[Poppins] font-[600] lg:text-[48px] text-[40px] leading-normal  ">
                    Hi, {getDetail?.name}
                  </h1>

                  <p className="text-[#95D6FF] font-[Poppins] text-[24px] lg:text-[25px] font-[400] leading-normal ">
                    Please wait for your host to start the quiz
                  </p>
                </div>

                <div className="flex mt-[7%]">
                  <img
                    className="w-[83px] h-[83px]  rounded-[83px] mr-[4%] "
                    src={getDetail?.avatarUrl}
                    alt="Avatar"
                    loading="lazy"
                  />
                  <h1 className="text-white flex justify-center items-center font-[Poppins] text-[25px] lg:text-[20px] font-[600] leading-normal  ">
                    {getDetail?.name}
                  </h1>
                </div>
              </div>
            </div>
          </div>

          {/* right*/}

          <div className="lg:w-[40%] lg:fixed lg:right-0 min-h-screen  bg-[#1D3441] p-4 lg:p-0 ">
            <div className="lg:px-[30px] lg:mt-[39px]  bg-[#1D3441] ">
              {/* count details */}
              <div className=" px-[16px] py-[8px] items-center bg-[#233D4E] gap-[14px] rounded-[90px] inline-flex mb-[48px] ">
                <img src={playerCount} alt="img"></img>
                <p className="text-white text-[16px] font-[Poppins] font-semibold  ">
                  {connectedPlayers.length}
                </p>
              </div>
              <br />
              {/* Players */}
              <div className="inline-flex flex-wrap  items-start gap-[24px] ">
                {connectedPlayers.map((player) => (
                  <div
                    key={player._id}
                    className="flex w-auto h-auto p-[16px] items-start gap-[11px] rounded-[10px] border-[1px] border-1 border-solid border-[#95D6FF]"
                  >
                    <img
                      className="w-[26px] h-[26px] rounded-[26px] bg-cover bg-no-repeat bg-center  "
                      src={player.avatarUrl}
                      alt="Avatar"
                      loading="lazy"
                    />
                    <div className="text-[#95D6FF] font-[Poppins] text-[16px] font-[400px] leading-normal ">
                      {player.name}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <dialog open={timer}>
              <div className="fixed top-0 left-0 w-full items-center h-full flex justify-center lg:px-0 px-[16px] ">
                <QuizTimer countdown={countdown} />
              </div>
            </dialog>
          </div>
        </div>
      </div>
    </main>
    </div>
  );
};

export default PlayerWaitingPage;
