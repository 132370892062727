import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { hostResetPassword } from "src/services/hostCredential/hostCredential.services";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";

const PasswordReset: React.FC = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { token = "", email } = useParams<{ token: string; email: string }>();

  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!email || password !== confirmPassword) {
      toast.error("Passwords do not match. Please try again.");
      return;
    }

    try {
      await hostResetPassword(email, password, token);

      toast.success("Password reset successful!");
      setTimeout(() => {
        navigate(`/`);
      }, 3000);
    } catch (error) {
      console.error("Password reset failed:", error);
      toast.error("Password reset failed. Please try again.");
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <div>
      <Helmet>
        <title>Password Reset</title>
        <meta
          name="description"
          content="Reset your password to regain access to your Quiz Circles account."
        />
      </Helmet>
      <ToastContainer position="top-center" />
      <div className="min-h-screen flex flex-col items-center justify-start bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div>
            <h2 className="mt-6 text-center text-2xl text-black font-[poppins] font-bold">
              Reset your password
            </h2>
          </div>
          <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <div className="relative">
                  <input
                    id="password"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    autoComplete="new-password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="border rounded w-full py-2 px-3 md:py-3 text-black font-[poppins] text-[15px] font-normal mb-4 pr-10"
                    placeholder="Password"
                  />
                  <FontAwesomeIcon
                    icon={showPassword ? faEyeSlash : faEye}
                    className="absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer"
                    onClick={togglePasswordVisibility}
                  />
                </div>
              </div>
              <div>
                <label htmlFor="confirm-password" className="sr-only">
                  Confirm Password
                </label>
                <div className="relative">
                  <input
                    id="confirm-password"
                    name="confirm-password"
                    type={showConfirmPassword ? "text" : "password"}
                    autoComplete="new-password"
                    required
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="border rounded w-full py-2 px-3 md:py-3 text-black font-[poppins] text-[15px] font-normal pr-10"
                    placeholder="Confirm Password"
                  />
                  <FontAwesomeIcon
                    icon={showConfirmPassword ? faEyeSlash : faEye}
                    className="absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer"
                    onClick={toggleConfirmPasswordVisibility}
                  />
                </div>
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="bg-gradient-to-r from-[#1EC6DA] to-[#6F2A96]  hover:shadow-[0px_20px_20px_-10px_rgba(131,42,184,0.4)] hover:inner-shadow-[inset 0px 20px 20px -10px rgba(131,42,184,0.4)] rounded-md text-white font-[poppins] text-base font-normal px-4 py-3 w-full mt-[10px] transition-colors duration-300 "
              >
                Reset password
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;
