import React from "react";
import logoText from "../../assets/textIcon.png";
import logoIcon from "../../assets/logoIcon.png";
import { Link } from "react-router-dom";

const Logo = () => {
  return (
    <Link to="/" className="">
       <div className="flex  items-center justify-center xl:gap-4 md:gap-4 gap-2
       bg-transparent">
       <img
          className="xl:w-[52px] xl:h-[49px] sm:w-[40px] sm:h-[36px] md:w-[52px] md:h-[49px] w-[27px]  h-[30px]  lg:w-[45px] lg:h-[49px]"
          src={logoIcon}
          alt="logoIcon"
        />
       <img
          className="2xl:w-[370px] xl:w-[330px] xl:h-[37px] md:w-[295px] md:h-[29.6px] w-[136px] sm:w-[200px] sm:h-[30px] h-[19.6px] mt-1  lg:w-[264px]  lg:h-[29.6px]"
          src={logoText}
          alt="logoText"

        />
      
            </div>
    </Link>
  );
};

export default Logo;
