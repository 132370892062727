import React, { FC, useEffect, useState, useRef, useCallback } from "react";
import { uploadImage } from "src/services/createQuiz/createQuiz.service";
import LoaderComponent from "src/components/loader/LoaderComponent";
import ImageUpload from "../imageUpload/ImageUpload";
import GenerateQuestion from "../geminiAi/geminiAi";
import { debounce } from "lodash";

type ImageTextTypeComponentsProp = {
  response: (data: any) => void;
  questions?: any;
};

const TrueOrFalse: FC<ImageTextTypeComponentsProp> = ({
  response,
  questions = {},
}) => {
  const [questionImg, setQuestionImg] = useState(questions?.questionImg);
  const [question, setQuestion] = useState(questions.question || "");
  const [options, setOptions] = useState<string[]>(["TRUE", "FALSE"]);
  const [correctAnswer, setCorrectAnswer] = useState<string | undefined>(
    questions.correctAnswer
  );
  const [isLoading, setIsLoading] = useState(false);
  const [categoryId, setCategoryId] = useState("");

  const questionId = questions?._id;
  const editable = questions?.isEditable;
  const responseRef = useRef(response);

  const outputData = useCallback((data: any) => {
    responseRef.current(data);
  }, []);

  useEffect(() => {
    if (questions.category_id) {
      setCategoryId(questions.category_id);
    }
  }, [questions]);

  useEffect(() => {
    responseRef.current = response;
  }, [response]);

  useEffect(() => {
    const debouncedOutputData = debounce((data: any) => {
      responseRef.current(data);
    }, 300);
    if (question !== "") {
      let questionType = "TEXT";
      if (questionImg) {
        questionType = "IMAGE_TEXT";
      }

      const answerType = "BOOLEAN";
      const data = {
        question,
        options,
        correctAnswer,
        questionImg,
        questionType,
        answerType,
        _id: questionId,
        isEditable: editable,
        category_id: categoryId,
      };
      debouncedOutputData(data);

      // Cleanup function to cancel debounce on unmount
      return () => {
        debouncedOutputData.cancel();
      };
    }
  }, [
    question,
    options,
    correctAnswer,
    questionImg,
    questionId,
    outputData,
    editable,
    categoryId,
  ]);

  const coverFileHandler = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      setIsLoading(true);
      try {
        const reader = new FileReader();
        reader.onload = () => {
          if (reader.result) {
            reader.result as string;
          }
        };
        reader.readAsDataURL(file);

        const imageUrl = await uploadImage(file);
        setQuestionImg(imageUrl);
      } catch (error) {
        console.error("Error uploading image:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleClearQuestions = () => {
    setQuestion("");
    setOptions(["TRUE", "FALSE"]);
    setCorrectAnswer(undefined);
    const clearedQuestionData = {
      ...questions,
      answer: "",
    };
    response(clearedQuestionData);
  };

  return (
    <div>
      <div className="w-full h-full">
        <hr className="border-1 mt-2" />
        {/* Generate Question Section */}
        <section className="container mx-auto mt-4">
          <GenerateQuestion
            onGenerate={(data: any) => {
              setQuestion(data.question);
              if (data.options) {
                setOptions(Object.values(data.options));
              }
              setCorrectAnswer(data.correct_answer);
            }}
            onClear={handleClearQuestions}
            type="TEXT-BOOLEAN"
          />
        </section>

        {/* question section*/}
        <section className="md:flex mt-[18px] items-center justify-between gap-4">
          <div className="w-auto relative lg:w-[312px] md:w-[300px] border-dashed border-[1px] border-[#1FC4D9] rounded-[10px] p-2">
            {!questionImg && (
              <div className="lg:w-[312px] md:h-[210px] w-full md:w-[300px] flex items-center justify-center">
                <ImageUpload type="question" />
              </div>
            )}
            {isLoading && <LoaderComponent />}
            {questionImg && !isLoading && (
              <img
                className="lg:w-[312px] md:h-[210px] w-full md:w-[300px] rounded-[10px]"
                src={questionImg}
                alt="Preview"
              />
            )}
            <label
              htmlFor="imageInput"
              className="absolute inset-0 flex items-center justify-center rounded-xl cursor-pointer"
            >
              <input
                id="imageInput"
                type="file"
                accept="image/*"
                className="absolute top-0 left-0 w-full h-full opacity-0"
                onChange={coverFileHandler}
              />
            </label>
          </div>

          <div className="border xl:mt-[40px] lg:mt-[20px] mt-8 border-[#D7D7D7] p-2 rounded-[10px] w-full">
            <textarea
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              className="text-[#000] font-[inter] lg:text-[18px] text-[16px] lg:font-[400] leading-normal ml-[40] mr-[10] w-full lg:w-[90%] max-h-[60px] border resize-none placeholder:text-zinc-400 placeholder:text-lg placeholder:font-normal flex items-center box-border pb-5 pt-4 px-2 overflow-auto no-scrollbar border-none focus:outline-none  "
              placeholder="Question"
            />
          </div>
        </section>

        {/* options section*/}
        <section className="space-y-4 lg:mt-[32px] mt-[24px] gap-2 text-[#000] font-[inter] lg:text-[18px] text-[16px] leading-normal">
          <h2 className="text-xl font-semibold mb-4">Options</h2>
          {options.map((option: any, index: number) => (
            <div
              key={index}
              className="relative w-full flex border border-[#0A687426l] bg-[#1FC4D903] rounded-[10px] lg:my-0 my-2"
            >
              <div className="flex flex-wrap w-[90%] p-2 border-none">
                {option}
              </div>
              <div className="absolute top-0 right-0 w-[10%] flex items-center h-full justify-center">
                <input
                  className="rounded-[10px] border-[#0000002B] bg-white"
                  type="checkbox"
                  name="answer"
                  checked={correctAnswer === option}
                  onChange={() => setCorrectAnswer(option)}
                />
              </div>
            </div>
          ))}
        </section>
      </div>
    </div>
  );
};

export default TrueOrFalse;
