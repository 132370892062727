import React, { useEffect, useState } from "react";
import correct from "../../assets/correct.svg";
import wrong from "../../assets/wrong.svg";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import {
  getClickedDetails,
  getQuestions,
} from "src/services/playerQuizPage/playerQuiz.service";
import { useNavigate, useParams } from "react-router-dom";
import { io } from "socket.io-client";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import events from "../websocket/EventsName";
import { useSpring, animated, config } from "react-spring";
import correctAudio from "../../audio/correctAudio.wav";
import inCorrectAudio from "../../audio/wrong1.wav";
import questionAudio from "../../audio/question.wav";
import CryptoJS from "crypto-js";
import Navbar from "src/components/navBar/NavBar";
import { Helmet } from "react-helmet";

interface PlayerData {
  marks: number;
  time: number;
  _id: string;
  name: string;
  socketId: string;
  avatarUrl: string;
}

const PlayerQuizPage = () => {
  const [showStartAlert, setShowStartAlert] = useState(false);
  const [correctAnswerEmitted, setCorrectAnswerEmitted] = useState<boolean>();
  const [trueCount, setTrueCount] = useState(0);
  const [falseCount, setFalseCount] = useState(0);
  const [marks, setMarks] = useState(0);
  const [cumulativeTime, setCumulativeTime] = useState<number>(0);
  const [countdown, setCountdown] = useState<any>();
  const [connectedPlayers, setConnectedPlayers] = useState<PlayerData[]>([]);
  const [gameSettings, setGameSettings] = useState<any>();
  const [questions, setQuestions] = useState<any>([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answer, setAnswer] = useState("");
  const [currentTimer, setCurrentTimer] = useState("questionTimer");
  const [showResult, setShowResult] = useState(false);
  const [selectedResult, setSelectedResult] = useState<boolean>();
  const [startTimer, setStartTimer] = useState(false);
  const [questionStartTime, setQuestionStartTime] = useState<number>(0);
  const { slug } = useParams();

  const [initialTimer, setInitialTimer] = useState(
    gameSettings?.respondTime || 0
  );

  const [lastCorrectAnswerTime, setLastCorrectAnswerTime] = useState<number>(0);
  const [marksAwarded, setMarksAwarded] = useState<number>(0);

  const [animatedPlayers, setAnimatedPlayers] = useState<PlayerData[]>([]);
  const [height, setHeight] = useState(0);
  const navigate = useNavigate();

  const selectAnswer = (selectedOption: any) => {
    setAnswer(selectedOption);
  };

  // Function to update height based on available space- leaderboard
  useEffect(() => {
    const updateHeight = () => {
      const windowHeight = window.innerHeight;
      const container = document.getElementById("leaderboard-container");
      if (container) {
        const offsetTop = container.offsetTop; // Get the offset top of the container
        // Calculate new height based on available space at the bottom of the screen
        const newHeight = windowHeight - offsetTop - 8; // Adjusted as per your requirement
        setHeight(newHeight);
      }
    };

    // Initial height calculation
    updateHeight();

    // Event listener for window resize
    window.addEventListener("resize", updateHeight);

    // Cleanup
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  //Play audio
  useEffect(() => {
    const correctSound = new Audio(correctAudio);
    const wrongAudio = new Audio(inCorrectAudio);

    if (showResult && selectedResult) {
      correctSound.play();
    } else if (showResult && !selectedResult) {
      wrongAudio.play();
    }
  }, [showResult, selectedResult]);

  useEffect(() => {
    const newQuestion = new Audio(questionAudio);
    if (currentTimer === "respondTime") {
      newQuestion.play();
    }
  }, [currentTimer]);

  useEffect(() => {
    if (showResult && answer === "") {
      const userInfo = JSON.parse(sessionStorage.getItem("userInfo") || "");
      const playerId = userInfo.user._id;
      const questionId = questions[currentQuestion]?.question_id;
      const body = {
        playerId,
        option: -1, // Indicate that no option was selected
        slug,
        questionId,
        correctAnswer: false,
      };
      getClickedDetails(body)
        .then((response) => {})
        .catch((error) => {
          console.error(error);
        });
    }
  }, [showResult, answer, cumulativeTime, currentQuestion, questions, slug]);

  useEffect(() => {
    if (startTimer) {
      setQuestionStartTime(Date.now());
    }
  }, [startTimer, currentQuestion]);

  const handleClick = (option: number, questionId: string, answer: string) => {
    const userInfo = JSON.parse(sessionStorage.getItem("userInfo") || "");
    const playerId = userInfo.user._id;

    // Time in Milliseconds
    const timeMs = Date.now() - questionStartTime;
    // Time in seconds
    const time = timeMs / 1000;

    // Calculate the response time percentage
    const respondTimePercentage = (time / initialTimer) * 100;

    // Determine the marks based on the response time
    let marksAwarded = 100; // Default marks if response time > 20%
    switch (true) {
      case respondTimePercentage <= 20:
        marksAwarded = 150;
        break;
      case respondTimePercentage <= 40:
        marksAwarded = 140;
        break;
      case respondTimePercentage <= 60:
        marksAwarded = 130;
        break;
      case respondTimePercentage <= 80:
        marksAwarded = 120;
        break;
      default:
      // Default case: marksAwarded remains 100
    }
    setMarksAwarded(marksAwarded);

    const correctAnswer =
      questions[currentQuestion]?.question.correctAnswer === answer;
    if (correctAnswer) {
      setSelectedResult(true);
      setCumulativeTime((prevCumulativeTime) => prevCumulativeTime + time);
    } else {
      setSelectedResult(false);
      marksAwarded = 0; // Set marks to 0 for incorrect answers
    }
    const body = {
      playerId,
      option,
      slug,
      questionId,
      time,
      correctAnswer,
    };

    getClickedDetails(body)
      .then((response) => {})
      .catch((error) => {
        console.error(error);
      });

    // Update the marks
    setMarks((prevMarks) => prevMarks + marksAwarded);
  };

  useEffect(() => {
    if (process.env.React_App_SERVER_URL) {
      const socket = io(process.env.React_App_SERVER_URL);
      if (socket) {
        socket.on(events.SOCKET_CONNECTING, () => {
          const userInfo = JSON.parse(sessionStorage.getItem("userInfo") || "");
          const avatartURL = JSON.parse(sessionStorage.getItem("url") || "");
          if (userInfo) {
            socket.emit(events.SEND_CLIENT_DATA, {
              _id: userInfo.user._id,
              name: userInfo.user.name,
              avatarUrl: avatartURL,
              slug: slug,
            });
          }
        });

        // Listen for the event that sends existing player data
        socket.on(events.PLAYERS_DATAS, (playersData: PlayerData[]) => {
          setConnectedPlayers(playersData);
        });
        socket.on(events.QUIZ_ROOM, (joinedRoom) => {
          // Now you can listen for updateCountdown events in the specific room
          socket.on(
            events.REST_RESPONSE_SIGNAL,
            (updatedCountdown, currentTimer, index) => {
              setCurrentTimer(currentTimer);
              if (currentTimer === "respondTime") {
                setInitialTimer(gameSettings?.respondTime);
                setShowResult(false);
                setCurrentQuestion(index);
                if (updatedCountdown === 10) {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }
              } else {
                setInitialTimer(gameSettings?.restTime);
                setShowResult(true);
                setCurrentQuestion(index);
              }
              setCountdown(updatedCountdown);

              socket.on("afterCounts", (data) => {
                // Perform navigation when the event is received
                navigate(`/player/result/${slug}`);
              });
            }
          );
        });

        socket.on("NewEventAfterStop", (data) => {
          // Show the alert when the event is received
          setShowStartAlert(true);
          // Set a timeout to navigate after 5 seconds
          setTimeout(() => {
            setShowStartAlert(false); // Hide the alert
            navigate(`/player/result/${slug}`);
          }, 5000);
        });

        return () => {
          socket.off(events.REST_RESPONSE_SIGNAL); // Remove the specific event listener
          socket.disconnect();
        };
      }
    }
  }, [slug, gameSettings, navigate, questions.length]);

  useEffect(() => {
    setAnswer("");
  }, [currentQuestion]);

  useEffect(() => {
    if (
      currentTimer === "restTime" &&
      !correctAnswerEmitted &&
      process.env.React_App_SERVER_URL
    ) {
      const userInfo = JSON.parse(sessionStorage.getItem("userInfo") || "");
      const playerId = userInfo.user._id;
      const socket = io(process.env.React_App_SERVER_URL);

      if (questions[currentQuestion]?.question.correctAnswer === answer) {
        setSelectedResult(true);
        // Count the correct answer
        setTrueCount((prevTrueCount) => prevTrueCount + 1);
        // No need to add marks here
        // Emit playerSelectedCorrectAnswer event for correct answers
        socket.emit(events.QUESTIONS_RESPONSE, {
          playerId,
          questionId: questions[currentQuestion]._id,
          slug,
          time: cumulativeTime,
          marks: marks,
          trueCount: trueCount + 1,
          falseCount,
        });
        // Update the last correct answer time
        setLastCorrectAnswerTime(cumulativeTime);
      } else {
        setSelectedResult(false);
        setFalseCount((prevFalseCount) => prevFalseCount + 1);

        // Emit playerSelectedCorrectAnswer event for incorrect answers
        socket.emit(events.QUESTIONS_RESPONSE, {
          playerId,
          questionId: questions[currentQuestion]._id,
          slug,
          time: lastCorrectAnswerTime, // Use the last correct answer time for wrong answers
          marks: marks, // Use the previous marks here
          trueCount,
          falseCount: falseCount + 1,
        });
      }
      // Set the state to indicate that the event has been emitted
      setCorrectAnswerEmitted(true);
    }
  }, [
    currentTimer,
    answer,
    currentQuestion,
    questions,
    cumulativeTime,
    slug,
    marks,
    correctAnswerEmitted,
    trueCount,
    falseCount,
    lastCorrectAnswerTime,
  ]);

  // count correct and wrong answer
  useEffect(() => {}, [trueCount, falseCount]);

  useEffect(() => {
    // Reset the correctAnswerEmitted state when the current question changes
    setCorrectAnswerEmitted(false);
  }, [currentQuestion]);

  // ...
  useEffect(() => {
    const secretKey = "quiz123456";
    if (slug) {
      getQuestions(slug)
        .then((data) => {
          setGameSettings(data?.gameSettings);
          setInitialTimer(data?.gameSettings?.respondTime);
          const decryptedQuestions = data?.questionDetails?.map(
            (question: any) => {
              const bytes = CryptoJS.AES.decrypt(
                question.question.correctAnswer,
                secretKey
              );
              const decryptValue = bytes.toString(CryptoJS.enc.Utf8);
              return {
                ...question,
                question: {
                  ...question.question,
                  correctAnswer: decryptValue,
                },
              };
            }
          );
          const decryptedData = {
            ...data,
            questionDetails: decryptedQuestions,
          };
          setQuestions(decryptedData.questionDetails);
          setStartTimer(true);
        })
        .catch((error) => {
          return error;
        });
    }
  }, [slug]);

  window.addEventListener("beforeunload", function (event) {
    localStorage.setItem("refreshFlag", "true");

    const confirmationMessage = "Are you sure you want to leave?";
    event.returnValue = confirmationMessage; // Standard for most browsers
    return confirmationMessage; // For some older browsers
  });

  window.addEventListener("load", function () {
    // Check if the refresh flag is set
    const refreshFlag = localStorage.getItem("refreshFlag");

    if (refreshFlag) {
      // Clear the refresh flag
      localStorage.removeItem("refreshFlag");

      // Navigate the user to another page after a short delay
      setTimeout(function () {
        window.location.href = `https://staging.quizcircles.com/player/home/${slug}`;
      }, 1000); // Adjust the delay as needed
    }
  });

  // Disable the back button
  useEffect(() => {
    const disableBackButton = () => {
      window.history.pushState(null as any, null as any, document.URL as any);
      window.addEventListener("popstate", () => {
        window.history.pushState(null as any, null as any, document.URL as any);
      });
    };
    disableBackButton();
    return () => {
      window.removeEventListener("popstate", disableBackButton);
    };
  }, []);

  // Animations for player leaderboard
  const leaderboardSpring = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: config.default,
  });

  useEffect(() => {
    setAnimatedPlayers(connectedPlayers);
  }, [connectedPlayers]);

  const sortPlayers = (players: PlayerData[]) => {
    return players.sort((a, b) => {
      if (a.marks !== b.marks) {
        return b.marks - a.marks;
      }
      return a.time - b.time;
    });
  };

  useEffect(() => {
    // Reset the correctAnswerEmitted state when the current question changes
    setCorrectAnswerEmitted(false);
  }, [currentQuestion]);

  // Update leaderboard animation when connected players change
  useEffect(() => {
    setAnimatedPlayers(connectedPlayers);
  }, [connectedPlayers]);

  return (
    <div
    className="bg-[#192E3B]">
    <div
    className="max-w-screen-2xl mx-auto  w-full bg-[#192E3B] ">
    <div className="w-full h-full bg-[#192E3B] font-[Poppins]">
      <Helmet>
        <title>Quiz Questions </title>
        <meta
          name="description"
          content="Answer the quiz questions displayed on this page."
        />
      </Helmet>
      <div className="lg:flex bg-[#192E3B] min-h-screen overflow-y-auto">
        <div className="fixed-container fixed top-0  px-[20px] lg:px-0 lg:fixed bg-[#192E3B] w-[100%] z-10">
          <Navbar hideItems={true} className="bg-[#192E3B]" />

          {/* Mobile view timer */}
          <div className="lg:mt-5 mt-0 bg-[#192E3B] lg:hidden flex">
            <p className="font-[Poppins] ml-[12px] text-white text-[16px] font-semibold leading-normal lg:py-0 py-[12px]">
              Question : {currentQuestion + 1} / {questions.length}
            </p>
          </div>
          {/* linear progress bar */}

          <div className="lg:hidden flex">
            <Box sx={{ width: "100%" }}>
              <LinearProgress
                variant="determinate"
                value={(countdown / initialTimer) * 100}
                sx={{ width: "100%" }}
              />
            </Box>
          </div>
        </div>
        {/*  left side */}
        <>
          <div className="bg-[#192E3B] flex-col lg:w-[66%] lg:min-h-screen lg:p-0 p-3 mt-[150px] lg:mt-[50px] ">
            {/* Question container */}

            <div className=" lg:w-[80%] lg:ml-20 bg-[#233d4e] lg:mt-[70px] rounded-[10px] flex-col lg:p-0 p-5">
              {/* Question */}

              <div className="flex justify-around flex-start ">
                <div className="lg:mt-[50px] lg:w-[85%] lg:mb-0 mb-4 ">
                  <p className="font-[Poppins] text-white text-[20px] font-normal leading-normal lg:flex  items-center text-justify">
                    {questions[currentQuestion]?.question.questionType ===
                      "IMAGE_TEXT" && (
                      <img
                        src={questions[currentQuestion]?.question.questionImg}
                        className="lg:w-[50%] lg:h-[50%] lg:mr-[8%] lg:mb-0 mb-[16px] rounded-[10px]"
                        alt="imgqs"
                      />
                    )}
                    {questions[currentQuestion]?.question.question}
                  </p>
                </div>
              </div>

              {/* Option */}

              <div className=" flex flex-wrap lg:py-[40px]  justify-center gap-[15px]">
                {questions[currentQuestion]?.question.options
                  .filter((option: string) => option !== "")

                  .map((option: any, index: any) => {
                    return (
                      <>
                        {/* QuestionType=text and answerType=image */}
                        {questions[currentQuestion].question.answerType ===
                        "IMAGE" ? (
                          <button
                            key={index}
                            disabled={
                              answer !== "" || currentTimer === "restTime"
                            }
                            className={`lg:w-[42%] w-full  rounded-[10px] m-2 flex items-center justify-center font-[Poppins] 
                    ${
                      answer === option && !showResult
                        ? "border-[2px] border-blue-400  "
                        : ""
                    }`}
                            onClick={() => {
                              selectAnswer(option);

                              handleClick(
                                index,
                                questions[currentQuestion]?.question._id,
                                option
                              );
                            }}
                          >
                            <div
                              className={`relative text-center w-full h-[200px]  ${
                                answer === option && !showResult
                                  ? "bg-transparent opacity-20  "
                                  : ""
                              } `}
                            >
                              <img
                                className="absolute inset-0 w-full h-full object-cover rounded-[10px] "
                                src={option}
                                alt="options"
                              />
                              <div className="absolute top-2 right-2">
                                <div
                                  className={` ${
                                    questions[currentQuestion]?.question
                                      .correctAnswer === option && showResult
                                      ? ""
                                      : "hidden"
                                  }`}
                                >
                                  <img
                                    className="w-[27px] h-[27px] "
                                    src={correct}
                                    alt="tick"
                                  />
                                </div>
                                <div
                                  className={`${
                                    questions[currentQuestion]?.question
                                      .correctAnswer !== option &&
                                    showResult &&
                                    answer === option
                                      ? ""
                                      : "hidden"
                                  }`}
                                >
                                  <img
                                    className="w-[27px] h-[27px] flex-shrink-0"
                                    src={wrong}
                                    alt="wrong"
                                  />
                                </div>
                              </div>
                            </div>
                          </button>
                        ) : // QuestionType=text and answerType=text

                        questions[currentQuestion].question.answerType ===
                            "TEXT" ||
                          questions[currentQuestion].question.answerType ===
                            "BOOLEAN" ? (
                          <button
                            key={index}
                            disabled={
                              answer !== "" || currentTimer === "restTime"
                            }
                            className={`lg:w-[42%] w-full rounded-[10px] font-[Poppins] p-2 border-[1px] border-solid flex justify-between items-center
                    
                    ${
                      questions[currentQuestion]?.question.correctAnswer ===
                        option && showResult
                        ? "bg-green-500"
                        : ""
                    }
                    ${
                      questions[currentQuestion]?.question.correctAnswer !==
                        option &&
                      showResult &&
                      answer === option
                        ? "bg-red-500"
                        : ""
                    }
                    ${
                      answer === option && !showResult
                        ? " bg-[#67c1f9]  border-[2px] "
                        : "border-[#95D6FF] border-[1px]"
                    }`}
                            onClick={() => {
                              selectAnswer(option);
                              handleClick(
                                index,
                                questions[currentQuestion]?.question._id,
                                option
                              );
                            }}
                          >
                            <div
                              className={`text-white flex  w-full justify-center items-center text-center font-[Poppins] text-[20px] font-semibold leading-normal `}
                            >
                              {option}
                            </div>

                            <div
                              className={` ${
                                questions[currentQuestion]?.question
                                  .correctAnswer === option && showResult
                                  ? ""
                                  : "hidden"
                              }`}
                            >
                              <img src={correct} alt="tick" />
                            </div>
                            <div
                              className={`${
                                questions[currentQuestion]?.question
                                  .correctAnswer !== option &&
                                showResult &&
                                answer === option
                                  ? ""
                                  : "hidden"
                              }`}
                            >
                              <img src={wrong} alt="wrong" />
                            </div>
                          </button>
                        ) : null}
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
          {showResult && (
            <div
              className={`lg:fixed lg:w-[66%]  lg:bottom-0 z-10 ${
                selectedResult
                  ? "bg-green-500 flex justify-between"
                  : "bg-red-500"
              } p-5 text-white font-[Poppins] font-normal lg:text-[20px] text-[14px] leading-normal `}
            >
              <p className="">
                {selectedResult && answer !== ""
                  ? "Hurry! You have chosen the correct answer."
                  : answer === ""
                  ? "You didn't choose anything."
                  : "Oops, your answer is incorrect."}
              </p>
              {selectedResult && (
                <div className="font-semibold">
                  You got {marksAwarded} points
                </div>
              )}
            </div>
          )}
        </>
        s{" "}
        {showStartAlert && (
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50 bg-black bg-opacity-50">
            <div className="lg:w-fit w-[80%] h-auto min-h-[150px] flex flex-col bg-[#1D3441] p-8 rounded-lg shadow-lg items-center justify-center">
              <div className="flex items-center justify-center my-3 text-[16px] md:text-[18px] font-bold text-center text-white">
                Host has ended the quiz
              </div>
            </div>
          </div>
        )}
        {/* circular timer and leaderboard */}
        <div className="bg-[#1D3441] lg:w-[34%] p-3 lg:p-0 lg:fixed lg:right-0 lg:min-h-screen lg:px-8 lg:gap-0 gap-4 z-20">
          <div>
            <div className="lg:mt-5 lg:flex hidden">
              <p className="font-[Poppins] text-white text-[16px] font-semibold leading-normal lg:py-0 py-[12px]">
                Question : {currentQuestion + 1} / {questions.length}
              </p>
            </div>

            {/* timer */}
            <div className="hidden lg:flex justify-center">
              <div className="  lg:w-[100%] w-[90%]  h-[237px] rounded-[10px] bg-[#233D4E] lg:mt-[40px] ">
                <div className="">
                  <div className="flex justify-center">
                    <p className="font-[Poppins] text-[14px] font-normal leading-normal text-white py-5">
                      {currentTimer === "respondTime"
                        ? "END'S IN"
                        : "NEXT QUESTION IN"}
                    </p>
                  </div>

                  <div className="flex justify-center">
                    <div className="flex justify-center items-center">
                      <div className="w-[128px] h-[128px]  relative">
                        <CircularProgressbarWithChildren
                          value={(countdown / initialTimer) * 100}
                          styles={buildStyles({
                            rotation: 180,
                            strokeLinecap: "round",
                            textSize: "16px",
                            pathTransitionDuration: 0.5,
                            pathColor: "#1AC8FF",
                            textColor: "#ffffff",
                            trailColor: "#1B4E5F",
                            backgroundColor: "#233D4E",
                          })}
                        >
                          <div className="font-semibold text-[44px] text-[#1AC8FF]">
                            {countdown}
                          </div>
                        </CircularProgressbarWithChildren>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* leaderboard */}
          <div>
            <h1 className="font-[Poppins] text-[14px] font-semibold leading-normal text-white py-3">
              Leaderboard
            </h1>
          </div>
          <animated.div
            style={{ ...leaderboardSpring, maxHeight: `${height}px`,minHeight:`100px` }}
            className="justify-center overflow-y-auto mt-[8px] no-scrollbar flex-wrap"
            id="leaderboard-container" // Add an id to your component
          >
            {sortPlayers(animatedPlayers).map((player: PlayerData, index) => (
              <AnimatedLeaderboardItem
                key={player._id}
                player={player}
                index={index}
              />
            ))}
          </animated.div>
        </div>
      </div>
    </div>
    </div>
    </div>
  );
};

const AnimatedLeaderboardItem: React.FC<{
  player: PlayerData;
  index: number;
}> = ({ player, index }) => {
  const [style, set] = useSpring(() => ({
    opacity: 0,
    transform: `translateY(${index * 4}px)`,
    config: config.stiff,
  }));

  const truncateTime = (time: number) => {
    return String(time).slice(0, 5);
  };

  useEffect(() => {
    const delay = setTimeout(() => {
      set({
        opacity: 1,
        transform: `translateY(${index * 4}px)`,
      });
    }, 100);

    return () => clearTimeout(delay);
  }, [index, set]);

  return (
    <animated.div
      style={style}
      className="w-[100%] text-[white] font-[Poppins] flex px-[12px] py-[10px] mb-[8px] rounded-[20px] bg-[#233D4E] h-[53px]"
    >
      <div className="flex items-center gap-3 w-[55%] ">
        <div className="flex w-[24px] h-[24px] justify-center items-center rounded-[90px] bg-[#E3E3E3]">
          <h1 className="text-[#171717] font-[Poppins] text-[12px] font-[400] ">
            {index + 1}
          </h1>
        </div>
        <img
          className="w-[40px] h-[40px] rounded-[40px]"
          src={player.avatarUrl} // Use the player's avatar URL
          alt="profile"
        />
        <p className="font-[400] text-[14px]">{player.name}</p>{" "}
      </div>
      <div className="flex w-[45%] items-center">
        <div className="flex gap-[2px] ">
          <p className="flex justify-start">🏆</p>
          <p className="text-[16px] font-[700] mr-[12px] text-right">
            {player.marks ?? 0}
          </p>
        </div>
        <div className="flex gap-[2px]">
          <p className="flex justify-start">⏰</p>
          <p className="text-[#95D6FF] text-right">
            {player.time === 0
              ? truncateTime(player.time ?? 0)
              : `${truncateTime(player.time ?? 0)}s`}
          </p>
        </div>
      </div>
    </animated.div>
  );
};

export default PlayerQuizPage;
