import React, { useEffect, useRef, useCallback, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Logo from "../Logo/logo";
import HomeImage from "src/assets/Home.svg";
import StackedFiles from "src/assets/upcoming icon.png";
import MyLibrary from "src/assets/MYLibrary.svg";
import menuIcon from "src/assets/MenuBar.svg";
import Logout from "src/assets/Logout.svg";
import demo from "src/assets/icons-demo.png";
import myGame from "src/assets/myGame.png";
import generateLink from "src/assets/generatelink.png";
import faq from "src/assets/icons-faq.png";
import contact from "src/assets/icons-contact.png";

import { Squash as Hamburger } from "hamburger-react";

interface NavbarProps {
  openLoginDialog?: () => void;
  openRegisterDialog?: () => void;
  isLogin?: (data: boolean) => void;
  hideItems?: boolean;
  showLogin?: boolean;
  className?: string;
  startGame?: () => void;
  showPreviewButton?: boolean;
  showPublishButton?: boolean;
  showExitButton?: boolean;
  showGenerateLink?: boolean;

  showUpcomingsDropdown?: boolean;
  showmenuIcon?: boolean;
  templateDetailsMobileView?: boolean;
  handlePreview?: () => void;
  handlePublish?: () => void;
  handleExit?: () => void;
  showProfile?: boolean;
  showHamburger?: boolean;
  scrolled?: boolean;
  lgGapSizeHome?: string;
  lgPaddingHome?: string;
  mdGapSize?: string;
  xlPaddingHome?: string;
  smPadding?: string;
  pxCreate?: string;
  sticky?: boolean;
  generateLinkButton?: () => void;
  setIsMenuOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

const Navbar: React.FC<NavbarProps> = ({
  openLoginDialog,
  generateLinkButton = () => {},
  openRegisterDialog,
  isLogin = () => {},
  hideItems = false,
  showLogin = false,
  className,
  startGame,
  showPreviewButton = false,
  showPublishButton = false,
  showExitButton = false,
  showGenerateLink = false,
  showProfile = true,
  showHamburger = true,
  showmenuIcon = true,
  templateDetailsMobileView = true,

  showUpcomingsDropdown = false,
  handlePreview,
  handlePublish,
  handleExit,
  lgGapSizeHome = "5",
  mdGapSize = "3",
  lgPaddingHome = "[80px]",
  smPadding = "12",
  xlPaddingHome = "[100px]",
  pxCreate = "[20px]",
  sticky = true,
}) => {
  const [userName, setUserName] = useState<string | null>(null);
  const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";
  const navbarRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [showDropdown, setShowDropdown] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const getSuperHost = localStorage.getItem("superHost") || "";
  const superHost = getSuperHost.replace(/["']/g, "");
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    const storedUserName = localStorage.getItem("Name") || "";
    const Name = storedUserName.replace(/["']/g, "");
    setUserName(Name);
    isLogin(isLoggedIn || false);
  }, [isLoggedIn, isLogin]);

  const closeDropdown = useCallback(() => {
    setShowDropdown(false);
    setOpen(false);
  }, [setShowDropdown]);

  const handleMouseMove = useCallback(
    (event: MouseEvent) => {
      if (
        showDropdown &&
        navbarRef.current &&
        !navbarRef.current.contains(event.target as Node)
      ) {
        closeDropdown();
      }
    },
    [showDropdown, closeDropdown]
  );

  const handleToggle = () => {
    setOpen(!isOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
      } else {
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    document.addEventListener("mousemove", handleMouseMove);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, [handleMouseMove]);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleDropdown = () => {
    setShowDropdown((prevState) => !prevState);
  };

  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    setIsMenuOpen(false);
    setOpen(false);
    navigate("/home");
  };

  const handleUpcomingsClick = () => {
    navigate("/host/upcoming-games");
  };
  const handleHomeClick = () => {
    setIsMenuOpen(false);
    navigate("/home");
  };
  const handleMyLibrary = () => {
    setOpen(false);
    navigate("/host/my-library");
  };

  const handleGenerateLink = () => {
    setOpen(false);
    generateLinkButton();
  };
  const handleMyGame = () => {
    setOpen(false);
    navigate("/host/my-game");
  };
  const handleFaqClick = () => {
    navigate("/faq");
  };
  const handleContactClick = () => {
    if (superHost === "admin") {
      navigate("/get-contact-details");
    } else {
      navigate("/contact");
    }
  };

  const renderProfilePicture = () => {
    if (userName) {
      const initials = userName
        .split(" ")
        .map((name) => name[0].toUpperCase())
        .join("");
      return (
        <div className="rounded-full lg:w-[37px] lg:h-[37px] w-[48px] h-[48px] bg-[#d3d3d3] flex items-center justify-center text-black font-bold">
          {initials}
        </div>
      );
    }
    return null;
  };

  return (
    <div
      className={`top-0 lg:w-full flex items-center z-[999999] ${
        sticky ? "sticky" : ""
      } ${className}`}
      ref={navbarRef}
    >
      <div
        className={`w-full flex justify-between items-center h-[80px] relative sm:px-${smPadding} lg:px-${lgPaddingHome} px-${pxCreate} md:px-[20x] xl:px-${xlPaddingHome}`}
      >
        <div>
          <Logo />
        </div>
        {!hideItems && (
          <nav
            className={`justify-between items-center inline-flex cursor-pointer ${
              isLoggedIn
                ? `xl:gap-5 lg:gap-${lgGapSizeHome} md:gap-${mdGapSize} gap-2`
                : `xl:gap-[27px] gap-2 lg:gap-5`
            }`}
          >
            {isLoggedIn ? (
              <>
                {isLoggedIn && location.pathname !== "/home" && (
                  <div
                    className="text-gray-950 opacity-60 text-base font-normal font-['Poppins'] capitalize leading-snug whitespace-nowrap hidden lg:inline hover:scale-110 hover:text-purple-700"
                    onClick={handleHomeClick}
                  >
                    <p>Home</p>
                  </div>
                )}
                {isLoggedIn &&
                  !showGenerateLink &&
                  location.pathname !== "/host/upcoming-games" && (
                    <div
                      className="text-gray-950 opacity-60 text-base font-normal font-['Poppins'] capitalize leading-snug hidden lg:inline hover:scale-110 hover:text-purple-700"
                      onClick={handleUpcomingsClick}
                    >
                      <p>Upcomings</p>
                    </div>
                  )}
                {location.pathname !== "/contact" && (
                  <div
                    className="text-gray-950 opacity-60 text-base font-normal font-['Poppins'] capitalize leading-snug hidden lg:inline hover:scale-110 hover:text-purple-700"
                    onClick={handleContactClick}
                  >
                    <p>Contact</p>
                  </div>
                )}
                {showPreviewButton && (
                  <div
                    className="md:h-12 h-12 lg:px-6 px-3 sm:px-8 xl:w-[129px] bg-gradient-to-r from-cyan-400 to-purple-800 rounded-[50px] justify-center items-center gap-2 flex hover:shadow-[0px_20px_20px_-10px_rgba(131,42,184,0.4)] hover:inner-shadow-[inset_0px_20px_20px_-10px_rgba(131,42,184,0.4)]"
                    onClick={handlePreview}
                  >
                    <p className="text-center text-white text-sm font-normal font-['Poppins'] capitalize leading-[48px] flex-wrap">
                      Preview
                    </p>
                  </div>
                )}
                {superHost === "admin" && (
                  <>
                    {showGenerateLink && (
                      <div
                        className="md:flex hidden md:h-12 h-12 md:px-3.5 lg:px-5 px-5  xl:w-[129px] bg-gradient-to-r from-cyan-400 to-purple-800 rounded-[50px] justify-center items-center gap-2  hover:shadow-[0px_20px_20px_-10px_rgba(131,42,184,0.4)] hover:inner-shadow-[inset_0px_20px_20px_-10px_rgba(131,42,184,0.4)]"
                        onClick={handleGenerateLink}
                      >
                        <p className="text-center text-white text-sm font-normal font-['Poppins'] capitalize leading-[48px] whitespace-nowrap">
                          Generate Link
                        </p>
                      </div>
                    )}
                  </>
                )}

                {showPublishButton && (
                  <div
                    className="md:h-12 h-12 md:px-6 px-3 sm:px-8 xl:w-[129px] bg-gradient-to-r from-cyan-400 to-purple-800 rounded-[50px] justify-center items-center gap-2 flex hover:shadow-[0px_20px_20px_-10px_rgba(131,42,184,0.4)] hover:inner-shadow-[inset_0px_20px_20px_-10px_rgba(131,42,184,0.4)]"
                    onClick={handlePublish}
                  >
                    <p className="text-center text-white text-sm font-normal font-['Poppins'] capitalize leading-[48px]">
                      Publish
                    </p>
                  </div>
                )}
                {showExitButton && (
                  <div
                    className="md:h-12 h-12 md:px-9 px-5 sm:px-8  xl:w-[129px] bg-gradient-to-r from-cyan-400 to-purple-800 rounded-[50px] justify-center items-center gap-2 flex hover:shadow-[0px_20px_20px_-10px_rgba(131,42,184,0.4)] hover:inner-shadow-[inset_0px_20px_20px_-10px_rgba(131,42,184,0.4)]"
                    onClick={handleExit}
                  >
                    <p className="text-center text-white text-sm font-normal font-['Poppins'] capitalize leading-[48px]">
                      Exit
                    </p>
                  </div>
                )}
                {showLogin && (
                  <div
                    className="md:w-[129px] md:px-[25px] h-12 px-[17px] bg-gradient-to-r from-cyan-400 to-purple-800 rounded-[50px] justify-center items-center gap-2 hidden md:inline hover:shadow-[0px_20px_20px_-10px_rgba(131,42,184,0.4)] hover:inner-shadow-[inset_0px_20px_20px_-10px_rgba(131,42,184,0.4)]"
                    onClick={startGame}
                  >
                    <p className="text-center text-white text-sm font-normal font-['Poppins'] capitalize leading-[48px] whitespace-nowrap">
                      Demo Quiz
                    </p>
                  </div>
                )}
                {showProfile && (
                  <div className="relative flex justify-start items-center lg:inline">
                    <div className="lg:border border-gray-300 rounded-full pr-1 flex items-center gap-2 md:gap-6 lg:gap-2 md:px-[15px] h-12 px-[17px]">
                      <div className="relative">{renderProfilePicture()}</div>
                      <div className="text-neutral-700 text-sm font-semibold font-['Poppins'] whitespace-nowrap ml-2 hidden lg:inline">
                        <p>{userName}</p>
                      </div>
                    </div>
                  </div>
                )}
                {showHamburger && (
                  <div className="hidden lg:inline" onClick={toggleDropdown}>
                    <Hamburger
                      toggled={isOpen}
                      size={30}
                      toggle={handleToggle}
                      color="gray"
                    />
                  </div>
                )}
                {showmenuIcon && (
                  <div className="inline lg:hidden" onClick={handleMenuToggle}>
                    <img
                      src={menuIcon}
                      alt="Profile"
                      className="rounded-full w-[27px] md:w-[35px] sm:w-[45px] object-cover"
                    />
                  </div>
                )}

                {templateDetailsMobileView && (
                  <div className="inline lg:hidden"   onClick={handleMenuToggle}>
                    <img
                      src={menuIcon}
                      alt="Profile"
                      className="rounded-full w-[27px] md:w-[40px] sm:w-[45px]
                      object-cover"
                   
                    />
                  </div>
                )}

                {showDropdown && (
                  <div className="absolute top-20 px-9 bg-white rounded-[10px] shadow border border-zinc-400 hidden lg:inline lg:right-[40px] xl:right-[85px]">
                    <ul>
                      {isLoggedIn && location.pathname !== "/myLibrary" && (
                        <li
                          className="text-gray-950 text-center py-3 opacity-60 text-base font-normal font-['Poppins'] capitalize leading-snug hover:scale-110 hover:text-purple-700 whitespace-nowrap"
                          onClick={handleMyLibrary}
                        >
                          <p>My Library</p>
                        </li>
                      )}
                      {isLoggedIn && location.pathname !== "/host/my-game" && (
                        <li
                          className="text-gray-950 text-center py-3 opacity-60 text-base font-normal font-['Poppins'] capitalize leading-snug hover:scale-110 hover:text-purple-700 whitespace-nowrap"
                          onClick={handleMyGame}
                        >
                          <p>My Game</p>
                        </li>
                      )}
                      {isLoggedIn &&
                        showUpcomingsDropdown &&
                        superHost === "admin" &&
                        location.pathname !== "/host/upcoming-games" && (
                          <li
                            className="text-gray-950 text-center py-3 opacity-60 text-base font-normal font-['Poppins'] capitalize leading-snug hover:scale-110 hover:text-purple-700 whitespace-nowrap"
                            onClick={handleUpcomingsClick}
                          >
                            <p>Upcomings</p>
                          </li>
                        )}
                      <li
                        className="text-gray-950 text-center py-3 opacity-60 text-base font-normal font-['Poppins'] capitalize leading-snug hover:scale-110 hover:text-purple-700 whitespace-nowrap"
                        onClick={handleLogout}
                      >
                        <p>Sign Out</p>
                      </li>
                    </ul>
                  </div>
                )}
              </>
            ) : (
              <>
                {location.pathname !== "/home" && (
                  <div
                    className="text-gray-950 opacity-60 text-base font-normal font-['Poppins'] capitalize leading-snug hidden lg:inline hover:scale-110 hover:text-purple-700"
                    onClick={handleHomeClick}
                  >
                    <p>Home</p>
                  </div>
                )}
                {location.pathname !== "/faq" && (
                  <div
                    className="text-gray-950 opacity-60 text-base font-normal font-['Poppins'] capitalize leading-snug hidden lg:inline hover:scale-110 hover:text-purple-700"
                    onClick={handleFaqClick}
                  >
                    <p>FAQs</p>
                  </div>
                )}
                {location.pathname !== "/contact" && (
                  <div
                    className="text-gray-950 opacity-60 text-base font-normal font-['Poppins'] capitalize leading-snug hidden lg:inline hover:scale-110 hover:text-purple-700"
                    onClick={handleContactClick}
                  >
                    <p>Contact</p>
                  </div>
                )}
                {showLogin && (
                  <div className="md:w-[129px] md:h-12 md:px-[25px] h-12 px-[17px] bg-gradient-to-r from-cyan-400 to-purple-800 rounded-[50px] justify-center items-center gap-2 hidden md:inline hover:shadow-[0px_20px_20px_-10px_rgba(131,42,184,0.4)] hover:inner-shadow-[inset_0px_20px_20px_-10px_rgba(131,42,184,0.4)]"  onClick={startGame}>
                    <p
                      className="text-center text-white text-sm font-normal font-['Poppins'] capitalize leading-[48px] whitespace-nowrap"
                    
                    >
                      Demo Quiz
                    </p>
                  </div>
                )}
                {showLogin && (
                  <div className="md:w-[129px] md:h-12 md:px-[25px] h-12 px-[17px] bg-gradient-to-r from-cyan-400 to-purple-800 rounded-[50px] justify-center items-center gap-2 hover:shadow-[0px_20px_20px_-10px_rgba(131,42,184,0.4)] hover:inner-shadow-[inset_0px_20px_20px_-10px_rgba(131,42,184,0.4)]"   onClick={openLoginDialog}>
                    <p
                      className="text-center text-white text-sm font-normal font-['Poppins'] capitalize leading-[48px] whitespace-nowrap"
                   
                    >
                      Login
                    </p>
                  </div>
                )}
                {showmenuIcon && (
                  <div className="inline md:hidden" onClick={handleMenuToggle}>
                    <img
                      src={menuIcon}
                      alt="Profile"
                      className="rounded-full w-[27px] md:w-[35px] sm:w-[45px] object-cover"
                     
                    />
                  </div>
                )}
              </>
            )}
          </nav>
        )}

        {isMenuOpen && (
          <div
            className="absolute top-0 right-0 bg-white w-full h-screen shadow-lg rounded-lg py-2 px-10
     md:px-20"
          >
            <div className="flex justify-end">
              <button
                onClick={handleMenuToggle}
                className="text-black-400 hover:text-gray-800 mt-5 h-5 w-5 object-cover ml-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            <nav>
              <ul className="space-y-4">
                <li>
                  <button
                    className="w-full border border-[#0A687426] hover:bg-gray-100 hover:border-gray-500 rounded-lg py-2 bg-[#1FC4D903] cursor-pointer mt-10"
                    onClick={handleHomeClick}
                    aria-label="Home"
                  >
                    <div className="flex items-center px-4">
                      <img
                        src={HomeImage}
                        alt="Home"
                        className="w-[16px] h-[16px] mr-[10px]"
                      />
                      <p className="text-zinc-500 text-lg font-normal font-['Inter']">
                        Home
                      </p>
                    </div>
                  </button>
                </li>
                {templateDetailsMobileView &&
                  superHost === "admin" &&
                  isLoggedIn && (
                    <li>
                      <button
                        className="w-full border border-[#0A687426] hover:bg-gray-100 hover:border-gray-500 rounded-lg py-2 bg-[#1FC4D903] cursor-pointer flex md:hidden "
                        onClick={handleGenerateLink}
                        aria-label="Generate link"
                      >
                        <div className="flex items-center px-4">
                          <img
                            src={generateLink}
                            alt="My Library"
                            className="w-[16px] h-[16px] mr-[10px]"
                          />
                          <p className="text-zinc-500 text-lg font-normal font-['Inter']">
                            GenerateLink
                          </p>
                        </div>
                      </button>
                    </li>
                  )}
                {isLoggedIn && (
                  <li>
                    <button
                      className="w-full border border-[#0A687426] hover:bg-gray-100 hover:border-gray-500 rounded-lg py-2 bg-[#1FC4D903] cursor-pointer"
                      onClick={handleMyLibrary}
                      aria-label="My Library"
                    >
                      <div className="flex items-center px-4">
                        <img
                          src={MyLibrary}
                          alt="My Library"
                          className="w-[16px] h-[16px] mr-[10px]"
                        />
                        <p className="text-zinc-500 text-lg font-normal font-['Inter']">
                          My Library
                        </p>
                      </div>
                    </button>
                  </li>
                )}
                {isLoggedIn && (
                  <li>
                    <button
                      className="w-full border border-[#0A687426] hover:bg-gray-100 hover:border-gray-500 rounded-lg py-2 bg-[#1FC4D903] cursor-pointer"
                      onClick={handleMyGame}
                      aria-label="My Game"
                    >
                      <div className="flex items-center px-4">
                        <img
                          src={myGame}
                          alt="My Game"
                          className="w-[16px] h-[16px] mr-[10px]"
                        />
                        <p className="text-zinc-500 text-lg font-normal font-['Inter']">
                          My Game
                        </p>
                      </div>
                    </button>
                  </li>
                )}
                {isLoggedIn && (
                  <li>
                    <button
                      className="w-full border border-[#0A687426] hover:bg-gray-100 hover:border-gray-500 rounded-lg py-2 bg-[#1FC4D903] cursor-pointer"
                      onClick={handleUpcomingsClick}
                      aria-label="Upcomings"
                    >
                      <div className="flex items-center px-4">
                        <img
                          src={StackedFiles}
                          alt="Upcomings"
                          className="w-[16px] h-[16px] mr-[10px]"
                        />
                        <p className="text-zinc-500 text-lg font-normal font-['Inter']">
                          Upcomings
                        </p>
                      </div>
                    </button>
                  </li>
                )}

                <li>
                  <button
                    className="w-full border border-[#0A687426] hover:bg-gray-100 hover:border-gray-500 rounded-lg py-2 bg-[#1FC4D903] cursor-pointer md:hidden
                    flex"
                    onClick={startGame}
                    aria-label="Demo Quiz"
                  >
                    <div className="flex items-center px-4">
                      <img
                        src={demo}
                        alt="Demo Quiz"
                        className="w-[16px] h-[20px] mr-[10px]"
                      />
                      <p className="text-zinc-500 text-lg font-normal font-['Inter']">
                        Demo Quiz
                      </p>
                    </div>
                  </button>
                </li>

                <li>
                  <button
                    className="w-full border border-[#0A687426] hover:bg-gray-100 hover:border-gray-500 rounded-lg py-2 bg-[#1FC4D903] cursor-pointer"
                    onClick={handleFaqClick}
                    aria-label="FAQ"
                  >
                    <div className="flex items-center px-4">
                      <img
                        src={faq}
                        alt="FAQ"
                        className="w-[17px] h-[20px] mr-[10px]"
                      />
                      <p className="text-zinc-500 text-lg font-normal font-['Inter']">
                        FAQ
                      </p>
                    </div>
                  </button>
                </li>

                <li>
                  <button
                    className="w-full border border-[#0A687426] hover:bg-gray-100 hover:border-gray-500 rounded-lg py-2 bg-[#1FC4D903] cursor-pointer"
                    onClick={handleContactClick}
                    aria-label="Contact"
                  >
                    <div className="flex items-center px-4">
                      <img
                        src={contact}
                        alt="Contact"
                        className="w-[16px] h-[16px] mr-[10px]"
                      />
                      <p className="text-zinc-500 text-lg font-normal font-['Inter']">
                        Contact
                      </p>
                    </div>
                  </button>
                </li>

                <li>
                  <button
                    className="w-full border border-[#0A687426] hover:bg-gray-100 hover:border-gray-500 rounded-lg py-2 bg-[#1FC4D903] cursor-pointer"
                    onClick={handleLogout}
                    aria-label="Sign out"
                  >
                    <div className="flex items-center px-4">
                      <img
                        src={Logout}
                        alt="Sign out"
                        className="w-[16px] h-[16px] mr-[10px]"
                      />
                      <p className="text-zinc-500 text-lg font-normal font-['Inter']">
                        Sign out
                      </p>
                    </div>
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
