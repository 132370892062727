import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import GenerateQuestion from "../geminiAi/geminiAi";
import { debounce } from "lodash";

type TextTypeComponentProp = {
  response: (data: any) => void;
  questions: any;
};

const TextTypeComponent: FC<TextTypeComponentProp> = ({
  response,
  questions,
}) => {
  const [question, setQuestion] = useState("");
  const [options, setOptions] = useState(["", "", "", ""]);
  const [selectedOption, setSelectedOption] = useState<string | undefined>("");
  const [categoryId, setCategoryId] = useState("");

  useEffect(() => {
    if (questions) {
      setQuestion(questions?.question || "");
      setOptions(questions.options || ["", "", "", ""]);
      setSelectedOption(questions.correctAnswer);
    }
    if (questions?.category_id) {
      setCategoryId(questions?.category_id);
    }
  }, [questions]);

  const questionId = questions?._id;
  const editable = questions?.isEditable;
  const responseRef = useRef(response);

  const outputData = useCallback((data: any) => {
    responseRef.current(data);
  }, []);

  useEffect(() => {
    responseRef.current = response;
  }, [response]);

  useEffect(() => {
    const debouncedOutputData = debounce((data: any) => {
      responseRef.current(data);
    }, 300);
    if (question !== "") {
      const questionType = "TEXT";
      const answerType = "TEXT";
      const data = {
        question,
        options,
        correctAnswer: selectedOption,
        questionType,
        answerType,
        _id: questionId,
        isEditable: editable,
        category_id: categoryId,
      };
      debouncedOutputData(data);
      return () => {
        debouncedOutputData.cancel();
      };
    }
  }, [
    selectedOption,
    question,
    options,
    outputData,
    questionId,
    editable,
    categoryId,
  ]);

  const handleCheckboxChange = (index: number) => {
    const option = options[index];
    setSelectedOption((prevSelectedOption) =>
      prevSelectedOption === option ? undefined : option
    );
  };

  const handleClearQuestions = () => {
    setQuestion("");
    setOptions(["", "", "", ""]);

    setSelectedOption("" || undefined);
    const clearedQuestionData = {
      ...response,
      answer: "",
    };

    response(clearedQuestionData);
  };

  const handleChangeOption = (index: number, value: string) => {
    const newOptions = [...options];
    newOptions[index] = value;
    setOptions(newOptions);
  };

  return (
    <div className="w-full h-full">
      <hr className="border-1 mt-2" />

      {/* Question Generation Section */}
      <section className="container mx-auto  mt-4 ">
        <GenerateQuestion
          onGenerate={(data: any) => {
            setQuestion(data.question);

            if (data.options) {
              setOptions(Object.values(data.options));
            }
            setSelectedOption(data.correct_answer);
          }}
          onClear={handleClearQuestions}
          type="TEXT"
        />
      </section>
      {/* Question Input Section */}
      <section className="border xl:mt-[40px] lg:mt-[20px] mt-8 border-[#D7D7D7] p-2 rounded-[10px]">
        <textarea
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          className="text-[#000] font-[inter] lg:text-[18px] text-[16px] lg:font-[400] leading-normal ml-[40] mr-[10] w-full max-h-[60px] border resize-none placeholder:text-zinc-400 placeholder:text-lg placeholder:font-normal flex items-center box-border pb-5 pt-4 px-2 overflow-auto border-none focus:outline-none placeholder:items-center no-scrollbar"
          placeholder="Question"
        />
      </section>
      {/* Options Section */}
      <section className="space-y-4 w-full lg:mt-[32px] mt-[24px] gap-2 text-[#000] font-[inter] lg:text-[18px]  text-[16px] leading-normal">
        <h2 className="text-xl font-semibold mb-4">Options</h2>
        {options.map((option: any, index: number) => (
          <div
            key={index}
            className="relative flex items-center lg:my-0 my-2 rounded-[10px] border border-[#0A687426] bg-[#1FC4D903] p-1"
          >
            <div className="flex w-[90%]">
              <textarea
                className="flex flex-wrap w-full py-[10px] px-2 border-none lg:text-[18px] text-[16px] h-[50px] font-[inter] lg:font-[400] resize-none rounded-[10px] placeholder:text-zinc-400 placeholder:text-lg placeholder:font-normal focus:outline-none no-scrollbar "
                placeholder={`Option ${index + 1}`}
                value={option}
                onChange={(e) => handleChangeOption(index, e.target.value)}
              />
            </div>

            <div className="absolute top-0 right-0 w-[10%] flex items-center h-full justify-center">
              <input
                className="rounded-[10px] border-[#0000002B] bg-white"
                type="checkbox"
                checked={selectedOption === option}
                onChange={() => handleCheckboxChange(index)}
              />
            </div>
          </div>
        ))}
      </section>
    </div>
  );
};

export default TextTypeComponent;
