import React, { useEffect, useState } from "react";
import Navbar from "src/components/navBar/NavBar";
import ReactPaginate from "react-paginate";
import { getContactDetails } from "src/services/contact/contact.service";

interface Contact {
  _id: string;
  name: string;
  email: string;
  phoneNumber: string;
  reason: string;
}

const AdminViewContact = () => {
  const [scrolled, setScrolled] = useState(false);
  const [contactDetail, setContactDetail] = useState<Contact[]>([]);
  const [contactCount, setContactCount] = useState<number>(0);
  const [page, setPage] = useState(0);
  const limit = 5;

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const response = await getContactDetails({ page: page + 1, limit });
        setContactDetail(response?.contact);
        setContactCount(response?.totalCount);
      } catch (error) {
        console.error("Error fetching contact details:", error);
      }
    };

    fetchContacts();
  }, [page, limit]);

  const handlePageClick = ({ selected }: { selected: number }) => {
    setPage(selected);
  };

  const pageCount = Math.ceil(contactCount / limit);

  return (
    <div className="max-w-screen-2xl mx-auto  w-full">
      {/* Navbar Component */}
      <Navbar className={`${
          scrolled ? "bg-white z-[999999] shadow-[#ABB3DE] shadow-lg " : ""
        }`}   templateDetailsMobileView={false}
        showmenuIcon={true}/>
      <div className="px-4 md:px-10 lg:px-16 xl:px-20 font-[Poppins] min-h-screen flex flex-col items-center ">
        <div className="p-3 md:p-5 w-full max-w-5xl border ">
          {/* Table for Displaying Contact Details */}
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-2 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                  No
                </th>
                <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Name
                </th>
                <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Email
                </th>
                <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-normal">
                  Reason
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {contactDetail?.map((item, index) => (
                <tr key={item._id}>
                  <td className="px-2 py-4 whitespace-nowrap text-center text-sm font-medium text-gray-900">
                    {page * limit + index + 1}
                  </td>
                  <td className="px-2 py-4 whitespace-nowrap text-sm text-gray-500">
                    {item.name}
                  </td>
                  <td className="px-2 py-4 max-w-[100px] truncate text-sm text-gray-500">
                    {item.email}
                  </td>
                  <td className="px-2 py-4 whitespace-normal text-sm text-gray-500 ">
                    {item.reason}
                  </td>
                 
                </tr>
              ))}
            </tbody>
          </table>
            {/* Pagination */}
        <ReactPaginate
          previousLabel={page > 0 ? "previous" : null}
          nextLabel={page < pageCount - 1 ? "next" : null}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={
            "flex justify-center pt-5 px-5 w-full max-w-5xl "
          }
          pageClassName={"mx-1"}
          previousClassName={"mx-1"}
          nextClassName={"mx-1"}
          activeClassName={"font-bold "}
        />
      </div></div>
    </div>
  );
};

export default AdminViewContact;
