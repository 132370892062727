import { useEffect, useState, useRef } from "react";
import {
  adminDemoPlayerData,
  getPlayerData,
} from "src/services/playerLeaderboard/playerLeaderboard.service";
import { useLocation, useParams } from "react-router-dom";
import CorrectIcon from "../../assets/CorrectIcon.svg";
import WrongIcon from "../../assets/WrongIcon.svg";
import gold from "../../assets/rank1.png";
import silver from "../../assets/rank2.png";
import copper from "../../assets/rank3.png";
import confetti from "canvas-confetti";
import LoaderComponent from "src/components/loader/LoaderComponent";
import confettiSound from "../../audio/confettiSound.wav";
import cheerSound from "../../audio/cheerSound.mp3";
import React from "react";
import MuteIcon from "../../assets/mute.png";
import UnmuteIcon from "../../assets/volume.png";

interface PlayerData {
  _id: string;
  name: string;
  avatarUrl: string;
  slug: string;
  trueCount: number;
  falseCount: number;
  marks: number;
  time: number;
}
const ProgressBar: React.FC<{
  correctAnswers: number;
  wrongAnswers: number;
}> = ({ correctAnswers, wrongAnswers }) => {
  const totalAnswers = correctAnswers + wrongAnswers;
  const progress = (correctAnswers / totalAnswers) * 100;
  return (
    <div className="h-[25.5px] bg-[#B54A4A] rounded-[90px] items-center flex justify-between">
      <div
        className={`h-[25.5px] bg-[#37BB8C]  rounded-[90px] items-center flex justify-between `}
        style={{ width: `${progress}%` }}
      >
        {correctAnswers > 0 && (
          <div className="flex justify-start items-center text-white font-[poppins] text-[14px] font-semibold ">
            <span>
              <img
                src={CorrectIcon}
                alt="Correct Answer"
                className="flex justify-start ml-[6.51px] mr-[8px]"
              />
            </span>
            <span>{correctAnswers}</span>
          </div>
        )}
        {wrongAnswers > 0 && correctAnswers === 0 && (
          // Only render if all answers are wrong
          <div className="flex justify-start items-center text-white font-[poppins] text-[14px] font-semibold ">
            <span>
              <img
                src={WrongIcon}
                alt="Wrong Answer"
                className="ml-[8px] mr-[6.51px]"
              />
            </span>
            <span className="ml-[15px]">{wrongAnswers}</span>
          </div>
        )}
      </div>
      {correctAnswers > 0 && (
        <div className="flex justify-end text-white font-[poppins] text-[14px] font-semibold items-center">
          {wrongAnswers > 0 && <span>{wrongAnswers}</span>}
          {wrongAnswers > 0 && (
            <span>
              <img
                src={WrongIcon}
                alt="Wrong Answer"
                className="ml-[8px] mr-[6.51px]"
              />
            </span>
          )}
        </div>
      )}
    </div>
  );
};
const truncateTime = (time: number) => {
  return String(time).slice(0, 5);
};
const PlayerLeaderboard = () => {
  const [playerDatas, setPlayerData] = useState<PlayerData[]>([]);
  const { slug } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isMuted, setIsMuted] = useState(true);
  const guestAvatar = sessionStorage
    .getItem("guestAvatar")
    ?.replace(/["']/g, "");
  const location = useLocation();
  const path = location.pathname;
  const initialFetch = useRef(true);

  const triggerConfetti = () => {
    const configBottomLeft = {
      particleCount: 300,
      spread: 200,
      startVelocity: 100,
      ticks: 1000,
      origin: {
        x: 0,
        y: 1,
      },
    };
    const configBottomRight = {
      particleCount: 300,
      spread: 200,
      startVelocity: 100,
      ticks: 1000,
      origin: {
        x: 1,
        y: 1,
      },
    };
    confetti(configBottomLeft);
    confetti(configBottomRight);
  };

  const playAudio = () => {
    if (!isMuted) {
      const audio = new Audio(cheerSound);
      audio.play().catch((err) => {
        console.error("Playback failed", err);
      });
    }
  };

  const playAudio2 = () => {
    if (!isMuted) {
      const audio = new Audio(confettiSound);
      audio.play().catch((err) => {
        console.error("Playback failed", err);
      });
    }
  };

  useEffect(() => {
    playAudio();
    // eslint-disable-next-line
  }, [playerDatas, isMuted]);

  useEffect(() => {
    if (initialFetch.current && slug) {
      initialFetch.current = false;
      setIsLoading(true);
      if (
        path === `/player/result/${slug}` ||
        path === `/leaderboard/${slug}`
      ) {
        getPlayerData(slug).then((data) => {
          const sortedData = data.sort((a: PlayerData, b: PlayerData) => {
            if (a.marks !== b.marks) {
              return b.marks - a.marks;
            } else {
              return a.time - b.time;
            }
          });
          setPlayerData(sortedData);
          playAudio2();
          triggerConfetti();
          setTimeout(() => {
            setIsLoading(false);
          }, 500);
        });
      } else {
        adminDemoPlayerData(slug || "").then((data) => {
          const sortedData = data.sort((a: PlayerData, b: PlayerData) => {
            if (a.marks !== b.marks) {
              return b.marks - a.marks;
            } else {
              return a.time - b.time;
            }
          });
          setPlayerData(sortedData);
          playAudio2();
          triggerConfetti();
          setTimeout(() => {
            setIsLoading(false);
          }, 500);
        });
      }
    }
    // eslint-disable-next-line
  }, [slug, path]);
  return (
    <div className="bg-[#192E3B] overflow-y-auto min-h-screen no-scrollbar font-[Poppins]">
      {isLoading && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50">
          <LoaderComponent />
        </div>
      )}
      <div className="w-full bg-[#192E3B] lg:px-[60px] xl:px-[90px] px-[4px]">
        <div className="mb-[16px]">
          <button
            className="fixed bottom-10 right-10 bg-green-500 p-4 rounded-full"
            onClick={() => setIsMuted(!isMuted)}
          >
            <img
              className="w-6 h-6"
              src={isMuted ? MuteIcon : UnmuteIcon}
              alt="Toggle Mute"
            />
          </button>
          <table className="min-w-full text-[#95D6FF] rounded-[50px]">
            <thead>
              <tr className="text-left font-[Poppins] mb-[14px] text-[16px] font-[600]  ">
                <th className="px-4 py-2 xl:px-10">Rank</th>
                <th className="md:px-20 px-16 py-2">Name</th>
                <th className="md:px-3 py-2">Score</th>
                <th className="px-4 py-2 table-cell">Time</th>
                <th className="px-11 py-2 hidden md:table-cell">Performance</th>
              </tr>
            </thead>
            <tbody className="">
              {playerDatas.map((player, index) => (
                <React.Fragment key={player._id}>
                  <tr className="bg-[#192E3B] h-4 ">
                    <td colSpan={5}></td>
                  </tr>
                  <tr
                    key={player._id + "-content"}
                    className="bg-[#233D4E] overflow-hidden rounded-[12px] shadow-lg"
                  >
                    <td className="md:px-4 py-2 md:rounded-l-[12px] xl:pl-10">
                      {index === 0 ? (
                        <img
                          className="w-[60px] h-[60px]"
                          src={gold}
                          alt="gold"
                        />
                      ) : index === 1 ? (
                        <img
                          className="w-[60px] h-[60px]"
                          src={silver}
                          alt="silver"
                        />
                      ) : index === 2 ? (
                        <img
                          className="w-[60px] h-[60px]"
                          src={copper}
                          alt="copper"
                        />
                      ) : (
                        <span className="text-white font-[Poppins] w-[60px] h-[60px] justify-center flex items-center text-[20px] font-[600]">
                          {index + 1}
                        </span>
                      )}
                    </td>
                    <td className="md:px-4 py-4 flex items-center text-white text-[15px] lg:text-[16px] font-[400] font-['Poppins'] ">
                      <img
                        className="w-[35px] mr-8"
                        src={player.avatarUrl || guestAvatar}
                        alt=""
                      />
                      <span className="truncate">{player.name}</span>
                    </td>
                    <td className="md:px-4 py-2 text-white text-[18px] lg:text-[24px] font-[700] font-['Poppins'] ">
                      {player.marks ?? 0}
                    </td>
                    <td className="md:px-4 py-2 text-[#95D6FF] text-[12px] md:text-[16px] font-[400] font-['Poppins'] whitespace-nowrap">
                      {player.time === 0
                        ? truncateTime(player.time ?? 0)
                        : `${truncateTime(player.time ?? 0)} s`}
                    </td>
                    <td className="px-4 py-2 hidden md:table-cell rounded-r-[12px] lg:w-[300.545px] xl:w-[506.545px] xl:pr-10">
                      <ProgressBar
                        correctAnswers={player.trueCount}
                        wrongAnswers={player.falseCount}
                      />
                    </td>
                  </tr>
                  <tr className="bg-[#233D4E] rounded-[12px] shadow-lg md:hidden">
                    <td colSpan={5} className="px-4 py-3">
                      <ProgressBar
                        correctAnswers={player.trueCount}
                        wrongAnswers={player.falseCount}
                      />
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
export default PlayerLeaderboard;
