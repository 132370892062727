import React, { useState, useEffect } from "react";
import checkCircle from "../../assets/CheckCircle.svg";

import {
  PaymentLink,
  getPaymentPlan,
} from "src/services/quizPackages/quizPackagesService";
import LoaderComponent from "src/components/loader/LoaderComponent";
import Loader from "react-js-loader";

interface QuizPackagesProps {
  totalQuestions: number;
  templateClick: boolean;
}

const QuizPackages: React.FC<QuizPackagesProps> = ({
  totalQuestions,
  templateClick,
}) => {
  const [plans, setPlans] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [maxAmountPlan, setMaxAmountPlan] = useState<any | null>(null);
  const quizIDWithQuotes = sessionStorage.getItem("quizID") || "";
  const quizID = quizIDWithQuotes.replace(/["']/g, "");
  useEffect(() => {
    setIsLoading(true);
    getPaymentPlan(quizID)
      .then((response) => {
        setPlans(response);

        const maxAmountPlan = response.reduce(
          (prevPlan: any, currentPlan: any) => {
            return prevPlan.amount > currentPlan.amount
              ? prevPlan
              : currentPlan;
          }
        );

        setMaxAmountPlan(maxAmountPlan);
      })
      .catch((error) => {
        console.error("Error fetching payment plans:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [quizID]);

  const handlePay = (selectedPlan: any) => {
    sessionStorage.setItem("selectedPlanId", selectedPlan._id);
    sessionStorage.setItem("limit", selectedPlan.players);
    setIsLoading(true);
    PaymentLink(selectedPlan._id)
      .then((response) => {
        if (response && response.payment_link) {
          sessionStorage.setItem("paymentid", response.payment_id);
          window.location.href = response.payment_link;
        }
      })
      .catch((error) => {
        console.error("Error creating payment link:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="">
      {isLoading ? (
        <div className="fixed top-0 left-0 w-full h-full mt-[30px] flex items-center justify-center z-50">
          <LoaderComponent />
        </div>
      ) : (
        <div className="font-[Poppins]  p-8 md:flex gap-2 ">
          {/* Plans Section */}

          {plans.map((plan) => (
            <div
              key={plan._id}
              className={`package border border-gray-300 rounded-md p-4 mb-2 md:mb-0 ${
                totalQuestions > plan.questions
                  ? "opacity-50 pointer-events-none"
                  : ""
              }`}
            >
              <h2 className="font-bold text-center lg:text-[23px] flex items-center justify-center flex-shrink-0 rounded-[4.951px] h-8 bg-gray-100 text-[#30a5cc]">
                {plan.plan_name}
              </h2>
              <h3 className="mt-2 text-[#171717] text-center text-[14px]">
                Pay per game
              </h3>
              <hr className="my-2 border-t border-gray-300 " />
              <p className="text-4xl font-bold mt-2 text-center text-[#30a5cc]">
                ${plan.amount}
              </p>
              <p className="mt-2 text-center text-[14px]">
                {plan === maxAmountPlan
                  ? "No Limit"
                  : `Maximum ${plan.players} Players`}
              </p>
              <hr className="my-2 border-t border-gray-300" />
              <ul className="text-[12px]">
                {!templateClick && (
                  <li className="flex items-center mt-2">
                    <img
                      src={checkCircle}
                      alt="plans"
                      className="w-4 h-4 mr-2"
                    />
                    <span>Max {plan.questions} Questions per game</span>
                  </li>
                )}
                <li className="flex items-center mt-2">
                  <img src={checkCircle} alt="plans" className="w-4 h-4 mr-2" />
                  <span>Leaderboard access</span>
                </li>
                <li className="flex items-center mt-2">
                  <img src={checkCircle} alt="plans" className="w-4 h-4 mr-2" />
                  <span>Create your own quiz</span>
                </li>
              </ul>
              <button
                className={`mt-4 flex gap-[10px]  px-4 py-2 rounded-[12px] bg-gradient-to-r from-[#1EC6DA] to-[#6F2A96] border :border-none text-white  lg:text-[23px] text-[16px] font-bold items-center justify-center hover:rounded-[12px] hover:shadow-[0px_20px_20px_-10px_rgba(131,42,184,0.4)] hover:inner-shadow-[inset 0px 20px 20px -10px rgba(131,42,184,0.4)] w-full`}
                onClick={() => handlePay(plan)}
                disabled={totalQuestions > plan.questions}
              >
                {isLoading ? (
                  <Loader type="bubble-top" size={30} bgColor={"#ffffff"} />
                ) : (
                  "Pay"
                )}
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default QuizPackages;
