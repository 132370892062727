import instance from "src/http/httpClient";

// create quiz
export const game = (data: any): Promise<any> => {
  return instance
    .post(`/host/quiz`, { ...data }, { timeout: 25000 })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

// create question
export const createQuestions = (data: any): Promise<any> => {
  return instance
    .post(`/host/questions`, { ...data })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

//edit the create quiz page
export const updateQuiz = (id: string, updatedQuizData: any): Promise<any> => {
  return instance
    .put(`/host/quiz/${id}`, updatedQuizData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
      throw error
    });
};

//Image Upload
export const uploadImage = (imageFile: File): Promise<any> => {
  const formData = new FormData();
  formData.append("image", imageFile);
  return instance
    .post("/image/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      return response.data.image_url;
    })
    .catch((error) => {
      console.error("Error:", error);
      throw error;
    });
};

// Delete
export const deleteQuestion = (id: string): Promise<any> => {
  return instance
    .delete(`/host/quiz/remove/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
      throw error
    });
};

// create page PUT
export const updateQuestions = (id: string,data:any): Promise<any> => {
  return instance
    .put(`/host/questions/${id}`,data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
      throw error
    });
};


// to get quiz details
//edit the create quiz page
export const getQuizDetail = (id: string): Promise<any> => {
  return instance
    .get(`/host/quiz/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
      throw error
    });
};




// Drag and drop Question Order
export const getQuestionOrder = (id: string, orderList: any[]): Promise<any> => {
  return instance
    .put(`/host/quiz/order/${id}`, { orderList })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};
