import instance from "src/http/httpClient";

export const fetchQuizDetails = (QuizId: string): Promise<any> => {
  return instance
    .get<any>(`/host/quiz/${QuizId}`)
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
    });
};
