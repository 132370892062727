import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { uploadImage } from "src/services/createQuiz/createQuiz.service";
import LoaderComponent from "src/components/loader/LoaderComponent";
import ImageUpload from "../imageUpload/ImageUpload";
import { debounce } from "lodash";

type ImageTextTypeComponentsProp = {
  response: (data: any) => void;
  questions: any;
};

const ImageTextTypeComponents: FC<ImageTextTypeComponentsProp> = ({
  response,
  questions,
}) => {
  const [questionImg, setQuestionImg] = useState(questions?.questionImg || "");
  const [question, setQuestion] = useState(questions?.question || "");
  const [options, setOptions] = useState(
    questions?.options || ["", "", "", ""]
  );
  const [selectedAnswers, setSelectedAnswers] = useState<string | undefined>(
    questions?.correctAnswer
  );
  const [isLoading, setIsLoading] = useState(false);
  const [categoryId, setCategoryId] = useState("");

  const questionId = questions?._id;
  const editable = questions?.isEditable;
  const responseRef = useRef(response);

  const outputData = useCallback((data: any) => {
    responseRef.current(data);
  }, []);

  useEffect(() => {
    responseRef.current = response;
  }, [response]);

  useEffect(() => {
    if (questions?.category_id) {
      setCategoryId(questions?.category_id);
    }
  }, [questions]);

  useEffect(() => {
    const debouncedOutputData = debounce((data: any) => {
      responseRef.current(data);
    }, 300);
    if (question !== "") {
      const questionType = "IMAGE_TEXT";
      const answerType = "TEXT";
      const data = {
        question,
        options,
        correctAnswer: selectedAnswers,
        questionImg,
        questionType,
        answerType,
        _id: questionId,
        isEditable: editable,
        category_id: categoryId,
      };

      debouncedOutputData(data);

      // Cleanup function to cancel debounce on unmount
      return () => {
        debouncedOutputData.cancel();
      };
    }
  }, [
    selectedAnswers,
    editable,
    question,
    options,
    questionImg,
    questionId,
    outputData,
    categoryId,
  ]);

  const coverFileHandler = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      setIsLoading(true);
      try {
        const reader = new FileReader();
        reader.onload = () => {
          reader.result as string;
        };
        reader.readAsDataURL(file);
        const imageUrl = await uploadImage(file);
        setQuestionImg(imageUrl);
      } catch (error) {
        console.error("Error uploading image:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleOptionChange = (index: number) => {
    setSelectedAnswers(options[index]);
  };

  return (
    <div>
      <div className="w-full h-full ">
        <hr className="border-1 mt-2" />
        {/* question Section*/}
        <section className="md:flex xl:mt-[18px] lg:mt-[20px] items-center justify-between gap-4">
          {/* {questionImg && ( */}
          <div className="w-auto relative lg:w-[312px] md:h-[210px] md:w-[300px] border-dashed border-[1px] border-[#1FC4D9] rounded-[10px]">
            {!questionImg && (
              <div className="lg:w-[312px] md:h-[210px] w-full h-full md:w-[300px] flex items-center justify-center">
                <ImageUpload type="question" />
              </div>
            )}
            {isLoading && <LoaderComponent />}
            {questionImg && !isLoading && (
              <img
                className="lg:w-[312px] md:h-[210px] w-auto md:w-[300px] object-cover rounded-[10px] p-2"
                src={questionImg}
                alt="Preview"
              />
            )}
            <label
              htmlFor="imageInput"
              className="absolute inset-0 flex items-center justify-center rounded-[10px] cursor-pointer"
            >
              <input
                id="imageInput"
                type="file"
                accept="image/*"
                className="absolute top-0 left-0 w-full h-full opacity-0  "
                onChange={coverFileHandler}
              />
            </label>
          </div>

          <div className="border xl:mt-[40px] lg:mt-[20px] mt-8 border-[#D7D7D7] p-2 rounded-[10px] w-full">
            <textarea
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              className="text-[#000] font-[inter] lg:text-[18px] text-[16px] lg:font-[400] leading-normal ml-[40] mr-[10] w-full lg:w-[90%] max-h-[60px] border resize-none placeholder:text-zinc-400 placeholder:text-lg placeholder:font-normal flex items-center box-border pb-5 pt-4 px-2 overflow-auto no-scrollbar border-none focus:outline-none  "
              placeholder="Question"
            />
          </div>
        </section>

        {/* options Section*/}

        <section className="space-y-4 lg:mt-[32px] mt-[24px] gap-2 text-[#000] font-[inter] lg:text-[18px] text-[16px] leading-normal ">
          <h2 className="text-xl font-semibold mb-4">Options</h2>
          {options.map((option: any, index: number) => (
            <div
              key={index}
              className="relative flex items-center bg-[#1FC4D903] border border-[#0A687426] rounded-[10px] lg:my-0 my-2 p-1"
            >
              <div className="flex w-[90%]">
                <textarea
                  className="flex flex-wrap w-full py-[10px] px-2 border-none lg:text-[18px] text-[16px] h-[50px] font-[inter] lg:font-[400] resize-none rounded-[10px] placeholder:text-zinc-400 placeholder:text-lg placeholder:font-normal focus:outline-none no-scrollbar "
                  placeholder={`Option ${index + 1}`}
                  value={option}
                  onChange={(e) => {
                    const newOptions = [...options];
                    newOptions[index] = e.target.value;
                    setOptions(newOptions);
                  }}
                />
              </div>

              <div className="absolute top-0 right-0 w-[10%] flex items-center h-full justify-center">
                <input
                  className="rounded-[10px] border-[#0000002B] bg-white"
                  type="checkbox"
                  checked={option === selectedAnswers}
                  onChange={() => handleOptionChange(index)}
                />
              </div>
            </div>
          ))}
        </section>
      </div>
    </div>
  );
};

export default ImageTextTypeComponents;
