import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { gameStore } from "src/services/gameSettings/gameSettings.service";
import LoaderComponent from "../loader/LoaderComponent";

const Payment = () => {
  const navigate = useNavigate();
  const quizIDWithQuotes = sessionStorage.getItem("quizID") || "";
  const { status } = useParams();
  const quizID = quizIDWithQuotes.replace(/["']/g, "");

  const paymentid = sessionStorage.getItem("paymentid") ?? "";
  useEffect(() => {
    if (status === "success" && quizID) {
      gameStore(quizID, paymentid)
        .then((response) => {
          sessionStorage.setItem(
            "gameSettings",
            JSON.stringify(response.gameSetting)
          );
          navigate(`/host/quiz/game-settings/${response.game._id}`);
        })
        .catch((error) => {
          console.error("Error while redirecting:", error);
        });
    } else if (status === "failed") {
      navigate(-1);
    }
  }, [paymentid, quizID, status, navigate]);

  return (
    <div className="flex w-full h-screen justify-center items-center">
      {status === "success" && (
        <div className="flex  items-center">
          <LoaderComponent />
        </div>
      )}
      {status === "failed" && (
        <h1 className="text-[40px] text-red-500 text-center">
          Payment Failed!
        </h1>
      )}
    </div>
  );
};

export default Payment;
