import React, { useState } from "react";
import SearchIcon from "src/assets/search.svg";

type searchBarProp = {
  handleSearchIconClick: (searchTerm: string) => void;
};

const SearchBar: React.FC<searchBarProp> = ({ handleSearchIconClick }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResult] = useState<any>(null);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchQuery(value);
    handleSearchIconClick(value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSearchIconClick(searchQuery);
    }
  };

  return (
    <div className="left-0 w-full flex justify-center items-start">
      {/* Search Bar */}
      <div
        className="relative w-full flex items-center"
        // onClick={() => {
        //   handleSearchIconClick(searchQuery);
        // }}
      >
        <img
          className="lg:w-[20px] w-[30px] absolute left-4"
          src={SearchIcon}
          alt="Search Icon"
        />
        <input
          type="text"
          value={searchQuery}
          onChange={handleSearch}
          onKeyDown={handleKeyDown}
          placeholder="Search Quiz"
          className="w-full h-[56px] pl-12 font-[400] text-[18px] border font-[Poppins] border-gray-300 rounded-md bg-white text-gray-600 focus:outline-none"
        />
      </div>
      {/* Display search results */}
      {searchResult && (
        <div>
          {searchResult.map((result: any) => (
            <div key={result.id}>{result.title}</div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SearchBar;
