import React, { FC, useEffect, useState } from "react";
import CorrectIcon from "../../assets/CorrectIcon.svg";
import WrongIcon from "../../assets/WrongIcon.svg";
import gold from "../../assets/rank1.png";
import silver from "../../assets/rank2.png";
import copper from "../../assets/rank3.png";
import { useSpring, animated } from "react-spring";
import LoaderComponent from "../loader/LoaderComponent";
interface PlayerData {
  _id: string;
  name: string;
  socketId: string;
  avatarUrl: string;
  marks: number;
  time: number;
  trueCount: number;
  falseCount: number;
}

const ProgressBar: React.FC<{
  correctAnswers: number;
  wrongAnswers: number;
}> = ({ correctAnswers, wrongAnswers }) => {
  const totalAnswers = correctAnswers + wrongAnswers;
  const progress = totalAnswers > 0 ? (correctAnswers / totalAnswers) * 100 : 0;

  return (
    <div className="h-[25.5px] bg-[#B54A4A] rounded-[90px] items-center flex justify-between">
      <div
        className="h-[25.5px] bg-[#37BB8C] rounded-[90px] items-center flex justify-between"
        style={{ width: `${progress}%` }}
      >
        {correctAnswers > 0 && (
          <div className="flex justify-start items-center text-white font-[poppins] text-[14px] font-semibold">
            <span>
              <img
                src={CorrectIcon}
                alt="Correct Answer"
                className="flex justify-start ml-[6.51px] mr-[8px]"
              />
            </span>
            <span>{correctAnswers}</span>
          </div>
        )}

        {wrongAnswers > 0 && correctAnswers === 0 && (
          <div className="flex justify-start items-center text-white font-[poppins] text-[14px] font-semibold">
            <span>
              <img
                src={WrongIcon}
                alt="Wrong Answer"
                className="ml-[8px] mr-[6.51px]"
              />
            </span>
            <span className="ml-[15px]">{wrongAnswers}</span>
          </div>
        )}
      </div>

      {correctAnswers > 0 && (
        <div className="flex justify-end text-white font-[poppins] text-[14px] font-semibold items-center">
          {wrongAnswers > 0 && <span>{wrongAnswers}</span>}
          {wrongAnswers > 0 && (
            <span>
              <img
                src={WrongIcon}
                alt="Wrong Answer"
                className="ml-[8px] mr-[6.51px]"
              />
            </span>
          )}
        </div>
      )}
    </div>
  );
};

type HostLiveLeaderboardProp = { data: PlayerData[] };

const HostLiveLeaderboard: FC<HostLiveLeaderboardProp> = ({ data }) => {
  const [connectedPlayers, setConnectedPlayers] = useState<PlayerData[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    setConnectedPlayers(data);
    setIsLoading(false);
  }, [data]);

  const sortedPlayers = connectedPlayers.slice().sort((a, b) => {
    if (a.marks !== b.marks) {
      return b.marks - a.marks;
    }
    return a.time - b.time;
  });

  return (
    <div className="w-full h-screen overflow-y-auto bg-[#192E3B] mt-[20px] no-scrollbar min-h-screen">
      {isLoading && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50 bg-opacity-50 bg-gray-900">
          <LoaderComponent />
        </div>
      )}
      <div className="w-full bg-[#192E3B] lg:px-[60px] xl:px-[90px] px-[4px] sm:px-[20px] md:px-[40px]">
        <div className="mb-[16px]">
          <table className="min-w-full text-[#95D6FF] rounded-[50px]">
            <thead>
              <tr className="text-left font-[Poppins] mb-[14px] text-[16px] font-[600]">
                <th className="px-4 py-2 xl:px-10">Rank</th>
                <th className="md:px-20 px-16 py-2">Name</th>
                <th className="md:px-3 py-2">Score</th>
                <th className="px-4 py-2 table-cell">Time</th>
                <th className="px-11 py-2 hidden md:table-cell">Performance</th>
              </tr>
            </thead>
            <tbody>
              {sortedPlayers.map((player, index) => (
                <PlayerRow key={player._id} player={player} index={index} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const PlayerRow: FC<{ player: PlayerData; index: number }> = ({
  player,
  index,
}) => {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setAnimate(true);
    }, 300 * index);
    return () => clearTimeout(timeout);
  }, [index]);

  const props = useSpring({
    transform: animate
      ? `translate3d(0px, ${index * 16}px, 0px) scale(1)`
      : "translate3d(0px, 0px, 0px) scale(0)",
    config: { duration: 300 },
  });

  const truncateTime = (time: number) => {
    return String(time).slice(0, 5);
  };

  return (
    <React.Fragment key={player._id}>
      <tr className="bg-[#192E3B]  ">
        <td colSpan={5}></td>
      </tr>
      <animated.tr
        style={props}
        key={player._id + "-content"}
        className="bg-[#233D4E] overflow-hidden rounded-[12px] shadow-lg"
      >
        <td className="md:px-4 py-2 md:rounded-l-[12px] xl:pl-10">
          {index === 0 ? (
            <img className="w-[60px] h-[60px]" src={gold} alt="gold" />
          ) : index === 1 ? (
            <img className="w-[60px] h-[60px]" src={silver} alt="silver" />
          ) : index === 2 ? (
            <img className="w-[60px] h-[60px]" src={copper} alt="copper" />
          ) : (
            <span className="text-white font-[Poppins] w-[60px] h-[60px] justify-center flex items-center text-[20px] font-[600]">
              {index + 1}
            </span>
          )}
        </td>
        <td className="md:px-4 py-4 flex items-center text-white text-[15px] lg:text-[16px] font-[400] font-['Poppins'] ">
          <img className="w-[35px] mr-8" src={player?.avatarUrl} alt="Avatar" />
          <span className="truncate">{player.name}</span>
        </td>

        <td className="md:px-4 py-2 text-white text-[18px] lg:text-[24px] font-[700] font-['Poppins'] ">
          {player.marks ?? 0}
        </td>
        <td className="md:px-4 py-2 text-[#95D6FF] text-[12px] md:text-[16px] font-[400] font-['Poppins'] whitespace-nowrap">
          {player.time === 0
            ? truncateTime(player.time ?? 0)
            : `${truncateTime(player.time ?? 0)} s`}
        </td>
        <td className="px-4 py-2 hidden md:table-cell rounded-r-[12px] lg:w-[300.545px] xl:w-[506.545px] xl:pr-10">
          <ProgressBar
            correctAnswers={player.trueCount}
            wrongAnswers={player.falseCount}
          />
        </td>
      </animated.tr>
      <animated.tr
        style={props}
        className="bg-[#233D4E] rounded-[12px] shadow-lg md:hidden"
      >
        <td colSpan={5} className="px-4 py-3">
          <ProgressBar
            correctAnswers={player.trueCount}
            wrongAnswers={player.falseCount}
          />
        </td>
      </animated.tr>
    </React.Fragment>
  );
};

export default HostLiveLeaderboard;
