import React, { useEffect, useState, useRef } from "react";
import QuizSummary from "src/components/quizSummary/QuizSummary";
import HostLiveLeaderboard from "../../components/hostLiveLeaderBoard/hostLiveLeaderboard";
import { io } from "socket.io-client";
import events from "../websocket/EventsName";
import { getPlayerData } from "src/services/playerLeaderboard/playerLeaderboard.service";
import LoaderComponent from "src/components/loader/LoaderComponent";
import Navbar from "src/components/navBar/NavBar";
import { useLocation } from "react-router-dom";

interface PlayerData {
  _id: string;
  name: string;
  socketId: string;
  avatarUrl: string;
  marks: number;
  time: number;
  trueCount: number;
  falseCount: number;
}

const option = [{ name: "LEADERBOARD" }, { name: "QUIZ SUMMARY" }];
const QuizResult = () => {
  const location = useLocation();
  const [quizEnded, setQuizEnded] = useState(false);
  const [currentTab, setCurrentTab] = useState({ name: "LEADERBOARD" });
  const [connectedPlayers, setConnectedPlayers] = useState<PlayerData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const slugWithQuotes = sessionStorage.getItem("slug") || "";
  const slug = slugWithQuotes.replace(/["']/g, "");
  const preventUpdatesRef = useRef(false);

  const queryArray = location.search.split("?");
  const mygamePopParam = queryArray.pop();


  const handleClick = () => {
    setQuizEnded(true);
    preventUpdatesRef.current = true;

    if (process.env.React_App_SERVER_URL) {
      const socket = io(process.env.React_App_SERVER_URL);
      socket.emit("STOP", { slug });

      getPlayerData(slug).then((data) => {
        if (!preventUpdatesRef.current) {
          setConnectedPlayers(data);
        }
      });
    }
  };

  useEffect(() => {
    document.title =
      currentTab.name === "LEADERBOARD"
        ? "Host Leaderboard"
        : "Host QuizSummary";
  }, [currentTab]);

  // Disable the back button
  useEffect(() => {
    const disableBackButton = () => {
      window.history.pushState(null as any, null as any, document.URL as any);
      window.addEventListener("popstate", () => {
        window.history.pushState(null as any, null as any, document.URL as any);
      });
    };
    disableBackButton();
    return () => {
      window.removeEventListener("popstate", disableBackButton);
    };
  }, []);

  useEffect(() => {
    if (process.env.React_App_SERVER_URL) {
      const socket = io(process.env.React_App_SERVER_URL);
      setIsLoading(true);
      const fallbackTimeout = setTimeout(() => {
        getPlayerData(slug)
          .then((data) => {
            if (data.length > 0 && !preventUpdatesRef.current) {
              setConnectedPlayers(data);

            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      }, 3000);

      socket.on(events.SOCKET_CONNECTING, () => {
        socket.emit(events.HOST_CONNECTING, { slug });
        socket.on(events.PLAYERS_DATAS, (playersData: PlayerData[]) => {
          if (playersData.length > 0 && !preventUpdatesRef.current) {
            setConnectedPlayers(playersData);
          }
        });
      });

      socket.on("afterCounts", () => {
        setQuizEnded(true);
        preventUpdatesRef.current = true;
        socket.disconnect();
      });

      socket.on("disconnect", () => {
        getPlayerData(slug)
          .then((data) => {
            if (data.length > 0 && !preventUpdatesRef.current) {
              setConnectedPlayers(data);
              setQuizEnded(true);
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      });

      return () => {
        socket.disconnect();
        clearTimeout(fallbackTimeout);
      };
    }
  }, [slug]);

  return (
    <div className="bg-[#192E3B] min-h-screen overflow-y-auto">
    <div className="max-w-screen-2xl mx-auto w-full">
      <div className="bg-[#192E3B] font-[Poppins]">
        <div className="flex justify-between items-center fixed w-full top-0 bg-[#192E3B] z-10">
          <Navbar hideItems={true} className="" />
        </div>
  
        <div className="lg:ml-[90px] md:ml-[40px] xl:ml-[100px] ml-[16px] lg:mt-[100px] sm:mt-[90px] mt-[80px] md:mt-[100px] bg-[#192E3B]">
          <div className="sm:flex justify-between items-center">
            <div className="flex">
              {option.map((data: any, index: number) => (
                <button
                  key={index}
                  className={`text-[#95D6FF] lg:w-[190px] w-[140px] mt-[4%] font-[Poppins] lg:text-[19px] text-[16px] font-[600] h-[42px] rounded-[10px] ${
                    currentTab.name === data.name ? "bg-[#008BB7] text-white" : ""
                  }`}
                  onClick={() => {
                    setCurrentTab({ name: data?.name });
                  }}
                >
                  {data?.name}
                </button>
              ))}
            </div>
            <button
              className={`flex justify-center items-center gap-[11.902px] shrink-0 px-4 py-2 mt-[20px] sm:mt-0
                rounded-[5px] lg:text-[20px] text-[16px] bg-[#008BB7] text-white lg:mr-[84px] md:mr-[7%] mr-[6%] xl:ml-[20%] ${
                quizEnded || mygamePopParam ? "hidden" : "block"
              }`}
              onClick={handleClick}
            >
              End Quiz
            </button>
          </div>
        </div>
  
        {isLoading && (
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50">
            <LoaderComponent />
          </div>
        )}
        <h2>
          {currentTab.name === "QUIZ SUMMARY" && <QuizSummary />}
          {currentTab.name === "LEADERBOARD" && (
            <HostLiveLeaderboard data={connectedPlayers} />
          )}
        </h2>
      </div>
    </div>
  </div>
  
  );
};

export default QuizResult;
