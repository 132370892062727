import React, { FC, useEffect, useRef, useState } from "react";
import { getCategory } from "src/services/createQuiz/category.service";
import dropdownImage from "src/assets/drop-down.png";

type CategoryProps = {
  selectedCategoryId: (id: string) => void;
  existingCategoryId?: string;
  newCategoryItem: any;
  handleAddCategory: () => void;
};

const Category: FC<CategoryProps> = ({
  selectedCategoryId = () => {},
  existingCategoryId,
  newCategoryItem,
  handleAddCategory,
}) => {
  const [categoryItem, setCategoryItem] = useState<any[]>([]);
  const [category, setCategory] = useState<any>(null);
  const getSuperHost = localStorage.getItem("superHost") || "";
  const superHost = getSuperHost.replace(/["']/g, "");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setCategoryItem([...categoryItem, newCategoryItem]);
    selectedCategoryId(newCategoryItem._id);
    setCategory(newCategoryItem);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newCategoryItem]);

  useEffect(() => {
    if (categoryItem?.length > 0) {
      if (existingCategoryId !== undefined) {
        const data = categoryItem.find(
          (data: any) => data?._id === existingCategoryId
        );

        setCategory(data);
        selectedCategoryId(data?._id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingCategoryId]);

  useEffect(() => {
    if (categoryItem.length === 0) {
      getCategory()
        .then((response) => {
          setCategoryItem(response);
          if (response.length > 0) {
            if (existingCategoryId !== undefined) {
              const data = response.find(
                (data: any) => data?._id === existingCategoryId
              );

              setCategory(data);
              selectedCategoryId(data._id);
            } else if (superHost === "host") {
              const data = response.find(
                (data: any) => data?.category_name === "Miscellaneous"
              );
              setCategory(data);
              selectedCategoryId(data._id);
            } else {
              setCategory(response[0]);
              selectedCategoryId(response[0]._id);
            }
          }
        })
        .catch((error) => {
          console.error("err", error);
        });
    }
  }, [categoryItem.length, selectedCategoryId, superHost, existingCategoryId]);

  const handleCategoryChange = (id: string) => {
    const selectedCategory = categoryItem.find((item) => item._id === id);
    setCategory(selectedCategory);
    selectedCategoryId(id);
    setIsDropdownOpen(false);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    // if (isDropdownOpen) {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // }
  }, []);

  return (
    <div className="flex font-[Poppins] gap-2">
      <div className="relative w-full " ref={dropdownRef}>
        <div
          className="flex items-center justify-between bg-[#f8fdfe] border border-[#0A687426] rounded-[10px] cursor-pointer"
          onClick={toggleDropdown}
        >
          <div className="truncate md:w-[300px] sm:w-[200px] lg:text-[18px] text-[14px] p-2">
            {category?.category_name?.charAt(0)?.toUpperCase() +
              category?.category_name?.slice(1) || "Select Category"}
          </div>
          <img
            src={dropdownImage}
            alt="Dropdown"
            className="w-[30px] h-[30px] ml-2"
          />
        </div>
        {isDropdownOpen === true && (
          <div className="absolute w-full top-[calc(100%+4px)] left-0 bg-[#ffffff] border border-[#B1B1B1] rounded-[12px]  gap-1 mt-1 p-2 z-[999999] h-[250px] overflow-y-auto">
            {categoryItem?.map((data: any) => {
              const capitalizeFirstLetter = (string: string) => {
                return string?.charAt(0)?.toUpperCase() + string?.slice(1);
              };

              return (
                <div
                  key={data._id}
                  className="text-black font-[Poppins] lg:text-[16px] text-[14px] font-[400] leading-normal px-4 py-2 cursor-pointer rounded-[6px] hover:bg-[#1FC4D9] truncate"
                  onClick={() => handleCategoryChange(data._id)}
                >
                  {capitalizeFirstLetter(data.category_name)}
                </div>
              );
            })}
          </div>
        )}
      </div>
      {superHost === "admin" && (
        <button
          className="text-center text-[20px] px-4 py-[2px] rounded-[10px] bg-gradient-to-r from-[#1EC6DA] to-[#6F2A96] text-white"
          onClick={handleAddCategory}
        >
          +
        </button>
      )}
    </div>
  );
};

export default Category;
