import { useEffect, useState } from "react";
import Tick from "src/assets/Tick.svg";
import Cancel from "src/assets/Cancel.svg";
import CryptoJS from "crypto-js";
import { getPlayerSummary } from "src/services/playerQuizSummary/playerQuizSummaryservice";
import { useLocation, useParams } from "react-router-dom";
import LoaderComponent from "../loader/LoaderComponent";

const PlayerQuizSummary = () => {
  const [quizData, setQuizData] = useState<any[]>([]);
  const { slug } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const path = location.pathname;
  const uniqueSlugWithQuotes = sessionStorage.getItem("slug") || "";
  const uniqueSlug = uniqueSlugWithQuotes.replace(/["']/g, "");

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  const slugData = path === `/player/result/${slug}` ? slug : uniqueSlug;

  useEffect(() => {
    const playerIdWithQuotes = sessionStorage.getItem("playerId") || "";
    const playerId = playerIdWithQuotes.replace(/["']/g, "");

    const secretKey = "quiz123456";
    if (playerId && slugData) {
      setIsLoading(true);
      getPlayerSummary(playerId, slugData)
        .then((data) => {
          const decryptedQuestions = data?.map((question: any) => {
            const bytes = CryptoJS.AES.decrypt(
              question?.question?.correctAnswer,
              secretKey
            );
            const decryptValue = bytes.toString(CryptoJS.enc.Utf8);
            const updatedQuestion = {
              ...question,
              question: {
                ...question.question,
                question: capitalizeFirstLetter(question.question.question),
                correctAnswer: decryptValue,
              },
            };
            return updatedQuestion;
          });
          setQuizData(decryptedQuestions);
        })
        .catch((error) => {
          console.error("Error fetching player summary:", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      console.error("Player ID or slug not found");
    }
  }, [slugData]);

  return (
    <>
      {isLoading && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50">
          <LoaderComponent />
        </div>
      )}

      <div className="bg-[#192E3B] min-h-screen lg:py-4 px-4 py-4 lg:px-20 ">
        {!isLoading && quizData.length === 0 ? (
          <div className="text-white text-2xl font-semibold flex items-center justify-center min-h-[60vh]">
            No question you have answered
          </div>
        ) : (
          <div className="w-full">
            {quizData.map((question: any, index: number) => {
              return (
                <div className="mt-4 relative" key={index}>
                  <div className="flex flex-row items-start lg:items-center bg-[#233D4E] xl:px-[20px] xl:py-[40px] lg:px-[10px] lg:py-[40px] md:px-[10px] z-10 w-full">
                    <div className="lg:w-[4%] lg:mb-0 ml-2">
                      <div className="text-[65px] font-extrabold text-white mt-10 lg:mt-0">
                        {index + 1}
                      </div>
                    </div>
                    <div className="flex-grow w-full lg:w-[90%] ml-0 lg:ml-[30px] mt-4 lg:mt-0">
                      {question?.question?.questionType === "TEXT" && (
                        <div className="mt-[17px]">
                          <div className="bg-[#233D4E] lg:px-[30px] lg:py-[10px] px-4 py-5 rounded-[10px]">
                            <div className="lg:flex lg:justify-between lg:p-0">
                              <div className="flex justify-end lg:hidden"></div>
                              <div>
                                <p className="text-white font-[Poppins] xl:text-[25px] lg:text-[20px] text-[20px] mt-[8px] lg:font-[400] leading-normal ml-[40px] mr-[10px] text-justify">
                                  {question?.question?.question}
                                </p>
                              </div>
                              <div className="lg:flex justify-end hidden"></div>
                            </div>

                            <div className="md:flex md:w-[100%] lg:mt-[32px] grid w-full gap-[24px] mt-[24px] text-white font-[Poppins] lg:text-[20px] text-[16px] font-[600] leading-normal">
                              {question?.question?.options
                                .filter((option: string) => option !== "")
                                .map((option: any, optionIndex: number) => (
                                  <div
                                    key={optionIndex}
                                    className={`flex items-center md:w-[25%] w-full p-4 md:p-1 lg:p-3 bg-[#192E3B] rounded-[10px] px-[16px] py-[20px] ${
                                      optionIndex === question?.selectedOption
                                        ? option ===
                                          question?.question?.correctAnswer
                                          ? "border-2 border-green-500"
                                          : "border-2 border-red-500"
                                        : ""
                                    }`}
                                  >
                                    <img
                                      className="mr-[16px] rounded-[10px]"
                                      src={
                                        option ===
                                        question?.question?.correctAnswer
                                          ? Tick
                                          : Cancel
                                      }
                                      alt="template"
                                    />
                                    {/* Option rendering based on answerType */}
                                    {question?.question?.answerType ===
                                      "TEXT" && (
                                      <div className="text-white font-[Poppins] lg:text-[16px] xl:text-[20px] text-[16px] font-[600] leading-normal">
                                        <span>{option}</span>
                                      </div>
                                    )}
                                    {question?.question?.answerType ===
                                      "IMAGE" && (
                                      <div>
                                        <img
                                          className="lg:w-[164px] h-[85.854px] md:w-[110px] w-[110px] md:h-[128px] rounded-[10px] lg:rounded-[10px]"
                                          src={option}
                                          alt="template"
                                        />
                                      </div>
                                    )}
                                    {question?.question?.answerType ===
                                      "BOOLEAN" && (
                                      <div className="text-white font-[Poppins] lg:text-[20px] text-[16px] font-[600] leading-normal">
                                        <span>{option}</span>
                                      </div>
                                    )}
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>
                      )}
                      {question?.question?.questionType === "IMAGE_TEXT" && (
                        <div className="my-[17px]">
                          <div className="md:w-full mt-[17px] bg-[#233D4E] lg:px-[30px] lg:py-[10px] px-2 py-5 rounded-[10px]">
                            <div className="lg:flex mt-[18px]">
                              <img
                                className="lg:w-[312px] md:h-[210px] w-full md:w-[300px] rounded-[10px] lg:rounded-[10px]"
                                src={question?.question?.questionImg}
                                alt="template"
                              />
                              <p className="lg:ml-[32px] text-white lg:mt-[80px] mt-[24px] font-[Poppins] xl:text-[25px] lg:text-[20px] text-[20px] font-[400] leading-normal text-justify">
                                {question?.question?.question}
                              </p>
                            </div>
                            <div className="md:flex md:w-[100%] lg:mt-[32px] grid w-full gap-[24px] mt-[24px] text-white font-[Poppins] lg:text-[20px] text-[16px] font-[600] leading-normal">
                              {question?.question?.options?.map(
                                (option: any, optionIndex: number) => (
                                  <div
                                    key={optionIndex}
                                    className={`flex items-center md:w-[25%] w-full p-4 md:p-1 lg:p-3 bg-[#192E3B] rounded-[10px] px-[16px] py-[20px] ${
                                      optionIndex === question?.selectedOption
                                        ? option ===
                                          question?.question?.correctAnswer
                                          ? "border-2 border-green-500"
                                          : "border-2 border-red-500"
                                        : ""
                                    }`}
                                  >
                                    <img
                                      className="mr-[16px] rounded-[10px]"
                                      src={
                                        option ===
                                        question?.question?.correctAnswer
                                          ? Tick
                                          : Cancel
                                      }
                                      alt="template"
                                    />
                                    {/* Option rendering based on answerType */}
                                    {question?.question?.answerType ===
                                      "TEXT" && (
                                      <div className="text-white font-[Poppins] lg:text-[16px] xl:text-[20px] text-[16px] font-[600] leading-normal">
                                        <span>{option}</span>
                                      </div>
                                    )}
                                    {question?.question?.answerType ===
                                      "IMAGE" && (
                                      <div>
                                        <img
                                          className="lg:w-[164px] h-[85.854px] md:w-[110px] w-[110px] md:h-[128px] rounded-[10px] lg:rounded-[10px]"
                                          src={option}
                                          alt="template"
                                        />
                                      </div>
                                    )}
                                    {question?.question?.answerType ===
                                      "BOOLEAN" && (
                                      <div className="text-white font-[Poppins] lg:text-[20px] text-[16px] font-[600] leading-normal">
                                        <span>{option}</span>
                                      </div>
                                    )}
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default PlayerQuizSummary;
