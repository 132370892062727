import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import LoaderComponent from "src/components/loader/LoaderComponent";
import { uploadImage } from "src/services/createQuiz/createQuiz.service";
import ImageUpload from "../imageUpload/ImageUpload";
import { debounce } from "lodash";

type TextImageTypeComponentProp = {
  response: (data: any) => void;
  questions: any;
};

const placeholderImage =
  "https://www.svgrepo.com/show/508699/landscape-placeholder.svg";

const TextImageTypeComponent: FC<TextImageTypeComponentProp> = ({
  response,
  questions,
}) => {
  const [listOfImages, setListOfImages] = useState<string[]>(
    new Array(4).fill("")
  );
  const [question, setQuestion] = useState<string>(questions?.question || "");
  const [correctAnswer, setCorrectAnswer] = useState<string | undefined>(
    questions?.correctAnswer
  );
  const [optionLoading, setoptionLoading] = useState<boolean[]>(
    new Array(4).fill(false)
  );
  const [categoryId, setCategoryId] = useState<string>("");

  const questionId = questions?._id;
  const editable = questions?.isEditable;
  const responseRef = useRef(response);

  useEffect(() => {
    if (questions?.category_id) {
      setCategoryId(questions?.category_id);
    }
  }, [questions]);

  const outputData = useCallback((data: any) => {
    responseRef.current(data);
  }, []);

  useEffect(() => {
    responseRef.current = response;
  }, [response]);

  useEffect(() => {
    const debouncedOutputData = debounce((data: any) => {
      responseRef.current(data);
    }, 300);
    if (question !== "") {
      const options = listOfImages.map(
        (image: any) => image || placeholderImage
      );
      const questionType = "TEXT";
      const answerType = "IMAGE";
      const data = {
        question,
        correctAnswer,
        options,
        questionType,
        answerType,
        _id: questionId,
        isEditable: editable,
        category_id: categoryId,
      };
      debouncedOutputData(data);

      // Cleanup function to cancel debounce on unmount
      return () => {
        debouncedOutputData.cancel();
      };
    }
  }, [
    correctAnswer,
    question,
    listOfImages,
    questionId,
    outputData,
    editable,
    categoryId,
  ]);

  const optionFileHandler = (event: any, index: number) => {
    const file = event.target.files[0];
    if (file) {
      setoptionLoading((prevLoading) =>
        prevLoading.map((loading, i) => (i === index ? true : loading))
      );

      uploadImage(file)
        .then((imageUrl) => {
          const updatedImages = listOfImages.map((image, i) => {
            if (i === index) {
              return imageUrl;
            }
            return image;
          });
          setListOfImages(updatedImages);
        })
        .catch((error) => {
          console.error("Error uploading image:", error);
        })
        .finally(() => {
          setoptionLoading((prevLoading) =>
            prevLoading.map((loading, i) => (i === index ? false : loading))
          );
        });
    }
  };

  return (
    <div className="w-full h-full">
      {/* question Section*/}
      <hr className="border-1 mt-2" />

      <section className="border xl:mt-[40px] lg:mt-[20px] mt-8 border-[#D7D7D7] p-2 rounded-[10px]">
        <textarea
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          className="text-[#000] font-[inter] lg:text-[18px] text-[16px] lg:font-[400] leading-normal ml-[40] mr-[10] w-full max-h-[60px] border resize-none placeholder:text-zinc-400 placeholder:text-lg placeholder:font-normal flex items-center box-border pb-5 pt-4 px-2 overflow-auto no-scrollbar border-none focus:outline-none  "
          placeholder="Question"
        />
      </section>
      {/* options Section*/}
      <section className="space-y-4 w-full lg:mt-[32px] mt-[24px] text-[#000] gap-8 2xl:gap-8 font-[inter] lg:text-[18px] text-[16px] font-[600] leading-normal ">
        <h2 className="text-xl font-semibold mb-4">Options</h2>
        {listOfImages.map((image, index) => (
          <div
            className="relative flex items-center rounded-[10px] lg:my-0 my-2 border-dashed border-[1px] border-[#1FC4D9] bg-[#1FC4D903]"
            key={index}
          >
            <div className="w-auto relative p-2">
              {image ? (
                <img
                  className="w-[200px] h-[130px] rounded-[10px] object-cover"
                  src={image}
                  alt="template"
                ></img>
              ) : (
                <div className="w-[200px] h-[130px] rounded-[10px] object-cover flex items-center ">
                  <ImageUpload type="option" />
                </div>
              )}

              <div className="absolute inset-0 flex items-center justify-center rounded-xl">
                {optionLoading[index] ? (
                  <LoaderComponent />
                ) : (
                  <label
                    htmlFor={`imageInput-${index}`}
                    className="absolute inset-0 flex items-center justify-center rounded-xl cursor-pointer"
                  >
                    <input
                      id={`imageInput-${index}`}
                      type="file"
                      accept="image/*"
                      className="absolute top-0 left-0 w-[200px] h-full opacity-0"
                      onChange={(event) => optionFileHandler(event, index)}
                    />
                  </label>
                )}
              </div>
            </div>
            <div className="absolute top-0 right-0 w-[10%] flex items-center h-full justify-center">
              <input
                className="border rounded-[10px] border-[#0000002B] bg-white"
                type="checkbox"
                name="answer"
                checked={correctAnswer === image}
                onChange={() => setCorrectAnswer(image)}
              />
            </div>
          </div>
        ))}
      </section>
    </div>
  );
};

export default TextImageTypeComponent;
