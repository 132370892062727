import React, { useEffect } from "react";

interface QuizTimerProps {
  countdown: number;
}

const QuizTimer: React.FC<QuizTimerProps> = ({ countdown }) => {
  useEffect(() => {
    const countdownInterval = setInterval(() => {}, 2000);

    return () => {
      clearInterval(countdownInterval);
    };
  }, [countdown]);

  return (
    <div className="w-[530px] flex justify-center items-center md:h-[550px] lg:h-[400px] h-[290px] flex-shrink rounded-[20px] bg-[#8BD3FD] bg-opacity-20 backdrop-blur-[18.5px]">
      {/* Countdown Display */}
      <div className="text-[168px] text-white font-[Poppins] font-semibold leading-normal">
        {countdown}
      </div>
    </div>
  );
};

export default QuizTimer;
