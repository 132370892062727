// export const homePageData = {
//     "@context": "https://schema.org/",
//     "@type": "WebPage",
//     "name": "Home Page",
//     "description": "Welcome to the home page of our website.",
//   };
  
//   export const myLibraryData = {
//     "@context": "https://schema.org/",
//     "@type": "CollectionPage",
//     "name": "My Library",
//     "description": "View your personal library of saved items.",
//   };
  
//   export const upcomingGamesData = {
//     "@context": "https://schema.org/",
//     "@type": "Event",
//     "name": "Upcoming Games",
//     "description": "Check out the upcoming games schedule.",
//   };

//   export const createQuizPageData = {
//     "@context": "https://schema.org/",
//     "@type": "WebPage",
//     "name": "Quiz Page",
//     "description": "This is a quiz page where users can create .",
//   };
export const sharedStructuredData = {
  "@context": "https://schema.org",
  "@type": "Website",
  "name": "Quiz Circles",
  "url": "https://quizcircles.com",
  "description": "Explore Quiz Circles and enjoy 2000+ exciting free and paid quiz templates! Pay per game, No subscription. Begin your quiz now and share nonstop excitement with friends!",
  "publisher": {
    "@type": "Organization",
    "name": "Quiz Circles",
    "url": "https://quizcircles.com"
  }
};
