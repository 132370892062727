import { useEffect, useRef, useState } from "react";
import Navbar from "src/components/navBar/NavBar";
import copyClipboard from "src/assets/copyClipboard.svg";
import { DatePicker } from "antd";
import { Switch } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import clipboard from "clipboard";
import { gameSettingsSwitch } from "src/services/gameSettings/gameSettings.service";
import FullHttpLink from "src/components/domainName/DomainName";
import moment from "moment";
import CustomTimePicker from "./timePicker";
import { Helmet } from "react-helmet";

const GameSettingsPage = () => {
  const navigate = useNavigate();
  const limit = sessionStorage.getItem("limit" || null);
  const [switchState, setSwitchState] = useState(false);
  const [respondTime, setRespondTime] = useState<number | undefined>(10);
  const [gameSettingDetail, setGameSettingDetail] = useState<any>({});
  const [restTime, setRestTime] = useState<number | undefined>(10);
  const [selectDate, setSelectDate] = useState<string | undefined>(undefined);
  const [selectTime, setSelectTime] = useState<string | undefined>(undefined);
  const [isPublic, setIsPublic] = useState(false);
  const { gameId } = useParams();
  const inputRef = useRef<HTMLInputElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [sameHost, setSameHost] = useState<boolean>();
  const [error, setError] = useState("");
  const [display_status, setDisplayStatus] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const switchStyle = {
    backgroundColor: switchState ? "#1fd655" : "gray",
  };
  const toggleStyle = {
    backgroundColor: isPublic ? "#1fd655" : "gray",
  };

  const quizIDWithQuotes = sessionStorage.getItem("quizID") || "";
  const quizID = quizIDWithQuotes.replace(/["']/g, "");
  const hostIDWithQuotes = localStorage.getItem("hostID") || "";
  const hostID = hostIDWithQuotes.replace(/["']/g, "");
  const quizCreatedByWithQuotes = sessionStorage.getItem("quizCreatedBy") || "";
  const quizCreatedBy = quizCreatedByWithQuotes.replace(/["']/g, "");
  const displayStatus = sessionStorage.getItem("displayStatus");

  useEffect(() => {
    if (hostID === quizCreatedBy) {
      setSameHost(true);
    } else {
      setSameHost(false);
    }
  }, [hostID, quizCreatedBy]);

  useEffect(() => {
    const gameSettings = JSON.parse(
      sessionStorage.getItem("gameSettings") || ""
    );
    setGameSettingDetail(gameSettings);
  }, []);

  useEffect(() => {
    if (displayStatus === "true") {
      setIsPublic(true);
    }
  }, [displayStatus]);

  const handleSwitchToggle = () => {
    setSwitchState(!switchState);
  };

  const handleDatePickerChange = (date: any, dateString: string | string[]) => {
    if (typeof dateString === "string") {
      setSelectDate(dateString);
    }
  };

  // Function to handle switch click
  const handleSwitchClick = () => {
    setIsPublic(!isPublic);
    setDisplayStatus(!isPublic);
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const clearError = () => {
    setError("");
  };

  const handleRespondTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value === "" || (Number(value) >= 1 && Number(value) <= 60)) {
      setRespondTime(value === "" ? undefined : Number(value));
    }
  };

  const handleRestTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value === "" || (Number(value) >= 1 && Number(value) <= 60)) {
      setRestTime(value === "" ? undefined : Number(value));
    }
  };

  const handleOnChangeCustomTimePicker = (value: string | undefined) => {
    setSelectTime(value);
  };

  // Copy clipboard
  useEffect(() => {
    if (inputRef.current && buttonRef.current) {
      const clipboardInstance = new clipboard(buttonRef.current, {
        target: () => inputRef.current!,
      });
      clipboardInstance.on("success", (e: clipboard.Event) => {});
      clipboardInstance.on("error", (e: clipboard.Event) => {});
      return () => {
        clipboardInstance.destroy();
      };
    }
  }, []);

  const gameSettingDetailsId = gameSettingDetail._id;
  const convertDateTime = () => {
    const combinedDateTimeString = `${selectDate}T${selectTime}`;
    const dateTimeMoment = moment(combinedDateTimeString, "YYYY-MM-DDTHH:mm");
    const utcDateTimeMoment = dateTimeMoment.utc();
    const formattedDateTime = utcDateTimeMoment.toISOString();
    return formattedDateTime;
  };

  const handleGameSettings = async () => {
    if (switchState) {
      if (
        !selectDate ||
        !selectTime ||
        respondTime === undefined ||
        restTime === undefined
      ) {
        setError("Please fill in all required fields.");
        return;
      }
    } else {
      if (respondTime === undefined || restTime === undefined) {
        setError("Please fill in all required fields.");
        return;
      }
    }
    if (
      gameSettingDetailsId &&
      (restTime !== undefined || respondTime !== undefined)
    ) {
      try {
        let params: any;
        const selectDateTime = convertDateTime();
        params = {
          restTime,
          respondTime,
          quizID,
          display_status,
        };
        if (limit !== null) {
          params.limit = limit;
        }
        if (selectDateTime !== null) {
          params.selectDateTime = selectDateTime;
        }
        await gameSettingsSwitch(gameSettingDetailsId, params);

        switchState
          ? navigate(`/host/upcoming-games`)
          : navigate(`/host/gamemode/${gameId}`);
      } catch (error) {
        console.error("Error updating game settings:", error);
      }
    } else {
      setError("Please fill in all required fields.");
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="max-w-screen-2xl mx-auto  w-full">
      <Helmet>
        <title>Quiz Settings</title>
        <meta
          name="description"
          content="Configure and customize your game settings for the best quiz experience on Quiz Circles."
        />
      </Helmet>
      <Navbar
        className={`bg-white ${
          scrolled ? "bg-white z-[99] shadow-[#ABB3DE] shadow-lg " : ""
        }`}
        lgPaddingHome="20"
        xlPaddingHome="[70px]"
        templateDetailsMobileView={false}
        showmenuIcon={true}
      />
      {/* Main Content */}
      <div className="bg-[#F8F9FC] h-screen md:p-9 p-4 overflow-y-auto scroll-smooth no-scrollbar font-[poppins]">
        <div className="lg:px-10 px-4 sm:px-12">
          <div
            className={`flex items-center space-x-4  `}
            onClick={handleGoBack}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              className="w-[24px] h-[24px] "
            >
              <g clipPath="url(#clip0_74_870)">
                <path
                  d="M23.0625 11.0625H3.20824L6.49717 7.7895C6.86416 7.42425 6.86556 6.83067 6.50031 6.46368C6.13507 6.09665 5.54144 6.09529 5.17446 6.4605L0.27583 11.3355C0.275502 11.3358 0.275268 11.3361 0.274987 11.3364C-0.0910603 11.7016 -0.0922322 12.2971 0.274893 12.6636C0.275221 12.6639 0.275455 12.6642 0.275737 12.6645L5.17436 17.5395C5.5413 17.9047 6.13492 17.9034 6.50022 17.5363C6.86547 17.1693 6.86407 16.5757 6.49708 16.2105L3.20824 12.9375H23.0625C23.5803 12.9375 24 12.5178 24 12C24 11.4822 23.5803 11.0625 23.0625 11.0625Z"
                  fill="#008BB7"
                />
              </g>
              <defs>
                <clipPath id="clip0_74_870">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <h1
              className="text-[#008BB7] font-[Poppins] lg:text-[20px] font-[400px]  text-[18px]  leading-normal "
              onClick={handleGoBack}
            >
              Back
            </h1>
          </div>
          {/* Link Section */}
          <section>
            <div className="flex gap-4 h-[56px] bg-[#FFF] w-fit rounded-[10px] mt-[51px] items-center">
              <div
                className="text-[#171717] font-normal sm:w-auto w-[200px] truncate font-[Poppins] ml-[10px]   text-[16px] lg:text-[20px] "
                id="foo"
                ref={inputRef}
              >
                <div className="truncate w-[3/4] sm:w-auto">
                  <FullHttpLink link={gameSettingDetail?.link} />
                </div>
              </div>

              <button
                ref={buttonRef}
                className="bg-[#008AB6] w-[50px] sm:w-[64px] h-[50px] sm:h-[56px] rounded-[10px] flex items-center justify-center "
                data-clipboard-target="#foo"
              >
                <img
                  src={copyClipboard}
                  alt="copyClipboardImage"
                  className=""
                />
              </button>
            </div>

            <div className="lg:mt-[48px] mt-[20px]">
              <h1 className="text-[#171717] font-[Poppins] font-bold  text-[16px] leading-normal">
                Quiz Settings
              </h1>
            </div>

            <div className="space-y-4 lg:h-fit bg-[#fff] p-4 mt-5">
              <div className="sm:flex items-center py-2">
                <h1 className="text-[#000] font-[Poppins] lg:text-[16px] font-normal  text-[16px] leading-normal">
                  Quiz Start Time
                </h1>
                <div className="flex items-center ml-auto gap-4 mt-2 sm:mt-0">
                  <Switch
                    style={switchStyle}
                    className="custom-switch mb-4 lg:mb-0"
                    onClick={handleSwitchToggle}
                  />
                  {switchState && (
                    <div className="flex">
                      <div className="mb-4 lg:mb-0 lg:ml-4">
                        <DatePicker
                          style={{
                            marginLeft: "10px",
                            borderRadius: "4px",
                          }}
                          className="text-black"
                          onChange={handleDatePickerChange}
                          disabledDate={(current: any) =>
                            current && current < moment().startOf("day")
                          }
                        />
                      </div>
                      <div className="ml-3">
                        <CustomTimePicker
                          onChange={handleOnChangeCustomTimePicker}
                          value={""}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {(sameHost) && (
                <div className="sm:flex justify-between items-center py-2 ">
                  <div>
                    <h1 className="text-[#000] font-[Poppins] lg:text-[16px] font-normal text-[16px] leading-normal ">
                      Keep this quiz as public
                    </h1>
                  </div>
                  <div className="md:flex items-center ml-auto mt-2 sm:mt-0 gap-4">
                    <Switch
                      style={toggleStyle}
                      className="custom-switch mb-4 lg:mb-0"
                      onClick={handleSwitchClick}
                    />
                  </div>
                </div>
              )}

              <div className="sm:flex justify-between items-center py-2 ">
                <h1 className="text-[#000] font-[Poppins] lg:text-[16px] font-normal  text-[16px] leading-normal">
                  Respond Time (Per Question)
                </h1>

                <div className="mt-2 sm:mt-0">
                  <input
                    type="number"
                    value={respondTime === undefined ? "" : respondTime}
                    onChange={(e) => {
                      handleRespondTimeChange(e);
                      clearError();
                    }}
                    className="lg:w-[144px] w-[144px] lg:h-[36px] h-[36px] p-2 border border-gray-300 rounded"
                    placeholder="Enter seconds"
                  />
                </div>
              </div>

              <div className="sm:flex justify-between items-center py-2 ">
                <h1 className="text-[#000] font-[Poppins] lg:text-[16px] font-normal text-[16px] leading-normal ">
                  Rest Time (Per Question)
                </h1>

                <div className="mt-2 sm:mt-0">
                  <input
                    type="number"
                    value={restTime === undefined ? "" : restTime}
                    onChange={(e) => {
                      handleRestTimeChange(e);
                      clearError();
                    }}
                    className="lg:w-[144px] w-[144px] lg:h-[36px] h-[36px] p-2 border border-gray-300 rounded"
                    placeholder="Enter seconds"
                  />
                </div>
              </div>

              <div className="mt-[20px] flex justify-end">
                {switchState ? (
                  <button
                    className="flex gap-[10px]  md:px-[25px] h-12 px-[17px] rounded-full bg-gradient-to-r from-[#1EC6DA] to-[#6F2A96] border :border-none text-white items-center justify-center hover:rounded-[12px] hover:shadow-[0px_20px_20px_-10px_rgba(131,42,184,0.4)] hover:inner-shadow-[inset 0px 20px 20px -10px rgba(131,42,184,0.4)]  ml-[24px]"
                    onClick={handleGameSettings}
                  >
                    <span className="text-[#FFFFFF] font-[Poppins] text-[20.829px] font-[400px] leading-normal  ">
                      Publish
                    </span>
                  </button>
                ) : (
                  // "Next" button
                  <button
                    className="flex gap-[10px]  md:px-[25px] h-12 px-[17px] rounded-full bg-gradient-to-r from-[#1EC6DA] to-[#6F2A96] border :border-none text-white items-center justify-center  hover:shadow-[0px_20px_20px_-10px_rgba(131,42,184,0.4)] hover:inner-shadow-[inset 0px 20px 20px -10px rgba(131,42,184,0.4)]   ml-[2px]"
                    onClick={handleGameSettings}
                  >
                    <span className="text-[#FFFFFF] font-[Poppins] text-[20.829px] font-[400px] leading-normal  ">
                      Next
                    </span>
                  </button>
                )}
                {/* Display error message if there is any */}
              </div>
            </div>
          </section>

          {/* Footer */}
          {error && (
            <p className="text-red-500 flex justify-end mt-2">{error}</p>
          )}
        </div>
      </div>
    </div>
  );
};
export default GameSettingsPage;
