import React, { useEffect, useState, useRef, useCallback } from "react";
import QuestionBank from "src/assets/qatar 2.png";
import ChatBox from "src/pages/createQuiz/ChatBox";
import CryptoJS from "crypto-js";
import {
  createQuestions,
  game,
  uploadImage,
  updateQuestions,
  updateQuiz,
  deleteQuestion,
  getQuizDetail,
  getQuestionOrder,
} from "src/services/createQuiz/createQuiz.service";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "src/components/navBar/NavBar";
import LoaderComponent from "src/components/loader/LoaderComponent";
import DropDownOption from "src/components/questionTypes/DropDownOption";
import { fetchQuizDetails } from "src/services/quizDetails.service.tsx/quizDetails.service";
import EditTrueOrFalse from "src/components/questionTypesEdit/EditTrueOrFalse";
import EditTextImageTypeComponent from "src/components/questionTypesEdit/EditTextImageTypeComponent";
import EditImageTextTypeComponents from "src/components/questionTypesEdit/EditImageTextTypeComponents";
import EditTextTypeComponent from "src/components/questionTypesEdit/EditTextTypeComponent";
import EditDropDownOption from "src/components/questionTypesEdit/EditDropDownOption";
import EditImageImageTypeComponents from "src/components/questionTypesEdit/EditImageImageTYpeComponents";
import CustomAlert from "src/components/custonAlert/CustomAlert";
import { createCategory } from "src/services/createQuiz/category.service";
import ImageUpload from "src/components/imageUpload/ImageUpload";
import { Helmet } from "react-helmet";
import StructuredData from "src/components/structuredData/StructuredData";
import { sharedStructuredData } from "src/components/structuredData/Data";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import tick from "src/assets/icons8-drag-and-drop-67.png";
import { debounce } from "lodash";

const QuizCreation = () => {
  const location = useLocation();
  const [templateId, setTemplateId] = useState<any>("" || null);
  const [quizID, setQuizID] = useState("");
  const [isChatBoxOpen, setIsChatBoxOpen] = useState(false);
  const getSuperHost = localStorage.getItem("superHost") || "";
  const superHost = getSuperHost.replace(/["']/g, "");
  const [totalQuestion, setTotalQuestion] = useState<any[]>(
    templateId !== null ? [] : [{ question: "" }]
  );
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [questionIds, setQuestionIds] = useState<any[]>([]);
  const [isSuccessMessageVisible, setIsSuccessMessageVisible] = useState(false);
  const [noQuestionsMessageVisible, setNoQuestionsMessageVisible] =
    useState(false);
  const [message, setMessage] = useState("");
  const secretKey = "quiz123456";
  const [, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [price, setPrice] = useState("");
  const navigate = useNavigate();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [indexToDelete, setIndexToDelete] = useState<any | null>(null);
  const [isimageLoading, setIsImageLoading] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const [isFree, setIsFree] = useState(superHost === "admin" ? "free" : "paid");
  const [isPublic, setIsPublic] = useState(
    superHost === "admin" ? "Public" : "Private"
  );
  const [activeTab, setActiveTab] = useState("settings");
  const [prevTab, setPrevTab] = useState("settings");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [isAdding, setIsAdding] = useState(false);
  const [newCategory, setNewCategory] = useState("");
  const [newCategoryItem, setNewCategoryItem] = useState<any[]>([]);
  const path = location.pathname;

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const templateId = params.get("templateId");
    const quizId = params.get("quizID");

    if (templateId) {
      setTemplateId(templateId);
    } else if (quizId) {
      setQuizID(quizId);
    } else {
      setTotalQuestion([{ question: "" }]);
    }
  }, [location]);

  const onDragEnd = async (result: any) => {
    const { destination, source } = result;
    if (!destination || destination.index === source.index) return;

    try {
      const updatedQuestions = Array.from(totalQuestion);
      const [movedQuestion] = updatedQuestions.splice(source.index, 1);
      updatedQuestions.splice(destination.index, 0, movedQuestion);

      setTotalQuestion(updatedQuestions);

      // API call to update the question order
      const orderList = updatedQuestions.map((questions, index) => ({
        quizQuestion_id: questions.mapId,
        order: index + 1,
      }));

      const id = templateId || quizID;
      if (id) {
        await getQuestionOrder(id, orderList);
      }
    } catch (error) {
      console.error("Failed to update question order:", error);
    }
  };

  const decryptAnswer = (
    encryptedAnswer: string | CryptoJS.lib.CipherParams
  ) => {
    const bytes = CryptoJS.AES.decrypt(encryptedAnswer, secretKey);
    const decryptedAnswer = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedAnswer;
  };

  useEffect(() => {
    if (quizID) {
      getQuizDetail(quizID)
        .then((response) => {
          setName(response?.quiz?.name);
          setImageUrl(response?.quiz?.imageUrl);
          setIsFree(response?.quiz?.is_free);
          setIsPublic(
            response?.quiz?.display_status === true ? "Public" : "Private"
          );
          if (response?.questions?.length > 0) {
            const questionObjects = response?.questions.map((q: any) => {
              const decryptedAnswer = decryptAnswer(q.question.correctAnswer);
              return {
                ...q.question,
                correctAnswer: decryptedAnswer,
                mapId: q._id,
              }
             
            });
            setQuestionIds(questionObjects.map((question:any)=>question?._id))
            setTotalQuestion(questionObjects);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [quizID]);

  useEffect(() => {
    if (isChatBoxOpen) {
      document.title = "Question Bank Questions";
    } else if (isAdding) {
      document.title = "Add Category";
    }
  }, [isChatBoxOpen, isAdding]);

  useEffect(() => {
    document.body.style.overflow = isChatBoxOpen ? "hidden" : "auto";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isChatBoxOpen]);

  const coverFileHandler = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsImageLoading(true);
    const file = event.target.files?.[0];
    if (file) {
      try {
        const imageUrl = await uploadImage(file);
        if (imageUrl) {
          const reader = new FileReader();
          reader.onload = () => {
            reader.result as string;
          };
          reader.readAsDataURL(file);

          setImageUrl(imageUrl);
          if (quizID || templateId) {
            debouncedUpdate({
              paramId: quizID || templateId,
              nameData: name,
              imageUrlData: imageUrl,
              isFreeData: isFree,
              isPublicData: isPublic,
            });
          }
        } else {
          throw new Error("Failed to get image URL");
        }
      } catch (error: any) {
        setAlertMessage("Image upload Failed");
        setShowAlert(true);
      } finally {
        setIsImageLoading(false);
      }
    } else {
      setIsImageLoading(false);
    }
  };

  const handleDelete = () => {
    const updateQuestions = (indexToDelete: number) => {
      const remainingData = totalQuestion.filter(
        (_, index) => indexToDelete !== index
      );
      setTotalQuestion([...remainingData]);

      if (indexToDelete > 0) {
        setCurrentQuestionIndex(indexToDelete - 1);
      } else if (remainingData.length === 0) {
        setTotalQuestion([{ question: "" }]);
      }
    };

    if (!deleteId) {
      if (indexToDelete !== null) {
        updateQuestions(indexToDelete);
        setIndexToDelete(null);
        setShowConfirmation(false);
      }
    } else {
      deleteQuestion(deleteId)
        .then((response) => {
          if (indexToDelete !== null) {
            updateQuestions(indexToDelete);
            setIndexToDelete(null);
            setShowConfirmation(false);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const handleAddQuestion = async () => {
    if (totalQuestion[currentQuestionIndex]?.question.trim() === "") {
      setAlertMessage("Please enter a question before adding!");
      setShowAlert(true);
      return;
    }
    if (questionIds.includes(totalQuestion[currentQuestionIndex]?._id)) {
      handleQuestionUpdate(totalQuestion[currentQuestionIndex]?._id, true);
      const isEmptyQuestion = totalQuestion.findIndex(
        (question, index) => question?._id === undefined
      );
      if (isEmptyQuestion === -1) {
        setCurrentQuestionIndex(totalQuestion.length);
        setTotalQuestion([...totalQuestion, { question: "" }]);
      } else {
        setCurrentQuestionIndex(isEmptyQuestion);
      }
    } else {
      const isSaved = await handleQuestionSave(currentQuestionIndex, true);
      if (!isSaved) {
        return;
      }
    }
  };

  const handleQuestionSave = async (
    index: number,
    quizUpdateByAddQuestion?: boolean
  ) => {
    setIsLoading(true);
    const alertShownForQuestion: any[] = [];
    const seenQuestions = new Set();
    const currentIndex = currentQuestionIndex;

    if (totalQuestion.length === 0) {
      setNoQuestionsMessageVisible(true);
      setMessage("There are no questions to save.");
      setTimeout(() => {
        setNoQuestionsMessageVisible(false);
      }, 3000);
      setIsLoading(false);
      return false;
    }

    const question = totalQuestion[currentIndex];
    const trimmedQuestion = question.question.trim();

    if (trimmedQuestion === "") {
      if (!alertShownForQuestion[currentIndex]) {
        setAlertMessage(`Question ${currentIndex + 1} is missing question.`);
        setShowAlert(true);
        alertShownForQuestion[currentIndex] = true;
      }
      setIsLoading(false);
      return false;
    }

    if (
      !question.options ||
      !Array.isArray(question.options) ||
      question.options.length === 0 ||
      question.options.some((option: string) => !option.trim())
    ) {
      if (!alertShownForQuestion[currentIndex]) {
        setAlertMessage(`Question ${currentIndex + 1} is missing options.`);
        setShowAlert(true);
        alertShownForQuestion[currentIndex] = true;
      }
      setIsLoading(false);
      return false;
    }

    const uniqueOptions = new Set(
      question.options.map((option: string) => option.trim())
    );
    if (uniqueOptions.size !== question.options.length) {
      if (!alertShownForQuestion[currentIndex]) {
        setAlertMessage(`Question ${currentIndex + 1} has duplicate options.`);
        setShowAlert(true);
        alertShownForQuestion[currentIndex] = true;
      }
      setIsLoading(false);
      return false;
    }

    if (!question.correctAnswer || !question.correctAnswer.trim()) {
      if (!alertShownForQuestion[currentIndex]) {
        setAlertMessage(
          `Question ${currentIndex + 1} does not have a correct answer.`
        );
        setShowAlert(true);
        alertShownForQuestion[currentIndex] = true;
      }
      setIsLoading(false);
      return false;
    }

    if (seenQuestions.has(trimmedQuestion)) {
      setAlertMessage(
        `Duplicate question found: Question ${currentIndex + 1} is a duplicate.`
      );
      setShowAlert(true);

      setIsLoading(false);
      return false;
    } else {
      seenQuestions.add(trimmedQuestion);
    }

    // Encrypt the correct answer
    const encryptedCorrectAnswer = CryptoJS.AES.encrypt(
      question.correctAnswer,
      secretKey
    ).toString();
    let isEditable: boolean;
    if (superHost === "admin") {
      isEditable = false;
    } else {
      isEditable = true;
    }
    const encryptedQuestion = {
      ...question,
      correctAnswer: encryptedCorrectAnswer,
      isEditable,
    };

    let questionId: string;

    try {
      if (!encryptedQuestion._id) {
        const response = await createQuestions(encryptedQuestion);
        if (!response) {
          throw new Error("Invalid response from createQuestions API");
        }
        questionId = response?._id;
        totalQuestion[index] = {
          ...totalQuestion[index],
          _id: response?._id,
          isEditable,
        };
        setQuestionIds((prevIds: any) => [...prevIds, response._id]);
      } else {
        const newIds = encryptedQuestion._id;
        questionId = newIds;
        totalQuestion[index] = {
          ...totalQuestion[index],
        };

        setQuestionIds((prevIds: any) => [...prevIds, newIds]);
      }
      if (questionId) {
        handleQuizUpdate({}, questionId, quizUpdateByAddQuestion, true);
      }

      if (!quizUpdateByAddQuestion) {
        setIsSuccessMessageVisible(true);
        setMessage("Question saved successfully");
        setTimeout(() => {
          setIsSuccessMessageVisible(false);
        }, 3000);
      }

      setIsLoading(false);
      return true;
    } catch (error: any) {
      console.error(error);
      setAlertMessage(error?.response?.data?.message);
      setShowAlert(true);

      setIsLoading(false);
      return false;
    }
  };

  const handleQuizSave = () => {
    const trimmedName = name.trim();
    if (!name.trim()) {
      setAlertMessage("Please give a quiz name!");
      setShowAlert(true);
      return;
    }
    if (!imageUrl.trim()) {
      setAlertMessage("Please give a quiz image!");
      setShowAlert(true);
      return;
    }
    if (!trimmedName) {
      return;
    }
    if (!imageUrl.trim()) {
      return;
    }

    let payload: any = {
      name: trimmedName,
      imageUrl,
    };

    if (isFree) {
      payload.is_free = isFree === "free" ? "free" : "paid";
    }
    if (isPublic) {
      payload.display_status = isPublic === "Public" ? true : false;
    }

    if (price) {
      payload.amount = price;
    }

    game(payload)
      .then((response) => {
        if (response) {
          sessionStorage.setItem("quizID", response._id);
          setQuizID(response._id);
          setActiveTab("questions");
          const newUrl = `${path}?quizID=${response._id}`;
          navigate(newUrl);
        } else {
          throw new Error("Invalid response from game API");
        }
      })
      .catch((error) => {
        setAlertMessage(error?.response?.data?.message);
        setShowAlert(true);
      });
  };

  const handleTabChange = (newTab: string) => {
    if (newTab === "questions") {
      if (!name.trim()) {
        setAlertMessage("Please give a quiz name!");
        setShowAlert(true);
        return;
      }
      if (!imageUrl.trim()) {
        setAlertMessage("Please give a quiz image!");
        setShowAlert(true);
        return;
      }

      if (!templateId && !quizID && prevTab === "settings") {
        handleQuizSave();

        return;
      }
    }

    setPrevTab(activeTab);
    setActiveTab(newTab);
  };

  const handleQuizUpdate = (
    updatedFields: {
      paramId?: string;
      nameData?: string;
      imageUrlData?: string;
      isFreeData?: string;
      isPublicData?: string;
    } = {},
    id?: string,
    quizUpdateByAddQuestion?: boolean,
    quizUpdateInQuestionCreate?: boolean
  ) => {
    const {
      paramId,
      nameData: updatedName,
      imageUrlData: updatedImageUrl,
      isFreeData: updatedIsFree,
      isPublicData: updatedIsPublic,
    } = updatedFields;

    const quizName = updatedName !== undefined ? updatedName : name;
    const quizImageUrl =
      updatedImageUrl !== undefined ? updatedImageUrl : imageUrl;
    const quizIsFree = updatedIsFree !== undefined ? updatedIsFree : isFree;
    const quizIsPublic =
      updatedIsPublic !== undefined ? updatedIsPublic : isPublic;

    if (!quizName) {
      setAlertMessage("Please enter the quiz title");
      setShowAlert(true);
      return;
    }
    if (!quizImageUrl) {
      setAlertMessage("Please select quiz image");
      setShowAlert(true);
      return;
    }

    let payload: any = {
      name: quizName,
      imageUrl: quizImageUrl,
      questions: id ? [{ question_id: id }] : [],
    };

    if (quizIsFree) {
      payload.is_free = quizIsFree === "free" ? "free" : "paid";
    }

    if (quizIsPublic) {
      payload.display_status = quizIsPublic === "Public" ? true : false;
    }

    if (price) {
      payload.amount = price;
    }

    updateQuiz(templateId || quizID || paramId, payload)
      .then((response) => {
        if (response?.data?.update?._id) {
          const questionId = id;
          const mapId = response?.data?.mapQuestion;

          const updatedTotalQuestion = totalQuestion.map((question) => {
            if (question._id === questionId) {
              return { ...question, mapId };
            }
            return question;
          });

          setTotalQuestion(updatedTotalQuestion);

          if (quizUpdateByAddQuestion === true) {
            setCurrentQuestionIndex(totalQuestion.length);
            setTotalQuestion([...totalQuestion, { question: "" }]);
          }

          if (!quizUpdateInQuestionCreate) {
            setIsSuccessMessageVisible(true);
            setMessage("Quiz updated successfully");
            setTimeout(() => {
              setIsSuccessMessageVisible(false);
            }, 3000);
          }
        } else {
          throw new Error("Invalid response from game API");
        }
      })
      .catch((error) => {
        console.error(error);
        setAlertMessage(error.response.data.message);
        setShowAlert(true);
      });
  };
  // eslint-disable-next-line
  const debouncedUpdate = useCallback(debounce(handleQuizUpdate, 750), []);

  const handleQuestionClick = (index: any) => {
    setCurrentQuestionIndex(index);
  };

  const openChatBox = () => {
    setIsChatBoxOpen(true);
  };

  const handleSelectQuestions = (selectedQuestions: any) => {
    if (totalQuestion.length === 1 && totalQuestion[0].question.trim() === "") {
      setTotalQuestion(selectedQuestions);
      setCurrentQuestionIndex(0);
    } else {
      if (totalQuestion[currentQuestionIndex]?.question !== "") {
        if (!questionIds.includes(totalQuestion[currentQuestionIndex]?._id)) {
          setAlertMessage(
            "Save the current question before adding new one from the Question Bank."
          );
          setShowAlert(true);
          return;
        }
        setTotalQuestion([...totalQuestion, ...selectedQuestions]);
        setCurrentQuestionIndex(totalQuestion.length);
      } else {
        totalQuestion[currentQuestionIndex] = selectedQuestions[0];
        setCurrentQuestionIndex(totalQuestion.length - 1);
      }
    }
  };

  const handleImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  function questionCreation(response: any, index: number) {
    setTotalQuestion((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];
      updatedQuestions[index] = { ...response };
      return updatedQuestions;
    });
  }

  useEffect(() => {
    if (templateId && templateId !== null) {
      setIsLoading(true);
      fetchQuizDetails(templateId)
        .then((data: any) => {
          setName(data?.quiz?.name);
          setImageUrl(data?.quiz?.imageUrl);
          setIsFree(data?.quiz?.is_free);
          setIsPublic(
            data?.quiz?.display_status === true ? "Public" : "Private"
          );
          if (data?.quiz?.amount) {
            const priceWithoutSymbol = data?.quiz?.amount.replace("$", "");
            setPrice(priceWithoutSymbol);
          }
          if (data?.questions.length > 0) {
            const decryptedQuestions = data?.questions?.map((question: any) => {
              const bytes = CryptoJS.AES.decrypt(
                question?.question?.correctAnswer,
                secretKey
              );
              const decryptValue = bytes.toString(CryptoJS.enc.Utf8);

              return {
                ...question,
                question: {
                  ...question.question,
                  correctAnswer: decryptValue,
                },
                mapId: question._id,
              };
            });
            const decrypt = decryptedQuestions.map((question: any) => ({
              ...question.question,
              mapId: question._id,
            }));
            setQuestionIds(decrypt.map((question: any) => question._id));
            setTotalQuestion(decrypt);
          } else {
            setTotalQuestion([{ question: "" }]);
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [templateId]);

  function editquestionCreation(response: any, index: number) {
    setTotalQuestion((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];
      if (updatedQuestions[index]) {
        updatedQuestions[index] = { ...response };
      } else {
        console.error("Index out of bounds: ", index);
      }
      return updatedQuestions;
    });
  }

  const handleQuestionUpdate = (
    id: number,
    quizUpdateByAddQuestion?: boolean
  ) => {
    const question = totalQuestion[currentQuestionIndex];

    const encryptedCorrectAnswer = CryptoJS.AES.encrypt(
      question.correctAnswer,
      secretKey
    ).toString();
    const encryptedQuestion = {
      ...question,
      correctAnswer: encryptedCorrectAnswer,
    };
    let payload: any;
    payload = {
      question: encryptedQuestion?.question,
      options: encryptedQuestion?.options,
      correctAnswer: encryptedQuestion?.correctAnswer,
      questionImg: encryptedQuestion?.questionImg,
      questionType: encryptedQuestion?.questionType,
    };
    updateQuestions(totalQuestion[currentQuestionIndex]._id, payload)
      .then((response) => {
        if (!quizUpdateByAddQuestion) {
          setIsSuccessMessageVisible(true);
          setMessage("Question updated successfully");
          setTimeout(() => {
            setIsSuccessMessageVisible(false);
          }, 3000);
        }
      })
      .catch((error) => console.error(error));
  };

  const handleInputChange = (event: any) => {
    setNewCategory(event.target.value);
  };

  const handleAddCategory = () => {
    const payload = {
      category_name: newCategory,
    };
    if (newCategory.trim() !== "") {
      createCategory(payload)
        .then((response) => {
          setNewCategoryItem(response);
          setNewCategory("");
          setIsAdding(false);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  useEffect(() => {
    if (
      totalQuestion.length === 0 ||
      (totalQuestion.length === 1 && totalQuestion[0].question === "")
    ) {
      setQuestionIds([]);
    }
  }, [totalQuestion]);

  const handlePreview = () => {
    try {
      const id = templateId || quizID;
      if (id) {
        navigate(`/host/quiz-detail/${id}`);
      }
    } catch (error) {
      console.error("Failed to navigate to quiz detail:", error);
    }
  };
  
  return (
    <div className="max-w-screen-2xl mx-auto  w-full">
      <Helmet>
        <title> Create a Quiz</title>
        <meta
          name="description"
          content="Create your own quiz with Quiz Circles and add your quiz to our platform."
        />
      </Helmet>
      <StructuredData data={sharedStructuredData} />
      <div className="w-full sticky top-0 z-10 font-[inter]">
        <Navbar
          showPreviewButton={
            (templateId && questionIds.length > 0) ||
            (quizID && questionIds.length > 0)
          }
          handlePreview={handlePreview}
          lgPaddingHome="[30px]"
          xlPaddingHome="[80px]"
          lgGapSizeHome="2"
          pxCreate="4"
          templateDetailsMobileView={false}
          showmenuIcon={true}
        />
      </div>

      {isChatBoxOpen && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-75 z-[9999999]">
          <ChatBox
            closeChatBox={() => setIsChatBoxOpen(false)}
            onSelectQuestions={handleSelectQuestions}
          />
        </div>
      )}
      <CustomAlert
        message={alertMessage}
        showAlert={showAlert}
        setShowAlert={setShowAlert}
      />
      {/* container */}
      <div className="font-[inter] w-full bg-[#F8F9FC] lg:flex lg:flex-col h-full fixed ">
        <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex-col justify-center items-center h-full py-[10px] w-[100%] px-[10px] sm:px-12 bg-transparent  pt-[90px] max-w-screen-2xl mx-auto">
          {/* l&r */}
          <div className="lg:flex flex-1 overflow-y-auto overflow-hidden h-full no-scrollbar">
            {/* responsive top */}
            <div className="lg:w-[30%] rounded-[6px] shadow-md border-[#E9E9E9] border flex flex-col lg:hidden p-4 ">
              <div className="flex justify-around p-2 border-[1px] rounded-[6px] border-[#D7D7D7] overflow-y-hidden bg-[#f8fdfe] ">
                {/* Questions tab */}
                <div
                  className={`text-neutral-500  text-lg font-semibold flex items-center justify-center cursor-pointer w-1/2 ${
                    activeTab === "questions"
                      ? "rounded-[6px] border bg-gradient-to-r from-[#1EC6DA] to-[#6F2A96]  text-white"
                      : "text-[#777777] bg-transparent"
                  } `}
                  onClick={() => handleTabChange("questions")}
                >
                  <h1 className="font-[inter] text-[18px]">Questions</h1>
                </div>

                {/* Settings tab */}

                <div
                  className={`text-neutral-500  text-lg font-semibold  flex items-center justify-center px-5 py-3 cursor-pointer w-1/2 ${
                    activeTab === "settings"
                      ? "rounded-[6px] border bg-gradient-to-r from-[#1EC6DA] to-[#6F2A96] text-white"
                      : "text-[#777777] bg-transparent"
                  }`}
                  onClick={() => handleTabChange("settings")}
                >
                  <h1 className="font-[inter] text-[18px]">Settings</h1>
                </div>
              </div>
              <div className="flex-1 h-screen overflow-hidden">
                <div className=" h-full overflow-y-auto py-3">
                  {activeTab === "settings" && (
                    <div className="hover:rounded px-3 py-6 rounded-md mb-4 cursor-pointer font-[inter] text-[18px]">
                      <h1 className="font-bold">SETTINGS</h1>
                      {templateId ? (
                        <p className="text-[14px]">
                          quiz name, cover image
                          {superHost === "admin" &&
                            ", total questions, price, category"}
                          {superHost === "host" && ", total questions"}
                        </p>
                      ) : (
                        <p className="text-[14px]">
                          quiz name, cover image
                          {superHost === "admin" && ", price, category"}
                        </p>
                      )}
                    </div>
                  )}

                  {/* //mobile */}

                  <DragDropContext onDragEnd={onDragEnd}>
                    {activeTab === "questions" && (
                      <Droppable droppableId="questions">
                        {(provided: any) => (
                          <div
                            className="no-scrollbar h-full overflow-y-auto space-y-2"
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                          >
                            {totalQuestion.map(
                              (question: any, index: number) => (
                                <Draggable
                                  key={question._id}
                                  draggableId={question._id}
                                  index={index}
                                >
                                  {(provided: any) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      className="w-full"
                                    >
                                      <div
                                        className={`flex items-center px-3 py-4 rounded-[12px] cursor-pointer w-full border border-[#D7D7D7] ${
                                          index === currentQuestionIndex
                                            ? "bg-gradient-to-r from-[#1EC6DA] to-[#6F2A96] text-white"
                                            : ""
                                        }`}
                                        onClick={() =>
                                          handleQuestionClick(index)
                                        }
                                      >
                                        <img
                                          src={tick}
                                          alt="Drag"
                                          {...provided.dragHandleProps}
                                          className="cursor-pointer mr-2  w-7
                        h-7"
                                        />
                                        <h1 className="font-[inter] text-[18px] truncate">
                                          {index + 1}. {question.question}
                                        </h1>
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              )
                            )}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    )}
                  </DragDropContext>
                </div>
              </div>
            </div>
            <div className="lg:flex justify-between p-5 w-full overflow-y-auto bg-white shadow-[#00000012]">
              {/* left */}
              <div className="lg:w-[30%] rounded-[6px] shadow-md border-[#E9E9E9] border lg:flex flex-col hidden p-4 overflow-y-auto overflow-hidden">
                <div className="flex justify-around p-2 border-[1px] rounded-[6px] border-[#D7D7D7] bg-[#f8fdfe]">
                  {/* Questions tab */}
                  <div
                    className={`text-neutral-500  text-lg font-semibold flex items-center justify-center cursor-pointer w-1/2 ${
                      activeTab === "questions"
                        ? "rounded-[6px] border bg-gradient-to-r from-[#1EC6DA] to-[#6F2A96]  text-white"
                        : "text-[#777777] bg-transparent"
                    }`}
                    onClick={() => handleTabChange("questions")}
                  >
                    <h1 className="font-[inter] text-[18px]">Questions</h1>
                  </div>
                  {/* Settings tab */}
                  <div
                    className={`text-neutral-500  text-lg font-semibold flex items-center justify-center px-5 py-3 cursor-pointer w-1/2 ${
                      activeTab === "settings"
                        ? "rounded-[6px] border bg-gradient-to-r from-[#1EC6DA] to-[#6F2A96]  text-white"
                        : "text-[#777777] bg-transparent"
                    }`}
                    onClick={() => handleTabChange("settings")}
                  >
                    <h1 className="font-[inter] text-[18px]">Settings</h1>
                  </div>
                </div>
                <div className="flex-1 h-screen overflow-hidden">
                  <div className=" h-full overflow-y-auto py-3">
                    {activeTab === "settings" && (
                      <div className="hover:rounded px-3 py-6 rounded-md mb-4 cursor-pointer font-[inter] text-[18px]">
                        <h1 className="font-bold">SETTINGS</h1>
                        {templateId ? (
                          <p className="text-[14px]">
                            quiz name, cover image
                            {superHost === "admin" &&
                              ", total questions, price, category"}
                            {superHost === "host" && ", total questions"}
                          </p>
                        ) : (
                          <p className="text-[14px]">
                            quiz name, cover image
                            {superHost === "admin" && ", price, category"}
                          </p>
                        )}
                      </div>
                    )}

                    {/* //desktop */}
                    <DragDropContext onDragEnd={onDragEnd}>
                      {activeTab === "questions" && (
                        <Droppable droppableId="questions">
                          {(provided: any) => (
                            <div
                              className="no-scrollbar h-full overflow-y-auto space-y-2"
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                            >
                              {totalQuestion.map(
                                (question: any, index: number) => (
                                  <Draggable
                                    key={question._id}
                                    draggableId={question._id}
                                    index={index}
                                  >
                                    {(provided: any) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        className="w-full"
                                      >
                                        <div
                                          className={`flex items-center px-3 py-4 rounded-[12px] cursor-pointer w-full border border-[#D7D7D7] ${
                                            index === currentQuestionIndex
                                              ? "bg-gradient-to-r from-[#1EC6DA] to-[#6F2A96] text-white"
                                              : ""
                                          }`}
                                          onClick={() =>
                                            handleQuestionClick(index)
                                          }
                                        >
                                          <img
                                            src={tick}
                                            alt="Drag"
                                            {...provided.dragHandleProps}
                                            className="cursor-pointer mr-2 w-7 h-7"
                                          />
                                          <h1 className="font-[inter] text-[18px] truncate">
                                            {index + 1}. {question.question}
                                          </h1>
                                        </div>
                                      </div>
                                    )}
                                  </Draggable>
                                )
                              )}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      )}
                    </DragDropContext>
                  </div>
                </div>
              </div>
              {/* right */}
              <div className="lg:w-[68%] mt-9 lg:mt-0  lg:flex flex-col overflow-y-auto overflow-hidden ">
                {/* quiz content */}
                {activeTab === "settings" && (
                  <>
                    <div className="flex-1 overflow-y-auto p-4 h-full">
                      <div className="space-y-1 ">
                        <h1>Quiz Name</h1>
                        <input
                          type="text"
                          placeholder="Enter Quiz Title"
                          className="bg-[#B8F7FF08] border border-[#D0D0D0] p-4 rounded-[10px] w-full focus:outline-none focus:ring-0 placeholder:text-zinc-400 placeholder:text-lg placeholder:font-normal"
                          value={name}
                          onChange={(e) => {
                            const newName = e.target.value;
                            setName(newName);
                            if (templateId || quizID) {
                              debouncedUpdate({
                                paramId: quizID || templateId,
                                nameData: newName,
                                imageUrlData: imageUrl,
                                isFreeData: isFree,
                                isPublicData: isPublic,
                              });
                            }
                          }}
                        />
                      </div>
                      <div className="space-y-2 ">
                        <h1 className="mt-4">Quiz Image</h1>
                        <div className="lg:h-[170px] gap-[5%] items-center py-[10px] md:flex w-full ">
                          <div
                            className="lg:w-[300px] w-[200px] h-[170px] relative border-dashed border-[1px] border-[#1FC4D9] rounded-[10px] flex justify-center items-center"
                            onClick={handleImageClick}
                          >
                            {!imageUrl && <ImageUpload type="question" />}
                            {imageUrl && (
                              <img
                                className="w-full h-[150px] rounded-[10px]"
                                src={imageUrl}
                                alt="Template"
                                loading="lazy"
                              />
                            )}

                            <div className="absolute inset-0 flex items-center justify-center rounded-xl">
                              {isimageLoading && <LoaderComponent />}
                              <input
                                type="file"
                                accept="image/*"
                                ref={fileInputRef}
                                onChange={coverFileHandler}
                                className="hidden"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-11">
                        {totalQuestion.length > 0 && templateId && (
                          <div className="space-y-1">
                            <p className="text-[#171717] font-[inter]   text-[18px] leading-normal ">
                              <span className=" w-full text-[16px]">
                                <h1>
                                  Total questions : {totalQuestion.length}{" "}
                                  Questions
                                </h1>
                              </span>
                            </p>
                          </div>
                        )}
                      </div>

                      <div className="mt-4 flex items-center">
                        {superHost === "admin" && (
                          <div className="flex space-x-2">
                            <h1 className="">Payment Type : </h1>
                            <div className="gap-4 md:flex flex-col space-y-1">
                              <div className="flex gap-3  items-center">
                                <div className="flex  items-center gap-3">
                                  <input
                                    type="radio"
                                    name="payment"
                                    checked={isFree === "free"}
                                    className=""
                                    onChange={() => {
                                      const paymentValue = "free";
                                      setIsFree(paymentValue);
                                      if (templateId || quizID) {
                                        debouncedUpdate({
                                          paramId: quizID || templateId,
                                          nameData: name,
                                          imageUrlData: imageUrl,
                                          isFreeData: paymentValue,
                                          isPublicData: isPublic,
                                        });
                                      }
                                    }}
                                  />
                                  <label> Free</label>
                                </div>
                                <div className="flex items-center  gap-3">
                                  <input
                                    type="radio"
                                    name="payment"
                                    checked={isFree === "paid"}
                                    className=" "
                                    onChange={() => {
                                      const paymentValue = "paid";
                                      setIsFree(paymentValue);
                                      if (templateId || quizID) {
                                        debouncedUpdate({
                                          paramId: quizID || templateId,
                                          nameData: name,
                                          imageUrlData: imageUrl,
                                          isFreeData: paymentValue,
                                          isPublicData: isPublic,
                                        });
                                      }
                                    }}
                                  />
                                  <label> Paid</label>
                                </div>
                              </div>
                              <div className=" space-y-1 items-center">
                                {isFree === "paid" && (
                                  <input
                                    className="border border-gray-400 font-[inter] p-2 rounded-[10px] px-2 font-bold  text-black focus:outline-none focus:ring-0 focus:border-gray-400 placeholder:text-zinc-400 placeholder:text-lg placeholder:font-normal"
                                    type="text"
                                    placeholder="$ Enter price"
                                    value={price}
                                    onChange={(e) => setPrice(e.target.value)}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="mt-4 flex items-center">
                        {superHost === "admin" && (
                          <div className="flex space-x-2">
                            <h1 className="">Visibility : </h1>
                            <div className="flex gap-3  items-center">
                              <div className="flex items-center  gap-3">
                                <input
                                  type="radio"
                                  name="visibility"
                                  checked={isPublic === "Private"}
                                  className=" "
                                  onChange={(e) => {
                                    const visbility = "Private";
                                    setIsPublic(visbility);
                                    if (templateId || quizID) {
                                      debouncedUpdate({
                                        paramId: quizID || templateId,
                                        nameData: name,
                                        imageUrlData: imageUrl,
                                        isFreeData: isFree,
                                        isPublicData: visbility,
                                      });
                                    }
                                  }}
                                />
                                <label> Private</label>
                              </div>
                              <div className="flex  items-center gap-3">
                                <input
                                  type="radio"
                                  name="visibility"
                                  checked={isPublic === "Public"}
                                  className=""
                                  onChange={(e) => {
                                    const visbility = "Public";
                                    setIsPublic(visbility);
                                    if (templateId || quizID) {
                                      debouncedUpdate({
                                        paramId: quizID || templateId,
                                        nameData: name,
                                        imageUrlData: imageUrl,
                                        isFreeData: isFree,
                                        isPublicData: visbility,
                                      });
                                    }
                                  }}
                                />
                                <label> Public</label>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
                {activeTab === "questions" && (
                  <>
                    <div className="flex-1 overflow-y-auto no-scrollbar xl:p-4 lg:p-[8px] p-2">
                      <div className="lg:flex items-center justify-center w-full">
                        <>
                          {totalQuestion.map((question, index) => {
                            const currentQuestion =
                              totalQuestion[currentQuestionIndex]?.question;

                            return (
                              <React.Fragment key={index}>
                                {index === currentQuestionIndex && (
                                  <div className="w-full">
                                    {question._id === undefined ||
                                    question === "" ? (
                                      <DropDownOption
                                        key={index}
                                        question={question}
                                        response={(response) => {
                                          questionCreation(response, index);
                                        }}
                                        onDelete={() => {
                                          setIndexToDelete(index);
                                          setShowConfirmation(true);
                                        }}
                                        index={index}
                                        handleAddCategory={() => {
                                          setIsAdding(true);
                                        }}
                                        newCategoryItem={newCategoryItem}
                                      />
                                    ) : (
                                      <>
                                        <EditDropDownOption
                                          question={question}
                                          onDelete={() => {
                                            setIndexToDelete(index);
                                            setShowConfirmation(true);
                                            setDeleteId(question?.mapId);
                                          }}
                                          response={(response) =>
                                            editquestionCreation(
                                              response,
                                              index
                                            )
                                          }
                                          index={index}
                                          handleCategorySelection={(id) => {
                                            totalQuestion[index] = {
                                              ...totalQuestion[index],
                                              category_id: id,
                                            };
                                          }}
                                          handleAddCategory={() => {
                                            setIsAdding(true);
                                          }}
                                          newCategoryItem={newCategoryItem}
                                        />

                                        {currentQuestion === "" ||
                                        currentQuestion ? (
                                          <>
                                            {question?.questionType ===
                                              "TEXT" &&
                                            question?.answerType === "TEXT" ? (
                                              <EditTextTypeComponent
                                                readOnly={
                                                  question.isEditable ===
                                                    true ||
                                                  (question?.isEditable ===
                                                    false &&
                                                    superHost === "admin")
                                                    ? false
                                                    : true
                                                }
                                                questionData={question}
                                                response={(responseData) =>
                                                  editquestionCreation(
                                                    responseData,
                                                    index
                                                  )
                                                }
                                                onQuestionsCleared={() => {}}
                                              />
                                            ) : question?.questionType ===
                                                "IMAGE_TEXT" &&
                                              question?.answerType ===
                                                "TEXT" ? (
                                              <EditImageTextTypeComponents
                                                readOnly={
                                                  question.isEditable ===
                                                    true ||
                                                  (question?.isEditable ===
                                                    false &&
                                                    superHost === "admin")
                                                    ? false
                                                    : true
                                                }
                                                questionData={question}
                                                response={(responseData) =>
                                                  editquestionCreation(
                                                    responseData,
                                                    index
                                                  )
                                                }
                                              />
                                            ) : question?.questionType ===
                                                "IMAGE_TEXT" &&
                                              question?.answerType ===
                                                "IMAGE" ? (
                                              <EditImageImageTypeComponents
                                                readOnly={
                                                  question.isEditable ===
                                                    true ||
                                                  (question?.isEditable ===
                                                    false &&
                                                    superHost === "admin")
                                                    ? false
                                                    : true
                                                }
                                                questionData={question}
                                                response={(responseData) =>
                                                  editquestionCreation(
                                                    responseData,
                                                    index
                                                  )
                                                }
                                              />
                                            ) : question?.questionType ===
                                                "TEXT" &&
                                              question?.answerType ===
                                                "IMAGE" ? (
                                              <EditTextImageTypeComponent
                                                readOnly={
                                                  question.isEditable ===
                                                    true ||
                                                  (question?.isEditable ===
                                                    false &&
                                                    superHost === "admin")
                                                    ? false
                                                    : true
                                                }
                                                questionData={question}
                                                response={(responseData) =>
                                                  editquestionCreation(
                                                    responseData,
                                                    index
                                                  )
                                                }
                                              />
                                            ) : (question?.questionType ===
                                                "IMAGE_TEXT" ||
                                                question?.questionType ===
                                                  "TEXT") &&
                                              question?.answerType ===
                                                "BOOLEAN" ? (
                                              <EditTrueOrFalse
                                                readOnly={
                                                  question.isEditable ===
                                                    true ||
                                                  (question?.isEditable ===
                                                    false &&
                                                    superHost === "admin")
                                                    ? false
                                                    : true
                                                }
                                                questionData={question}
                                                response={(responseData) =>
                                                  editquestionCreation(
                                                    responseData,
                                                    index
                                                  )
                                                }
                                              />
                                            ) : null}
                                          </>
                                        ) : null}
                                      </>
                                    )}
                                  </div>
                                )}
                              </React.Fragment>
                            );
                          })}
                        </>
                      </div>
                    </div>
                  </>
                )}
                {activeTab === "questions" && (
                  <div className="bg-white flex-wrap gap-2 border-gray-300 text-center py-3 flex justify-between w-full bottom-0 z-10 lg:relative lg:w-auto lg:bottom-auto lg:z-auto px-3 lg:px-5  ">
                    <div className="">
                      <button
                        className="flex md:text-[20px] md:font-[500] gap-[10px] md:px-6 px-5 py-2 h-full rounded-[6px] bg-red-400 border border-none text-white items-center justify-center  hover:shadow-[0px_20px_20px_-10px_rgba(131,42,184,0.4)] hover:inner-shadow-[inset 0px 20px 20px -10px rgba(131,42,184,0.4)]"
                        onClick={() => navigate(-1)}
                      >
                        Close
                      </button>
                    </div>
                    <div className="sm:hidden flex">
                      {(!questionIds.includes(
                        totalQuestion[currentQuestionIndex]?._id
                      ) ||
                        (questionIds.includes(
                          totalQuestion[currentQuestionIndex]?._id
                        ) &&
                          totalQuestion[currentQuestionIndex]?.isEditable !==
                            false) ||
                        superHost === "admin") && (
                        <button
                          className="flex md:text-[20px] md:font-[500] gap-[10px] md:px-6 px-5 py-2 rounded-[6px] bg-[#65c15d] border border-none text-white items-center justify-center hover:shadow-[0px_20px_20px_-10px_rgba(131,42,184,0.4)] hover:inner-shadow-[inset 0px 20px 20px -10px rgba(131,42,184,0.4)]"
                          onClick={() => {
                            if (
                              !questionIds.includes(
                                totalQuestion[currentQuestionIndex]?._id
                              )
                            ) {
                              handleQuestionSave(currentQuestionIndex);
                            } else {
                              handleQuestionUpdate(currentQuestionIndex);
                            }
                          }}
                        >
                          {!questionIds.includes(
                            totalQuestion[currentQuestionIndex]?._id
                          )
                            ? "Save"
                            : "Update"}
                        </button>
                      )}
                    </div>

                    <div className="sm:hidden flex">
                      <button
                        className="flex md:text-[20px] md:font-[500] gap-[10px] md:px-6 px-5 py-2  rounded-[6px] bg-gradient-to-r from-[#1EC6DA] to-[#6F2A96] border border-none text-white items-center justify-center  hover:shadow-[0px_20px_20px_-10px_rgba(131,42,184,0.4)] hover:inner-shadow-[inset 0px 20px 20px -10px rgba(131,42,184,0.4)]"
                        onClick={handleAddQuestion}
                      >
                        <svg
                          className="w-5 h-5 "
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M12 4v16m8-8H4"
                          ></path>
                        </svg>
                        <span className="md:hidden sm:flex hidden">
                          Question
                        </span>
                        <span className="hidden md:inline">Add Question</span>
                      </button>
                    </div>

                    <div className="sm:hidden flex">
                      {activeTab === "questions" && (
                        <div className=" z-10 ">
                          <img
                            src={QuestionBank}
                            alt="QuizChatImage"
                            className="rounded-[6px] cursor-pointer z-50"
                            onClick={openChatBox}
                          />
                        </div>
                      )}
                    </div>

                    <div className=" sm:flex lg:justify-end lg:space-x-4 space-x-2 hidden">
                      {(!questionIds.includes(
                        totalQuestion[currentQuestionIndex]?._id
                      ) ||
                        (questionIds.includes(
                          totalQuestion[currentQuestionIndex]?._id
                        ) &&
                          totalQuestion[currentQuestionIndex]?.isEditable !==
                            false) ||
                        superHost === "admin") && (
                        <button
                          className="flex md:text-[20px] md:font-[500] gap-[10px] md:px-6 px-5 py-2 rounded-[6px] bg-[#65c15d] border border-none text-white items-center justify-center hover:shadow-[0px_20px_20px_-10px_rgba(131,42,184,0.4)] hover:inner-shadow-[inset 0px 20px 20px -10px rgba(131,42,184,0.4)]"
                          onClick={() => {
                            if (
                              !questionIds.includes(
                                totalQuestion[currentQuestionIndex]?._id
                              )
                            ) {
                              handleQuestionSave(currentQuestionIndex);
                            } else {
                              handleQuestionUpdate(currentQuestionIndex);
                            }
                          }}
                        >
                          {!questionIds.includes(
                            totalQuestion[currentQuestionIndex]?._id
                          )
                            ? "Save"
                            : "Update"}
                        </button>
                      )}
                      <button
                        className="flex md:text-[20px] md:font-[500] gap-[10px] md:px-6 px-5 py-2  rounded-[6px] bg-gradient-to-r from-[#1EC6DA] to-[#6F2A96] border border-none text-white items-center justify-center  hover:shadow-[0px_20px_20px_-10px_rgba(131,42,184,0.4)] hover:inner-shadow-[inset 0px 20px 20px -10px rgba(131,42,184,0.4)]"
                        onClick={handleAddQuestion}
                      >
                        <svg
                          className="w-5 h-5 "
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M12 4v16m8-8H4"
                          ></path>
                        </svg>
                        <span className="md:hidden sm:flex hidden">
                          Question
                        </span>
                        <span className="hidden md:inline">Add Question</span>
                      </button>

                      {activeTab === "questions" && (
                        <div className=" z-10 ">
                          {/* <h1 className="text-white flex items-center justify-center h-full font-sans font-[400] leading-4">QA</h1> */}
                          <img
                            src={QuestionBank}
                            alt="QuizChatImage"
                            className="rounded-[6px] cursor-pointer z-50"
                            onClick={openChatBox}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {activeTab === "settings" && (
                  <div className="bg-white  border-gray-300 text-center py-3 flex justify-between space-x-4 px-5 w-full bottom-0 z-10 lg:relative lg:w-auto lg:bottom-auto lg:z-auto">
                    <button
                      className="flex md:text-[20px] md:font-[500] gap-[10px] md:px-6 px-5 py-2  rounded-[6px] bg-red-400 border border-none text-white items-center justify-center hover:shadow-[0px_20px_20px_-10px_rgba(131,42,184,0.4)] hover:inner-shadow-[inset 0px 20px 20px -10px rgba(131,42,184,0.4)]"
                      onClick={() => navigate(-1)}
                    >
                      Close
                    </button>
                    <button
                      className="flex md:text-[20px] md:font-[500] gap-[10px] md:px-6 px-5 py-2 rounded-[6px] bg-[#65c15d] border border-none text-white items-center justify-center  hover:shadow-[0px_20px_20px_-10px_rgba(131,42,184,0.4)] hover:inner-shadow-[inset 0px 20px 20px -10px rgba(131,42,184,0.4)]"
                      onClick={() => {
                        if (templateId || quizID) {
                          handleQuizUpdate();
                        } else {
                          handleQuizSave();
                        }
                      }}
                    >
                      {templateId || quizID ? "Update" : "Save"}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          {showConfirmation && (
            <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-[inter]">
              <div className="lg:w-fit w-full h-auto min-h-[150px] flex flex-col md:max-w-[360px] min-w-[300px] px-2 md:px-4 bg-slate-50 rounded-lg shadow-lg py-2 items-center justify-center border border-slate-400">
                <div className="flex w-full items-center justify-center my-3 text-[16px] md:text-[18px] font-bold text-center text-black">
                  Do you want to Delete this Question?
                </div>
                <div className="flex flex-row w-full justify-between gap-x-2">
                  <button
                    className="bg-slate-800 hover:bg-slate-500 rounded-[12px] bg-gradient-to-r from-[#1EC6DA] to-[#6F2A96] border border-none text-white  lg:text-[23px] text-[16px] font-bold hover:shadow-[0px_20px_20px_-10px_rgba(131,42,184,0.4)] hover:inner-shadow-[inset 0px 20px 20px -10px rgba(131,42,184,0.4)]  rounded-md text-white font-[inter] text-base font-normal px-4 py-2 w-full "
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDelete();
                    }}
                  >
                    Yes
                  </button>
                  <button
                    className="bg-slate-800 hover:bg-slate-500 rounded-[12px] bg-gradient-to-r from-[#1EC6DA] to-[#6F2A96] border border-none text-white  lg:text-[23px] text-[16px] font-bold hover:shadow-[0px_20px_20px_-10px_rgba(131,42,184,0.4)] hover:inner-shadow-[inset 0px 20px 20px -10px rgba(131,42,184,0.4)]  rounded-md text-white font-[inter] text-base font-normal px-4 py-2 w-full "
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowConfirmation(false);
                    }}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {isSuccessMessageVisible && (
        <div className="fixed top-[80px] left-1/2 transform -translate-x-1/2 bg-green-500 text-white p-2 rounded shadow-lg z-[999999]">
          {message}
        </div>
      )}
      {noQuestionsMessageVisible && (
        <div className="fixed top-[80px] left-1/2 transform -translate-x-1/2 bg-red-500 text-white p-2 rounded shadow-lg z-[999999]">
          {message}
        </div>
      )}
      {isAdding && (
        <div className="fixed inset-0 z-[999999999] flex justify-center items-center w-full h-screen bg-gray-800 bg-opacity-50 font-[inter]">
          <div className="bg-white p-6 rounded-lg shadow-xl w-full max-w-md">
            <h1 className="text-lg font-semibold mb-4">Category Name:</h1>
            <input
              className="border border-gray-300 rounded px-3 py-2 mb-5 w-full focus:outline-none focus:ring-2 focus:ring-blue-500 placeholder:text-zinc-400 placeholder:text-lg placeholder:font-normal"
              type="text"
              placeholder="Add category"
              value={newCategory}
              onChange={handleInputChange}
              autoFocus
            />
            <div className="flex justify-end">
              <button
                className="bg-blue-600 text-white px-5 py-2 rounded-md mr-3 hover:bg-blue-700 transition duration-300"
                onClick={handleAddCategory}
              >
                Save
              </button>
              <button
                className="bg-gray-300 text-gray-700 px-5 py-2 rounded-md hover:bg-gray-400 transition duration-300"
                onClick={() => setIsAdding(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default QuizCreation;
