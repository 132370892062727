import React, { useEffect, useState } from "react";
import Navbar from "src/components/navBar/NavBar";
import { useNavigate } from "react-router-dom";
import TemplateCard from "src/components/quizCard/QuizCard";
import { getUpcomingGames } from "src/services/upcomingGames/upcomingGames.service";
import moment from "moment";
import LoaderComponent from "src/components/loader/LoaderComponent";
import { Helmet } from "react-helmet";
import PageNotFound from "../../routeConfig/PageNotFound";
import StructuredData from "src/components/structuredData/StructuredData";
import { sharedStructuredData } from "src/components/structuredData/Data";

const UpcomingGames = () => {
  const navigate = useNavigate();
  const [upcomingGames, setUpcomingGames] = useState<any>([]);

  const [upcomingGamesCount, setUpcomingGamesCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isHovered, setIsHovered] = useState<number>();
  const [scrolled, setScrolled] = useState(false);
  const [is404, setIs404] = useState(false);

  // Disable the back button
  useEffect(() => {
    const disableBackButton = () => {
      window.history.pushState(null as any, null as any, document.URL as any);
      window.addEventListener("popstate", () => {
        window.history.pushState(null as any, null as any, document.URL as any);
      });
    };

    disableBackButton();
    return () => {
      window.removeEventListener("popstate", disableBackButton);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    setIsLoading(true);
    getUpcomingGames()
      .then((response) => {
        const data = response.data;

        if (Array.isArray(data)) {
          const currentDate = moment();
          const upcomingData = data.filter((item) => {
            const eventDate = moment(item.selectDateTime);
            const gameExpired = eventDate.isBefore(
              currentDate.subtract(30, "minutes")
            );

            return !gameExpired;
          });

          // Set up the upcoming games
          setUpcomingGames(upcomingData);
          setUpcomingGamesCount(upcomingData.length);
        } else {
          console.error("Invalid data format:", data);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          setIs404(true); // Set 404 state to true
        } else {
          console.error(error);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (is404) {
    return <PageNotFound />; // Render PageNotFound component
  }

  const handleClick = (id: string) => {
    navigate(`/host/gamemode/${id}`);
  };

  return (
    <div
    className="max-w-screen-2xl mx-auto  w-full">
      <Helmet>
        <meta charSet="UTF-8" />
        <title>Upcoming Games</title>
        <meta name="title" content="Upcoming Games | Quiz Circles" />
        <meta
          name="description"
          content="Stay updated with the upcoming games on Quiz Circles. Never miss a game!"
        />
        <meta property="og:title" content="Upcoming Games | Quiz Circles" />
        <meta
          property="og:description"
          content="Stay updated with the upcoming games on Quiz Circles. Never miss a game!"
        />
      </Helmet>
      <StructuredData data={sharedStructuredData} />
      <Navbar
        className={`top-0 ${
          scrolled ? "bg-white z-[99] shadow-[#ABB3DE] shadow-lg " : ""
        }`}
        templateDetailsMobileView={false}
        showmenuIcon={true}
      />
      {isLoading ? (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50">
          <LoaderComponent />
        </div>
      ) : (
        <>
          <div className="w-full h-full bg-[#F8F9FC] overflow-y-auto min-h-screen font-[Poppins]">
            <div className="lg:px-[80px] px-[20px] md:px-[40px] xl:px-[100px] mb-10 ">
              <h1 className="font-[Poppins] text-black text-[18px] font-semibold leading-normal mt-[2%] md:mt-[40px]">
                Scheduled Games ({upcomingGamesCount})
              </h1>
              {upcomingGamesCount === 0 ? (
              <div className="flex items-center justify-center min-h-[60vh]">
                <p className="text-center text-gray-600 text-lg">
                  No upcoming Quizzes are scheduled.
                </p>
              </div>
            ) : (
          
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2  xl:grid-cols-3 md:gap-x-5 lg:gap-x-7 mt-[10px] gap-y-4 mb-8">
                {upcomingGames &&
                  upcomingGames.map((template: any, index: number) => {
                    const eventDate = new Date(template.selectDateTime);
                    const date = eventDate.toLocaleDateString("en-GB");
                    const time = eventDate.toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: true,
                    });

                    return (
                      <button
                        className={`bg-white rounded-[12px] hover:bg-gradient-to-r hover:from-[#1EC6DA] hover:to-[#6F2A96] hover:border-none hover:border-transparent hover:text-white hover:items-center hover:justify-center hover:rounded-[8px] hover:shadow-[0px_20px_20px_-10px_rgba(131,42,184,0.4)] hover:inner-shadow-[inset 0px 20px 20px -10px rgba(131,42,184,0.4)]`}
                        onMouseEnter={() => setIsHovered(index)}
                        onMouseLeave={() => setIsHovered(-1)}
                        onClick={() => {
                          handleClick(template.id);
                        }}
                        key={index}
                      >
                        <TemplateCard
                          id={template.id}
                          imageUrl={template.imageUrl}
                          name={template.quizName}
                          date={date}
                          time={time}
                          isHovered={isHovered === index ? true : false}
                          deletedId={(id) => {
                            const remainingGames = upcomingGames.filter(
                              (card: any, index: any) => card.id !== id
                            );
                            setUpcomingGames(remainingGames);
                            setUpcomingGamesCount(remainingGames.length);
                          }}
                        />
                      </button>
                    );
                  })}
              </div>
                  )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default UpcomingGames;
