import instance from "src/http/httpClient";

// demo quiz
export const demoData = (): Promise<any> => {
  return instance
    .get<any>(`/host/demo`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

// Admin demo
export const adminDemoData = (
 params:any
): Promise<any> => {

  return instance
    .get<any>(`/host/admin/demo`,{ params })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};
