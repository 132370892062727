import React, { FC, useEffect, useState } from "react";
import Delete from "src/assets/createPageDelete.svg";
import Category from "../category/Category";

type DropDownOptionProp = {
  response: (data: any) => void;
  question: any;
  onDelete: () => void;
  index: number;
  readOnly?: boolean;
  handleCategorySelection: (id: string) => void;
  newCategoryItem: any;
  handleAddCategory: () => void;
};

const EditDropDownOption: FC<DropDownOptionProp> = ({
  question,
  onDelete,
  index,
  handleCategorySelection,
  newCategoryItem,
  handleAddCategory,
}) => {
  const [selectedOption, setSelectedOption] = useState<string>("Text");
  const [questionText, setQuestionText] = useState<string>("");
  const getSuperHost = localStorage.getItem("superHost") || "";
  const superHost = getSuperHost.replace(/["']/g, "");
  const [questionData, setQuestionData] = useState<any>();

  useEffect(() => {
    if (question) {
      setQuestionText(question.question || "");

      setQuestionData(question);
    }
  }, [question]);

  useEffect(() => {
    if (question.questionType === "TEXT" && question.answerType === "IMAGE") {
      setSelectedOption("Text and Image");
    } else if (
      question.answerType === "TEXT" &&
      question.questionType === "TEXT"
    ) {
      setSelectedOption("Text");
    } else if (
      question.answerType === "IMAGE" &&
      question.questionType === "IMAGE_TEXT"
    ) {
      setSelectedOption("Double Image");
    } else if (
      question.answerType === "BOOLEAN" &&
      question.questionType === "IMAGE_TEXT"
    ) {
      setSelectedOption("True or False");
    } else if (
      question.answerType === "BOOLEAN" &&
      question.questionType === "TEXT"
    ) {
      setSelectedOption("True or False");
    } else if (
      question.answerType === "TEXT" &&
      question.questionType === "IMAGE_TEXT"
    ) {
      setSelectedOption("Image and Text");
    }
  }, [question]);

  return (
    <div className="w-full ">
      <header
        className={`${
          superHost === "admin"
            ? "top-0 flex flex-col w-full lg:mt-[20px] xl:mt-0 items-center"
            : "top-0 w-full flex items-center justify-between"
        }`}
      >
        <div
          className={`${
            superHost === "admin"
              ? "w-full md:flex hidden"
              : "flex-grow overflow-hidden"
          }`}
        >
          <h1 className="rounded-md mb-4 cursor-pointer truncate font-[inter] text-[20px]">
            {index + 1}. {questionText}
          </h1>
        </div>
        <div
          className={`${
            superHost === "admin"
              ? "flex sm:justify-between justify-end w-full sm:items-center items-end sm:flex-row flex-col sm:gap-0 gap-4"
              : "flex justify-end items-center flex-shrink-0"
          }`}
        >
          {superHost === "admin" && (
            <section>
              <Category
                selectedCategoryId={handleCategorySelection}
                existingCategoryId={
                  questionData?.category_id || question?.category_id
                }
                newCategoryItem={newCategoryItem}
                handleAddCategory={handleAddCategory}
              />
            </section>
          )}
          <div className="flex items-center gap-2">
            <div className="text-black font-[inter] text-center lg:text-[18px] text-[14px] font-[400] leading-normal rounded-[6px] px-2 py-2 bg-[#f8fdfe] border border-[#0A687426] transition duration-300 ease-in-out w-[180px]">
              {selectedOption === "Text" && <span>Text</span>}
              {selectedOption === "Image and Text" && (
                <span>Image and Text</span>
              )}
              {selectedOption === "True or False" && <span>True or False</span>}
              {selectedOption === "Double Image" && <span>Double Image</span>}
              {selectedOption === "Text and Image" && (
                <span>Text and Image</span>
              )}
            </div>

            <button
              onClick={onDelete}
              className="hover:shadow-[0px_20px_20px_-10px_rgba(131,42,184,0.4)] hover:inner-shadow-[inset 0px 20px 20px -10px rgba(131,42,184,0.4)] rounded-full"
            >
              <img src={Delete} alt="Delete" className="object-fit" />
            </button>
          </div>
        </div>
      </header>
    </div>
  );
};

export default EditDropDownOption;
