import React, { useEffect, useState, useCallback } from "react";
import Navbar from "src/components/navBar/NavBar";
import {
  getMyLibrary,
  deleteMyLibrary,
} from "src/services/myLibrary/myLibrary.service";
import TemplateCard from "src/components/quizCard/QuizCard";
import { useNavigate } from "react-router-dom";
import PlusIcon from "src/assets/PlusIcon.svg";
import LoaderComponent from "src/components/loader/LoaderComponent";
import { Helmet } from "react-helmet";
import StructuredData from "src/components/structuredData/StructuredData";
import { sharedStructuredData } from "src/components/structuredData/Data";

const MyLibrary: React.FC = () => {
  const navigate = useNavigate();
  const limit = 6;
  const [myLibrary, setMyLibrary] = useState<any[]>([]);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [templateToDelete, setTemplateToDelete] = useState<any | null>(null);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState<number>(0);
  const [hasData, setHasData] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [scrolled, setScrolled] = useState(false);
  const [isHovered, setIsHovered] = useState<number>();

  const fetchLibrary = useCallback(async () => {
    const params = { limit, page };
    setIsLoading(true);
    try {
      const data = await getMyLibrary(params);
      setCount(data.count);
      if (page === 1) {
        setMyLibrary(data.quiz);
      } else {
        setMyLibrary((prevDetails) => [...prevDetails, ...data.quiz]);
      }
      setHasData(data.quiz.length > 0 && page * limit < data.count);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [page]);

  useEffect(() => {
    fetchLibrary();
  }, [fetchLibrary, page]);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 0);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleTemplateClick = (template: any) => {
    sessionStorage.setItem("template", JSON.stringify(template._id));
    navigate(`/create-quiz?templateId=${template._id}`);
  };

  const handleDelete = (_id: string) => {
    setTemplateToDelete(myLibrary.find((template) => template._id === _id));
    setIsDeleteDialogOpen(true);
  };

  const confirmDelete = () => {
    setIsDeleteDialogOpen(false);
    deleteMyLibrary(templateToDelete?._id)
      .then(() => {
        setMyLibrary((prevLibrary) =>
          prevLibrary.filter(
            (template) => template._id !== templateToDelete?._id
          )
        );
      })
      .catch((error) => {
        console.error("Error deleting template:", error);
      });
  };

  const cancelDelete = () => {
    setIsDeleteDialogOpen(false);
    setTemplateToDelete(null);
  };

  const handlePlusIconClick = () => {
    navigate("/create-quiz");
  };

  const loadMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  return (

    <div className="font-[poppins] max-w-screen-2xl mx-auto  w-full ">
      <Helmet>
        <meta charSet="UTF-8" />
        <title>My Library</title>
        <meta name="title" content="My Library | Quiz Circles" />
        <meta
          name="description"
          content="Access and manage your saved quizzes and templates in My Library on Quiz Circles."
        />
        <meta property="og:title" content="My Library | Quiz Circles" />
        <meta
          property="og:description"
          content="Access and manage your saved quizzes and templates in My Library on Quiz Circles."
        />
      </Helmet>
      <StructuredData data={sharedStructuredData} />
      <Navbar
        className={`top-0 ${
          scrolled ? "bg-white z-[99] shadow-[#ABB3DE] shadow-lg " : ""
        }`}
        templateDetailsMobileView={false}
        showmenuIcon={true}
      />
      {myLibrary.length >= 0 && (
        <div className="w-full h-full bg-white overflow-y-auto min-h-screen relative">
          <div
            className={`lg:px-[80px] px-[20px] md:px-[40px] xl:px-[100px] sm:px-12 mb-10`}
          >
            <div className="flex justify-between items-center">
              <div>
                <h1 className="font-[Poppins] text-black text-[18px] lg:text-[22px] font-extrabold leading-normal md:mt-[30px] mt-[2%]">
                  My Library
                </h1>
              </div>
              {count === 0 && (
                <button
                  className={`flex items-center gap-[10px] lg:px-[32px] lg:py-[17px] px-4 py-2 rounded-[12px] bg-gradient-to-r from-[#1EC6DA] to-[#6F2A96] border border-none text-white justify-center hover:shadow-[0px_20px_20px_-10px_rgba(131,42,184,0.4)] hover:inner-shadow-[inset 0px 20px 20px -10px rgba(131,42,184,0.4)]`}
                  onClick={handlePlusIconClick}
                >
                  <div className="w-[18px] h-[18px]">
                    <img
                      src={PlusIcon}
                      alt="Create Quiz"
                      className={`transition-transform duration-300 flex items-center ${
                        isHovered ? "filter brightness-0 invert" : ""
                      }`}
                    />
                  </div>
                  <div>
                    <p className="lg:text-[18px] text-[16px] font-[400] text-white">
                      Create Quiz
                    </p>
                  </div>
                </button>
              )}
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-3 md:gap-x-5 lg:gap-x-7 md:mt-[50px]  mt-[10px]  gap-y-4 mb-8">
              {myLibrary.map((template: any, index: number) => (
                <button
                  className="relative"
                  onClick={() => handleTemplateClick(template)}
                  key={index}
                >
                  <div className="relative">
                    <div
                      className={`bg-white rounded-[12px] hover:bg-gradient-to-r hover:from-[#1EC6DA] hover:to-[#6F2A96] hover:border-none hover:border-transparent hover:text-white hover:items-center hover:justify-center hover:rounded-[8px] hover:shadow-[0px_20px_20px_-10px_rgba(131,42,184,0.4)] hover:inner-shadow-[inset 0px 20px 20px -10px rgba(131,42,184,0.4)]`}
                      onMouseEnter={() => setIsHovered(index)}
                      onMouseLeave={() => setIsHovered(-1)}
                    >
                      <TemplateCard
                        id={template._id}
                        imageUrl={template.imageUrl}
                        name={template.name}
                        questions={template.questionCount}
                        played={template.played}
                        deletedId={() => handleDelete(template._id)}
                        isHovered={isHovered === index}
                        deleteLibrary={true}
                      />
                    </div>
                    {isDeleteDialogOpen &&
                      templateToDelete?._id === template._id && (
                        <div
                          className={`fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-[Poppins] 2xl:z-20 md:z-20  z-10`}
                        >
                          <div className="lg:w-fit w-full h-auto min-h-[150px] flex flex-col md:max-w-[360px] min-w-[300px] px-2 md:px-4 bg-slate-50 rounded-lg shadow-lg py-2 items-center justify-center border border-slate-400 z-20">
                            <div className="flex w-full items-center justify-center my-3 text-[16px] md:text-[18px] font-bold text-center text-black">
                              Do you want to Delete "{templateToDelete.name}"?
                            </div>
                            <div className="flex flex-row w-full justify-between gap-x-2">
                              <button
                                className="bg-slate-800 hover:bg-slate-500 w-full py-2 rounded-full text-white"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  confirmDelete();
                                }}
                              >
                                Yes
                              </button>
                              <button
                                className="bg-slate-800 hover:bg-slate-500 w-full py-2 rounded-full text-white"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  cancelDelete();
                                }}
                              >
                                No
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                </button>
              ))}
            </div>
            {hasData && (
              <div className="flex justify-center">
                <button
                  className={`flex items-center justify-center gap-[10px] px-[32px] py-[17px] z-10 rounded-[12px] bg-gradient-to-r from-[#1EC6DA] to-[#6F2A96] border border-none text-white  hover:shadow-[0px_20px_20px_-10px_rgba(131,42,184,0.4)] hover:inner-shadow-[inset 0px 20px 20px -10px rgba(131,42,184,0.4)]`}
                  onClick={loadMore}
                >
                  Load More
                </button>
              </div>
            )}
          </div>
        </div>
      )}

      {isLoading && (
        <div className="fixed inset-0 flex items-center justify-center  z-50">
          <LoaderComponent />
        </div>
      )}
    </div>
  );
};

export default MyLibrary;
