import React from "react";
import { Provider } from "react-redux";
import "./App.css";
import Routers from "./routeConfig";
import { store } from "./redux/store";
import "@fontsource/poppins";
import '@fontsource/inter'
import '@fontsource/urbanist'; 
import '@fontsource/outfit'

function App() {
  return (
    <>
      <Provider store={store}>
        <Routers />
      </Provider>
    </>
  );
}
export default App;
