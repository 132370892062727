const events = {
  PLAYERS_DATAS: "playerUpdate",
  GET_READY: "getready",
  START_COUNTDOWNS: "startCountdown",

  FOR_NAVIGATION: "additionalEvent",
  HOST_CONNECTING: "hostConnect",
  HOST_START_QUIZ: "startQuiz",
  SEND_QUESTION_COUNTS: "quizDetail",
  SOCKET_CONNECTING: "connect",
  SEND_CLIENT_DATA: "clientConnect",
  QUESTIONS_RESPONSE: "playerSelectedCorrectAnswer",
  QUIZ_ROOM: "roomJoined",
  REST_RESPONSE_SIGNAL: "updateCountdown",
};

export default events;
