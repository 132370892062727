import React from "react";

interface CustomAlertProps {
  message: string;
  showAlert: boolean;
  setShowAlert: React.Dispatch<React.SetStateAction<boolean>>;
}

const CustomAlert: React.FC<CustomAlertProps> = ({
  message,
  showAlert,
  setShowAlert,
}) => {
  if (!showAlert) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 font-[Poppins] z-[99999]">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm mx-auto">
        <p className="mb-4">{message}</p>
        <div
          className="flex justify-center"
          onClick={() => setShowAlert(false)}
        >
          <button className=" bg-gradient-to-r from-cyan-400 to-purple-800  text-white px-4 py-2 rounded hover:bg-blue-700">
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomAlert;
