import instance from "src/http/httpClient";

//////// API Integration for Quiz Listing Catagories
export const fetchQuizCatagories = (): Promise<any> => {
  return instance
    .get<any>(`/host/category`)
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
    });
};

export const selectedQuizCatagoriesQuestions = (
  selectedCategory: string,
  searchTerm: string,
  page: number,
  limit: number
): Promise<any> => {
  const params: { [key: string]: any } = {};

  if (selectedCategory) {
    params.category_id = selectedCategory;
  }
  if (searchTerm) {
    params.searchTerm = searchTerm;
  }
  if (page) {
    params.page = page;
  }
  if (limit) {
    params.limit = limit;
  }

  return instance
    .get<any>("/host/questions", { params })
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
    });
};
