import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import image from "../../assets/playerHomeImg.svg";
import {
  getAvatar,
  playerCredential,
} from "src/services/playerHome/playerHome.services";
import LoaderComponent from "src/components/loader/LoaderComponent";
import Navbar from "src/components/navBar/NavBar";
import { Helmet } from "react-helmet";

interface ScrollButtonProps {
  direction: "left" | "right";
  onClick: () => void;
  disabled: boolean;
  style?: React.CSSProperties;
  hidden?: boolean;
  scrollDistance: {
    current: number;
    total: number;
  };
}

const ScrollButton: React.FC<ScrollButtonProps> = ({
  direction,
  onClick,
  disabled,
  style,
  hidden,
  scrollDistance,
}) => {
  const buttonText = direction === "left" ? "<<" : ">>";
  const isLeft = direction === "left";

  return (
    <button
      className={`absolute ${
        isLeft ? "left-0" : "right-0"
      } -translate-y-1/2 top-50% h-6 w-6 rounded-full ${
        disabled
          ? "bg-slate-800 text-white opacity-70"
          : "bg-white text-slate-400 pointer-events-auto"
      } shadow-md cursor-pointer hover:scale-110`}
      onClick={onClick}
      disabled={disabled}
      style={{
        ...style,
        visibility: hidden ? "hidden" : "visible",
      }}
    >
      {buttonText}
    </button>
  );
};

const JoinPlayer = () => {
  const [avatar, setAvatar] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [gameCode, setGameCode] = useState("");
  const [selectedAvatar, setSelectedAvatar] = useState<any>();
  const scrollDiv = useRef<HTMLDivElement>(null);
  const [scrollDistance, setScrollDistance] = useState<{
    current: number;
    total: number;
  }>({ current: 0, total: 0 });
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    getAvatar()
      .then((response) => {
        setSelectedAvatar(response[0]);
        setAvatar(response);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handleStartQuiz = () => {
    if (validateFields()) {
      const userAgent = navigator.userAgent.toLowerCase();
      const isMobile = /iphone|ipad|ipod|android|blackberry|windows phone/.test(
        userAgent
      );

      playerCredential(name, email, selectedAvatar?._id, gameCode, isMobile)
        .then((response) => {
          if (response) {
            sessionStorage.setItem("userInfo", JSON.stringify(response));
            sessionStorage.setItem("slug", JSON.stringify(gameCode));
            sessionStorage.setItem(
              "playerId",
              JSON.stringify(response.user._id)
            );
          }
          navigate(`/player/waiting?player_id=${response.user._id}`);
        })
        .catch((error) => {
          setShowPopup(true);
          setPopupMessage(error.response.data.message);
        });
    }
  };

  const validateFields = () => {
    if (!name.trim()) {
      setPopupMessage("Please enter your name.");
      setShowPopup(true);
      return false;
    }
    if (!email.trim()) {
      setPopupMessage("Please enter your email.");
      setShowPopup(true);
      return false;
    }
    if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      setPopupMessage("Please enter a valid email address.");
      setShowPopup(true);
      return false;
    }
    if (!gameCode.trim()) {
      setPopupMessage("Please enter the game code.");
      setShowPopup(true);
      return false;
    }
    return true;
  };

  const handleScroll = () => {
    if (scrollDiv.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollDiv.current;
      const totalScroll = scrollWidth - clientWidth;
      const currentScroll = scrollLeft;

      setScrollDistance({
        current: currentScroll,
        total: totalScroll,
      });
    }
  };

  useEffect(() => {
    const currentScrollDiv = scrollDiv.current;

    if (currentScrollDiv) {
      handleScroll();
      currentScrollDiv.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (currentScrollDiv) {
        currentScrollDiv.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  return (
    <main className="max-w-screen-2xl mx-auto w-full">
      <Helmet>
        <meta charSet="UTF-8" />
        <link rel="canonical" href="https://quizcircles.com/join-player" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <title>Player Join Quiz using Quiz Code</title>
        <meta name="title" content="Join Quiz using Quiz Code | Quiz Circles" />
        <meta
          name="description"
          content="Join the Quiz using Quiz Code with Quiz Circles."
        />
        <meta
          property="og:title"
          content="Join Quiz using Quiz Code | Quiz Circles"
        />
        <meta
          property="og:description"
          content="Join the Quiz using Quiz Code with Quiz Circles."
        />
        <meta property="og:url" content="https://quizcircles.com/join-player" />
      </Helmet>
      <Navbar hideItems={true} className="bg-[#192E3C]"/>
      <div className="bg-[#192E3C] w-[full] min-h-screen overflow-y-auto font-[Poppins]">
        {isLoading ? (
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50">
            <LoaderComponent />
          </div>
        ) : (
          <div className="md:flex justify-center px-[5%] ">
            <div className="text-[#95D6FF] text-[14px] font-normal  font-[Poppins]  px-[3%] lg:w-[50%] md:w-[85%] pt-[40px] leading-normal">
              <h2 className="text-[18px] font-semibold">Choose Avatar</h2>
              <br />

              <div className="lg:w-[400px] h-16 flex relative items-center">
                <div
                  className="flex gap-[20px] overflow-x-auto  justify-between items-start lg:w-[400px] no-scrollbar mb-[32px]  "
                  ref={scrollDiv}
                >
                  {avatar.map((data: any, index: number) => (
                    <img
                      key={index}
                      className={`w-16 h-16 rounded-[64px] cursor-pointer ${
                        selectedAvatar?._id === data?._id
                          ? "border-blue-300 border-[10px]"
                          : ""
                      }`}
                      src={data?.avatarUrl}
                      alt="avatar"
                      onClick={() => setSelectedAvatar(data)}
                    />
                  ))}
                </div>
                <ScrollButton
                  direction="left"
                  onClick={() => {
                    scrollDiv.current?.scrollBy({ left: -300 });
                  }}
                  disabled={false}
                  style={{ left: "-2rem" }}
                  hidden={scrollDistance.current === 0} // Pass scrollDistance as a prop
                  scrollDistance={scrollDistance} // Pass scrollDistance as a prop
                />
                <ScrollButton
                  direction="right"
                  onClick={() => {
                    scrollDiv.current?.scrollBy({ left: 300 });
                  }}
                  disabled={false}
                  style={{ right: "-2rem" }}
                  hidden={
                    scrollDistance.current +
                      (scrollDiv.current?.clientWidth ?? 0) >=
                      (scrollDiv.current?.scrollWidth ?? 0) || // Hide the right arrow when at the end or beyond
                    !scrollDiv.current // Hide the right arrow if scrollDiv is not yet initialized
                  }
                  scrollDistance={scrollDistance} // Pass scrollDistance as a prop
                />
              </div>
              <label className="text-[18px] font-semibold">Name</label>
              <br />

              <input
                id="name"
                value={name}
                placeholder="Enter Name"
                onChange={(e) => {
                  setName(e.target.value);
                }}
                required
                className="lg:w-[400px] w-full h-[56px] mb-[31.76px] mt-[13.18px] flex-shrink bg-[#233D4E] text-white rounded-[10px] px-[16px] py-[24px] gap-[8px]"
              />
              <br />
              <label className="text-[18px] font-semibold">Email</label>
              <br />
              <input
                id="email"
                value={email}
                placeholder="Enter Email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                required
                className={`lg:w-[400px] w-full h-[56px] flex-shrink mb-[25px] mt-[13.18px] bg-[#233D4E] text-white rounded-[10px] px-[16px] py-[24px] gap-[8px]`}
              />
              <br />
              <label className="text-[18px] font-semibold">Quiz Code</label>
              <br />
              <input
                id="QuizCode"
                value={gameCode}
                onChange={(e) => {
                  const value = e.target.value.slice(0, 6);
                  setGameCode(value);
                }}
                maxLength={6}
                placeholder="Enter Quiz Code"
                className="lg:w-[400px] w-full h-[56px] flex-shrink mb-[31.76px] mt-[13.18px] bg-[#233D4E] text-white rounded-[10px] px-[16px] py-[24px] gap-[8px]"
              />

              <button
                onClick={handleStartQuiz}
                className="flex md:mb-[120px] mb-[65px] flex-shrink rounded-[5.008px] md:gap-[10.016px] text-white bg-[#008BB7] lg:w-[400px] w-full h-[56px] px-[10.016px] py-[20.032px] justify-center flex-col items-center text-[18px] font-semibold"
              >
                Join Quiz
              </button>
            </div>

            <div className="hidden lg:flex justify-center items-center  ">
              <img
                className="w-[90%] h-[95%]"
                src={image}
                alt="Player home"
              ></img>
            </div>
          </div>
        )}
        {showPopup && (
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50 bg-black bg-opacity-50">
            <div className="lg:w-fit w-full h-auto min-h-[150px] flex flex-col md:max-w-[360px] min-w-[300px] px-2 md:px-4 bg-slate-50 rounded-lg shadow-lg py-2 items-center justify-center border border-slate-400">
              <div className="flex w-full items-center justify-center my-3 text-[16px] md:text-[18px] font-bold text-center text-black">
                {/* Display error message */}
                <span>{popupMessage}</span>
              </div>
              <div className="flex flex-row w-full justify-between gap-x-2">
                <button
                  className="bg-slate-800 hover:bg-slate-500 w-full py-2 rounded-full text-white"
                  onClick={() => {
                    // Close popup on button click
                    setShowPopup(false);
                  }}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </main>
  );
};

export default JoinPlayer;
